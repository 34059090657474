import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    POST_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/POST_RESOURCE_MANAGEMENT`,
    POST_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/POST_RESOURCE_MANAGEMENT_REQUEST`,
    POST_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/POST_RESOURCE_MANAGEMENT_SUCCESS`,
    POST_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/POST_RESOURCE_MANAGEMENT_FAILURE`,

    LIST_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT`,
    LIST_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_REQUEST`,
    LIST_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_SUCCESS`,
    LIST_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/LIST_RESOURCE_MANAGEMENT_FAILURE`,

    RESOURCE_MANAGEMENT_BY_ID: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_BY_ID`,
    RESOURCE_MANAGEMENT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_BY_ID_REQUEST`,
    RESOURCE_MANAGEMENT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_BY_ID_SUCCESS`,
    RESOURCE_MANAGEMENT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/RESOURCE_MANAGEMENT_BY_ID_FAILURE`,

    UPDATE_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT`,
    UPDATE_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT_REQUEST`,
    UPDATE_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT_SUCCESS`,
    UPDATE_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_MANAGEMENT_FAILURE`,

    DELETE_RESOURCE_MANAGEMENT: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT`,
    DELETE_RESOURCE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT_REQUEST`,
    DELETE_RESOURCE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT_SUCCESS`,
    DELETE_RESOURCE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/DELETE_RESOURCE_MANAGEMENT_FAILURE`,

    SELECT_MODULE_ID: `${STATE_REDUCER_KEY}/SELECT_MODULE_ID`,
    SELECT_MODULE_ID_REQUEST: `${STATE_REDUCER_KEY}/SELECT_MODULE_ID_REQUEST`,
    SELECT_MODULE_ID_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_MODULE_ID_SUCCESS`,
    SELECT_MODULE_ID_FAILURE: `${STATE_REDUCER_KEY}/SELECT_MODULE_ID_FAILURE`,

    SELECT_PARENT_ID: `${STATE_REDUCER_KEY}/SELECT_PARENT_ID`,
    SELECT_PARENT_ID_REQUEST: `${STATE_REDUCER_KEY}/SELECT_PARENT_ID_REQUEST`,
    SELECT_PARENT_ID_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_PARENT_ID_SUCCESS`,
    SELECT_PARENT_ID_FAILURE: `${STATE_REDUCER_KEY}/SELECT_PARENT_ID_FAILURE`

};

export const resourceManagementById = createAction(ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID);
export const submitResourceManagement = createAction(ACTION_TYPES.POST_RESOURCE_MANAGEMENT);
export const deleteResourceManagement = createAction(ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT);
export const updateResourceManagement = createAction(ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT);
export const listResourceManagement = createAction(ACTION_TYPES.LIST_RESOURCE_MANAGEMENT);
export const selectModuleId = createAction(ACTION_TYPES.SELECT_MODULE_ID);
export const selectParentId = createAction(ACTION_TYPES.SELECT_PARENT_ID);
