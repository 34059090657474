import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchDeviceListApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.GET_DEVICE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS_REQUEST, ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS_SUCCESS, ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS_FAILURE],
            data
        }
    };
};

export const saveImageApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.SAVE_IMAGE,
        method: REQUEST_METHOD.POST_FORMDATA,
        payload: {
            types: [ACTION_TYPES.SAVE_IMAGE_DETAILS_REQUEST, ACTION_TYPES.SAVE_IMAGE_DETAILS_SUCCESS, ACTION_TYPES.SAVE_IMAGE_DETAILS_FAILURE],
            data
        }
    };
};

export const getByImageApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.GET_BY_IMAGE.replace(":id", data?.mapId),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_BY_IMAGE_DETAILS_REQUEST, ACTION_TYPES.GET_BY_IMAGE_DETAILS_SUCCESS, ACTION_TYPES.GET_BY_IMAGE_DETAILS_FAILURE]
        }
    };
};

export const deleteMapApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_MAP_DETAILS_REQUEST, ACTION_TYPES.DELETE_MAP_DETAILS_SUCCESS, ACTION_TYPES.DELETE_MAP_DETAILS_FAILURE]
        }
    };
};

export const fetchMapListApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.GET_MAP,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_MAP_LIST_DETAILS_REQUEST, ACTION_TYPES.FETCH_MAP_LIST_DETAILS_SUCCESS, ACTION_TYPES.FETCH_MAP_LIST_DETAILS_FAILURE],
            data
        }
    };
};

export const fetchHotZoneMapApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.GET_HOT_ZONE_MAP.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS_REQUEST, ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS_SUCCESS, ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS_FAILURE]
        }
    };
};

export const patchDroppedDeviceApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.PATCH_DROPPED_DEVICE,
        method: REQUEST_METHOD.PATCH_FORMDATA,
        payload: {
            types: [ACTION_TYPES.PATCH_DROPPED_DEVICE_REQUEST, ACTION_TYPES.PATCH_DROPPED_DEVICE_SUCCESS, ACTION_TYPES.PATCH_DROPPED_DEVICE_FAILURE],
            data
        }
    };
};

export const patchHotZoneMapApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.PATCH_DROPPED_DEVICE,
        method: REQUEST_METHOD.PATCH_FORMDATA,
        payload: {
            types: [ACTION_TYPES.PATCH_HOT_ZONE_DETAILS_REQUEST, ACTION_TYPES.PATCH_HOT_ZONE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_HOT_ZONE_DETAILS_FAILURE],
            data
        }
    };
};

export const patchMapNameApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.PATCH_DROPPED_DEVICE,
        method: REQUEST_METHOD.PATCH_FORMDATA,
        payload: {
            types: [ACTION_TYPES.PATCH_MAP_NAME_REQUEST, ACTION_TYPES.PATCH_MAP_NAME_SUCCESS, ACTION_TYPES.PATCH_MAP_NAME_FAILURE],
            data
        }
    };
};

export const deleteDeviceApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.DELETE_DEVICE.replace(":emapId", data.emapId).replace(":deviceId", data.deviceId),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_DEVICE_DETAILS_REQUEST, ACTION_TYPES.DELETE_DEVICE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_DEVICE_DETAILS_FAILURE]
        }
    };
};

export const getByNameApi = (data) => {
    return {
        url: API_URL.ELECTRONIC_MAP.GET_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_MAP_NAME_REQUEST, ACTION_TYPES.GET_MAP_NAME_SUCCESS, ACTION_TYPES.GET_MAP_NAME_FAILURE]
        }
    };
};
