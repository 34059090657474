import _ from "lodash";
import { put, select, take } from "redux-saga/effects";
import { store } from "../app/store";
import { DEFAULT_TABLE_ID } from "../modules/common/constants";
import { getTableProps } from "../modules/common/selectors";
import { actions as commonActions } from "../modules/common/slice";
import { getPayloadData } from "./apiUtils";

/**
*  Set pagination , table data , in progress
 * @param  {Array} COMPARISON_TYPES ["ACTION_SUCCESS","ACTION_FAILURE"]
 * @param  {String} TABLE_ID
 * @returns {} void
 */

export function* setCommonTableData(COMPARISON_TYPES, TABLE_ID) {
    yield put(commonActions.setLoading({ key: TABLE_ID || DEFAULT_TABLE_ID, requestInProgress: true }));
    const responseAction = yield take(COMPARISON_TYPES);
    if (responseAction.type === COMPARISON_TYPES[0]) {
        let { pageNo, totalRecords, totalPages, content: data = [] } = getPayloadData(responseAction);
        yield put(commonActions.setPagination({ key: TABLE_ID || DEFAULT_TABLE_ID, pagination: { pageNo, totalRecords, totalPages } }));
        yield put(commonActions.setTableData({ key: TABLE_ID || DEFAULT_TABLE_ID, data }));
    } else if (responseAction.type === COMPARISON_TYPES[1]) {
        yield put(commonActions.setLoading({ key: TABLE_ID || DEFAULT_TABLE_ID, requestInProgress: false }));
    }
}

/**
*  Returns additionalFilters.
* @param {string} key to identify table.
* @returns {object} additionalFilters
*/

export function* getAdditionalFilters(key) {
    const tableProps = yield select(getTableProps);
    let additionalFilters = _.get(tableProps, `${key || DEFAULT_TABLE_ID}.additionalFilters`, {});
    return additionalFilters;
}

/**
*  Returns Selected row index and selected data.
* @param {string} key to identify table.
* @returns {object} { rowsSelected, selectedData }
*/

export function* getSelectedRows(key) {
    const tableProps = yield select(getTableProps);
    let rowsSelected = _.get(tableProps, `${key || DEFAULT_TABLE_ID}.rowSelected`, {});
    let data = _.get(tableProps, `${key || DEFAULT_TABLE_ID}.data`, []);
    let selectedData = [];
    for (const selectedIndex in rowsSelected) {
        selectedData.push(data[selectedIndex]);
    }
    return { rowsSelected, selectedData };
}

/**
*  Returns Pagination details.
* @param {string} key to identify table.
* @returns {object} { pageNo, pageSize, ...filters }
*/

export function* getPaginationPayload(key) {
    const tableProps = yield select(getTableProps);
    let tableDetails = _.get(tableProps, `${key || DEFAULT_TABLE_ID}`, {});
    const { pagination: { pageNo, pageSize }, filters = {} } = tableDetails;
    return { pageNo, pageSize, ...filters };
}

/**
*  Returns Pagination details to component without yield.
* @param {string} key to identify table.
* @returns {object} { pageNo, pageSize }
*/
export const getTablePagination = (key) => {
    let { common: { table = {} } = {} } = store.getState();
    let tableDetails = _.get(table, `${key || DEFAULT_TABLE_ID}`, {});
    const { pagination: { pageNo, pageSize } } = tableDetails;
    return { pageNo, pageSize };
};
