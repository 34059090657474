import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIVE_VIEW_BY_ID: `${STATE_REDUCER_KEY}/LIVE_VIEW_BY_ID`,
    LIVE_VIEW_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/LIVE_VIEW_BY_ID_REQUEST`,
    LIVE_VIEW_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/LIVE_VIEW_BY_ID_SUCCESS`,
    LIVE_VIEW_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/LIVE_VIEW_BY_ID_FAILURE`,

    GROUP_DEVICE_LIST: `${STATE_REDUCER_KEY}/GROUP_DEVICE_LIST`,
    GROUP_DEVICE_LIST_REQUEST: `${STATE_REDUCER_KEY}/GROUP_DEVICE_LIST_REQUEST`,
    GROUP_DEVICE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/GROUP_DEVICE_LIST_SUCCESS`,
    GROUP_DEVICE_LIST_FAILURE: `${STATE_REDUCER_KEY}/GROUP_DEVICE_LIST_FAILURE`,

    STOP_STREAMING: `${STATE_REDUCER_KEY}/STOP_STREAMING`,
    STOP_STREAMING_REQUEST: `${STATE_REDUCER_KEY}/STOP_STREAMING_REQUEST`,
    STOP_STREAMING_SUCCESS: `${STATE_REDUCER_KEY}/STOP_STREAMING_SUCCESS`,
    STOP_STREAMING_FAILURE: `${STATE_REDUCER_KEY}/STOP_STREAMING_FAILURE`,
    STOP_STREAMING_TAB: `${STATE_REDUCER_KEY}/STOP_STREAMING_TAB`,

    START_RECORDING: `${STATE_REDUCER_KEY}/START_RECORDING`,
    START_RECORDING_REQUEST: `${STATE_REDUCER_KEY}/START_RECORDING_REQUEST`,
    START_RECORDING_SUCCESS: `${STATE_REDUCER_KEY}/START_RECORDING_SUCCESS`,
    START_RECORDING_FAILURE: `${STATE_REDUCER_KEY}/START_RECORDING_FAILURE`,

    STOP_RECORDING: `${STATE_REDUCER_KEY}/STOP_RECORDING`,
    STOP_RECORDING_REQUEST: `${STATE_REDUCER_KEY}/STOP_RECORDING_REQUEST`,
    STOP_RECORDING_SUCCESS: `${STATE_REDUCER_KEY}/STOP_RECORDING_SUCCESS`,
    STOP_RECORDING_FAILURE: `${STATE_REDUCER_KEY}/STOP_RECORDING_FAILURE`,

    //Save view
    SAVE_VIEW: `${STATE_REDUCER_KEY}/SAVE_VIEW`,
    SAVE_VIEW_REQUEST: `${STATE_REDUCER_KEY}/SAVE_VIEW_REQUEST`,
    SAVE_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_VIEW_SUCCESS`,
    SAVE_VIEW_FAILURE: `${STATE_REDUCER_KEY}/SAVE_VIEW_FAILURE`,

    LIST_VIEW: `${STATE_REDUCER_KEY}/LIST_VIEW`,
    LIST_VIEW_REQUEST: `${STATE_REDUCER_KEY}/LIST_VIEW_REQUEST`,
    LIST_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/LIST_VIEW_SUCCESS`,
    LIST_VIEW_FAILURE: `${STATE_REDUCER_KEY}/LIST_VIEW_FAILURE`,

    EDIT_VIEW_BY_ID: `${STATE_REDUCER_KEY}/EDIT_VIEW_BY_ID`,
    EDIT_VIEW_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/EDIT_VIEW_BY_ID_REQUEST`,
    EDIT_VIEW_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_VIEW_BY_ID_SUCCESS`,
    EDIT_VIEW_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/EDIT_VIEW_BY_ID_FAILURE`,

    UPDATE_VIEW: `${STATE_REDUCER_KEY}/UPDATE_VIEW`,
    UPDATE_VIEW_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_VIEW_REQUEST`,
    UPDATE_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_VIEW_SUCCESS`,
    UPDATE_VIEW_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_VIEW_FAILURE`,

    DELETE_VIEW: `${STATE_REDUCER_KEY}/DELETE_VIEW`,
    DELETE_VIEW_REQUEST: `${STATE_REDUCER_KEY}/DELETE_VIEW_REQUEST`,
    DELETE_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_VIEW_SUCCESS`,
    DELETE_VIEW_FAILURE: `${STATE_REDUCER_KEY}/DELETE_VIEW_FAILURE`,

    MOVE_UP_VIEW: `${STATE_REDUCER_KEY}/MOVE_UP_VIEW`,
    MOVE_UP_VIEW_REQUEST: `${STATE_REDUCER_KEY}/MOVE_UP_VIEW_REQUEST`,
    MOVE_UP_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/MOVE_UP_VIEW_SUCCESS`,
    MOVE_UP_VIEW_FAILURE: `${STATE_REDUCER_KEY}/MOVE_UP_VIEW_FAILURE`,

    MOVE_DOWN_VIEW: `${STATE_REDUCER_KEY}/MOVE_DOWN_VIEW`,
    MOVE_DOWN_VIEW_REQUEST: `${STATE_REDUCER_KEY}/MOVE_DOWN_VIEW_REQUEST`,
    MOVE_DOWN_VIEW_SUCCESS: `${STATE_REDUCER_KEY}/MOVE_DOWN_VIEW_SUCCESS`,
    MOVE_DOWN_VIEW_FAILURE: `${STATE_REDUCER_KEY}/MOVE_DOWN_VIEW_FAILURE`,

    VIEW_LIST: `${STATE_REDUCER_KEY}/VIEW_LIST`,
    VIEW_LIST_REQUEST: `${STATE_REDUCER_KEY}/VIEW_LIST_REQUEST`,
    VIEW_LIST_SUCCESS: `${STATE_REDUCER_KEY}/VIEW_LIST_SUCCESS`,
    VIEW_LIST_FAILURE: `${STATE_REDUCER_KEY}/VIEW_LIST_FAILURE`,

    VIEW_LIST_BY_ID: `${STATE_REDUCER_KEY}/VIEW_LIST_BY_ID`,
    VIEW_LIST_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/VIEW_LIST_BY_ID_REQUEST`,
    VIEW_LIST_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/VIEW_LIST_BY_ID_SUCCESS`,
    VIEW_LIST_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/VIEW_LIST_BY_ID_FAILURE`,

    VIEW_DELETE: `${STATE_REDUCER_KEY}/VIEW_DELETE`,
    VIEW_DELETE_REQUEST: `${STATE_REDUCER_KEY}/VIEW_DELETE_REQUEST`,
    VIEW_DELETE_SUCCESS: `${STATE_REDUCER_KEY}/VIEW_DELETE_SUCCESS`,
    VIEW_DELETE_FAILURE: `${STATE_REDUCER_KEY}/VIEW_DELETE_FAILURE`,

    GET_STREAM_SWITCH: `${STATE_REDUCER_KEY}/GET_STREAM_SWITCH`,
    GET_STREAM_SWITCH_REQUEST: `${STATE_REDUCER_KEY}/GET_STREAM_SWITCH_REQUEST`,
    GET_STREAM_SWITCH_SUCCESS: `${STATE_REDUCER_KEY}/GET_STREAM_SWITCH_SUCCESS`,
    GET_STREAM_SWITCH_FAILURE: `${STATE_REDUCER_KEY}/GET_STREAM_SWITCH_FAILURE`

};

export const liveViewById = createAction(ACTION_TYPES.LIVE_VIEW_BY_ID);
export const fetchGroupDeviceList = createAction(ACTION_TYPES.GROUP_DEVICE_LIST);
export const stopStreaming = createAction(ACTION_TYPES.STOP_STREAMING);
export const startRecording = createAction(ACTION_TYPES.START_RECORDING);
export const stopRecording = createAction(ACTION_TYPES.STOP_RECORDING);
export const saveView = createAction(ACTION_TYPES.SAVE_VIEW);
export const listView = createAction(ACTION_TYPES.LIST_VIEW);
export const editViewById = createAction(ACTION_TYPES.EDIT_VIEW_BY_ID);
export const updateView = createAction(ACTION_TYPES.UPDATE_VIEW);
export const deleteView = createAction(ACTION_TYPES.DELETE_VIEW);
export const moveUpView = createAction(ACTION_TYPES.MOVE_UP_VIEW);
export const moveDownView = createAction(ACTION_TYPES.MOVE_DOWN_VIEW);
export const viewList = createAction(ACTION_TYPES.VIEW_LIST);
export const viewListById = createAction(ACTION_TYPES.VIEW_LIST_BY_ID);
export const viewDelete = createAction(ACTION_TYPES.VIEW_DELETE);
export const getStreamSwitch = createAction(ACTION_TYPES.GET_STREAM_SWITCH);
export const stopStreamingTab = createAction(ACTION_TYPES.STOP_STREAMING_TAB);
