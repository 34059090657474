import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getUser = (state) => state[STATE_REDUCER_KEY];

const userDetails = (user) => user.userDetails.data;
export const getUserDetails = flow(getUser, userDetails);

const webSocketDetails = (user) => user?.socket;
export const getWebSocketDetails = flow(getUser, webSocketDetails);

const pingIntervalDetails = (user) => user?.pingInterval;
export const getPingIntervalDetails = flow(getUser, pingIntervalDetails);

const changePasswordForm = (user) => user?.changePassword?.data;
export const getChangePasswordForm = flow(getUser, changePasswordForm);

const updatePasswordForm = (user) => user.updatePassword.data;
export const getUpdatePasswordForm = flow(getUser, updatePasswordForm);

const userId = (user) => user.userId;
export const getUserId = flow(getUser, userId);

const forgetPasswordForm = (user) => user.forgetPassword.data;
export const getForgetPassword = flow(getUser, forgetPasswordForm);
