/* eslint-disable camelcase */
export default {
    welcome: "സ്വാഗതം",
    home: "ഹോം",
    login: "ലോഗിൻ ",
    lets_go: "Let's Go",
    try_again: "വീണ്ടും ശ്രമിക്കുക",
    something_went_wrong: "എന്തോ കുഴപ്പം സംഭവിച്ചു",
    copyright: "പകർപ്പവകാശം",
    privacy_policy: "സ്വകാര്യതാ നയം",
    terms_of_service: "സേവന നിബന്ധനകൾ",
    cpu_management: "സിപിയു മാനേജ്മെൻ്റ്",
    about_us: "ഞങ്ങളേക്കുറിച്ച്",
    settings: "ക്രമീകരണങ്ങൾ",
    user_name: "ഉപയോക്തൃനാമം",
    password: "പാസ്വേഡ്",
    is_required: "{{type}} ആവശ്യമാണ്",
    device: "ഉപകരണം",
    live: "ലൈവ്",
    alarm: "അലാറം",
    logout: "പുറത്തുകടക്കുക",
    profile: "പ്രൊഫൈൽ",
    malayalam: "മലയാളം",
    english: "ഇംഗ്ലീഷ്",
    english_uk: "ഇംഗ്ലീഷ് (UK)",
    english_us: "ഇംഗ്ലീഷ് (US)",
    language: "ഭാഷ",
    successfully: "വിജയകരമായി",
    logged: "ലോഗിൻ ചെയ്തു",

    //home module
    common_functions: "പൊതുവായ പ്രവർത്തനങ്ങൾ",
    favourites: "പ്രിയപ്പെട്ടവ",
    basic_functions: "അടിസ്ഥാന പ്രവർത്തനങ്ങൾ",
    electronic_map: "ഇലക്ട്രോണിക് മാപ്പ്",
    management_and_operation_function_of_electronic_map: "ഇലക്ട്രോണിക് മാപ്പിൻ്റെ മാനേജ്മെൻ്റും പ്രവർത്തന പ്രവർത്തനവും.",
    tv_wall_configuration_preview_offline_switch_settings: "ടിവി വാൾ കോൺഫിഗറേഷൻ, പ്രിവ്യൂ, ഓഫ്‌ലൈൻ സ്വിച്ച് ക്രമീകരണങ്ങൾ.",
    tv_wall: "ടിവി വാൾ",
    alarm_control: "അലാറം നിയന്ത്രണം",
    real_time_indication_of_unit_alarm_message_ti_facilitate_access_ti_unit_exception: "യൂണിറ്റ് അലാറം സന്ദേശത്തിൻ്റെ തത്സമയ സൂചന ടി യൂണിറ്റ് ഒഴിവാക്കൽ ആക്സസ് സുഗമമാക്കുന്നു.",
    typical_operation_on_client_onfiguration: "ക്ലയൻ്റ് കോൺഫിഗറേഷനിലെ സാധാരണ പ്രവർത്തനം.",
    system_setup: "സിസ്റ്റം സജ്ജീകരണം",
    add_modify_and_delete_groups: "ഗ്രൂപ്പുകൾ ചേർക്കുക, പരിഷ്ക്കരിക്കുക, ഇല്ലാതാക്കുക.",
    device_administration: "ഉപകരണ അഡ്മിനിസ്ട്രേഷൻ",
    search_and_play_the_recording_file_at_the_monitoring_point: "മോണിറ്ററിംഗ് പോയിൻ്റിൽ റെക്കോർഡിംഗ് ഫയൽ തിരയുക, പ്ലേ ചെയ്യുക.",
    playback: "പ്ലേബാക്ക്",
    view_the_live_images_at_the_monitoring_point: "മോണിറ്ററിംഗ് പോയിൻ്റിൽ തത്സമയ ചിത്രങ്ങൾ കാണുക.",
    alarm_configuration: "അലാറം കോൺഫിഗറേഷൻ",
    operation_logs: "ഓപ്പറേഷൻ ലോഗുകൾ",
    user_managemet: "ഉപയോക്തൃ മാനേജ്മെൻ്റ്",

    //device module
    name: "പേര്",
    group: "ഗ്രൂപ്പ്",
    device_type: "ഉപകരണ തരം",
    device_model: "ഉപകരണ മോഡൽ",
    ip_address: "IP വിലാസം",
    port: " പോർട്ട്",
    video_channel: "വീഡിയോ ചാനൽ",
    connection: "കണക്ഷൻ",
    operation: "ഓപ്പറേഷൻ",
    encode_device: "ഉപകരണം എൻകോഡ് ചെയ്യുക",
    decoding_device: "ഡീകോഡിംഗ് ഉപകരണം",
    refresh: "പുതുക്കുക",
    auto_search: "ഓട്ടോ തിരയൽ",
    add: "ചേർക്കുക",
    edit: "എഡിറ്റ് ചെയ്യുക",
    delete: "ഇല്ലാതാക്കുക",
    invert_selection: "വിപരീത തിരഞ്ഞെടുപ്പ്",
    add_mode: "മോഡ് ചേർക്കുക",
    device_name: "ഉപകരണത്തിന്റെ പേര്",
    select_protocol: "പ്രോട്ടോക്കോൾ തിരഞ്ഞെടുക്കുക",
    device_ip: "ഉപകരണ ഐ.പി",
    alarm_input: "അലാറം ഇൻപുട്ട്",
    alarm_output: "അലാറം ഔട്ട്പുട്ട്",
    add_device: "ഉപകരണം ചേർക്കുക",
    user_names: "ഉപയോക്തൃ നാമം",
    off_line_add: "ഓഫ്-ലൈൻ ചേർക്കുക",
    import_to_group: "ഗ്രൂപ്പിലേക്ക് ഇറക്കുമതി ചെയ്യുക",
    cancel: "റദ്ദാക്കുക",
    please_enter_device_name: "ഉപകരണത്തിൻ്റെ പേര് നൽകുക",
    please_enter_device_model: "ദയവായി ഉപകരണ മോഡൽ നൽകുക",
    please_enter_device_ip: "ദയവായി ഉപകരണ IP നൽകുക",
    please_enter_user_name: "ദയവായി ഉപയോക്തൃനാമം നൽകുക",

    //system setup
    common_settings: "പൊതുവായ ക്രമീകരണങ്ങൾ",
    video_settings: "വീഡിയോ ക്രമീകരണങ്ങൾ",
    alarm_grade: "അലാറം ഗ്രേഡ്",
    storage_management: "സ്റ്റോറേജ് മാനേജ്മെൻ്റ്",
    record_plan: "റെക്കോർഡ് പ്ലാൻ",
    p2p_settings: "P2P ക്രമീകരണങ്ങൾ",
    restore_default: "നേരത്തെയുള്ളത് പുനസ്ഥാപിക്കുക",
    save: "സേവ്",
    login_settings: "ലോഗിൻ ക്രമീകരണങ്ങൾ",
    automatic_login_software: "ഓട്ടോമാറ്റിക് ലോഗിൻ സോഫ്റ്റ്‌വെയർ",
    browse: "ബ്രൗസ്",
    path_settings: "പാത്ത് ക്രമീകരണങ്ങൾ",
    save_path_of_capture_photo: "ഫോട്ടോ എടുക്കുന്നതിനുള്ള പാത്ത് സംരക്ഷിക്കുക",
    video_download_save_path: "വീഡിയോ ഡൗൺലോഡ് സേവ് പാത്ത്",
    system: "സിസ്റ്റം",
    skin: "സ്കിൻ",
    data_backup: "ഡാറ്റ ബാക്കപ്പ്",
    database_backup: "ഡാറ്റാബേസ് ബാക്കപ്പ്",
    backup: "ബാക്കപ്പ്",
    recover: "വീണ്ടെടുക്കുക",
    reset: "പുനഃസജ്ജമാക്കുക",
    re_index: "റീ സൂചിക",
    output: "ഔട്ട്പുട്ട്",
    import: "ഇറക്കുമതി ചെയ്യുക",
    log_settings: "ലോഗ് ക്രമീകരണങ്ങൾ",
    period_of_keeping_operation_log: "പ്രവർത്തനരേഖ സൂക്ഷിക്കുന്ന കാലയളവ്",
    period_of_keeping_alarm_log: "അലാറം ലോഗ് സൂക്ഷിക്കുന്ന കാലയളവ്",
    automatically_restore_the_state_last_time_you_exit_from_the_system: "നിങ്ങൾ സിസ്റ്റത്തിൽ നിന്ന് അവസാനമായി പുറത്തുകടക്കുമ്പോൾ അവസ്ഥ യാന്ത്രികമായി പുനഃസ്ഥാപിക്കുക",
    switch_between_main_and_s: "മെയിനിനും എസ്സിനും ഇടയിൽ മാറുക",
    automatic_switch_screen_number: "ഓട്ടോമാറ്റിക് സ്വിച്ച് സ്ക്രീൻ നമ്പർ",
    mannual_default_connection: "മാനുവൽ ഡിഫോൾട്ട് കണക്ഷൻ",
    play_property_setting: "പ്ലേ പ്രോപ്പർട്ടി ക്രമീകരണം",
    decoding_mode_setting: "ഡീകോഡിംഗ് മോഡ് ക്രമീകരണം",
    video_download_format: "വീഡിയോ ഡൗൺലോഡ് ഫോർമാറ്റ്",
    preview_frame_rate: "പ്രിവ്യൂ ഫ്രെയിം റേറ്റ്",
    instant_playback_time: "തൽക്ഷണ പ്ലേബാക്ക് സമയം",
    region_selection: "മേഖല തിരഞ്ഞെടുക്കൽ",
    continuous_recording_time: "തുടർച്ചയായ റെക്കോർഡിംഗ് സമയം",
    recording_format: "റെക്കോർഡിംഗ് ഫോർമാറ്റ്",
    type_of_record: "ടൈപ്പ് ഓഫ് റെക്കോർഡ്",
    min_desk_space: "മിനി. ഡെസ്ക് സ്പേസ്",
    percentage: "ശതമാനം",
    disk_full: "ഡിസ്ക് ഫുൾ",
    location: "സ്ഥാനം",
    total_size_g: "ആകെ വലിപ്പം(G)",
    available_space_g: "ലഭ്യമായ സ്ഥലം(G)",
    resource_management: "റിസോഴ്സ് മാനേജ്മെൻ്റ്",
    role_management: "റോൾ മാനേജ്മെൻ്റ്",
    resource_id: "റിസോഴ്സ് ഐഡി",
    module: "മൊഡ്യൂൾ",
    description: "വിവരണം",
    parent: "പാരന്റ്",
    group_type: "ഗ്രൂപ്പ് ടൈപ്പ്",
    is_parent: "ഈസ് പാരന്റ്",
    show_checkbox: "ചെക്ക്ബോക്സ് കാണിക്കുക",
    id: "ഐഡി",
    role_name: "റോളിൻ്റെ പേര്",
    role_level: "റോൾ ലെവൽ",
    add_and_manage_resources: "വിഭവങ്ങൾ ചേർക്കുക, കൈകാര്യം ചെയ്യുക.",
    assign_permission_to_the_required_roles: "ആവശ്യമായ റോളുകൾക്ക് അനുമതി നൽകുക.",
    user_management: "ഉപയോക്തൃ മാനേജ്മെൻ്റ്",
    user_level: "ഉപയോക്തൃ നില",
    validity: "സാധുത",
    confirm_password: "പാസ്വേഡ് സ്ഥിരീകരിക്കുക",
    add_user: "ഉപയോക്താവിനെ ചേർക്കുക",
    user_type: "ഉപയോക്തൃ ടൈപ്പ്",
    login_id: "ലോഗിൻ ഐഡി",
    designation: "പദവി",
    email: "ഇമെയിൽ",
    mobile_no: "മൊബൈൽ നമ്പർ",
    update_user: "ഉപയോക്താവിനെ അപ്ഡേറ്റ് ചെയ്യുക",
    are_sure_want_delete: " ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
    update: "അപ്ഡേറ്റ് ചെയ്യുക",
    password_mismatch: "പാസ്‌വേഡ് പൊരുത്തക്കേട്",
    must_be: "{{ type }} കുറഞ്ഞത് {{ count }} നീളം ഉണ്ടായിരിക്കണം",
    updated: "{{type}} വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു",
    delete_success: "{{type}} വിജയകരമായി ഇല്ലാതാക്കി",
    please_choose_option: "ദയവായി ഒരു ഓപ്ഷൻ തിരഞ്ഞെടുക്കുക",
    live_view: "തത്സമയ കാഴ്ച",
    select: " തിരഞ്ഞെടുക്കുക",
    advanced_functions: "വിപുലമായ പ്രവർത്തനങ്ങൾ",
    operations: "പ്രവർത്തനങ്ങൾ",
    device_list: "ഉപകരണ ലിസ്റ്റ്",
    view: "കാണുക",
    device_mode: "ഉപകരണ മോഡ്",
    update_device: "ഉപകരണം അപ്ഡേറ്റ് ചെയ്യുക",
    invalid_type: "അസാധുവായ തരം",
    success: "വിജയം",
    created: "{{type}} വിജയകരമായി സൃഷ്ടിച്ചു",
    test: "ടെസ്റ്റ്",
    offline: "ഓഫ്‌ലൈൻ",
    video_loss_alarm: "വീഡിയോ നഷ്‌ട അലാറം",
    alarm_input_alarm: "അലാറം ഇൻപുട്ട് അലാറം",
    audio_loss_alarm: "ഓഡിയോ ലോസ് അലാറം",
    disk_absence_on_until: " ഒഴിവാക്കുന്നത് വരെ ഡിസ്കിൻ്റെ അഭാവം ഓണാണ്",
    recording_exception_on: "വരെ റെക്കോർഡിംഗ് ഒഴിവാക്കൽ ഓണാണ്",
    containing_capacity: " കപ്പാസിറ്റി കവിയുന്നു",
    sharply_increasing: "ശേഷി കുത്തനെ വർദ്ധിപ്പിക്കുന്നു",
    grade: "{{value}} - ഗ്രേഡ്",
    color_mode: "വർണ്ണ മോഡ്",
    customer_device_list: "ഉപഭോക്തൃ ഉപകരണ ലിസ്റ്റ്",
    enable_local_recoding_schedule: "പ്രാദേശിക റീകോഡിംഗ് ഷെഡ്യൂൾ പ്രവർത്തനക്ഷമമാക്കുക",
    channel_name: "ചാനലിൻ്റെ പേര് : ",
    main_stream: "പ്രധാന സ്ട്രീം",
    code_stream: "കോഡ് സ്ട്രീം",
    time_template: "സമയ ടെംപ്ലേറ്റ്",
    full_cycle_template: "മുഴുവൻ സൈക്കിൾ ടെംപ്ലേറ്റ്",
    copy_to: "ഇതിലേക്ക് പകർത്തുക",
    all: "എല്ലാം",
    ok: "ശരി",
    period: "കാലഘട്ടം",
    regular: "പതിവ്",
    motion: "ചലനം",

    //Alarm Control
    real_time_alarm: "തത്സമയ അലാറം",
    history_alarm: "ചരിത്ര അലാറം",
    time_of_alarm: "അലാറത്തിൻ്റെ സമയം",
    source_of_alarm: "അലാറത്തിൻ്റെ ഉറവിടം",
    alarm_type: "അലാറം തരം",
    alarm_status: "അലാറം നില",
    preview: "പ്രിവ്യൂ",
    comment: "അഭിപ്രായം",
    check: "ചെക്ക്",
    filter_level: "ഫിൽട്ടർ ലെവൽ",
    select_all: "എല്ലാം തിരഞ്ഞെടുക്കുക",
    grade_1: "ഗ്രേഡ് 1",
    grade_2: "ഗ്രേഡ് 2",
    grade_3: "ഗ്രേഡ് 3",
    grade_4: "ഗ്രേഡ് 4",
    grade_5: "ഗ്രേഡ് 5",
    user_check: "ഉപയോക്തൃ പരിശോധന",
    status_check: "സ്റ്റാറ്റസ് ചെക്ക്",
    time_check: "സമയ പരിശോധന",
    time: "സമയം",
    query: "ചോദ്യം"

};
