import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { I18n } from "../../../common/components";
import { successNotify } from "../../../utils/notificationUtils";
import { TABLE_IDS } from "../../../common/constants";
import { setCommonTableData } from "../../../utils/tableUtils";
import { getTableProps } from "../../common/selectors";
import { actions as commonActions } from "../../common/slice";
import { getActiveRoleResourceList, getDataAccessMappings } from "./selector";
import { actions as sliceActions } from "./slice";

export function* userLevelDropDown(action) {
    yield call(handleAPIRequest, Api.userLevelDropDown, action?.payload);
}

export function* listUsers({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.USERS_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listUsersDetails, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_USERS_SUCCESS, ACTION_TYPES.LIST_USERS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.USERS_LIST);
}

const formatValues = (values = {}) => {
    if (values.userValidity) {
        _.set(values, "userValidity", Date.parse(values?.userValidity));
    }
    // let roles = [{ id: values.roles.id, name: values.roles.name }];
    // if (values.roles) {
    //     _.set(values, "roles", roles);
    // }
    // _.unset(values, "confirmPassword");
    return values;
};

export function* submitUserDetailsForm({ payload }) {
    let { values: { roles, ...rest }, resetForm } = payload;
    const activeRoleResourceList = yield select(getActiveRoleResourceList);
    const dataAccessMappings = yield select(getDataAccessMappings);
    let updatedRestValues = { ...rest };
    delete updatedRestValues.confirmPassword;
    const newPayload = formatValues(updatedRestValues);
    let roleUpdatedPayload = { ...newPayload, roles: [roles] };
    const updatedPayload = { ...roleUpdatedPayload, activeRoleResourceList, dataAccessMappings };
    yield fork(handleAPIRequest, Api.submitUserDetails, { ...updatedPayload });
    const responseAction = yield take([ACTION_TYPES.POST_USER_DETAILS_SUCCESS, ACTION_TYPES.POST_USER_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_USER_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("user") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        yield put(sliceActions.clear());
        resetForm();
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listUsers, { payload: tablePayload });
        yield put(commonActions.setOpenModal(false));
    }
}

export function* updateUserDetailsForm({ payload }) {
    const activeRoleResourceList = yield select(getActiveRoleResourceList);
    const dataAccessMappings = yield select(getDataAccessMappings);
    const newPayload = formatValues(payload);
    const updatedPayload = { ...newPayload, activeRoleResourceList, dataAccessMappings };
    let newUpdatedPayload = _.omit(updatedPayload, "activeRoleResourceMappingList");
    yield fork(handleAPIRequest, Api.updateUserDetails, { ...newUpdatedPayload });
    const responseAction = yield take([ACTION_TYPES.UPDATE_USER_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_USER_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_USER_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("user") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listUsers, { payload: tablePayload });
        yield put(commonActions.setOpenModal(false));
    }
}

export function* userDetailsById({ payload: id }) {
    yield call(handleAPIRequest, Api.userDetailsById, id);
}

export function* deleteUserDetails({ payload: id }) {
    yield fork(handleAPIRequest, Api.deleteUserDetails, id);
    const response = yield take([ACTION_TYPES.DELETE_USER_DETAILS_SUCCESS, ACTION_TYPES.DELETE_USER_DETAILS_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_USER_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("user") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listUsers, { payload: tablePayload });
    }
}

export function* checkedResource({ payload }) {
    yield call(handleAPIRequest, Api.checkedResourcesData, payload);
}

export default function* userSaga() {
    yield all([
        takeLatest(ACTION_TYPES.SELECT_USER_ROLES, userLevelDropDown),
        takeLatest(ACTION_TYPES.POST_USER_DETAILS, submitUserDetailsForm),
        takeLatest(ACTION_TYPES.UPDATE_USER_DETAILS, updateUserDetailsForm),
        takeLatest(ACTION_TYPES.LIST_USERS, listUsers),
        takeLatest(ACTION_TYPES.USER_DETAILS_BY_ID, userDetailsById),
        takeLatest(ACTION_TYPES.DELETE_USER_DETAILS, deleteUserDetails),
        takeLatest(ACTION_TYPES.CHECKED_RESOURCE_DATA, checkedResource)
    ]);
}
