import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listDeviceDetails = (data) => ({
    url: API_URL.DEVICE.ENCODE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_DEVICE_REQUEST, ACTION_TYPES.LIST_DEVICE_SUCCESS, ACTION_TYPES.LIST_DEVICE_FAILURE],
        data
    }
});

export const addModeDropDown = () => {
    return {
        url: API_URL.DEVICE.DEVICE_MODE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_DEVICE_MODE_REQUEST, ACTION_TYPES.SELECT_DEVICE_MODE_SUCCESS, ACTION_TYPES.SELECT_DEVICE_MODE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const deviceTypeDropDown = () => {
    return {
        url: API_URL.DEVICE.DEVICE_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_DEVICE_TYPE_REQUEST, ACTION_TYPES.SELECT_DEVICE_TYPE_SUCCESS, ACTION_TYPES.SELECT_DEVICE_TYPE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const deviceProtocolDropDown = () => {
    return {
        url: API_URL.DEVICE.DEVICE_PROTOCOL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_DEVICE_PROTOCOL_REQUEST, ACTION_TYPES.SELECT_DEVICE_PROTOCOL_SUCCESS, ACTION_TYPES.SELECT_DEVICE_PROTOCOL_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const submitDeviceDetails = (data) => {
    return {
        url: API_URL.DEVICE.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_DEVICE_DETAILS_REQUEST, ACTION_TYPES.POST_DEVICE_DETAILS_SUCCESS, ACTION_TYPES.POST_DEVICE_DETAILS_FAILURE],
            data
        }
    };
};

export const updateDeviceDetails = (data) => {
    return {
        url: API_URL.DEVICE.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_DEVICE_DETAILS_REQUEST, ACTION_TYPES.UPDATE_DEVICE_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_DEVICE_DETAILS_FAILURE],
            data
        }
    };
};

export const deviceDetailsById = (id) => {
    return {
        url: API_URL.DEVICE.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.DEVICE_GET_BY_ID_REQUEST, ACTION_TYPES.DEVICE_GET_BY_ID_SUCCESS, ACTION_TYPES.DEVICE_GET_BY_ID_FAILURE]
        }
    };
};

export const deleteDeviceDetails = (id) => {
    return {
        url: `${API_URL.DEVICE.DELETE}?deviceIds=${id}`,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_DEVICE_REQUEST, ACTION_TYPES.DELETE_DEVICE_SUCCESS, ACTION_TYPES.DELETE_DEVICE_FAILURE]
        }
    };
};

export const deleteDecodeDeviceDetails = (id) => {
    return {
        url: `${API_URL.DEVICE.DELETE}?deviceIds=${id}`,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_DECODE_DEVICE_REQUEST, ACTION_TYPES.DELETE_DECODE_DEVICE_SUCCESS, ACTION_TYPES.DELETE_DECODE_DEVICE_FAILURE]
        }
    };
};

export const listDecodeDeviceDetails = (data) => ({
    url: API_URL.DEVICE.DECODE_LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_DECODE_DEVICE_REQUEST, ACTION_TYPES.LIST_DECODE_DEVICE_SUCCESS, ACTION_TYPES.LIST_DECODE_DEVICE_FAILURE],
        data
    }
});

export const listCustomizedDetails = (data) => ({
    url: API_URL.GROUP.CUSTOMIZED,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_CUSTOMIZED_REQUEST, ACTION_TYPES.LIST_CUSTOMIZED_SUCCESS, ACTION_TYPES.LIST_CUSTOMIZED_FAILURE],
        data
    }
});

export const submitDeviceGroupDetails = (data) => {
    return {
        url: API_URL.GROUP.SAVE_GROUP,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_DEVICE_GROUP_DETAILS_REQUEST, ACTION_TYPES.POST_DEVICE_GROUP_DETAILS_SUCCESS, ACTION_TYPES.POST_DEVICE_GROUP_DETAILS_FAILURE],
            data
        }
    };
};

export const updateDeviceGroupDetails = (data) => {
    return {
        url: API_URL.GROUP.SAVE_GROUP,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS_REQUEST, ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS_FAILURE],
            data
        }
    };
};

export const listDeviceAll = (data) => ({
    url: API_URL.GROUP.DEVICE_ALL,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_DEVICE_ALL_REQUEST, ACTION_TYPES.LIST_DEVICE_ALL_SUCCESS, ACTION_TYPES.LIST_DEVICE_ALL_FAILURE],
        data
    }
});

export const importGroupDetails = (data) => {
    return {
        url: API_URL.GROUP.IMPORT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.IMPORT_GROUP_REQUEST, ACTION_TYPES.IMPORT_GROUP_SUCCESS, ACTION_TYPES.IMPORT_GROUP_FAILURE],
            data
        }
    };
};

export const exportGroupDetails = (data) => {
    return {
        url: API_URL.GROUP.IMPORT,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.EXPORT_GROUP_REQUEST, ACTION_TYPES.EXPORT_GROUP_SUCCESS, ACTION_TYPES.EXPORT_GROUP_FAILURE],
            data
        }
    };
};

export const moveUpwardGroupDetails = (id) => {
    return {
        url: API_URL.GROUP.MOVE_UPWARD.replace(":id", id),
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.MOVE_UPWARD_REQUEST, ACTION_TYPES.MOVE_UPWARD_SUCCESS, ACTION_TYPES.MOVE_UPWARD_FAILURE]
        }
    };
};

export const moveDownGroupDetails = (id) => {
    return {
        url: API_URL.GROUP.MOVE_DOWN.replace(":id", id),
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.MOVE_DOWN_REQUEST, ACTION_TYPES.MOVE_DOWN_SUCCESS, ACTION_TYPES.MOVE_DOWN_FAILURE]
        }
    };
};

export const deviceGroupDetailsById = (id) => {
    return {
        url: API_URL.GROUP.GROUP_BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.DEVICE_GROUP_GET_BY_ID_REQUEST, ACTION_TYPES.DEVICE_GROUP_GET_BY_ID_SUCCESS, ACTION_TYPES.DEVICE_GROUP_GET_BY_ID_FAILURE]
        }
    };
};

export const deleteGroupDetails = (id) => {
    return {
        url: API_URL.GROUP.DELETE.replace(":id", id),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_GROUP_REQUEST, ACTION_TYPES.DELETE_GROUP_SUCCESS, ACTION_TYPES.DELETE_GROUP_FAILURE]
        }
    };
};

export const listAutoSearchData = () => ({
    url: API_URL.DEVICE.AUTO_SEARCH,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.AUTO_SEARCH_LIST_REQUEST, ACTION_TYPES.AUTO_SEARCH_LIST_SUCCESS, ACTION_TYPES.AUTO_SEARCH_LIST_FAILURE]
    }
});

export const countOnlineOfflineDevice = () => ({
    url: API_URL.DEVICE.ONLINE_OFFLINE_DEVICES,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES_REQUEST, ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES_SUCCESS, ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES_FAILURE]
    }
});

export const deviceAddViaAutoSearchApi = (data) => {
    return {
        url: API_URL.DEVICE.DEVICE_BULK_ADD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH_REQUEST, ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH_SUCCESS, ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH_FAILURE],
            data
        }
    };
};
