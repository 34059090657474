import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    POST_ROLE_DATA: `${STATE_REDUCER_KEY}/POST_ROLE_DATA`,
    POST_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/POST_ROLE_DATA_REQUEST`,
    POST_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/POST_ROLE_DATA_SUCCESS`,
    POST_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/POST_ROLE_DATA_FAILURE`,

    LIST_ROLE_DATA: `${STATE_REDUCER_KEY}/LIST_ROLE_DATA`,
    LIST_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/LIST_ROLE_DATA_REQUEST`,
    LIST_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ROLE_DATA_SUCCESS`,
    LIST_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/LIST_ROLE_DATA_FAILURE`,

    FETCH_ROLE_DATA_BY_ID: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_BY_ID`,
    FETCH_ROLE_DATA_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_BY_ID_REQUEST`,
    FETCH_ROLE_DATA_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_BY_ID_SUCCESS`,
    FETCH_ROLE_DATA_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ROLE_DATA_BY_ID_FAILURE`,

    UPDATE_ROLE_DATA: `${STATE_REDUCER_KEY}/UPDATE_ROLE_DATA`,
    UPDATE_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_ROLE_DATA_REQUEST`,
    UPDATE_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_ROLE_DATA_SUCCESS`,
    UPDATE_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_ROLE_DATA_FAILURE`,

    DELETE_ROLE_DATA: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA`,
    DELETE_ROLE_DATA_REQUEST: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA_REQUEST`,
    DELETE_ROLE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA_SUCCESS`,
    DELETE_ROLE_DATA_FAILURE: `${STATE_REDUCER_KEY}/DELETE_ROLE_DATA_FAILURE`,

    FETCH_RESOURCE_DATA: `${STATE_REDUCER_KEY}/FETCH_RESOURCE_DATA`,
    FETCH_RESOURCE_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_RESOURCE_DATA_REQUEST`,
    FETCH_RESOURCE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_RESOURCE_DATA_SUCCESS`,
    FETCH_RESOURCE_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_RESOURCE_DATA_FAILURE`,

    CHECKED_RESOURCE_DATA: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA`,
    CHECKED_RESOURCE_DATA_REQUEST: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA_REQUEST`,
    CHECKED_RESOURCE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA_SUCCESS`,
    CHECKED_RESOURCE_DATA_FAILURE: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA_FAILURE`,

    CREATE_RESOURCE_DATA: `${STATE_REDUCER_KEY}/CREATE_RESOURCE_DATA`,
    CREATE_RESOURCE_DATA_REQUEST: `${STATE_REDUCER_KEY}/CREATE_RESOURCE_DATA_REQUEST`,
    CREATE_RESOURCE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/CREATE_RESOURCE_DATA_SUCCESS`,
    CREATE_RESOURCE_DATA_FAILURE: `${STATE_REDUCER_KEY}/CREATE_RESOURCE_DATA_FAILURE`,

    UPDATE_RESOURCE_DATA: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_DATA`,
    UPDATE_RESOURCE_DATA_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_DATA_REQUEST`,
    UPDATE_RESOURCE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_DATA_SUCCESS`,
    UPDATE_RESOURCE_DATA_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_RESOURCE_DATA_FAILURE`,

    ROLE_DATA_DROPDOWN: `${STATE_REDUCER_KEY}/ROLE_DATA_DROPDOWN`,
    ROLE_DATA_DROPDOWN_REQUEST: `${STATE_REDUCER_KEY}/ROLE_DATA_DROPDOWN_REQUEST`,
    ROLE_DATA_DROPDOWN_SUCCESS: `${STATE_REDUCER_KEY}/ROLE_DATA_DROPDOWN_SUCCESS`,
    ROLE_DATA_DROPDOWN_FAILURE: `${STATE_REDUCER_KEY}/ROLE_DATA_DROPDOWN_FAILURE`
};

export const postRoleData = createAction(ACTION_TYPES.POST_ROLE_DATA);
export const listRoleData = createAction(ACTION_TYPES.LIST_ROLE_DATA);
export const fetchRoleDataById = createAction(ACTION_TYPES.FETCH_ROLE_DATA_BY_ID);
export const updateRoleData = createAction(ACTION_TYPES.UPDATE_ROLE_DATA);
export const deleteRoleData = createAction(ACTION_TYPES.DELETE_ROLE_DATA);
export const fetchResourceData = createAction(ACTION_TYPES.FETCH_RESOURCE_DATA);
export const checkedResourceData = createAction(ACTION_TYPES.CHECKED_RESOURCE_DATA);
export const createResourceData = createAction(ACTION_TYPES.CREATE_RESOURCE_DATA);
export const updateResourceData = createAction(ACTION_TYPES.UPDATE_RESOURCE_DATA);
export const roleDataDropdown = createAction(ACTION_TYPES.ROLE_DATA_DROPDOWN);
