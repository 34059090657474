import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { FORM_CONTROL_STYLE } from "./style";
import TextError from "./TextError";
const CustomCheckbox = (props) => {
  const { label, name, onChangeFromController, check, size, iconLabel, indeterminate, ...rest } = props;
  return (
    <Grid sx={FORM_CONTROL_STYLE}>
      <Field style={{ color: "label.main" }} name={name}>
        {({ field, form }) => {
          const isChecked = typeof check !== "undefined" ? check : field.value;
          return (
            <FormControlLabel
              {...field}
              {...rest}
              id={name}
              control={
                <Checkbox
                  size={size}
                  color="secondary"
                  sx={{
                    "&.Mui-checked": {
                      color: "#E4002B"
                    },
                    "&.MuiCheckbox-root": {
                      padding: 0
                    }
                  }}
                  checked={isChecked}
                  indeterminate={indeterminate}
                />
              }
              label={label ?? iconLabel}
              onChange={(e) => {
                form.setFieldValue(name, e.target.checked);
                onChangeFromController && onChangeFromController(e.target.checked);
              }}
            />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </Grid>
  );
};
export default CustomCheckbox;
