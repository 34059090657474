import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    rolePermissionById: {
        requestInProgress: false,
        data: []
    },
    resourceData: {
        requestInProgress: false,
        data: []
    },
    checkedResourceData: {
        requestInProgress: false,
        data: []
    },
    roleData: []
};
const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.rolePermissionById = initialState.rolePermissionById;
        },
        clearRole: (state) => {
            state.roleData = initialState.roleData;
        },
        setRole: (state, action) => {
            state.roleData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_ROLE_DATA_BY_ID_REQUEST, (state) => {
                state.rolePermissionById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_ROLE_DATA_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "rolePermissionById.requestInProgress", false);
                _.set(state, "rolePermissionById.data", action.payload);
            })
            .addCase(ACTION_TYPES.FETCH_ROLE_DATA_BY_ID_FAILURE, (state) => {
                state.rolePermissionById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_RESOURCE_DATA_REQUEST, (state) => {
                state.resourceData.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_RESOURCE_DATA_SUCCESS, (state, action) => {
                _.set(state, "resourceData.requestInProgress", false);
                _.set(state, "resourceData.data", action?.payload);
            })
            .addCase(ACTION_TYPES.FETCH_RESOURCE_DATA_FAILURE, (state) => {
                state.resourceData.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.CHECKED_RESOURCE_DATA_REQUEST, (state) => {
                state.checkedResourceData.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.CHECKED_RESOURCE_DATA_SUCCESS, (state, action) => {
                _.set(state, "checkedResourceData.requestInProgress", false);
                _.set(state, "checkedResourceData.data", action?.payload?.content);
            })
            .addCase(ACTION_TYPES.CHECKED_RESOURCE_DATA_FAILURE, (state) => {
                state.checkedResourceData.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.ROLE_DATA_DROPDOWN_REQUEST, (state) => {
                state.roleDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.ROLE_DATA_DROPDOWN_SUCCESS, (state, action) => {
                _.set(state, "roleDropdown.requestInProgress", false);
                _.set(state, "roleDropdown.data", action?.payload?.content);
            })
            .addCase(ACTION_TYPES.ROLE_DATA_DROPDOWN_FAILURE, (state) => {
                state.roleDropdown.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;
