import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    FETCH_QUERY_RECORD: `${STATE_REDUCER_KEY}/FETCH_QUERY_RECORD`,
    FETCH_QUERY_RECORD_REQUEST: `${STATE_REDUCER_KEY}/FETCH_QUERY_RECORD_REQUEST`,
    FETCH_QUERY_RECORD_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_QUERY_RECORD_SUCCESS`,
    FETCH_QUERY_RECORD_FAILURE: `${STATE_REDUCER_KEY}/FETCH_QUERY_RECORD_FAILURE`,

    FETCH_DEVICE_LIST_DATA: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DATA`,
    FETCH_DEVICE_LIST_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DATA_REQUEST`,
    FETCH_DEVICE_LIST_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DATA_SUCCESS`,
    FETCH_DEVICE_LIST_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DATA_FAILURE`,

    FETCH_DOWNLOAD: `${STATE_REDUCER_KEY}/FETCH_DOWNLOAD`,
    FETCH_DOWNLOAD_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DOWNLOAD_REQUEST`,
    FETCH_DOWNLOAD_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DOWNLOAD_SUCCESS`,
    FETCH_DOWNLOAD_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DOWNLOAD_FAILURE`,

    STOP_PLAYBACK: `${STATE_REDUCER_KEY}/STOP_PLAYBACK`,
    STOP_PLAYBACK_REQUEST: `${STATE_REDUCER_KEY}/STOP_PLAYBACK_REQUEST`,
    STOP_PLAYBACK_SUCCESS: `${STATE_REDUCER_KEY}/STOP_PLAYBACK_SUCCESS`,
    STOP_PLAYBACK_FAILURE: `${STATE_REDUCER_KEY}/STOP_PLAYBACK_FAILURE`
};

export const fetchQueryRecord = createAction(ACTION_TYPES.FETCH_QUERY_RECORD);
export const fetchDeviceListData = createAction(ACTION_TYPES.FETCH_DEVICE_LIST_DATA);
export const fetchDownloadData = createAction(ACTION_TYPES.FETCH_DOWNLOAD);
export const fetchStopPlayback = createAction(ACTION_TYPES.STOP_PLAYBACK);
