import moment from "moment";

export const DATE_FORMAT = "DD/MM/YYYY";

export const DATE_TIME_FORMAT = "DD/MM/YYYY hh:mm:ss A";

export const TIME_FORMAT = "hh:mm A";

/**
 * Converts date to epoch
 * @example
 * toEpoch(new Date( ));
 * // returns epoch of given date
 * @returns {long} Returns long data type of date
 */

export const toEpoch = (date) => {
    return date && moment(date).valueOf() || null;
};

export const dateTimeToLong = (dateTimeString) => {
    const dateTime = new Date(dateTimeString); // Create a Date object
    const timestamp = dateTime.getTime(); // Get the timestamp in milliseconds
    return timestamp; // Return the timestamp as a long integer
};

/**
 * Converts epoch to moment
 * @example
 * fromEpoch(1670970530);
 * // returns Moment instance of : Tuesday, December 13, 2022 10:28:50 PM
 * @returns {moment} Returns the moment instance
 */

export const fromEpoch = (epoch) => {
    return epoch && moment(epoch) || null;
};

export const formatDate = (date, format = DATE_TIME_FORMAT) => {
    return date && moment(date).format(format) || null;
};

export const convertLongToTime = (longValue) => {
    // Create a new Date object with the long value (milliseconds)
    let date = new Date(longValue);

    // Extract hours, minutes, and seconds
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    let seconds = date.getUTCSeconds();

    // Format the time
    let timeString = hours.toString().padStart(2, "0") + ":" +
        minutes.toString().padStart(2, "0") + ":" +
        seconds.toString().padStart(2, "0");

    return timeString;
};

export function getCurrentDateMidnightTimeMillis() {
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return midnight.getTime();
}

export function getCurrentDateEndOfDayMillis() {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    return endOfDay.getTime();
}
