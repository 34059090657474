import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import * as Api from "./api";
import { ACTION_TYPES } from "./actions";
import { successNotify } from "../../../utils/notificationUtils";
import { I18n } from "../../../common/components";
import { actions as sliceActions } from "./slice";

export function* fetchDevice({ payload }) {
    yield call(handleAPIRequest, Api.fetchDeviceListApi, payload);
}

export function* saveImage({ payload }) {
    const { formData, setOpen, setMapId } = payload;
    yield fork(handleAPIRequest, Api.saveImageApi, formData);
    const responseAction = yield take([ACTION_TYPES.SAVE_IMAGE_DETAILS_SUCCESS, ACTION_TYPES.SAVE_IMAGE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_IMAGE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("image") }) }));
        yield call(handleAPIRequest, Api.fetchMapListApi, formData);
        setMapId(responseAction?.payload?.id);
        setOpen(false);
    }
}

export function* getByImage({ payload }) {
    yield call(handleAPIRequest, Api.getByImageApi, payload);
}

export function* deleteMap({ payload }) {
    const { mapId, setMapId } = payload;
    yield fork(handleAPIRequest, Api.deleteMapApi, mapId);
    const responseAction = yield take([ACTION_TYPES.DELETE_MAP_DETAILS_SUCCESS, ACTION_TYPES.DELETE_MAP_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_MAP_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("delete"), message: I18n("delete_success", { type: I18n("map") }) }));
        yield call(handleAPIRequest, Api.fetchMapListApi, payload);
        yield put(sliceActions.resetMapData());
        setMapId("");
    }
}

export function* fetchEMap({ payload }) {
    yield call(handleAPIRequest, Api.fetchMapListApi, payload);
}

export function* fetchHotZoneMap({ payload }) {
    yield call(handleAPIRequest, Api.fetchHotZoneMapApi, payload);
}

export function* patchDroppedDevice({ payload }) {
    let { formData, mapId } = payload;
    yield fork(handleAPIRequest, Api.patchDroppedDeviceApi, formData);
    const responseAction = yield take([ACTION_TYPES.PATCH_DROPPED_DEVICE_SUCCESS, ACTION_TYPES.PATCH_DROPPED_DEVICE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_DROPPED_DEVICE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("device") }) }));
        yield call(handleAPIRequest, Api.getByImageApi, { mapId: mapId });
        yield call(handleAPIRequest, Api.fetchMapListApi);
    }
}

export function* patchHotZoneMap({ payload }) {
    const { formData, mapId, setAddZone } = payload;
    yield fork(handleAPIRequest, Api.patchHotZoneMapApi, formData);
    const responseAction = yield take([ACTION_TYPES.PATCH_HOT_ZONE_DETAILS_SUCCESS, ACTION_TYPES.PATCH_HOT_ZONE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_HOT_ZONE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("map") }) }));
        yield call(handleAPIRequest, Api.getByImageApi, { mapId: mapId });
        yield call(handleAPIRequest, Api.fetchMapListApi);
        setAddZone(false);
    }
}

export function* patchMapName({ payload }) {
    const { formData, setOpenInfo, mapId } = payload;
    yield fork(handleAPIRequest, Api.patchMapNameApi, formData);
    const responseAction = yield take([ACTION_TYPES.PATCH_MAP_NAME_SUCCESS, ACTION_TYPES.PATCH_MAP_NAME_FAILURE]);
    if (responseAction.type === ACTION_TYPES.PATCH_MAP_NAME_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("map_name") }) }));
        yield call(handleAPIRequest, Api.fetchMapListApi, formData);
        yield call(handleAPIRequest, Api.getByImageApi, { mapId: mapId });
        setOpenInfo(false);
    }
}

export function* deleteDeviceDetails({ payload }) {
    const { emapId, setDeviceId } = payload;
    yield fork(handleAPIRequest, Api.deleteDeviceApi, payload);
    const responseAction = yield take([ACTION_TYPES.DELETE_DEVICE_DETAILS_SUCCESS, ACTION_TYPES.DELETE_DEVICE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.DELETE_DEVICE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete", { type: I18n("device") }) }));
        yield call(handleAPIRequest, Api.getByImageApi, { mapId: emapId });
        yield call(handleAPIRequest, Api.fetchMapListApi);
        setDeviceId(false);
    }
}

export function* getByName({ payload }) {
    yield call(handleAPIRequest, Api.getByNameApi, payload);
}

export default function* electronicMapSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS, fetchDevice),
        takeLatest(ACTION_TYPES.SAVE_IMAGE_DETAILS, saveImage),
        takeLatest(ACTION_TYPES.GET_BY_IMAGE_DETAILS, getByImage),
        takeLatest(ACTION_TYPES.DELETE_MAP_DETAILS, deleteMap),
        takeLatest(ACTION_TYPES.FETCH_MAP_LIST_DETAILS, fetchEMap),
        takeLatest(ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS, fetchHotZoneMap),
        takeLatest(ACTION_TYPES.PATCH_DROPPED_DEVICE, patchDroppedDevice),
        takeLatest(ACTION_TYPES.PATCH_HOT_ZONE_DETAILS, patchHotZoneMap),
        takeLatest(ACTION_TYPES.PATCH_MAP_NAME, patchMapName),
        takeLatest(ACTION_TYPES.DELETE_DEVICE_DETAILS, deleteDeviceDetails),
        takeLatest(ACTION_TYPES.GET_MAP_NAME, getByName)
    ]);
}
