import { Yup } from "../../../utils/yupUtils";
import { I18n } from "../../../common/components";
import _ from "lodash";

export const passwordRegex = /^[0-9a-zA-Z#@.$_-]*$/;
export const userNameRegex = /^[0-9a-zA-Z#@.$_-]*$/;
export const isInitialAndFinalWhiteSpace = /^(?! ).*[^ ]$/;

export const loginSchema = Yup.object({
    loginId: Yup
        .string()
        .matches(userNameRegex, I18n("user_name_message"))
        .required(I18n("is_required", { type: I18n("user_name") })),
    loginPassword: Yup
        .string()
        .matches(passwordRegex, I18n("password_message"))
        .required(I18n("is_required", { type: I18n("password") }))
});

export const changePasswordSchema = Yup.object({
    oldPassword: Yup
        .string()
        .required(I18n("is_required", { type: I18n("current_password") })),
    newPassword: Yup
        .string()
        .required(I18n("is_required", { type: I18n("new_password") })),
    confirmPassword: Yup
        .string()
        .required(I18n("is_required", { type: I18n("confirm_password") }))
        .oneOf([Yup.ref("newPassword")], I18n("password_mismatch"))
});

const customUpdatePasswordSchema = ({ oldPassword, newPassword, confirmPassword, questionOne, questionTwo, questionThree, answerOne, answerTwo, answerThree }) => {
    let errors = {};

    if (_.isEmpty(oldPassword)) {
        _.set(errors, "oldPassword", I18n("is_required", { type: I18n("current_password") }));
    } else if (!isInitialAndFinalWhiteSpace.test(oldPassword)) {
        _.set(errors, "oldPassword", I18n("no_leading_trailing_whitespace", { type: I18n("current_password") }));
    }

    if (_.isEmpty(newPassword)) {
        _.set(errors, "newPassword", I18n("is_required", { type: I18n("new_password") }));
    } else if (newPassword.length < 8) {
        _.set(errors, "newPassword", I18n("must_be", { type: I18n("new_password"), count: 8 }));
    } else if (!isInitialAndFinalWhiteSpace.test(newPassword)) {
        _.set(errors, "newPassword", I18n("no_leading_trailing_whitespace", { type: I18n("new_password") }));
    } else if (oldPassword === newPassword) {
        _.set(errors, "newPassword", I18n("old_and_new_password_not_be_same", { type: I18n("new_password") }));
    }

    if (_.isEmpty(confirmPassword)) {
        _.set(errors, "confirmPassword", I18n("is_required", { type: I18n("confirm_password") }));
    } else if (newPassword.length < 8) {
        _.set(errors, "newPassword", I18n("must_be", { type: I18n("new_password"), count: 8 }));
    } else if (!isInitialAndFinalWhiteSpace.test(confirmPassword)) {
        _.set(errors, "confirmPassword", I18n("no_leading_trailing_whitespace", { type: I18n("confirm_password") }));
    } else if (newPassword !== confirmPassword) {
        _.set(errors, "confirmPassword", I18n("mismatch_password"));
    }

    if (_.isEmpty(questionOne)) {
        _.set(errors, "questionOne", I18n("is_required", { type: I18n("security_question_one") }));
    }
    if (_.isEmpty(questionTwo)) {
        _.set(errors, "questionTwo", I18n("is_required", { type: I18n("security_question_two") }));
    }
    if (_.isEmpty(questionThree)) {
        _.set(errors, "questionThree", I18n("is_required", { type: I18n("security_question_three") }));
    }

    if (_.isEmpty(answerOne)) {
        _.set(errors, "answerOne", I18n("is_required", { type: I18n("answer_one") }));
    } else if (!isInitialAndFinalWhiteSpace.test(answerOne)) {
        _.set(errors, "answerOne", I18n("no_leading_trailing_whitespace", { type: I18n("answer_one") }));
    }
    if (_.isEmpty(answerTwo)) {
        _.set(errors, "answerTwo", I18n("is_required", { type: I18n("answer_two") }));
    } else if (!isInitialAndFinalWhiteSpace.test(answerTwo)) {
        _.set(errors, "answerTwo", I18n("no_leading_trailing_whitespace", { type: I18n("answer_two") }));
    }
    if (_.isEmpty(answerThree)) {
        _.set(errors, "answerThree", I18n("is_required", { type: I18n("answer_three") }));
    } else if (!isInitialAndFinalWhiteSpace.test(answerThree)) {
        _.set(errors, "answerThree", I18n("no_leading_trailing_whitespace", { type: I18n("answer_three") }));
    }

    return errors;
};


const customForgetPasswordSchema = ({ newPassword, confirmPassword, questionOne, questionTwo, questionThree, answerOne, answerTwo, answerThree, loginId }) => {
    let errors = {};
    if (_.isEmpty(newPassword)) {
        _.set(errors, "newPassword", I18n("is_required", { type: I18n("new_password") }));
    } else if (newPassword.length < 8) {
        _.set(errors, "newPassword", I18n("must_be", { type: I18n("new_password"), count: 8 }));
    } else if (!isInitialAndFinalWhiteSpace.test(newPassword)) {
        _.set(errors, "newPassword", I18n("no_leading_trailing_whitespace", { type: I18n("new_password") }));
    }

    if (_.isEmpty(loginId)) {
        _.set(errors, "loginId", I18n("is_required", { type: I18n("user_name") }));
    }

    if (_.isEmpty(confirmPassword)) {
        _.set(errors, "confirmPassword", I18n("is_required", { type: I18n("confirm_password") }));
    } else if (newPassword.length < 8) {
        _.set(errors, "newPassword", I18n("must_be", { type: I18n("new_password"), count: 8 }));
    } else if (!isInitialAndFinalWhiteSpace.test(confirmPassword)) {
        _.set(errors, "confirmPassword", I18n("no_leading_trailing_whitespace", { type: I18n("confirm_password") }));
    } else if (newPassword !== confirmPassword) {
        _.set(errors, "confirmPassword", I18n("mismatch_password"));
    }

    if (_.isEmpty(questionOne)) {
        _.set(errors, "questionOne", I18n("is_required", { type: I18n("security_question_one") }));
    }
    if (_.isEmpty(questionTwo)) {
        _.set(errors, "questionTwo", I18n("is_required", { type: I18n("security_question_two") }));
    }
    if (_.isEmpty(questionThree)) {
        _.set(errors, "questionThree", I18n("is_required", { type: I18n("security_question_three") }));
    }
    if (_.isEmpty(answerOne)) {
        _.set(errors, "answerOne", I18n("is_required", { type: I18n("answer_one") }));
    } else if (!isInitialAndFinalWhiteSpace.test(answerOne)) {
        _.set(errors, "answerOne", I18n("no_leading_trailing_whitespace", { type: I18n("answer_one") }));
    }
    if (_.isEmpty(answerTwo)) {
        _.set(errors, "answerTwo", I18n("is_required", { type: I18n("answer_two") }));
    } else if (!isInitialAndFinalWhiteSpace.test(answerTwo)) {
        _.set(errors, "answerTwo", I18n("no_leading_trailing_whitespace", { type: I18n("answer_two") }));
    }
    if (_.isEmpty(answerThree)) {
        _.set(errors, "answerThree", I18n("is_required", { type: I18n("answer_three") }));
    } else if (!isInitialAndFinalWhiteSpace.test(answerThree)) {
        _.set(errors, "answerThree", I18n("no_leading_trailing_whitespace", { type: I18n("answer_three") }));
    }

    return errors;
};

export { customUpdatePasswordSchema, customForgetPasswordSchema };
