import { FormControlLabel, Grid, Radio } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";

const RadioButtons = (props) => {
  const { label, name, options = [], size, handleChange, labelColor = "#9DB2BF", ...rest } = props;

  return (
    <Grid sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
      {label && <div>{label}</div>}
      {options.map((option) => {
        return (
          <Field key={option.id} name={name}>
            {({ field, form }) => {
              const handleRadioChange = (e) => {
                const { value } = e.target;
                form.setFieldValue(name, value);
                if (handleChange) {
                  handleChange(value);
                }
              };

              return (
                <FormControlLabel
                  control={
                    <Radio size={size} color="secondary"
                      sx={{
                        "&.Mui-checked": {
                          color: "#E4002B"
                        }
                      }}
                      {...field}
                      {...rest}
                      value={option.id}
                      checked={(typeof field.value === "object" ? field?.value?.id : field?.value) === (option?.id || "").toString()}
                      onChange={handleRadioChange}
                    />
                  }
                  label={option.name}
                  sx={{ display: "flex", mr: "10px", alignItems: "center", color: labelColor || "black" }}
                />
              );
            }}
          </Field>
        );
      })}
      <ErrorMessage component={TextError} name={name} />
    </Grid>
  );
};

export default RadioButtons;
