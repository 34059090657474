import React, { useEffect } from "react";
import { useLocation } from "react-router";
import Navigate from "./Navigate";
import { STORAGE_KEYS } from "../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { navigateTo } from "../../common/actions";
import { automaticLogin, sendRoutes } from "../register/actions";
import { STATE_REDUCER_KEY } from "../../home/homeModule";
import { actions } from "../../common/slice";

export const PrivateRoute = ({ children }) => {
    let hasToken = localStorage.getItem(STORAGE_KEYS.SESSION_ID);
    let hasBearerToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);

    const systemSwitch = useSelector(state => state[STATE_REDUCER_KEY].systemSwitch);
    const userManagementSwitch = useSelector(state => state[STATE_REDUCER_KEY].userManagementSwitch);
    const resourceManagementSwitch = useSelector(state => state[STATE_REDUCER_KEY].resourceManagementSwitch);
    const roleManagementSwitch = useSelector(state => state[STATE_REDUCER_KEY].roleManagementSwitch);
    const alarmConfigurationSwitch = useSelector(state => state[STATE_REDUCER_KEY].alarmConfigurationSwitch);
    const tvWallSwitch = useSelector(state => state[STATE_REDUCER_KEY].tvWallSwitch);
    const playBack = useSelector(state => state[STATE_REDUCER_KEY].playBack);
    const operationLogsSwitch = useSelector(state => state[STATE_REDUCER_KEY].operationLogsSwitch);
    const electronicMapSwitch = useSelector(state => state[STATE_REDUCER_KEY].electronicMapSwitch);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "app") {
            window.electron.onDataReceived((event, state) => {
                if (state.id === "channelListIds") {
                    dispatch(actions.setChannelIds({ tabId: state?.tab, data: state?.data }));
                } else if (state.id === "deviceListIds") {
                    dispatch(actions.setDeviceIds({ tabId: state?.tab, data: state?.data }));
                } else if (state.id === "updateMapping") {
                    dispatch(actions.setUpdatedMapping({ tabId: state?.tab, data: state?.data }));
                } else if (state.id === "groupListIds") {
                    dispatch(actions.setGroupIds({ tabId: state?.tab, data: state?.data }));
                } else if (state.id === "recordSettings") {
                    dispatch(actions.setRecordSettings({ tabId: state?.tab, data: state?.data }));
                } else if (state.id === "viewStatus") {
                    dispatch(actions.setViewStatus({ tabId: state?.tab, data: state?.data }));
                } else if (state.id === "lengthOfVideoPlayer") {
                    dispatch(actions.setUpdatedVideoLength({ tabId: state?.tab, data: state?.data }));
                } else {
                    dispatch({ type: "UPDATE_COMMON_STATE", payload: state });
                }
            });
        }

    }, []);

    // Automatic Login
    useEffect(() => {
        if (hasToken && hasToken.localeCompare("undefined") !== 0 && hasBearerToken === null) {
            dispatch(automaticLogin({ sessionId: hasToken }));
        }
    }, []);

    // Navigation Handling
    useEffect(() => {
        if (location.pathname === "/") {
            dispatch(navigateTo("/home"));
        }
    }, [location, dispatch]);

    // Route Sending Based on Location and Switches
    useEffect(() => {
        const sendRouteIfRequired = (route) => {
            if (route) {
                dispatch(sendRoutes({ route, sessionId: hasToken }));
            }
        };

        if (hasToken && hasToken.localeCompare("undefined") !== 0 && hasBearerToken !== null) {
            switch (location.pathname) {
                case "/live":
                case "/device":
                    sendRouteIfRequired(location.pathname);
                    break;
                default:
                    if (location.pathname === "/home" && !systemSwitch && !userManagementSwitch && !resourceManagementSwitch && !roleManagementSwitch && !alarmConfigurationSwitch && !tvWallSwitch && !playBack && !operationLogsSwitch) {
                        sendRouteIfRequired(location.pathname);
                    }
                    if (systemSwitch) {
                        sendRouteIfRequired("systemSwitch");
                    }
                    if (userManagementSwitch) {
                        sendRouteIfRequired("userManagementSwitch");
                    }
                    if (resourceManagementSwitch) {
                        sendRouteIfRequired("resourceManagementSwitch");
                    }
                    if (roleManagementSwitch) {
                        sendRouteIfRequired("roleManagementSwitch");
                    }
                    if (alarmConfigurationSwitch) {
                        sendRouteIfRequired("alarmConfigurationSwitch");
                    }
                    if (tvWallSwitch) {
                        sendRouteIfRequired("tvWallSwitch");
                    }
                    if (playBack) {
                        sendRouteIfRequired("playBack");
                    }
                    if (operationLogsSwitch) {
                        sendRouteIfRequired("operationLogsSwitch");
                    }
                    if (electronicMapSwitch) {
                        sendRouteIfRequired("electronicMapSwitch");
                    }
                    break;
            }
        }
    }, [
        systemSwitch,
        userManagementSwitch,
        resourceManagementSwitch,
        roleManagementSwitch,
        alarmConfigurationSwitch,
        tvWallSwitch,
        playBack,
        operationLogsSwitch,
        electronicMapSwitch,
        location.pathname,
        hasToken,
        hasBearerToken,
        dispatch
    ]);

    return hasToken && hasToken.localeCompare("undefined") !== 0 ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }
            }
        />
    );
};
