import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const authenticateUser = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.USER_PROFILE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.AUTHENTICATE_USER_REQUEST, ACTION_TYPES.AUTHENTICATE_USER_SUCCESS, ACTION_TYPES.AUTHENTICATE_USER_FAILURE],
            data: { ...data }
        }
    };
};

export const changePassword = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.CHANGE_PASSWORD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.CHANGE_PASSWORD_REQUEST, ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, ACTION_TYPES.CHANGE_PASSWORD_FAILURE],
            data: { ...data }
        }
    };
};

export const forgetPassword = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.FORGET_PASSWORD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FORGET_PASSWORD_REQUEST, ACTION_TYPES.FORGET_PASSWORD_SUCCESS, ACTION_TYPES.FORGET_PASSWORD_FAILURE],
            data: { ...data }
        }
    };
};

export const updatePassword = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.CHANGE_PASSWORD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.UPDATE_PASSWORD_REQUEST, ACTION_TYPES.UPDATE_PASSWORD_SUCCESS, ACTION_TYPES.UPDATE_PASSWORD_FAILURE],
            data: { ...data }
        }
    };
};

export const getUserProfile = (data = {}) => {
    return {
        url: API_URL.USER_MANAGEMENT.USER_PROFILE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FETCH_USER_PROFILE_REQUEST, ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS, ACTION_TYPES.FETCH_USER_PROFILE_FAILURE],
            data
        }
    };
};

export const getSecurityQuestionsApi = () => {
    return {
        url: API_URL.USER_MANAGEMENT.SECURITY_QUESTIONS,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SECURITY_QUESTIONS_REQUEST, ACTION_TYPES.SECURITY_QUESTIONS_SUCCESS, ACTION_TYPES.SECURITY_QUESTIONS_FAILURE]
        }
    };
};

export const automaticLoginApi = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.AUTOMATIC_LOGIN,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.AUTOMATIC_LOGIN_REQUEST, ACTION_TYPES.AUTOMATIC_LOGIN_SUCCESS, ACTION_TYPES.AUTHENTICATE_USER_FAILURE],
            data
        }
    };
};

export const sendRoutesApi = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.SEND_ROUTES,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SEND_ROUTES_REQUEST, ACTION_TYPES.SEND_ROUTES_SUCCESS, ACTION_TYPES.SEND_ROUTES_FAILURE],
            data
        }
    };
};
