import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import { I18n } from "../../../common/components";
import { handleAPIRequest } from "../../../utils/http";
import { successNotify } from "../../../utils/notificationUtils";
import { ACTION_TYPES, commonSettingsById, commonSettingsListAll, listBackupMessages, storageManagementById, storageManagementListAll, timeProgressById, videoSettingsById, videoSettingsListAll } from "./actions";
import * as Api from "./api";
import { dateTimeToLong, getCurrentDateEndOfDayMillis, getCurrentDateMidnightTimeMillis } from "../../../utils/dateUtils";
import { actions as commonActions } from "../../common/slice";
// import { getDeviceById } from "../../device/device/actions";
import { commonFileDownload } from "../../common/actions";
import { API_URL } from "../apiUrls";
import { actions as registerActions } from "../../user-management/register/slice";

export function* commonSettingsByData({ payload: id }) {
    yield call(handleAPIRequest, Api.commonSettingsById, id);
}

export function* submitCommonSettingsForm(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.updateCommonSettings, action.payload);
        const responseAction = yield take([ACTION_TYPES.UPDATE_COMMON_SETTINGS_SUCCESS, ACTION_TYPES.UPDATE_COMMON_SETTINGS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_COMMON_SETTINGS_SUCCESS) {
            yield put(registerActions.setAutomaticLogin(rest.automaticLogin));
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("common_settings") }) }));
        }
    } else {
        yield fork(handleAPIRequest, Api.submitCommonSettings, rest);
        const responseAction = yield take([ACTION_TYPES.POST_COMMON_SETTINGS_SUCCESS, ACTION_TYPES.POST_COMMON_SETTINGS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_COMMON_SETTINGS_SUCCESS) {
            yield put(registerActions.setAutomaticLogin(rest.automaticLogin));
            yield put(commonSettingsById(responseAction.payload.id));
            yield put(commonSettingsListAll());
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("common_settings") }) }));
        }
    }
}

export function* commonSettingsList(action) {
    yield call(handleAPIRequest, Api.commonSettingsListAll, action?.payload);
}

export function* skinDropDown(action) {
    yield call(handleAPIRequest, Api.skinDropDown, action?.payload);
}

export function* languageDropDown(action) {
    yield call(handleAPIRequest, Api.languageDropDown, action?.payload);
}

export function* logDropDown(action) {
    yield call(handleAPIRequest, Api.logDropDown, action?.payload);
}

const formatValues = (values = {}) => {
    let stream = { id: values.stream };
    if (values.stream) {
        _.set(values, "stream", stream);
    }
    if (_.isEmpty(values.stream.id)) {
        _.set(values, "stream", {});
    }
    return values;
};

const formatEditValues = (values = {}) => {
    if (typeof (values.stream) === "object") {
        let stream = values.stream;
        _.set(values, "stream", stream);
    } else {
        let stream = { id: values.stream };
        _.set(values, "stream", stream);
    }
    return values;
};

export function* submitVideoSettingsForm(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.updateVideoSettings, formatEditValues(action.payload));
        const responseAction = yield take([ACTION_TYPES.UPDATE_VIDEO_SETTINGS_SUCCESS, ACTION_TYPES.UPDATE_VIDEO_SETTINGS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_VIDEO_SETTINGS_SUCCESS) {
            yield put(registerActions.setInstancePlayBackTime(rest?.playbackTime?.name));
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("video_settings") }) }));
        }
    } else {
        yield fork(handleAPIRequest, Api.submitVideoSettings, formatValues(rest));
        const responseAction = yield take([ACTION_TYPES.POST_VIDEO_SETTINGS_SUCCESS, ACTION_TYPES.POST_VIDEO_SETTINGS_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_VIDEO_SETTINGS_SUCCESS) {
            yield put(registerActions.setInstancePlayBackTime(rest?.playbackTime?.name));
            yield put(videoSettingsById(responseAction.payload.id));
            yield put(videoSettingsListAll());
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("video_settings") }) }));
        }
    }
}

export function* videoSettingsList(action) {
    yield call(handleAPIRequest, Api.videoSettingsListAll, action?.payload);
}

export function* videoSettingsByData({ payload: id }) {
    yield call(handleAPIRequest, Api.videoSettingsById, id);
}

export function* streamTypeDropDown(action) {
    yield call(handleAPIRequest, Api.streamTypeDropDown, action?.payload);
}

export function* frameRateDropDown(action) {
    yield call(handleAPIRequest, Api.frameRateDropDown, action?.payload);
}

export function* playPropertyDropDown(action) {
    yield call(handleAPIRequest, Api.playPropertySettingDropDown, action?.payload);
}

export function* decodingSettingDropDown(action) {
    yield call(handleAPIRequest, Api.decodingSettingDropDown, action?.payload);
}

export function* videoDownloadFormatDropDown(action) {
    yield call(handleAPIRequest, Api.videoDownloadFormatDropDown, action?.payload);
}

export function* playbackTimeDropDown(action) {
    yield call(handleAPIRequest, Api.playBackTimeDropDown, action?.payload);
}

export function* isAutomaticDropDown(action) {
    yield call(handleAPIRequest, Api.isAutomaticDropDown, action?.payload);
}

export function* alarmGradeListAll(action) {
    yield call(handleAPIRequest, Api.alarmGradeListAll, action?.payload);
}

export function* submitAlarmGrade({ payload }) {
    yield fork(handleAPIRequest, Api.submitAlarmGrade, payload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_ALARM_GRADE_SUCCESS, ACTION_TYPES.UPDATE_ALARM_GRADE_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_ALARM_GRADE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("alarm_grade") }) }));
        yield call(alarmGradeListAll, {});
    }
}

export function* diskSpaceDropDown(action) {
    yield call(handleAPIRequest, Api.diskSpaceDropDown, action?.payload);
}

export function* diskFullDropDown(action) {
    yield call(handleAPIRequest, Api.diskFullDropDown, action?.payload);
}

export function* recordingTypeDropDown(action) {
    yield call(handleAPIRequest, Api.recordingTypeDropDown, action?.payload);
}

export function* recordingFormatDropDown(action) {
    yield call(handleAPIRequest, Api.recordingFormatDropDown, action?.payload);
}


const storageFormatValues = (values = {}) => {
    let diskFull = { id: values.diskFull };
    let diskSpaceType = { id: values.diskSpace };
    if (values.diskFull) {
        _.set(values, "diskFull", diskFull);
    }
    if (_.isEmpty(values.diskFull.id)) {
        _.set(values, "diskFull", {});
    }
    if (values.diskSpaceType) {
        _.set(values, "diskSpaceType", diskSpaceType);
    }
    if (_.isEmpty(values.diskSpaceType.id)) {
        _.set(values, "diskSpaceType", {});
    }
    let formattedPayload = _.omit(values, "checked");

    return formattedPayload;
};

const storageFormatEditValues = (values = {}) => {
    if (typeof values.diskFull === "object") {
        let diskFull = values.diskFull;
        _.set(values, "diskFull", diskFull);
    } else {
        let diskFull = { id: values.diskFull };
        _.set(values, "diskFull", diskFull);
    }
    if (typeof values.diskSpaceType === "object") {
        let diskSpaceType = values.diskSpaceType;
        _.set(values, "diskSpaceType", diskSpaceType);
    } else {
        let diskSpaceType = { id: values.diskSpaceType };
        _.set(values, "diskSpaceType", diskSpaceType);
    }
    let formattedPayload = _.omit(values, "checked");

    return formattedPayload;
};

export function* submitStorageManagementForm(action) {
    let { payload: { id, ...rest } = {} } = action;
    if (id) {
        yield fork(handleAPIRequest, Api.updateStorageManagement, storageFormatEditValues(action.payload));
        const responseAction = yield take([ACTION_TYPES.UPDATE_STORAGE_MANAGEMENT_SUCCESS, ACTION_TYPES.UPDATE_STORAGE_MANAGEMENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.UPDATE_STORAGE_MANAGEMENT_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("storage_management") }) }));
            yield call(handleAPIRequest, Api.storageManagementById, id);
        }
    } else {
        yield fork(handleAPIRequest, Api.submitStorageManagement, storageFormatValues(rest));
        const responseAction = yield take([ACTION_TYPES.POST_STORAGE_MANAGEMENT_SUCCESS, ACTION_TYPES.POST_STORAGE_MANAGEMENT_FAILURE]);
        if (responseAction.type === ACTION_TYPES.POST_STORAGE_MANAGEMENT_SUCCESS) {
            yield put(storageManagementById(responseAction.payload.id));
            yield put(storageManagementListAll());
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("storage_management") }) }));
        }
    }
}

export function* storageManagementListData(action) {
    yield call(handleAPIRequest, Api.storageManagementListAll, action?.payload);
}

export function* storageManagementByData({ payload: id }) {
    yield call(handleAPIRequest, Api.storageManagementById, id);
}

export function* getDriveList(action) {
    yield call(handleAPIRequest, Api.getDriveList, action?.payload);
}

export function* timeTemplateDropDown(action) {
    yield call(handleAPIRequest, Api.timeTemplateDropDown, action?.payload);
}

export function* codeStreamDropDown(action) {
    yield call(handleAPIRequest, Api.codeStreamDropDown, action?.payload);
}

const recordPlanFormatValues = (values = {}) => {
    let device = { id: values.device.id, name: values.device.name };
    let deviceChannel = { id: values.channel.id, name: values.channel.name };
    let { periodSetting } = values.values;
    let { enableRecording, streamType, timeTemplate } = values.recordPlanFilter;
    let updatedPeriodSetting = periodSetting.map((val) => {
        return {
            ...val,
            startTime: dateTimeToLong(val.startTime),
            endTime: dateTimeToLong(val.endTime)
        };
    });
    let settings;
    if (values.values.days === true) {
        // eslint-disable-next-line no-undef
        settings = { day: [...new Set(values.day)], periodSetting: updatedPeriodSetting };
    } else {
        // eslint-disable-next-line no-undef
        settings = { day: [...new Set(values.selectedDay)], periodSetting: updatedPeriodSetting };
    }
    let updatedPayload = { device, deviceChannel, enableRecording, streamType, timeTemplate, settings };
    return updatedPayload;
};

export function* submitRecordPlan({ payload }) {
    let { recordPlanFilter, channel, device } = payload;
    yield fork(handleAPIRequest, Api.submitRecordPlan, recordPlanFormatValues(payload));
    const responseAction = yield take([ACTION_TYPES.SAVE_RECORD_PLAN_SUCCESS, ACTION_TYPES.SAVE_RECORD_PLAN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_RECORD_PLAN_SUCCESS) {
        yield put(timeProgressById({ channelId: channel.id, deviceId: device.id, streamType: recordPlanFilter.streamType.id }));
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("record_plan") }) }));
        yield put(commonActions.setOpenModal(false));
    }
}

export function* submitCopyTo({ payload }) {
    const { setModalOpen, ...rest } = payload;
    yield fork(handleAPIRequest, Api.submitCopyTo, rest);
    const responseAction = yield take([ACTION_TYPES.SAVE_COPY_TO_SUCCESS, ACTION_TYPES.SAVE_COPY_TO_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_COPY_TO_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("record_plan") }) }));
        setModalOpen(false);
    }
}

export function* getRecordPlanSettingById({ payload }) {
    yield call(handleAPIRequest, Api.getRecordPlanSettingById, payload);
}

export function* timeProgressByIdData({ payload }) {
    yield call(handleAPIRequest, Api.getTimeProgressById, payload);
}

export function* listCustomized({ payload }) {
    yield call(handleAPIRequest, Api.listCustomizedDetails, payload);
}

const fullCycleFormatValues = (values = {}) => {
    if (values.deviceDatas) {
        let device = { id: values.deviceDatas.id };
        _.set(values, "device", device);
    }
    if (values.recordPlanFilter) {
        let timeTemplate = values.recordPlanFilter.timeTemplate;
        let streamType = values.recordPlanFilter.streamType;
        _.set(values, "enableRecording", values.recordPlanFilter.enableRecording);
        _.set(values, "streamType", streamType);
        _.set(values, "timeTemplate", timeTemplate);
        _.set(values, "settings", {});
        _.set(values, "fullCycleStartTime", getCurrentDateMidnightTimeMillis());
        _.set(values, "fullCycleEndTime", getCurrentDateEndOfDayMillis());
    }
    if (values.channel) {
        let deviceChannel = { id: values.channel.id, name: values.channel.name };
        _.set(values, "deviceChannel", deviceChannel);
    }
    let formattedPayload = _.omit(values, ["recordPlanFilter", "deviceDatas", "channel", "periodSetting"]);

    return formattedPayload;
};

const newRecordPlanFormatValues = (values = {}) => {
    const days = Object.keys(values.periodSetting).filter(day => values.periodSetting[day].length > 0);

    let periodId = 1;
    const periodSetting = [];
    days.forEach(day => {
        values.periodSetting[day].forEach(period => {
            periodSetting.push({
                startTime: period.start,
                endTime: period.end,
                periodId: periodId++
            });
        });
    });

    const newPayload = {
        device: { id: values.deviceDatas.id, name: values.deviceDatas.name },
        deviceChannel: { id: values.deviceDatas.deviceChannel[0].id, name: values.deviceDatas.deviceChannel[0].name },
        enableRecording: values.recordPlanFilter.enableRecording,
        streamType: values.recordPlanFilter.streamType,
        timeTemplate: values.recordPlanFilter.timeTemplate,
        settings: { day: days, periodSetting: periodSetting }
    };
    return newPayload;
};

export function* submitFullCycleForm({ payload }) {
    let { recordPlanFilter, channel, deviceDatas } = payload;
    if (recordPlanFilter?.timeTemplate.name === "Full cycle template") {
        yield fork(handleAPIRequest, Api.submitFullCycle, fullCycleFormatValues(payload));
        const responseAction = yield take([ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE_SUCCESS, ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("record_plan") }) }));
            yield put(timeProgressById({ channelId: channel.id, deviceId: deviceDatas.id, streamType: recordPlanFilter.streamType.id }));
        }
    } else {
        yield fork(handleAPIRequest, Api.submitRecordPlan, newRecordPlanFormatValues(payload));
        const responseAction = yield take([ACTION_TYPES.SAVE_RECORD_PLAN_SUCCESS, ACTION_TYPES.SAVE_RECORD_PLAN_FAILURE]);
        if (responseAction.type === ACTION_TYPES.SAVE_RECORD_PLAN_SUCCESS) {
            yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("record_plan") }) }));
        }
    }
}

export function* saveEnableLocalEncoding({ payload }) {
    let enable = payload?.deviceChannel[0]?.enableRecording;
    yield fork(handleAPIRequest, Api.saveEnableLocalEncoding, payload);
    const responseAction = yield take([ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING_SUCCESS, ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING_SUCCESS) {
        // yield put(getDeviceById(payload?.id));
        if (enable) {
            yield put(successNotify({ title: I18n("success"), message: I18n("record_plan_for_this_channel") }));
        } else {
            yield put(successNotify({ title: I18n("success"), message: I18n("record_plan_for_this_channel_disabled") }));
        }
    }
}

export function* saveOutput({ payload }) {
    const { selectedItems, setOpenOutput } = payload;
    const collectionList = selectedItems.map((item) => item.id);
    yield fork(handleAPIRequest, Api.saveOutput, collectionList);
    const responseAction = yield take([ACTION_TYPES.SAVE_OUTPUT_SUCCESS, ACTION_TYPES.SAVE_OUTPUT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_OUTPUT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("data_bakup") }));
        setOpenOutput(false);
    }
}

export function* saveBackup() {
    yield put(commonFileDownload({
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.OUTPUT,
        data: {},
        file: { name: I18n("system"), ext: "backup" }
    }));
}

export function* listBackupMessagesAll({ payload }) {
    const { setOpenRecover, setOpenImport, setOpenBackup, ...rest } = payload;
    yield fork(handleAPIRequest, Api.listBackupMessages, rest);
    const responseAction = yield take([ACTION_TYPES.LIST_BACKUP_MESSAGES_SUCCESS, ACTION_TYPES.LIST_BACKUP_MESSAGES_FAILURE]);
    if (responseAction.type === ACTION_TYPES.LIST_BACKUP_MESSAGES_SUCCESS) {
        if (setOpenBackup) {
            setOpenBackup(true);
            yield call(saveBackup, {});
        } else if (setOpenImport) {
            setOpenImport(true);
        } else {
            setOpenRecover(true);
        }
    }
}

export function* saveRecover({ payload }) {
    const { setOpenRecover, selectedFile } = payload;
    yield fork(handleAPIRequest, Api.saveRecover, selectedFile);
    const responseAction = yield take([ACTION_TYPES.SAVE_RECOVER_SUCCESS, ACTION_TYPES.SAVE_RECOVER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_RECOVER_SUCCESS) {
        yield put(listBackupMessages({ setOpenRecover }));
    }
}

export function* saveImport({ payload }) {
    const { setOpenImport, selectedFile } = payload;
    yield fork(handleAPIRequest, Api.saveImport, selectedFile);
    const responseAction = yield take([ACTION_TYPES.SAVE_IMPORT_SUCCESS, ACTION_TYPES.SAVE_IMPORT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.SAVE_IMPORT_SUCCESS) {
        yield put(listBackupMessages({ setOpenImport, fullList: false }));
    }
}

export function* restoreDefault(action) {
    yield call(handleAPIRequest, Api.restoreDefault, action?.payload);
}

export default function* systemSetupSaga() {
    yield all([
        takeLatest(ACTION_TYPES.POST_COMMON_SETTINGS, submitCommonSettingsForm),
        takeLatest(ACTION_TYPES.COMMON_SETTINGS_BY_ID, commonSettingsByData),
        takeLatest(ACTION_TYPES.COMMON_SETTINGS_LIST_ALL, commonSettingsList),
        takeLatest(ACTION_TYPES.SELECT_SKIN, skinDropDown),
        takeLatest(ACTION_TYPES.SELECT_LANGUAGE, languageDropDown),
        takeLatest(ACTION_TYPES.SELECT_LOG, logDropDown),
        takeLatest(ACTION_TYPES.POST_VIDEO_SETTINGS, submitVideoSettingsForm),
        takeLatest(ACTION_TYPES.VIDEO_SETTINGS_BY_ID, videoSettingsByData),
        takeLatest(ACTION_TYPES.VIDEO_SETTINGS_LIST_ALL, videoSettingsList),
        takeLatest(ACTION_TYPES.SELECT_STREAM_TYPE, streamTypeDropDown),
        takeLatest(ACTION_TYPES.SELECT_FRAME_RATE, frameRateDropDown),
        takeLatest(ACTION_TYPES.SELECT_PLAY_PROPERTY, playPropertyDropDown),
        takeLatest(ACTION_TYPES.SELECT_DECODING_SETTING, decodingSettingDropDown),
        takeLatest(ACTION_TYPES.SELECT_VIDEO_DOWNLOAD_FORMAT, videoDownloadFormatDropDown),
        takeLatest(ACTION_TYPES.SELECT_PLAY_BACK_TIME, playbackTimeDropDown),
        takeLatest(ACTION_TYPES.SELECT_IS_AUTOMATIC, isAutomaticDropDown),
        takeLatest(ACTION_TYPES.ALARM_GRADE_LIST_ALL, alarmGradeListAll),
        takeLatest(ACTION_TYPES.UPDATE_ALARM_GRADE, submitAlarmGrade),
        takeLatest(ACTION_TYPES.SELECT_DISK_SPACE, diskSpaceDropDown),
        takeLatest(ACTION_TYPES.SELECT_DISK_FULL, diskFullDropDown),
        takeLatest(ACTION_TYPES.SELECT_RECORDING_TYPE, recordingTypeDropDown),
        takeLatest(ACTION_TYPES.SELECT_RECORDING_FORMAT, recordingFormatDropDown),
        takeLatest(ACTION_TYPES.POST_STORAGE_MANAGEMENT, submitStorageManagementForm),
        takeLatest(ACTION_TYPES.STORAGE_MANAGEMENT_LIST_ALL, storageManagementListData),
        takeLatest(ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID, storageManagementByData),
        takeLatest(ACTION_TYPES.GET_DRIVE_LIST, getDriveList),
        takeLatest(ACTION_TYPES.GET_TIME_TEMPLATE, timeTemplateDropDown),
        takeLatest(ACTION_TYPES.GET_CODE_STREAM, codeStreamDropDown),
        takeLatest(ACTION_TYPES.SAVE_RECORD_PLAN, submitRecordPlan),
        takeLatest(ACTION_TYPES.GET_RECORD_PLAN_SETTING_BY_ID, getRecordPlanSettingById),
        takeLatest(ACTION_TYPES.SAVE_COPY_TO, submitCopyTo),
        takeLatest(ACTION_TYPES.LIST_CUSTOMIZED_DEVICES, listCustomized),
        takeLatest(ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE, submitFullCycleForm),
        takeLatest(ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING, saveEnableLocalEncoding),
        takeLatest(ACTION_TYPES.TIME_PROGRESS_BY_ID, timeProgressByIdData),
        takeLatest(ACTION_TYPES.SAVE_OUTPUT, saveOutput),
        takeLatest(ACTION_TYPES.SAVE_BACKUP, saveBackup),
        takeLatest(ACTION_TYPES.SAVE_RECOVER, saveRecover),
        takeLatest(ACTION_TYPES.SAVE_IMPORT, saveImport),
        takeLatest(ACTION_TYPES.LIST_BACKUP_MESSAGES, listBackupMessagesAll),
        takeLatest(ACTION_TYPES.RESTORE_DEFAULT, restoreDefault)
    ]);
}
