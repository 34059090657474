import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { actions as commonActions } from "./modules/user/slice";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "./layouts/DashboardLayout";
import { ThemeProvider } from "./layouts/ThemeContext";
import hls from "hls.js";
import zipy from "zipyai";
import { STATE_REDUCER_KEY } from "./modules/common/constants";
import { STATE_REDUCER_KEY as LIVE_CONSTANT } from "./modules/live/liveView/constants";
import { actions } from "./modules/common/slice";
import { stopStreaming } from "./modules/live/liveView/actions";
import _ from "lodash";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updatedMappingDataFull = useSelector(state => state[STATE_REDUCER_KEY]?.updateMapping);
  // const lengthOfVideoPlayerFull = useSelector(state => state[STATE_REDUCER_KEY]?.lengthOfVideoPlayer) || 4;
  // const idsGroupDataFull = useSelector(state => state[STATE_REDUCER_KEY]?.groupListIds) || [];
  const idsDataFull = useSelector(state => state[STATE_REDUCER_KEY]?.channelListIds);
  const idsDeviceDataFull = useSelector(state => state[STATE_REDUCER_KEY]?.deviceListIds);
  const tabs = useSelector(state => state.common.tab);
  // const recordSettingsAll = useSelector(state => state.common?.recordSettings) || [];
  // const viewStatusFull = useSelector(state => state[STATE_REDUCER_KEY]?.viewStatus);
  const liveViewById = useSelector(state => state[LIVE_CONSTANT]?.liveViewById.data);


  const updatingForWindow = (type, data, tab) => {
    if (process.env.REACT_APP_ENV_TYPE === "app") {
      if (type === "channel") {
        window.electron.updateState({ id: "channelListIds", data, tab });
      }
      if (type === "device") {
        window.electron.updateState({ id: "deviceListIds", data, tab });
      }
      if (type === "updateMap") {
        window.electron.updateState({ id: "updateMapping", data, tab });
      }
      if (type === "group") {
        window.electron.updateState({ id: "groupListIds", data, tab });
      }
      if (type === "record") {
        window.electron.updateState({ id: "recordSettings", data, tab });
      }
      if (type === "viewStatus") {
        window.electron.updateState({ id: "viewStatus", data, tab });
      }
      if (type === "length") {
        window.electron.updateState({ id: "lengthOfVideoPlayer", data, tab });
      }
    }
  };

  const retryLiveVideo = (liveDetails, type) => {
    let tempFullUpdateMap = { ...updatedMappingDataFull };
    let tempIds = { ...idsDataFull };
    let tempDeviceIds = { ...idsDeviceDataFull };
    let slotList = [];
    Object.entries(tempFullUpdateMap).map(item => {
      let tempArray = [...item[1]];
      let tempIdsArray = tempIds[item[0]] ? [...tempIds[item[0]]] : [];
      let tempDeviceIdsArray = tempDeviceIds[item[0]] ? [...tempDeviceIds[item[0]]] : [];
      let tabIdArray = item[0].split("_");
      tempArray.map((data, i) => {
        if (data?.deviceId === liveDetails?.deviceId && data?.channelId === liveDetails?.channelId) {
          if (type === "RETRY_FAILED") {
            tempArray[i] = { ...tempArray[i], streamUrl: "", isLoading: false, groupId: null, deviceId: null, channelId: null };
            slotList.push(data?.streamUrl?.slotId);
            const elementIndex = tempIdsArray.indexOf(`${liveDetails?.channelId}_${liveDetails?.deviceId}`);
            if (elementIndex > -1) {
              tempIdsArray.splice(elementIndex, 1);
              let deviceIndex = tempDeviceIdsArray?.indexOf(liveDetails?.deviceId);
              if (deviceIndex !== -1) {
                tempDeviceIdsArray.splice(deviceIndex, 1);
                // getGroupId(liveViewDetails?.deviceId);
              }
            }
          } else {
            tempArray[i] = { ...tempArray[i], streamUrl: liveDetails };
          }
        }
      });
      tempFullUpdateMap[item[0]] = tempArray;
      tempIds[item[0]] = tempIdsArray;
      tempDeviceIds[item[0]] = tempDeviceIdsArray;
      dispatch(actions.setUpdatedMapping({ tabId: parseInt(tabIdArray[0]), data: tempArray }));
      if (type === "RETRY_FAILED") {
        dispatch(actions.setChannelIds({ tabId: parseInt(tabIdArray[0]), data: tempIdsArray }));
        dispatch(actions.setDeviceIds({ tabId: parseInt(tabIdArray[0]), data: tempDeviceIdsArray }));
      }
      if (process.env.REACT_APP_ENV_TYPE === "app") {
        updatingForWindow("updateMap", tempArray, tabIdArray[0]);
        if (type === "RETRY_FAILED") {
          updatingForWindow("channel", tempIdsArray, tabIdArray[0]);
          updatingForWindow("device", tempDeviceIdsArray, tabIdArray[0]);
        }
      }

    });
    if (type === "RETRY_FAILED") {
      dispatch(stopStreaming(slotList));
    }
  };


  const liveViewFromSocket = (liveViewDetails) => {
    // if (process.env.REACT_APP_ENV_TYPE === "app") {
    if (!_.isEmpty(liveViewDetails)) {
      let thisTab = tabs.filter(x => x.id === liveViewDetails?.tabId)[0];
      // if (thisTab && !thisTab?.isInside) {
      let updateMapOfCurrentTab = updatedMappingDataFull[`${liveViewDetails?.tabId}_tab`] ? [...updatedMappingDataFull[`${liveViewDetails?.tabId}_tab`]] : [];
      let ids = idsDataFull[`${liveViewDetails?.tabId}_tab`] ? [...idsDataFull[`${liveViewDetails?.tabId}_tab`]] : [];
      let deviceIdsTab = idsDeviceDataFull[`${liveViewDetails?.tabId}_tab`] ? [...idsDeviceDataFull[`${liveViewDetails?.tabId}_tab`]] : [];
      updateMapOfCurrentTab.forEach((data, i) => {
        if (data?.deviceId === liveViewDetails?.deviceId && data?.channelId === liveViewDetails?.channelId) {
          if (liveViewDetails?.status === "SUCCESS") {
            updateMapOfCurrentTab[i] = { ...updateMapOfCurrentTab[i], streamUrl: liveViewDetails, isLoading: false };
          } else if (liveViewDetails?.status === "RETRY_FAILURE" || liveViewDetails?.status === "FAILURE") {
            updateMapOfCurrentTab[i] = { ...updateMapOfCurrentTab[i], streamUrl: "", isLoading: false, groupId: null, deviceId: null, channelId: null };
            //unchecking checkboxes in failure scenarios and stop the streaming
            const elementIndex = ids.indexOf(`${liveViewDetails?.channelId}_${liveViewDetails?.deviceId}`);

            if (elementIndex > -1) {
              ids.splice(elementIndex, 1);
              let deviceIndex = deviceIdsTab?.indexOf(liveViewDetails?.deviceId);
              if (deviceIndex !== -1) {
                deviceIdsTab.splice(deviceIndex, 1);
                // getGroupId(liveViewDetails?.deviceId);
              }
              dispatch(actions.setChannelIds({ tabId: liveViewDetails?.tabId, data: ids }));
              dispatch(actions.setDeviceIds({ tabId: liveViewDetails?.tabId, data: deviceIdsTab }));
              // if (process.env.REACT_APP_ENV_TYPE === "app") {
              //   updatingForWindow("channel", ids, liveViewDetails?.tabId);
              //   updatingForWindow("device", deviceIdsTab, liveViewDetails?.tabId);
              // }
            }
            if (liveViewDetails?.slotId !== 0) {
              dispatch(stopStreaming([liveViewDetails?.slotId]));

            }

          }
        }
      });
      dispatch(actions.setUpdatedMapping({ tabId: liveViewDetails?.tabId, data: updateMapOfCurrentTab }));

      if (process.env.REACT_APP_ENV_TYPE === "app") {
        if (thisTab && !thisTab?.isInside) {
          updatingForWindow("updateMap", updateMapOfCurrentTab, liveViewDetails?.tabId);
          updatingForWindow("channel", ids, liveViewDetails?.tabId);
          updatingForWindow("device", deviceIdsTab, liveViewDetails?.tabId);
        }
      }


      // }
      if (liveViewDetails?.status === "RETRY_FAILED" || liveViewDetails?.status === "RETRY_SUCCESS") {
        retryLiveVideo(liveViewDetails, liveViewDetails?.status);
      }
    }
    // }
  };

  useEffect(() => {
    liveViewFromSocket(liveViewById);
  }, [liveViewById]);

  useEffect(() => {
    if (process.env.REACT_APP_ZIPY_CODE) {
      zipy.init(process.env.REACT_APP_ZIPY_CODE);
    }
    if (!window.Hls) {
      window.Hls = hls;
    }
    dispatch(commonActions.setNavigator(navigate));
  }, []);

  return (
    <ThemeProvider>
      <Typography className="app" component="div">
        <DashboardLayout>
          <Grid sx={{ marginTop: "8px", height: "100%" }}>
            <Outlet />
          </Grid>
        </DashboardLayout>
      </Typography>
    </ThemeProvider>
  );
}

export default App;
