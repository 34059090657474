import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            light: "#000000",
            main: "#FFFFFF",
            theme: "#000"
        },
        secondary: {
            light: "#EFEFEF",
            main: "#F6F6F6",
            primary: "#FFFFFF"
        },
        background: {
            main: "#F6F6F6",
            light: "#E4002B"
        },
        cardBackground: {
            main: "#"
        },
        purple: {
            main: "#7066e0"
        },
        error: {
            main: "#FC3B3B"
        },
        white: {
            main: "#ffffff"
        },
        shaded: {
            main: "#FFFFFF"
        },
        dark: {
            main: "#000"
        },
        boneWhite: {
            main: "#F9F6EE"
        },
        fontColor: {
            main: "#AFAFAF",
            light: "#000"
        },
        header: {
            main: "#FFFFFF",
            light: ""
        },
        button: {
            main: "",
            primary: "#E4002B"
        },
        sideBar: {
            main: "#FFFFFF",
            dark: "#EFEFEF"
        },
        inputField: {
            main: "#EAEAEA"
        },
        footer: {
            main: "#E4E4E4",
            primary: "#080A0E"
        },
        iconColor: {
            main: "#000"
        },
        label: {
            main: "#000"
        },
        tablabel: {
            main: "#000"
        }
    }
});

export default lightTheme;
