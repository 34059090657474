import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
import { getPayloadContent } from "../../../utils/apiUtils";
import { ACTION_TYPES as COMMON_ACTION } from "../../common/actions";

const DEVICE_DETAILS = {
    deviceMode: "",
    deviceType: "",
    name: "",
    offlineAdd: false,
    protocol: "",
    deviceModel: "",
    deviceIp: "",
    port: "",
    userName: "",
    password: "",
    videoChannel: "",
    alarmInput: "",
    alarmOutput: "",
    dns: "",
    importToGroup: false,
    deviceChannel: []
};

const initialState = {
    deviceGetById: {
        requestInProgress: false,
        data: {
            ...DEVICE_DETAILS
        }
    },
    deviceList: {
        requestInProgress: false,
        data: {}
    },
    count: 0,
    deviceModeDropdown: {
        requestInProgress: false,
        data: []
    },
    deviceTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    deviceProtocolDropdown: {
        requestInProgress: false,
        data: []
    },
    encodeDevice: "",
    decodeDevice: "",
    customizedList: {
        requestInProgress: false,
        data: []
    },
    deviceListAll: {
        requestInProgress: false,
        data: []
    },
    deviceGroupById: {
        requestInProgress: false,
        data: {
            name: ""
        }
    },
    requestInProgress: false,
    countOnlineOfflineDevices: {
        requestInProgress: false,
        data: []
    },
    deviceAddCountAutoSearch: 0,
    progressiveCount: 0,
    socketData: []
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.deviceGetById = initialState.deviceGetById;
        },
        clearDeviceGroup: (state) => {
            state.deviceGroupById = initialState.deviceGroupById;
        },
        setCount: (state) => {
            state.count = state.count + 1;
        },
        decrementCount: (state) => {
            state.count = state.count - 1;
        },
        setRowSelectionData: (state, action) => {
            state.deviceList.data = action.payload;
        },
        setCountDeviceAdd: (state, action) => {
            state.deviceAddCountAutoSearch = action?.payload;
        },
        decrementCountDeviceAdd: (state) => {
            state.deviceAddCountAutoSearch = state.deviceAddCountAutoSearch - 1;
        },
        incrementProgressiveBar: (state) => {
            state.progressiveCount = state.progressiveCount + 1;
        },
        decrementProgressiveBar: (state) => {
            state.progressiveCount = 0;
        },
        setSocketData: (state, action) => {
            state.socketData = action?.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.DEVICE_GET_BY_ID_REQUEST, (state) => {
                state.deviceGetById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.DEVICE_GET_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "deviceGetById.requestInProgress", false);
                _.set(state, "deviceGetById.data", action.payload);
            })
            .addCase(ACTION_TYPES.DEVICE_GET_BY_ID_FAILURE, (state) => {
                state.deviceGetById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_DEVICE_MODE_SUCCESS, (state, action) => {
                _.set(state, "deviceModeDropdown.requestInProgress", false);
                _.set(state, "deviceModeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_DEVICE_TYPE_SUCCESS, (state, action) => {
                _.set(state, "deviceTypeDropdown.requestInProgress", false);
                _.set(state, "deviceTypeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_DEVICE_PROTOCOL_SUCCESS, (state, action) => {
                _.set(state, "deviceProtocolDropdown.requestInProgress", false);
                _.set(state, "deviceProtocolDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.LIST_DEVICE_SUCCESS, (state, { payload }) => {
                _.set(state, "encodeDevice", payload?.totalRecords);
            })
            .addCase(ACTION_TYPES.LIST_DECODE_DEVICE_SUCCESS, (state, { payload }) => {
                _.set(state, "decodeDevice", payload?.totalRecords);
            })
            .addCase(ACTION_TYPES.DEVICE_GROUP_GET_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "deviceGroupById.data", payload);
            })
            .addCase(ACTION_TYPES.MOVE_DOWN_REQUEST, (state) => {
                state.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.MOVE_DOWN_SUCCESS, (state) => {
                _.set(state, "requestInProgress", false);
            })
            .addCase(ACTION_TYPES.MOVE_DOWN_FAILURE, (state) => {
                state.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.MOVE_UPWARD_REQUEST, (state) => {
                state.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.MOVE_UPWARD_SUCCESS, (state) => {
                _.set(state, "requestInProgress", false);
            })
            .addCase(ACTION_TYPES.MOVE_UPWARD_FAILURE, (state) => {
                state.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.IMPORT_GROUP_REQUEST, (state) => {
                state.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.IMPORT_GROUP_SUCCESS, (state) => {
                _.set(state, "requestInProgress", false);
            })
            .addCase(ACTION_TYPES.IMPORT_GROUP_FAILURE, (state) => {
                state.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.EXPORT_GROUP_REQUEST, (state) => {
                state.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.EXPORT_GROUP_SUCCESS, (state) => {
                _.set(state, "requestInProgress", false);
            })
            .addCase(ACTION_TYPES.EXPORT_GROUP_FAILURE, (state) => {
                state.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.LIST_CUSTOMIZED_REQUEST, (state) => {
                state.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIST_CUSTOMIZED_SUCCESS, (state, { payload }) => {
                _.set(state, "requestInProgress", false);
                _.set(state, "customizedList.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_CUSTOMIZED_FAILURE, (state) => {
                state.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.LIST_DEVICE_ALL_REQUEST, (state) => {
                state.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIST_DEVICE_ALL_SUCCESS, (state, { payload }) => {
                _.set(state, "requestInProgress", false);
                _.set(state, "deviceListAll.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_DEVICE_ALL_FAILURE, (state) => {
                state.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.POST_DEVICE_DETAILS_REQUEST, (state) => {
                state.deviceGetById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.POST_DEVICE_DETAILS_SUCCESS, (state) => {
                _.set(state, "deviceGetById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.POST_DEVICE_DETAILS_FAILURE, (state) => {
                state.deviceGetById.requestInProgress = false;
            })
            .addCase(COMMON_ACTION.FETCH_SEARCH_DATA_REQUEST, (state) => {
                _.set(state, "customizedList.requestInProgress", true);
            })
            .addCase(COMMON_ACTION.FETCH_SEARCH_DATA_SUCCESS, (state, { payload }) => {
                _.set(state, "customizedList.data", payload);
                _.set(state, "customizedList.requestInProgress", false);
            })
            .addCase(COMMON_ACTION.FETCH_SEARCH_DATA_FAILURE, (state) => {
                _.set(state, "customizedList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES_REQUEST, (state) => {
                _.set(state, "countOnlineOfflineDevices.requestInProgress", true);
            })
            .addCase(ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES_SUCCESS, (state, { payload }) => {
                _.set(state, "countOnlineOfflineDevices.data", payload);
                _.set(state, "countOnlineOfflineDevices.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES_FAILURE, (state) => {
                _.set(state, "countOnlineOfflineDevices.requestInProgress", false);
            });
    }
});
export const { actions, reducer } = slice;
