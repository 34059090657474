import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listResourceDetails = (data) => ({
    url: API_URL.ROLE_MANAGEMENT.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_ROLE_DATA_REQUEST, ACTION_TYPES.LIST_ROLE_DATA_SUCCESS, ACTION_TYPES.LIST_ROLE_DATA_FAILURE],
        data
    }
});

export const fetchResourcesData = (data) => {
    return {
        url: API_URL.ROLE_MANAGEMENT.LIST_RESOURCE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FETCH_RESOURCE_DATA_REQUEST, ACTION_TYPES.FETCH_RESOURCE_DATA_SUCCESS, ACTION_TYPES.FETCH_RESOURCE_DATA_FAILURE],
            data
        }
    };
};

export const saveResourcesData = (data) => {
    return {
        url: API_URL.ROLE_MANAGEMENT.SAVE_RESOURCE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.CREATE_RESOURCE_DATA_REQUEST, ACTION_TYPES.CREATE_RESOURCE_DATA_SUCCESS, ACTION_TYPES.CREATE_RESOURCE_DATA_FAILURE],
            data
        }
    };
};

export const checkedResourcesData = (data) => {
    return {
        url: API_URL.ROLE_MANAGEMENT.CHECKED_RESOURCE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.CHECKED_RESOURCE_DATA_REQUEST, ACTION_TYPES.CHECKED_RESOURCE_DATA_SUCCESS, ACTION_TYPES.CHECKED_RESOURCE_DATA_FAILURE],
            data
        }
    };
};

export const updateResourcesData = (data) => {
    return {
        url: API_URL.ROLE_MANAGEMENT.SAVE_RESOURCE,
        method: REQUEST_METHOD.PUT,
        payload: {
            types: [ACTION_TYPES.UPDATE_RESOURCE_DATA_REQUEST, ACTION_TYPES.UPDATE_RESOURCE_DATA_SUCCESS, ACTION_TYPES.UPDATE_RESOURCE_DATA_FAILURE],
            data
        }
    };
};
