import { Form, withFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import { TbUser } from "react-icons/tb";

import { Components, FormController, I18n, Icons } from "../../../../common/components";
import { STATE_REDUCER_KEY } from "../../../user-management/register/constants";
import { STATE_REDUCER_KEY as COMMON_REDUCER_KEY } from "../../constants";
import { fetchUnlockScreen } from "../../actions";
import { Typography } from "@mui/material";

const { Modal, Card, Grid, CardContent, Button } = Components;
const { Visibility, VisibilityOff } = Icons;

const LockScreen = (props) => {
    const { handleSubmit, values, resetForm, setFieldValue } = props;
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const themes = useSelector(state => state[COMMON_REDUCER_KEY].switch.data);
    const open = useSelector(state => state[COMMON_REDUCER_KEY].lockScreen);
    const userId = useSelector(state => state[STATE_REDUCER_KEY].userId);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [password, setPassword] = useState();
    const passwordRegex = /^[0-9a-zA-Z#@.$_-]*$/;

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "primary.main",
        border: themes === "darkTheme" ? "1px solid #526D82" : "",
        p: 2,
        borderRadius: "20px",
        width: "500px",
        height: "333px",
        overflow: "auto"
    };

    const handleUnlock = () => {
        let trimmedPassword = values?.loginPassword;
        let payload = { loginId: userId, loginPassword: trimmedPassword };
        if (_.isEmpty(trimmedPassword)) {
            setPassword("Password is required");
        } else if (!passwordRegex.test(trimmedPassword)) {
            setPassword(I18n("password_message"));
        } else {
            setPassword("");
            dispatch(fetchUnlockScreen(payload));
            setShowPassword(false);
        }
    };

    useEffect(() => {
        return () => {
            resetForm();
            setFieldValue("loginPassword", "");
        };
    }, [open]);

    useEffect(() => {
        let trimmedPassword = values?.loginPassword;
        if (!passwordRegex.test(trimmedPassword)) {
            setPassword(I18n("password_message"));
        } else {
            setPassword("");
        }
    }, [values?.loginPassword]);

    return (
        <>
            <Modal open={open} sx={{ zIndex: 200 }}>
                <Card sx={modalStyle} >
                    <Form onSubmit={handleSubmit}>
                        <CardContent sx={{ ml: 2, mr: 2 }}>
                            <Grid container spacing={4} >
                                <Grid item xs={12} md={12} lg={12}>
                                    <FormController control="input" name="loginId" value={userId} label={I18n("user_name")} placeholder="User Name" icon={<TbUser color="#5E5E5E" />} disabled={true} />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <FormController control="input" name="loginPassword" label={I18n("password")} placeholder="Password" type={showPassword ? "text" : "password"} icon={showPassword ? <VisibilityOff sx={{ color: "#5E5E5E" }} /> : <Visibility sx={{ color: "#5E5E5E" }} />} onClick={handleClickShowPassword} />
                                    {(password && (values?.loginPassword === "" || values?.loginPassword)) && <Typography sx={{ color: "red" }} className="error-message">{password}</Typography>}
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Grid sx={{ display: "flex", alignItems: "center", gap: "10px" }} container justifyContent="center" >
                            <Button variant="contained" startIcon={<LockOpenIcon />} onClick={() => handleUnlock()}>{I18n("unlock_screen")}</Button>
                        </Grid>
                    </Form>
                </Card>
            </Modal>
        </>
    );
};

const AddDeviceForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({ loginId: "", loginPassword: "" }),
    displayName: "LockScreen"
})(LockScreen);

export default connect(null, null)(AddDeviceForm);
