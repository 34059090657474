import { Divider } from "@mui/material";
import { Components, I18n } from "../../../../common/components";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Logout } from "@mui/icons-material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../actions";
import { actions as sliceActions } from "../../../user-management/register/slice";
import UpdatePassword from "../../../user-management/register/components/UpdatePassword";
import { useState } from "react";
// import { actions as homeActions } from "../../../home/home/slice";

const { MenuItem, Menu } = Components;

const ProfileMenu = ({ anchorEl, handleClose, open }) => {
    const socket = useSelector((state) => state?.register?.socket);
    const pingInterval = useSelector((state) => state?.register?.pingInterval);
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const active = open ? "block" : "none";
    const logOut = () => {
        dispatch(logoutUser({ from: "header" }));
        if (process.env.REACT_APP_ENV_TYPE === "app") {
            window.electron.sendCloseLiveTab();
        }

        if (socket) {
            if (socket.readyState === WebSocket.OPEN) {
                clearInterval(pingInterval);
                dispatch(sliceActions.setSocketOpen(false));
                dispatch(sliceActions.setPingInterval(0));
                dispatch(sliceActions.setWebSocket({}));
                socket.close();
            }
        }
    };
    const listStyle = {
        mx: 1,
        pl: 2,
        pr: 4,
        borderRadius: "10px",
        color: "primary.light",
        "&:hover": {
            color: "primary.light"
        },
        fontSize: "14px"
    };

    const handleOpen = () => {
        setOpenStatus(true);
        setStatus(true);
    };
    return (
        <>
            <Menu
                sx={{ display: active, mt: 5, mr: 2 }}
                id="basic-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
            >
                <MenuItem onClick={() => handleOpen()} sx={listStyle} >
                    <ListItemIcon>
                        <Person2OutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    {I18n("update_password")}
                </MenuItem>
                {/* {process.env.REACT_APP_ENV_TYPE !== "app" && */}
                <>
                    <Divider sx={{ color: "primary.light" }} />
                    <MenuItem
                        onClick={logOut}
                        sx={listStyle}
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        {I18n("logout")}
                    </MenuItem>
                </>
                {/* } */}
            </Menu >
            {status === true && <UpdatePassword open={openStatus} setOpenStatus={setOpenStatus} setOpen={handleClose} />}
        </>
    );
};

export default ProfileMenu;
