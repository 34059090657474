import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES as PLAYBACK_ACTION_TYPES } from "../../home/playBack/actions";

const formatPayloadValues = (values = {}) => {
    const { fromTime, toTime, deviceCheck, ...rest } = values;
    const deviceList = {
        fromTime,
        toTime,
        deviceList: deviceCheck,
        ...rest
    };
    return deviceList;
};

const initialState = {
    playBackRecordData: {
        requestInProgress: false,
        data: []
    },
    deviceListData: {},
    playbackSlotId: {}
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearPlayBack: (state) => {
            state.playBackRecordData.data = initialState.playBackRecordData.data;
            state.deviceListData = initialState.deviceListData;
            state.playbackSlotId = initialState.playbackSlotId;
        },
        setDeviceListData: (state, payload) => {
            const filteredData = Object.keys(payload?.payload).reduce((acc, key) => {
                const value = payload?.payload[key];
                // Check if the value is not null, not undefined, not an empty string, and not NaN
                if (value !== null && value !== undefined && value !== "" && !Number.isNaN(value)) {
                    acc[key] = value;
                }
                return acc;
            }, {});
            state.deviceListData = formatPayloadValues(filteredData);
        },
        setPlaybackSlot: (state, payload) => {
            state.playbackSlotId = payload?.payload;
        },
        clearPlayBackSlot: (state) => {
            state.playbackSlotId = initialState.playbackSlotId;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PLAYBACK_ACTION_TYPES.FETCH_QUERY_RECORD_REQUEST, (state) => {
            state.playBackRecordData.requestInProgress = true;
        });
        builder.addCase(PLAYBACK_ACTION_TYPES.FETCH_QUERY_RECORD_SUCCESS, (state, { payload }) => {
            _.set(state, "playBackRecordData.requestInProgress", false);
            _.set(state, "playBackRecordData.data", payload);
        });
        builder.addCase(PLAYBACK_ACTION_TYPES.FETCH_QUERY_RECORD_FAILURE, (state) => {
            state.playBackRecordData.requestInProgress = false;
            _.set(state, "playBackRecordData.data", {});
        });
    }
});

export const { reducer, actions } = slice;
