import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import { I18n } from "../../../common/components";
import { TABLE_IDS } from "../../../common/constants";
import { handleAPIRequest } from "../../../utils/http";
import { successNotify } from "../../../utils/notificationUtils";
import { setCommonTableData } from "../../../utils/tableUtils";
import { getTableProps } from "../../common/selectors";
import { actions as commonActions } from "../../common/slice";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";

export function* listResourceManagement({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.RESOURCE_MANAGEMENT_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listResourceDetails, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.RESOURCE_MANAGEMENT_LIST);
}

export function* submitResourceManagement({ payload }) {
    yield fork(handleAPIRequest, Api.submitResourceDetails, payload);
    const responseAction = yield take([ACTION_TYPES.POST_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.POST_RESOURCE_MANAGEMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_RESOURCE_MANAGEMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("resource_management") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listResourceManagement, { payload: tablePayload });
        yield put(commonActions.setOpenModal(false));
    }
}

export function* updateResourceManagement({ payload }) {
    let updatedPayload = _.omit(payload, "resourceId");
    yield fork(handleAPIRequest, Api.updateResourceDetails, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("resource_management") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listResourceManagement, { payload: tablePayload });
        yield put(commonActions.setOpenModal(false));
    }
}

export function* resourceManagementById({ payload: id }) {
    yield call(handleAPIRequest, Api.resourceDetailsById, id);
}

export function* deleteResourceManagement({ payload: id }) {
    yield fork(handleAPIRequest, Api.deleteResourceDetails, id);
    const response = yield take([ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("resource_management") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listResourceManagement, { payload: tablePayload });
    }
}

export function* selecthModuleId(action) {
    yield call(handleAPIRequest, Api.selecthModuleId, action.payload);
}

export function* selectParent({ payload }) {
    yield call(handleAPIRequest, Api.selectParent, { isParent: true, moduleId: payload });
}

export default function* resourceManagementSaga() {
    yield all([
        takeLatest(ACTION_TYPES.LIST_RESOURCE_MANAGEMENT, listResourceManagement),
        takeLatest(ACTION_TYPES.POST_RESOURCE_MANAGEMENT, submitResourceManagement),
        takeLatest(ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT, updateResourceManagement),
        takeLatest(ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID, resourceManagementById),
        takeLatest(ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT, deleteResourceManagement),
        takeLatest(ACTION_TYPES.SELECT_MODULE_ID, selecthModuleId),
        takeLatest(ACTION_TYPES.SELECT_PARENT_ID, selectParent)
    ]);
}
