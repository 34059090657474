import { createAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { call, delay, put, select } from "redux-saga/effects";
import { deleteRequest, getRequest, patchFormData, patchRequest, postFormData, postRequest, putRequest } from "../app/axios";
import { HTTP_CONSTANTS, REQUEST_METHOD, STORAGE_KEYS } from "../common/constants";
import { API_URL } from "../modules/user-management/apiUrls";
import { errorNotify, warningNotify } from "./notificationUtils";
import { I18n } from "../common/components";
import { navigateTo } from "../modules/common/actions";
import { actions as sliceActions } from "../modules/home/homeModule/slice";
import { actions as registerActions } from "../modules/user-management/register/slice";
import { actions as commonActions } from "../modules/common/slice";
import { actions as playbackActions } from "../modules/home/playback-persist/slice";
import { getPingIntervalDetails, getWebSocketDetails } from "../modules/user-management/register/selectors";

const HTTP_RESPONSE_STATUS = {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
    UN_AUTHORIZED: 401
};

const ERROR_CODES = {
    JWT_TOKEN_EXPIRED: 4401,
    INVALID_TOKEN: 4403
};

export const generateListedErrors = (array = []) => {
    if (array.length > 1) {
        return _.join(array, "</li><li>");
    } else {
        return _.head(array);
    }
};

const KEY_CLOAK_APIS = [
    API_URL.USER_MANAGEMENT.AUTH_TOKEN
];

const NON_RESTRICTED_URLS = [
    API_URL.USER_MANAGEMENT.USER_PROFILE,
    API_URL.USER_MANAGEMENT.CHANGE_PASSWORD,
    API_URL.USER_MANAGEMENT.SECURITY_QUESTIONS,
    API_URL.USER_MANAGEMENT.AUTOMATIC_LOGIN
];

const requestWrapper = (body = {}) => ({ payLoad: { ...body } });

export const getRequestParams = ({ url, data, method }) => {
    let headers = HTTP_CONSTANTS.HTTP_HEADERS;
    let baseURL = "";
    let authHeaders = {};
    let bearerToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
    let sessionId = localStorage.getItem(STORAGE_KEYS.SESSION_ID);
    let extraParams = {};

    const api = (method === REQUEST_METHOD.POST_FORMDATA) ? postFormData : (method === REQUEST_METHOD.DELETE) ? deleteRequest : (method === REQUEST_METHOD.PUT) ? putRequest : (method === REQUEST_METHOD.PATCH) ? patchRequest : (method === REQUEST_METHOD.POST) ? postRequest : (method === REQUEST_METHOD.PATCH_FORMDATA) ? patchFormData : getRequest;

    if (url === API_URL.USER_MANAGEMENT.AUTH_TOKEN) {
        headers["Content-Type"] = "application/x-www-form-urlencoded";
        baseURL = process.env.REACT_APP_AUTH_URL;
    } else if (method === REQUEST_METHOD.POST_FORMDATA || method === REQUEST_METHOD.PATCH_FORMDATA) {
        headers["Content-Type"] = "multipart/form-data";
        baseURL = process.env.REACT_APP_AUTH_URL;
        if (!NON_RESTRICTED_URLS.includes(url)) {
            if (bearerToken) {
                authHeaders = { Authorization: `Bearer ${bearerToken}`, sessionId: sessionId };
            }
        }
    } else {
        baseURL = process.env.REACT_APP_API_URL;
        headers["Content-Type"] = "application/json";
        if (!NON_RESTRICTED_URLS.includes(url)) {
            if (bearerToken) {
                authHeaders = { Authorization: `Bearer ${bearerToken}`, sessionId: sessionId };
            }
            // else {
            //     window.location.hash = "/";
            //     return;
            // }
        }
    }

    if ((method === REQUEST_METHOD.PUT || method === REQUEST_METHOD.PATCH || method === REQUEST_METHOD.POST) && url !== API_URL.USER_MANAGEMENT.AUTH_TOKEN) {
        data = requestWrapper(data);
    }

    if (method === REQUEST_METHOD.FILE) {
        extraParams.responseType = "blob";
    }

    return { config: { headers: { ...headers, ...authHeaders }, ...extraParams }, baseURL, data, api };
};

const API_RESULT_CODE = {
    SUCCESS: "COMM_OPERATION_SUCCESS",
    FAILURE: "COMM_OPERATION_FAILURE",
    CHANGE_PASSWORD: "COMM_DEFAULT_PASSWORD_CHANGE",
    AUTOMATIC_LOGIN: "COMM_DEFAULT_LOGIN"
};

function* invokeApi(method, url, payload) {
    // TODO: headers , token , etc ... need to be handled
    const { types = ["REQUEST", "SUCCESS", "FAILURE"], data: payloadData } = payload;
    let requestAction = createAction(types[0]), successAction = createAction(types[1]), failureAction = createAction(types[2]);
    const socket = yield select(getWebSocketDetails);
    const pingInterval = yield select(getPingIntervalDetails);
    yield put(requestAction());
    const { api, config, baseURL, data } = getRequestParams({ url, data: payloadData, method });
    const apiResponse = yield call(api, url, { config, baseURL, data });
    const { data: response, error } = apiResponse;
    if (error) {
        yield put(failureAction({ error }));
        const { code: id, message, response: { status, statusText, data: { resultString, errors = [], errorCode, error_description: errorDescription, error: dataError } = {} } = {} } = error;
        let errorMessage = {};
        switch (status) {
            case HTTP_RESPONSE_STATUS.BAD_REQUEST:
                errorMessage = { title: `${I18n(resultString) || "ERROR"}`, message: `<ul><li>${generateListedErrors(errors)}</li></ul>` };
                break;
            case HTTP_RESPONSE_STATUS.FORBIDDEN:
                {
                    if ((4401 === ERROR_CODES.JWT_TOKEN_EXPIRED) && (I18n(resultString) !== "Access Denied")) {
                        errorMessage = { title: "Token Expired", message: "Please login again." };
                        yield delay(500);
                        localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
                        localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
                        yield put(sliceActions.clearAll());
                        yield put(commonActions.clearLive());
                        yield put(playbackActions.clearPlayBack());
                        yield put(navigateTo("/login"));
                    } else if ((errorCode === ERROR_CODES.INVALID_TOKEN) && (I18n(resultString) !== "Access Denied")) {
                        errorMessage = { title: "Invalid Token", message: "Please login again." };
                        yield delay(500);
                        localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
                        localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
                        yield put(sliceActions.clearAll());
                        yield put(commonActions.clearLive());
                        yield put(playbackActions.clearPlayBack());
                        yield put(navigateTo("/login"));
                    } else {
                        errorMessage = { title: `${dataError || statusText || "ERROR"}`, message: I18n(resultString) || I18n(errorDescription) };
                        yield delay(500);
                        localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
                        localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
                        yield put(sliceActions.clearAll());
                        yield put(commonActions.clearLive());
                        yield put(playbackActions.clearPlayBack());
                        if (socket) {
                            clearInterval(pingInterval);
                            yield put(registerActions.setSocketOpen(false));
                            yield put(registerActions.setPingInterval(0));
                            yield put(registerActions.setWebSocket({}));
                            socket.close();
                        }
                        yield put(navigateTo("/login"));
                    }
                }
                break;
            case HTTP_RESPONSE_STATUS.NOT_FOUND:
            case HTTP_RESPONSE_STATUS.INTERNAL_SERVER_ERROR:
                errorMessage = { title: "Error", message: I18n(resultString) };
                break;
            case HTTP_RESPONSE_STATUS.SERVICE_UNAVAILABLE:
                errorMessage = { title: "Error", message: I18n(resultString) || I18n(message) };
                break;
            case HTTP_RESPONSE_STATUS.UN_AUTHORIZED:
                errorMessage = { title: "Error", message: I18n(resultString) || I18n(message) };
                yield delay(500);
                yield put(sliceActions.clearAll());
                yield put(commonActions.clearLive());
                yield put(playbackActions.clearPlayBack());
                yield put(navigateTo("/login"));

                break;
            default:
                errorMessage = { title: `${status || ""} ${id || "ERROR"}`, message: I18n(resultString) || I18n(message) };
                break;
        }
        yield put(errorNotify({ id, ...errorMessage }));
    } else {
        if (_.get(response, "resultCode", "") === API_RESULT_CODE.FAILURE) {
            yield put(warningNotify({ id: "ERROR_PRIMARY", title: "Operation Failure", message: _.get(response, "resultString", "Operation Failure") }));
        } else if (_.has(response, "error")) {
            let customError = response.error || {};
            yield put(failureAction({ error: customError }));
            const { code, message, response: { status, data: { resultString } = {} } = {} } = customError;
            yield put(errorNotify({ id: "ERROR_PRIMARY", title: `${status || ""} ${code || "ERROR"}`, message: I18n(resultString) || I18n(message) }));
        } else {
            yield put(successAction(KEY_CLOAK_APIS.includes(url) || method === REQUEST_METHOD.FILE ? response : _.get(response, "payLoad", {})));
        }
        if (url === API_URL.USER_MANAGEMENT.USER_PROFILE) {
            if (_.get(response, "resultCode", "COMM_DEFAULT_PASSWORD_CHANGE") === API_RESULT_CODE.CHANGE_PASSWORD) {
                yield put(navigateTo("/change-password"));
                yield put(warningNotify({ id: "ERROR_PRIMARY", title: "Change default password", message: _.get(response, "errors", "Change default password") }));
            } else {
                yield put(navigateTo("/home"));
            }
        }
        if (url === API_URL.USER_MANAGEMENT.AUTOMATIC_LOGIN) {
            if (_.get(response, "resultCode", "COMM_DEFAULT_LOGIN") === API_RESULT_CODE.AUTOMATIC_LOGIN) {
                localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
                localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
                yield put(navigateTo("/login"));
            }
        }
    }

    return { response, error };
}

export function* handleAPIRequest(apiFn, ...rest) {
    let { method, url, payload } = apiFn(...rest);
    return yield call(invokeApi, method, url, payload);
}
