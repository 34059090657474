import { all, fork, put, take, takeLatest } from "redux-saga/effects";

import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { successNotify } from "../../../utils/notificationUtils";
import { I18n } from "../../../common/components";
import { actions as sliceActions } from "../playback-persist/slice";

const formatPayloadValues = (values = {}) => {
    const { fromTime, toTime, uniqueEntries, ...rest } = values;
    const deviceList = {
        fromTime,
        toTime,
        deviceList: uniqueEntries,
        ...rest
    };
    return deviceList;
};
export function* fetchQueryRecord({ payload }) {
    const { ...data } = payload;
    const filteredData = Object.keys(data).reduce((acc, key) => {
        const value = data[key];
        // Check if the value is not null, not undefined, not an empty string, and not NaN
        if (value !== null && value !== undefined && value !== "" && !Number.isNaN(value)) {
            acc[key] = value;
        }
        return acc;
    }, {});
    yield fork(handleAPIRequest, Api.fetchQueryRecordApi, formatPayloadValues(filteredData));
    const responseAction = yield take([ACTION_TYPES.FETCH_QUERY_RECORD_SUCCESS, ACTION_TYPES.FETCH_QUERY_RECORD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.FETCH_QUERY_RECORD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("playback_query_filtered") }));
    }
}
export function* fetchDownload({ payload }) {
    const filteredData = Object.keys(payload).reduce((acc, key) => {
        const value = payload[key];
        // Check if the value is not null, not undefined, not an empty string, and not NaN
        if (value !== null && value !== undefined && value !== "" && !Number.isNaN(value)) {
            acc[key] = value;
        }
        return acc;
    }, {});
    yield fork(handleAPIRequest, Api.fetchDownloadApi, formatPayloadValues(filteredData));
    const responseAction = yield take([ACTION_TYPES.FETCH_DOWNLOAD_SUCCESS, ACTION_TYPES.FETCH_DOWNLOAD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.FETCH_DOWNLOAD_SUCCESS) {
        // yield put(successNotify({ title: I18n("success"), message: I18n("download_initiated") }));
    }
}

export function* fetchStopPlayback({ payload }) {
    const { slotResponse } = payload;
    yield put(sliceActions.clearPlayBack());
    yield fork(handleAPIRequest, Api.fetchStopPlaybackApi, slotResponse);
    const responseAction = yield take([ACTION_TYPES.STOP_PLAYBACK_SUCCESS, ACTION_TYPES.STOP_PLAYBACK_FAILURE]);
    if (responseAction.type === ACTION_TYPES.STOP_PLAYBACK_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("previous_playback_stopped") }));
        yield put(sliceActions.setPlaybackSlot({}));
    }
}
export default function* playBackSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_QUERY_RECORD, fetchQueryRecord),
        takeLatest(ACTION_TYPES.FETCH_DOWNLOAD, fetchDownload),
        takeLatest(ACTION_TYPES.STOP_PLAYBACK, fetchStopPlayback)
    ]);
}
