import { lazy } from "react";
import App from "../App";
import { I18n, Icons, RootBoundary } from "../common/components";
import { ThemeProvider } from "../layouts/ThemeContext";

import { routes as userManagement } from "../modules/user-management/routes";
const TabComponent = lazy(() => import("./live/liveView/components/TabComponent"));
const HomeSubWrapper = lazy(() => import("./home/homeModule/components/HomeSubWrapper"));
const DeviceWrapper = lazy(() => import("./device/device/components/DeviceWrapper"));
const AlarmPage = lazy(() => import("./alarm/components/AlarmPage"));
const RoleManagementAdd = lazy(() => import("./home/roleManagement/components/RoleManagementAdd"));
const TabReactHlsLIve = lazy(() => import("./live/liveView/components/TabReactHlsLIve"));
const ElectronicMap = lazy(() => import("./home/electronicMap/components/ElectronicMapDetails"));
import { MODULE_PATH } from "./paths";
import { PrivateRoute } from "./user-management/protected-route/protectedRoute";

const { Home } = Icons;

const routes =
    [
        {
            path: "/",
            element:
                <PrivateRoute>
                    <App />
                </PrivateRoute>,
            errorElement: <RootBoundary />,
            icon: <Home />,
            children: [
                {
                    path: MODULE_PATH.HOME,
                    element:
                        <PrivateRoute>
                            <HomeSubWrapper />
                        </PrivateRoute>,
                    errorElement: <RootBoundary />
                },
                {
                    path: MODULE_PATH.DEVICE,
                    element:
                        <PrivateRoute>
                            <DeviceWrapper />
                        </PrivateRoute>,
                    errorElement: <RootBoundary />
                },
                {
                    path: MODULE_PATH.LIVE,
                    element:
                        <PrivateRoute>
                            <TabComponent />
                        </PrivateRoute>,
                    errorElement: <RootBoundary />
                },
                {
                    path: MODULE_PATH.ALARM,
                    element:
                        <PrivateRoute>
                            <AlarmPage />
                        </PrivateRoute>,
                    errorElement: <RootBoundary />
                },
                {
                    path: MODULE_PATH.RESOURCES,
                    element:
                        <PrivateRoute>
                            <RoleManagementAdd />
                        </PrivateRoute>,
                    errorElement: <RootBoundary />
                },
                {
                    path: MODULE_PATH.ELECTRONIC_MAP,
                    element:
                        <PrivateRoute>
                            <ElectronicMap />
                        </PrivateRoute>,
                    errorElement: <RootBoundary />
                }
            ]
        },
        {
            path: MODULE_PATH.SEPARATE_TAB,
            element:
                <PrivateRoute>
                    <ThemeProvider>
                        <TabReactHlsLIve />
                    </ThemeProvider>
                </PrivateRoute>,
            errorElement: <RootBoundary />
        },
        {
            title: I18n("user_management"),
            children: userManagement || []
        }
    ];

export { routes };

