import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import darkThemes from "../common/themes/darkThemes";
import lightThemes from "../common/themes/lightThemes";
import { STATE_REDUCER_KEY } from "../modules/common/constants";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const themes = useSelector(state => state[STATE_REDUCER_KEY].switch.data);
    const [theme, setTheme] = useState(lightThemes);

    useEffect(() => {
        if (themes === "lightTheme") {
            setTheme(lightThemes);
        } else {
            setTheme(darkThemes);
        }
    }, [themes]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === lightThemes ? darkThemes : lightThemes));
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            <MuiThemeProvider theme={theme}>
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
