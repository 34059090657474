import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    usersList: {
        requestInProgress: false,
        data: {}
    },
    count: 0
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setCount: (state) => {
            state.count = state.count + 1;
        },
        decrementCount: (state) => {
            state.count = state.count - 1;
        },
        setRowSelectionData: (state, action) => {
            state.usersList.data = action.payload;
        }
    },
    extraReducers: () => { }
});
export const { actions, reducer } = slice;
