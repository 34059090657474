import { all, call, fork, put, take, takeLatest, select } from "redux-saga/effects";
import _ from "lodash";

import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES, viewList } from "./actions";
import * as Api from "./api";
import { infoNotify, successNotify } from "../../../utils/notificationUtils";
import { I18n } from "../../../common/components";
import { TABLE_IDS } from "../../../common/constants";
import { getTableProps, getUpdateMapping } from "../../common/selectors";
import { setCommonTableData } from "../../../utils/tableUtils";
import { getUserDetails } from "../../../utils/userUtils";
import { actions as commonSlice } from "../../common/slice";

export function* getGroupDeviceList({ payload }) {
    yield call(handleAPIRequest, Api.fetchGroupDeviceListApi, payload);
}

export function* liveDetailsById({ payload }) {
    const updatedPayload = { deviceList: [...payload.data], mainStream: payload.mainStream ?? false };
    yield fork(handleAPIRequest, Api.liveDetailsById, updatedPayload);
    const response = yield take([ACTION_TYPES.LIVE_VIEW_BY_ID_SUCCESS, ACTION_TYPES.LIVE_VIEW_BY_ID_FAILURE]);
    if (response.type === ACTION_TYPES.LIVE_VIEW_BY_ID_FAILURE) {
        yield put(commonSlice.clearUpdateMappingOnFailure({ tabId: payload?.data[0]?.tabId, deviceList: [...payload.data] }));
        yield put(commonSlice.clearChannelIds({ tabId: payload?.data[0]?.tabId }));
        yield put(commonSlice.clearDeviceIds({ tabId: payload?.data[0]?.tabId }));
        yield put(commonSlice.clearGroupIds({ tabId: payload?.data[0]?.tabId }));
    }

}

export function* stopStreaming({ payload }) {
    const updatedPayload = { slotList: [...payload] };
    yield fork(handleAPIRequest, Api.stopStreaming, updatedPayload);
    const response = yield take([ACTION_TYPES.STOP_STREAMING_SUCCESS, ACTION_TYPES.STOP_STREAMING_FAILURE]);
    if (response.type === ACTION_TYPES.STOP_STREAMING_SUCCESS) {
        yield put(infoNotify({ title: I18n("success"), message: I18n("stream_stopped") }));
    }
}

export function* stopStreamingTab({ payload }) {
    let slotList = [];
    let updateMapping = yield select(getUpdateMapping);
    let newUpdateMapping = updateMapping[`${payload?.tabId}_tab`];
    window.electron.consoling({ newUpdateMapping, payload, updateMapping });
    newUpdateMapping && newUpdateMapping.forEach(data => {
        if (data?.streamUrl !== "") {
            slotList.push(data?.streamUrl?.slotId);
        }
    });
    if (slotList.length > 0) {
        const updatedPayload = { slotList };
        yield fork(handleAPIRequest, Api.stopStreaming, updatedPayload);
        const response = yield take([ACTION_TYPES.STOP_STREAMING_SUCCESS, ACTION_TYPES.STOP_STREAMING_FAILURE]);
        if (response.type === ACTION_TYPES.STOP_STREAMING_SUCCESS) {
            yield put(infoNotify({ title: I18n("success"), message: I18n("stream_stopped") }));
        }
    }
}

export function* startRecording({ payload }) {
    const updatedPayload = { deviceList: [...payload] };
    yield fork(handleAPIRequest, Api.startRecording, updatedPayload);
    const response = yield take([ACTION_TYPES.START_RECORDING_SUCCESS, ACTION_TYPES.START_RECORDING_FAILURE]);
    if (response.type === ACTION_TYPES.START_RECORDING_SUCCESS) {
        yield put(infoNotify({ title: I18n("success"), message: I18n("recording_started", { type: I18n("recording") }) }));
    }
}

export function* stopRecording({ payload }) {
    const updatedPayload = { slotList: [...payload] };
    yield fork(handleAPIRequest, Api.stopRecording, updatedPayload);
    const response = yield take([ACTION_TYPES.STOP_RECORDING_SUCCESS, ACTION_TYPES.STOP_RECORDING_FAILURE]);
    if (response.type === ACTION_TYPES.STOP_RECORDING_SUCCESS) {
        yield put(infoNotify({ title: I18n("success"), message: I18n("recording_stoped", { type: I18n("recording") }) }));
    }
}

export function* viewListData({ payload }) {
    yield fork(handleAPIRequest, Api.fetchViewListApi, { ...payload });
}

export function* listSaveView({ payload }) {
    const userId = getUserDetails();
    const updatedPayload = { payload, userId: userId.userId, saveToCruisePlan: true };
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.LIVE_VIEW_SAVE_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listSaveViewListApi, { ...filters, ...updatedPayload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_VIEW_SUCCESS, ACTION_TYPES.LIST_VIEW_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.LIVE_VIEW_SAVE_LIST);
}

export function* saveView({ payload }) {
    const { newPayload: { setSaveViewModal, ...rest } } = payload;
    yield fork(handleAPIRequest, Api.saveViewApi, rest);
    const response = yield take([ACTION_TYPES.SAVE_VIEW_SUCCESS, ACTION_TYPES.SAVE_VIEW_FAILURE]);
    if (response.type === ACTION_TYPES.SAVE_VIEW_SUCCESS) {
        yield put(infoNotify({ title: I18n("success"), message: I18n("view_saved") }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listSaveView, { payload: tablePayload });
        yield put(viewList({ userId: rest.userId }));
        setSaveViewModal(false);
    }
}

export function* saveViewById({ payload }) {
    yield call(handleAPIRequest, Api.fetchViewByIdApi, payload);
}

export function* updateView({ payload }) {
    const { newPayload: { setEditStatus, ...rest } } = payload;
    yield fork(handleAPIRequest, Api.updateViewApi, rest);
    const response = yield take([ACTION_TYPES.UPDATE_VIEW_SUCCESS, ACTION_TYPES.UPDATE_VIEW_FAILURE]);
    if (response.type === ACTION_TYPES.UPDATE_VIEW_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("view_updated") }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listSaveView, { payload: tablePayload });
        yield put(viewList({ userId: rest.userId }));
        setEditStatus(false);
    }
}

export function* deleteView({ payload }) {
    const { setDeleteModal } = payload;
    yield fork(handleAPIRequest, Api.deleteViewApi, payload.id);
    const response = yield take([ACTION_TYPES.DELETE_VIEW_SUCCESS, ACTION_TYPES.DELETE_VIEW_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_VIEW_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("view_deleted") }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listSaveView, { payload: tablePayload });
        setDeleteModal(false);
    }
}

export function* moveUpView({ payload }) {
    const { id } = payload;
    yield fork(handleAPIRequest, Api.moveUpViewApi, id[0]);
    const response = yield take([ACTION_TYPES.MOVE_UP_VIEW_SUCCESS, ACTION_TYPES.MOVE_UP_VIEW_FAILURE]);
    if (response.type === ACTION_TYPES.MOVE_UP_VIEW_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("moved_up") }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listSaveView, { payload: tablePayload });
        yield put(commonSlice.setRowSelection({ key: TABLE_IDS.LIVE_VIEW_SAVE_LIST }));
    }
}

export function* moveDownView({ payload }) {
    const { id } = payload;
    yield fork(handleAPIRequest, Api.moveDownViewApi, id[0]);
    const response = yield take([ACTION_TYPES.MOVE_DOWN_VIEW_SUCCESS, ACTION_TYPES.MOVE_DOWN_VIEW_FAILURE]);
    if (response.type === ACTION_TYPES.MOVE_DOWN_VIEW_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("moved_down") }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listSaveView, { payload: tablePayload });
        yield put(commonSlice.setRowSelection({ key: TABLE_IDS.LIVE_VIEW_SAVE_LIST }));
    }
}

export function* viewById({ payload }) {
    let { editName } = payload;
    if (editName) {
        yield call(handleAPIRequest, Api.viewById, payload.id);
    } else {
        yield call(handleAPIRequest, Api.viewById, ...payload);
    }
}

export function* viewDelete({ payload }) {
    const userId = getUserDetails();
    yield fork(handleAPIRequest, Api.viewDelete, payload);
    const response = yield take([ACTION_TYPES.VIEW_DELETE_SUCCESS, ACTION_TYPES.VIEW_DELETE_FAILURE]);
    if (response.type === ACTION_TYPES.VIEW_DELETE_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("view_deleted") }));
        yield put(viewList(userId));
    }
}

export function* getStreamSwitch({ payload }) {
    const updatedPayload = { deviceList: [...payload.data], mainStream: payload.mainStream ?? true };
    yield fork(handleAPIRequest, Api.getStreamSwitch, updatedPayload);
    const response = yield take([ACTION_TYPES.GET_STREAM_SWITCH_SUCCESS, ACTION_TYPES.GET_STREAM_SWITCH_FAILURE]);
    if (response.type === ACTION_TYPES.GET_STREAM_SWITCH_SUCCESS) {
        let updateMapping = yield select(getUpdateMapping);
        let newUpdateMapping = updateMapping[`${payload?.tabId}_tab`] && updateMapping[`${payload?.tabId}_tab`].map((d) => {
            let updateData = response.payload.deviceList.find((data) => {
                return data.deviceId === d.deviceId;
            });
            if (!_.isEmpty(updateData)) {
                return {
                    ...d,
                    streamUrl: {
                        ...d.streamUrl,
                        mainStream: response.payload.mainStream
                    }
                };
            }
            return d;
        });
        yield put(commonSlice.setUpdatedMapping({ tabId: payload?.tabId, data: newUpdateMapping }));
        yield put(successNotify({ title: I18n("success"), message: I18n("stream_switched") }));
    }
}


export default function* liveViewSaga() {
    yield all([
        takeLatest(ACTION_TYPES.GROUP_DEVICE_LIST, getGroupDeviceList),
        takeLatest(ACTION_TYPES.LIVE_VIEW_BY_ID, liveDetailsById),
        takeLatest(ACTION_TYPES.STOP_STREAMING, stopStreaming),
        takeLatest(ACTION_TYPES.START_RECORDING, startRecording),
        takeLatest(ACTION_TYPES.STOP_RECORDING, stopRecording),
        takeLatest(ACTION_TYPES.SAVE_VIEW, saveView),
        takeLatest(ACTION_TYPES.LIST_VIEW, listSaveView),
        takeLatest(ACTION_TYPES.EDIT_VIEW_BY_ID, saveViewById),
        takeLatest(ACTION_TYPES.UPDATE_VIEW, updateView),
        takeLatest(ACTION_TYPES.DELETE_VIEW, deleteView),
        takeLatest(ACTION_TYPES.MOVE_UP_VIEW, moveUpView),
        takeLatest(ACTION_TYPES.MOVE_DOWN_VIEW, moveDownView),
        takeLatest(ACTION_TYPES.VIEW_LIST, viewListData),
        takeLatest(ACTION_TYPES.VIEW_LIST_BY_ID, viewById),
        takeLatest(ACTION_TYPES.VIEW_DELETE, viewDelete),
        takeLatest(ACTION_TYPES.GET_STREAM_SWITCH, getStreamSwitch),
        takeLatest(ACTION_TYPES.STOP_STREAMING_TAB, stopStreamingTab)
    ]);
}
