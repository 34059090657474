import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LOG_OUT: `${STATE_REDUCER_KEY}/LOG_OUT`,
    LOG_OUT_REQUEST: `${STATE_REDUCER_KEY}/LOG_OUT_REQUEST`,
    LOG_OUT_SUCCESS: `${STATE_REDUCER_KEY}/LOG_OUT_SUCCESS`,
    LOG_OUT_FAILURE: `${STATE_REDUCER_KEY}/LOG_OUT_FAILURE`,

    FETCH_SEARCH_DATA: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA`,
    FETCH_SEARCH_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA_REQUEST`,
    FETCH_SEARCH_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA_SUCCESS`,
    FETCH_SEARCH_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SEARCH_DATA_FAILURE`,

    FETCH_UNLOCK_SCREEN: `${STATE_REDUCER_KEY}/FETCH_UNLOCK_SCREEN`,
    FETCH_UNLOCK_SCREEN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_UNLOCK_SCREEN_REQUEST`,
    FETCH_UNLOCK_SCREEN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_UNLOCK_SCREEN_SUCCESS`,
    FETCH_UNLOCK_SCREEN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_UNLOCK_SCREEN_FAILURE`,

    FETCH_LOCK_SCREEN: `${STATE_REDUCER_KEY}/FETCH_LOCK_SCREEN`,
    FETCH_LOCK_SCREEN_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOCK_SCREEN_REQUEST`,
    FETCH_LOCK_SCREEN_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOCK_SCREEN_SUCCESS`,
    FETCH_LOCK_SCREEN_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOCK_SCREEN_FAILURE`,

    NAVIGATE_TO: `${STATE_REDUCER_KEY}/NAVIGATE_TO`,

    COMMON_DOWNLOAD_FILE: `${STATE_REDUCER_KEY}/COMMON_DOWNLOAD_FILE`,
    COMMON_DOWNLOAD_FILE_REQUEST: `${STATE_REDUCER_KEY}/COMMON_DOWNLOAD_FILE_REQUEST`,
    COMMON_DOWNLOAD_FILE_SUCCESS: `${STATE_REDUCER_KEY}/COMMON_DOWNLOAD_FILE_SUCCESS`,
    COMMON_DOWNLOAD_FILE_FAILURE: `${STATE_REDUCER_KEY}/COMMON_DOWNLOAD_FILE_FAILURE`

};

export const logoutUser = createAction(ACTION_TYPES.LOG_OUT);
export const navigateTo = createAction(ACTION_TYPES.NAVIGATE_TO);
export const fetchSearchData = createAction(ACTION_TYPES.FETCH_SEARCH_DATA);
export const fetchUnlockScreen = createAction(ACTION_TYPES.FETCH_UNLOCK_SCREEN);
export const fetchLockScreen = createAction(ACTION_TYPES.FETCH_LOCK_SCREEN);
export const commonFileDownload = createAction(ACTION_TYPES.COMMON_DOWNLOAD_FILE);
