export const MODULE_PATH = {
    HOME: "home",
    DEVICE: "device",
    LIVE: "live",
    ALARM: "alarm",
    RESOURCES: "home/resources/:id",
    REACT_PLAYER_LIVE: "react-player-live",
    REACT_HLS_PLAYER_LIVE: "reacthls-player-live",
    VIDEO_PLAYER_LIVE: "video-player-live",
    SEPARATE_TAB: "tabview/:tabid",
    ELECTRONIC_MAP: "electronic-map"
};
