import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "../user-management/register/actions";

const initialState = {
    userDetails: {
        data: {},
        requestInProgress: false
    },
    navigator: "",
    playerNumber: {
        requestInProgress: false,
        data: {
            screenNumber: 1
        }
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setNavigator: (state, action) => {
            state.navigator = action.payload;
        },
        setPlayBack: (state, action) => {
            state.playerNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_USER_PROFILE_REQUEST, (state) => {
                state.userDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS, (state, action) => {
                state.userDetails.requestInProgress = false;
                state.userDetails.data = action.payload;
            });
    }
});

export const { actions, reducer } = slice;
