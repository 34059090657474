export const API_URL = {
    USER_MANAGEMENT: {
        SELECT_USER_LEVEL: "admin-service/api/auth/v1/crud/model/role/all",
        SAVE: "admin-service/api/auth/v1/crud/model/users",
        LIST: "admin-service/api/auth/v1/crud/model/users/all",
        BY_ID: "admin-service/api/auth/v1/crud/model/users/:id",
        DELETE: "admin-service/api/auth/v1/crud/model/users/delete"
    },
    RESOURCE_MANAGEMENT: {
        LIST: "admin-service/api/auth/v1/crud/model/resources/all",
        SAVE: "admin-service/api/auth/v1/crud/model/resources",
        SELECT_MODULE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=228883f0-dc50-11ee-ac7f-325096b39f47",
        BY_ID: "admin-service/api/auth/v1/crud/model/resources/:id",
        DELETE: "admin-service/api/auth/v1/crud/model/resources/delete",
        SELECT_PARENT: "admin-service/api/auth/v1/crud/model/resources/all"
    },
    ROLE_MANAGEMENT: {
        LIST: "admin-service/api/auth/v1/crud/model/role/all",
        LIST_RESOURCE: "admin-service/api/auth/v1/crud/model/resources/list/all/menu",
        CHECKED_RESOURCE: "admin-service/api/auth/v1/crud/model/role-resource/all",
        SAVE_RESOURCE: "admin-service/api/auth/v1/crud/model/role-resource"
    },
    SYSTEM_SETUP: {
        COMMON_SETTINGS: {
            SAVE: "admin-service/api/auth/v1/crud/model/common-setting",
            SKIN: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=272ff7d0-df61-11ee-8b60-325096b39f47",
            LANGUAGE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=272ff76c-df61-11ee-9e2b-325096b39f47",
            LOG: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2761bec0-df69-11ee-938f-325096b39f47",
            LIST_ALL: "admin-service/api/auth/v1/crud/model/common-setting/all",
            BY_ID: "admin-service/api/auth/v1/crud/model/common-setting/:id",
            OUTPUT: "admin-service/api/auth/v1/system/backup",
            RESTORE: "admin-service/api/auth/v1/system/restore",
            LIST_MESSAGES: "admin-service/api/auth/v1/system/backup-messages"
        },
        VIDEO_SETTINGS: {
            SAVE: "admin-service/api/auth/v1/crud/model/video-setting",
            STREAM_TYPE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f7e4-df69-11ee-a7f9-325096b39f47",
            PLAY_BACK_TIME: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f5aa-df69-11ee-892b-325096b39f47",
            FRAME_RATE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f528-df69-11ee-ad16-325096b39f47",
            VIDEO_DOWNLOAD_FORMAT: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2761bf60-df69-11ee-8329-325096b39f47",
            DECODING_SETTING: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f442-df69-11ee-bc3f-325096b39f47",
            PLAY_PROPERTY_SETTING: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2761bfce-df69-11ee-be1c-325096b39f47",
            IS_AUTOMATIC: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f690-df69-11ee-96d1-325096b39f47",
            LIST_ALL: "admin-service/api/auth/v1/crud/model/video-setting/all",
            BY_ID: "admin-service/api/auth/v1/crud/model/video-setting/:id"
        },
        ALARM_GRADE: {
            LIST: "admin-service/api/auth/v1/crud/model/index-alarm-type/alarm-grade/all",
            SAVE: "admin-service/api/auth/v1/crud/model/index-alarm-type"
        },
        STORAGE_MANAGEMENT: {
            DISK_FULL: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f776-df69-11ee-a5a2-325096b39f47",
            DISK_SPACE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f6fe-df69-11ee-bb9a-325096b39f47",
            RECORDING_FORMAT: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f618-df69-11ee-8334-325096b39f47",
            RECORDING_TYPE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f7e4-df69-11ee-a7f9-325096b39f47",
            LIST_ALL: "admin-service/api/auth/v1/crud/model/storage-management/all",
            BY_ID: "admin-service/api/auth/v1/crud/model/storage-management/:id",
            SAVE: "admin-service/api/auth/v1/crud/model/storage-management",
            DRIVE_LIST: "admin-service/api/auth/v1/crud/model/storage-management/drive-list",
            RESTORE: "admin-service/api/auth/v1/crud/model/storage-management/restore-default"
        },
        RECORD_PLAN: {
            CODE_STREAM: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f7e4-df69-11ee-a7f9-325096b39f47",
            TIME_TEMPLATE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763fb7c-df69-11ee-9a84-325096b39f47",
            SAVE: "admin-service/api/auth/v1/crud/model/record-plan",
            COPY_TO: "admin-service/api/auth/v1/crud/model/record-plan/copy",
            GET_RECORD_PLAN_SETTING_BY_ID: "admin-service/api/auth/v1/crud/model/record-plan/by-day",
            CUSTOMIZED_DEVICE: "admin-service/api/auth/v1/crud/model/device-group/custom-device-list",
            ENABLE_LOCAL_ENCODING: "admin-service/api/auth/v1/crud/model/device/channel/enable-record-plan",
            GET_TIME_PROGRESS_BY_ID: "admin-service/api/auth/v1/crud/model/record-plan/all"
        }
    },
    ALARM_CONFIGURATION: {
        LIST: ""
    },
    PLAYBACK: {
        QUERY_RECORD: "admin-service/api/auth/v1/core/playback/create/playlist",
        STOP: "admin-service/api/auth/v1/core/playback/stop"
    },
    OPERATION_LOGS: {
        LIST: "admin-service/api/auth/v1/crud/model/operation-logs/all",
        SELECT_LOG_TYPE: "admin-service/api/auth/v1/crud/model/log-type/all",
        SELECT_DETAILED_TYPE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all",
        FILTER_DESCRIPTION: "",
        SELECT_USERNAME: "admin-service/api/auth/v1/crud/model/users/all"
    },
    ELECTRONIC_MAP: {
        GET_DEVICE_LIST: "admin-service/api/auth/v1/crud/model/device-group/custom-device-list",
        SAVE_IMAGE: "admin-service/api/auth/v1/crud/model/e-map",
        GET_BY_IMAGE: "admin-service/api/auth/v1/crud/model/e-map/:id",
        DELETE: "admin-service/api/auth/v1/crud/model/e-map/:id",
        GET_MAP: "admin-service/api/auth/v1/crud/model/e-map/all",
        PATCH_DROPPED_DEVICE: "admin-service/api/auth/v1/crud/model/e-map",
        GET_HOT_ZONE_MAP: "admin-service/api/auth/v1/crud/model/e-map/no-hotzone/:id",
        DELETE_DEVICE: "admin-service/api/auth/v1/crud/model/e-map/:emapId/device/:deviceId",
        GET_BY_ID: "admin-service/api/auth/v1/crud/model/e-map/:id"
    }
};
