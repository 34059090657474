import { Grid, InputLabel, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import { FORM_CONTROL_STYLE, useStyles } from "./style";
import TextError from "./TextError";
import { useEffect, useState } from "react";
import { STATE_REDUCER_KEY } from "../../../../modules/common/constants";
import { useSelector } from "react-redux";

function CustomSelect(props) {
  const classes = useStyles();
  const themes = useSelector(state => state[STATE_REDUCER_KEY].switch.data);
  const { name = "", options = [], multiple = false, label = "", errorName = "", statusError = false, defaultValue = false, onChangeFromController, disabled = false, isMandatory = false, videoFilter = false, menuPlacement = "", ...rest } = props;

  const [style, setStyle] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setStyle({ fontSize: "14px", minHeight: "40px" });
      } else {
        setStyle({ fontSize: "18px", minHeight: "50px" });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Grid sx={FORM_CONTROL_STYLE}>
      <InputLabel className={classes.label} sx={{ color: "label.main" }} htmlFor={name}>{label} {isMandatory && <span style={{ color: "red", fontSize: "14px" }}> *</span>}</InputLabel>
      <Field
        as='select'
        name={name}
      >
        {
          ({ field, form }) => {
            return (
              <>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      ...style,
                      border: (state.isFocused && themes === "darkTheme") ? "1px solid #C0E1EC" : "1px solid #C0E1EC",
                      boxShadow: "none",
                      color: themes === "darkTheme" ? "#9DB2BF" : "#000000",
                      fontWeight: 400,
                      height: "55px",
                      fontSize: "14px",
                      borderRadius: "10px",
                      backgroundColor: themes === "darkTheme" ? "#151F2D" : "#EFEFEF",
                      "&:hover": {
                        borderColor: ""
                      },
                      ...(videoFilter && { minHeight: "5px" }),
                      ...(multiple && { maxHeight: "100px", overflowY: "auto" })

                    }),
                    input: base => ({
                      ...base,
                      color: "#9DB2BF"
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: themes === "darkTheme" ? "#9DB2BF" : "#000000"
                    }),
                    option: (base) => ({
                      ...base,
                      backgroundColor: themes === "darkTheme" ? "#151F2D" : "#EFEFEF",
                      color: themes === "darkTheme" ? "#9DB2BF" : "#000000",
                      fontWeight: 400,
                      fontSize: "14px"
                    }),
                    singleValue: base => ({
                      ...base,
                      color: themes === "darkTheme" ? "#9DB2BF" : "#000000" // Change color of selected value
                    })
                  }}
                  {...field}
                  {...rest}
                  id={name}
                  options={options}
                  onChange={value => {
                    onChangeFromController && onChangeFromController(value);
                    form.setFieldValue(field.name, value);
                  }}
                  isDisabled={disabled}
                  defaultValue={defaultValue}
                  dropdownHeight={600}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isMulti={multiple}
                  name={name}
                  menuPlacement={menuPlacement ? menuPlacement : "auto"}
                />
                {statusError ? <Typography variant="p" sx={{ color: "red", mt: 1, lineHeight: 0, fontSize: "14px" }}>{errorName}</Typography> :
                  <ErrorMessage component={TextError} name={name} />}
              </>
            );
          }
        }
      </Field>
    </Grid>
  );
}

export default CustomSelect;
