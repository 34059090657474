import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    userDetails: {
        requestInProgress: false,
        data: { loginId: "", loginPassword: "" },
        resourceId: [],
        requestStatus: ""
    },
    socket: {},
    socketOpen: false,
    pingInterval: "",
    changePassword: {
        requestInProgress: false,
        data: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            answerOne: "",
            answerTwo: "",
            answerThree: "",
            questionOne: "",
            questionTwo: "",
            questionThree: ""
        }
    },
    forgetPassword: {
        requestInProgress: false,
        data: {
            loginId: "",
            newPassword: "",
            confirmPassword: "",
            answerOne: "",
            answerTwo: "",
            answerThree: "",
            questionOne: "",
            questionTwo: "",
            questionThree: ""
        }
    },
    updatePassword: {
        requestInProgress: false,
        data: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            answerOne: "",
            answerTwo: "",
            answerThree: "",
            questionOne: "",
            questionTwo: "",
            questionThree: ""
        }
    },
    userId: "",
    securityQuestions: {
        requestInProgress: false,
        data: []
    },
    autoMaticLogin: {
        requestInProgress: false,
        data: ""
    },
    playBackInstanceTime: {
        data: ""
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clear: (state) => {
            state.userDetails = initialState.userDetails;
            state.playBackInstanceTime = initialState.playBackInstanceTime;
        },
        setLoginFormData: (state, action) => {
            _.set(state, "login.data.username", action.payload);
        },
        setWebSocket: (state, action) => {
            state.socket = action.payload;
        },
        setSocketOpen: (state, action) => {
            state.socketOpen = action.payload;
        },
        setPingInterval: (state, action) => {
            state.pingInterval = action.payload;
        },
        setLoginId: (state, action) => {
            state.userId = action.payload;
        },
        setAutomaticLogin: (state, { payload }) => {
            state.autoMaticLogin.data = payload;
        },
        setInstancePlayBackTime: (state, { payload }) => {
            state.playBackInstanceTime.data = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ACTION_TYPES.GET_USER_DETAILS_REQUEST, (state) => {
            state.userDetails.requestInProgress = true;
        }).addCase(ACTION_TYPES.GET_USER_DETAILS_FAILURE, (state) => {
            state.userDetails.requestInProgress = false;
        }).addCase(ACTION_TYPES.GET_USER_DETAILS_SUCCESS, (state, actions) => {
            state.userDetails.requestInProgress = false;
            state.userDetails.data = actions.payload;
        });
        builder.addCase(ACTION_TYPES.SET_OTP_DETAILS, (state, actions) => {
            state.otpDetails = actions.payload;
        });
        builder.addCase(ACTION_TYPES.AUTHENTICATE_USER_REQUEST, (state) => {
            state.userDetails.requestInProgress = true;
        });
        builder.addCase(ACTION_TYPES.AUTHENTICATE_USER_SUCCESS, (state, actions) => {
            _.set(state, "userDetails.requestInProgress", false);
            state.userDetails.resourceId = actions.payload;
        });
        builder.addCase(ACTION_TYPES.AUTHENTICATE_USER_FAILURE, (state) => {
            state.userDetails.requestInProgress = false;
        });
        builder.addCase(ACTION_TYPES.AUTOMATIC_LOGIN_REQUEST, (state) => {
            state.autoMaticLogin.requestInProgress = true;
        }).addCase(ACTION_TYPES.AUTOMATIC_LOGIN_SUCCESS, (state) => {
            state.autoMaticLogin.requestInProgress = false;
        }).addCase(ACTION_TYPES.AUTOMATIC_LOGIN_FAILURE, (state) => {
            state.autoMaticLogin.requestInProgress = false;
        });
        //builder case for security questions
        builder.addCase(ACTION_TYPES.SECURITY_QUESTIONS_REQUEST, (state) => {
            state.securityQuestions.requestInProgress = true;
        }).addCase(ACTION_TYPES.SECURITY_QUESTIONS_SUCCESS, (state, { payload }) => {
            state.securityQuestions.requestInProgress = false;
            state.securityQuestions.data = payload?.content;
        }).addCase(ACTION_TYPES.SECURITY_QUESTIONS_FAILURE, (state) => {
            state.securityQuestions.requestInProgress = false;
        });
    }
});

export const { reducer, actions } = slice;
