export const STATE_REDUCER_KEY = "liveView";

export const OPERATIONS = {
    MENU: "MENU",
    IMAGE_EDIT: "IMAGE_EDIT"
};

export const OPTIONS = [
    { id: "1", name: "Preset" },
    { id: "2", name: "Tour" }

];

export const COUNT = [
    { id: "1", name: 1 },
    { id: "2", name: 2 },
    { id: "3", name: 3 },
    { id: "4", name: 4 },
    { id: "5", name: 5 },
    { id: "6", name: 6 },
    { id: "7", name: 7 },
    { id: "8", name: 8 },
    { id: "9", name: 9 },
    { id: "10", name: 10 }
];

export const commonArray = [];
for (let i = 0; i <= 64; i++) {
    commonArray.push(i);
}

export const gridAvailability = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63];
export let gridAvailabilityForLoader = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63];

// export const videoUrls = Array.from({ length: 64 }, () => ({}));
export const videoUrls = (val) => {
    return Array.from({ length: val }, (v, i) => ({
        streamUrl: "",
        isLoading: false,
        groupId: null,
        deviceId: null,
        channelId: null,
        index: i,
        isError: false,
        errorMessage: []
    }));
};


export const COLOR_MODE = [
    { id: "1", name: "Standard" }
];

export const CHECKBOX_TYPES = {
    GROUP: "GROUP",
    DEVICE: "DEVICE",
    CHANNEL: "CHANNEL"
};

export const getVideoHeight = (lengthOfVideoPlayer) => {
    switch (lengthOfVideoPlayer) {
        case 1:
            return "60vh";
        case 4:
            return "30vh";
        case 9:
            return "19vh";
        case 16:
            return "14vh";
        case 25:
            return "11vh";
        case 49:
            return "7vh";
        case 64:
            return "6vh";
        case 32:
            return "12vh";
        case 36:
            return "9vh";
        case 8:
            return "9vh";
        case 48:
            return "9vh";
        default:
            return "auto";
    }
};

export const getFullScreenVideoHeight = (lengthOfVideoPlayer) => {
    switch (lengthOfVideoPlayer) {
        case 1:
            return "97vh";
        case 4:
            return "48vh";
        case 9:
            return "32vh";
        case 16:
            return "24vh";
        case 25:
            return "19vh";
        case 49:
            return "13vh";
        case 64:
            return "11vh";
        case 32:
            return "12vh";
        case 36:
            return "15vh";
        case 8:
            return "9vh";
        case 48:
            return "9vh";
        default:
            return "auto";
    }
};

export const getIconHeight = (lengthOfVideoPlayer) => {
    return lengthOfVideoPlayer === 4 || lengthOfVideoPlayer === 1 || lengthOfVideoPlayer === 9 ? "52" : lengthOfVideoPlayer === 16 ? "32" : "19";
};

export const getIconWidth = (lengthOfVideoPlayer) => {
    return lengthOfVideoPlayer === 4 || lengthOfVideoPlayer === 1 || lengthOfVideoPlayer === 9 ? "50" : lengthOfVideoPlayer === 16 ? "30" : "20";
};

export const getFullScreenIconHeight = (lengthOfVideoPlayer) => {
    return lengthOfVideoPlayer === 4 || lengthOfVideoPlayer === 1 || lengthOfVideoPlayer === 9 ? "52" : lengthOfVideoPlayer === 16 ? "37" : "30";
};

export const getFullScreenIconWidth = (lengthOfVideoPlayer) => {
    return lengthOfVideoPlayer === 4 || lengthOfVideoPlayer === 1 || lengthOfVideoPlayer === 9 ? "50" : lengthOfVideoPlayer === 16 ? "35" : "30";
};

export const INITIAL_TAB = 1;
export const getZoomIconHeight = (lengthOfVideoPlayer) => {
    return lengthOfVideoPlayer === 4 || lengthOfVideoPlayer === 1 || lengthOfVideoPlayer === 9 ? "20" : "10";
};
