import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY, DEFAULT_TABLE_ID, COMMON_TABLE_INITIAL_STATE } from "./constants";

const initialState = {
    table: {},
    statusModal: {
        isOpen: false,
        title: "",
        content: null,
        showLoader: false
    },
    switch: {
        data: "lightTheme"
    },
    language: {
        data: "en"
    },
    openModal: false,
    logoSwitch: false,
    timeProgressBar: false,
    updateMapping: {},
    availability: {
        requestInProgress: false,
        data: []
    },
    availabilityGrid: {
        requestInProgress: false,
        data: []
    },
    lengthOfVideoPlayer: {
        requestInProgress: false,
        data: 4
    },
    deviceListIds: {
        requestInProgress: false,
        data: []
    },
    channelListIds: {
        requestInProgress: false,
        data: []
    },
    groupListIds: {
        requestInProgress: false,
        data: []
    },
    //for progress showing in device bulk add
    deviceBulkAdd: [],
    progressiveData: {},
    progressiveCount: 0,
    updatedBulkAdd: [],
    failedDeviceAdd: 0,
    successDeviceAdd: 0,
    recordSettings: {},
    deviceDataSetting: {
        requestInProgress: false,
        data: []
    },
    viewStatus: {},
    viewItems: {
        requestInProgress: false,
        data: []
    },
    cruisePlanList: [],
    cpuMemoryData: {
        requestInProgress: false,
        data: []
    },
    tab: [{ id: 1, isInside: true }],
    searchFilter: {},
    lockScreen: false,
    logout: {
        requestInProgress: false
    },
    backupStatusData: {
        requestInProgress: false,
        data: []
    },
    restoreStatusData: {
        requestInProgress: false,
        data: []
    },
    recordingWarningMessages: "",
    recordingSuccessMessages: ""
};

const resetTable = (state, action) => {
    const { key = DEFAULT_TABLE_ID, ...rest } = action.payload;
    _.set(state, `table.${key}`, { ...COMMON_TABLE_INITIAL_STATE, ...rest });
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: (state) => {
            state.table = initialState.table;
            state.statusModal = initialState.statusModal;
            state.cpuMemoryData = initialState.cpuMemoryData;
            state.searchFilter = initialState.searchFilter;
        },
        clearBackupStatus: (state) => {
            state.backupStatusData.data = initialState.backupStatusData.data;
        },
        clearRestoreStatus: (state) => {
            state.restoreStatusData.data = initialState.restoreStatusData.data;
        },
        clearUpdateMapping: (state, { payload }) => {
            const newUpdateMapping = state.updateMapping;
            delete newUpdateMapping[`${payload.tabId}_tab`];
            // _.set(state, `updateMapping.${payload.tabId}_tab`, []);
            state.updateMapping = newUpdateMapping;
        },
        clearUpdateMappingOnFailure: (state, { payload }) => {
            const tempUpdateMaping = state.updateMapping[`${payload.tabId}_tab`];
            const deviceList = payload?.deviceList.map(d => d.deviceId);
            const channelList = payload?.deviceList.map(d => d.channelId);
            tempUpdateMaping.forEach((item, i) => {
                if (deviceList.includes(item.deviceId) && channelList.includes(item.channelId)) {
                    tempUpdateMaping[i] = { ...tempUpdateMaping[i], streamUrl: "", isLoading: false, groupId: null, deviceId: null, channelId: null };
                }
            });
            _.set(state, `updateMapping.${payload.tabId}_tab`, tempUpdateMaping);
        },
        clearChannelIds: (state, { payload }) => {
            _.set(state, `channelListIds.${payload.tabId}_tab`, []);
        },
        clearDeviceIds: (state, { payload }) => {
            _.set(state, `deviceListIds.${payload.tabId}_tab`, []);
        },
        clearGroupIds: (state, { payload }) => {
            _.set(state, `groupListIds.${payload.tabId}_tab`, []);
        },
        clearLength: (state, { payload }) => {
            _.set(state, `lengthOfVideoPlayer.${payload.tabId}_tab`, 4);
        },
        clearTab: (state, { payload }) => {
            let newTabsList = state.tab;
            let elementIndex = newTabsList.findIndex(x => x.id === parseInt(payload?.tabId));
            if (elementIndex > -1) {
                newTabsList.splice(elementIndex, 1);
            }
            state.tab = newTabsList;
        },
        clearTheme: (state) => {
            state.switch.data = initialState.switch.data;
        },
        clearLive: (state) => {
            state.deviceListIds = initialState.deviceListIds;
            state.lengthOfVideoPlayer = initialState.lengthOfVideoPlayer;
            state.availabilityGrid = initialState.availabilityGrid;
            state.availability = initialState.availability;
            state.updateMapping = initialState.updateMapping;
            state.groupListIds = initialState.groupListIds;
            state.channelListIds = initialState.channelListIds;
            state.recordSettings = initialState.recordSettings;
            state.cruisePlanList = initialState.cruisePlanList;
            state.viewItems = initialState.viewItems;
            state.deviceDataSetting = initialState.deviceDataSetting;
            state.viewStatus = initialState.viewStatus;
            state.cpuMemoryData = initialState.cpuMemoryData;
            state.tab = initialState.tab;
            state.lockScreen = initialState.lockScreen;
            state.backupStatusData.data = initialState.backupStatusData.data;
            state.restoreStatusData.data = initialState.restoreStatusData.data;
            state.recordingWarningMessages = initialState.recordingWarningMessages;
            state.recordingSuccessMessages = initialState.recordingSuccessMessages;
        },
        clearTable: (state, action) => resetTable(state, action),
        initializeTable: (state, action) => {
            const { key = DEFAULT_TABLE_ID, ...rest } = action.payload || {};
            _.set(state, `table.${key}`, { ...COMMON_TABLE_INITIAL_STATE, ...rest, additionalFilters: _.get(state, `table[${key}].additionalFilters`, {}) });
        },
        setPagination: (state, action) => {
            const { key = DEFAULT_TABLE_ID, pagination = {}, reset = false } = action.payload;
            _.set(state, `table.${key}.pagination`, reset ? { ...COMMON_TABLE_INITIAL_STATE.pagination, ...pagination } : { ...COMMON_TABLE_INITIAL_STATE.pagination, ..._.get(state, `table[${key}].pagination`, {}), ...pagination });
        },
        setTableData: (state, action) => {
            const { key = DEFAULT_TABLE_ID, requestInProgress = false, data = [] } = action.payload;
            _.set(state, `table.${key}.data`, data);
            _.set(state, `table.${key}.requestInProgress`, requestInProgress);
        },
        setFilters: (state, action) => {
            const { key = DEFAULT_TABLE_ID, filters = {}, reset = false, clearKey = false, filterKey = "" } = action.payload;
            if (clearKey) {
                _.unset(state, `table.${key}.filters.${filterKey}`);
            } else {
                _.set(state, `table.${key}.filters`, reset ? filters : { ...state.table[key].filters, ...filters });
            }
        },
        setLoading: (state, action) => {
            const { key = DEFAULT_TABLE_ID, requestInProgress = true } = action.payload;
            _.set(state, `table.${key}.requestInProgress`, requestInProgress);
        },
        setAdditionalFilters: (state, action) => {
            const { key = DEFAULT_TABLE_ID, filters = {} } = action.payload;
            _.set(state, `table.${key}.additionalFilters`, filters);
        },
        setRowSelection: (state, action) => {
            const { key = DEFAULT_TABLE_ID, items = {} } = action.payload;
            _.set(state, `table.${key}.rowSelected`, items);
        },
        setSearchFilter: (state, action) => {
            state.searchFilter = action.payload;
        },
        setStatusModal: (state, action) => {
            state.statusModal = { ...state.statusModal, showLoader: true, ...action.payload };
        },
        resetStatusModal: (state) => {
            state.statusModal = initialState.statusModal;
        },
        setSwitch: (state, action) => {
            _.set(state, "switch.data", action.payload);
        },
        setLanguage: (state, action) => {
            _.set(state, "language.data", action.payload);
        },
        setOpenModal: (state, action) => {
            _.set(state, "openModal.data", action.payload);
        },
        setSwitchLogo: (state, action) => {
            state.logoSwitch = action.payload;
        },
        setUpdatedMapping: (state, { payload }) => {
            // state.updateMapping.data = payload;
            _.set(state, `updateMapping.${payload.tabId}_tab`, payload.data);
        },
        setUpdatedVideoLength: (state, { payload }) => {
            // state.lengthOfVideoPlayer.data = payload;
            _.set(state, `lengthOfVideoPlayer.${payload.tabId}_tab`, payload.data);
        },
        setDeviceIds: (state, { payload }) => {
            // state.deviceListIds.data = payload;
            _.set(state, `deviceListIds.${payload.tabId}_tab`, payload.data);
        },
        setChannelIds: (state, { payload }) => {
            // state.channelListIds.data = payload;
            _.set(state, `channelListIds.${payload.tabId}_tab`, payload.data);
        },
        setGroupIds: (state, { payload }) => {
            // state.groupListIds.data = payload;
            _.set(state, `groupListIds.${payload.tabId}_tab`, payload.data);
        },
        setDeviceBulkAdd: (state, { payload }) => {
            state.deviceBulkAdd = payload;
        },
        clearBulkAddData: (state) => {
            state.deviceBulkAdd = initialState.deviceBulkAdd;
        },
        setProgressiveData: (state, { payload }) => {
            state.progressiveData = payload;
        },
        setProgressiveCount: (state) => {
            state.progressiveCount += 1;
        },
        clearProgressiveData: (state) => {
            state.progressiveData = initialState.progressiveData;
        },
        clearWarningMessages: (state) => {
            state.recordingWarningMessages = initialState.recordingWarningMessages;
            state.recordingSuccessMessages = initialState.recordingSuccessMessages;
        },
        clearProgressiveCount: (state) => {
            state.progressiveCount = initialState.progressiveCount;
        },
        setFailed: (state) => {
            state.failedDeviceAdd += 1;
        },
        setSuccess: (state) => {
            state.successDeviceAdd += 1;
        },
        clearSetFailed: (state) => {
            state.failedDeviceAdd = initialState.failedDeviceAdd;
        },
        clearSetSuccess: (state) => {
            state.successDeviceAdd = initialState.successDeviceAdd;
        },
        setResetTimeProgressBar: (state, action) => {
            _.set(state, "timeProgressBar", action.payload);
        },
        setRecordSettings: (state, action) => {
            _.set(state, `recordSettings.${action.payload.tabId}_tab`, action.payload?.data);
        },
        setDeviceDataSetting: (state, action) => {
            _.set(state, "deviceDataSetting.data", action.payload);
        },
        setViewStatus: (state, { payload }) => {
            // _.set(state, "viewStatus", action.payload);
            _.set(state, `viewStatus.${payload.tabId}_tab`, payload.data);
        },
        updateViewItem: (state, { payload }) => {
            state.viewItems.data = payload;
        },
        setCruisePlanList: (state, action) => {
            _.set(state, "cruisePlanList", action?.payload);
        },
        setLock: (state, { payload }) => {
            _.set(state, "lockScreen", payload);
        },
        setRestartLive: (state, { payload }) => {
            let tempLiveData = state.updateMapping.data?.map((d) => {
                if (!_.isEmpty(payload)) {
                    if (d?.deviceId === payload?.deviceId && d?.channelId === payload?.channelId) {
                        return { ...d, streamUrl: payload };
                    }
                    return d;
                }
                return d;
            });
            state.updateMapping.data = tempLiveData;
        },
        setCpuAndMemory: (state, { payload }) => {
            state.cpuMemoryData.data = payload;
        },
        setTab: (state, { payload }) => {
            state.tab = payload;
        },
        setBackupStatus: (state, { payload }) => {
            state.backupStatusData.data.push(payload);
        },
        setRestoreStatus: (state, { payload }) => {
            state.restoreStatusData.data.push(payload);
        },
        setWarningMessages: (state, { payload }) => {
            state.recordingWarningMessages = payload;
        },
        setSuccessMessages: (state, { payload }) => {
            state.recordingSuccessMessages = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase("UPDATE_COMMON_STATE", (state, { payload }) => {
            state[payload.id] = payload.data;
        });
        builder.addCase(ACTION_TYPES.LOG_OUT_REQUEST, (state) => {
            state.logout.requestInProgress = true;
        }).addCase(ACTION_TYPES.LOG_OUT_SUCCESS, (state) => {
            state.logout.requestInProgress = false;
        }).addCase(ACTION_TYPES.LOG_OUT_FAILURE, (state) => {
            state.logout.requestInProgress = false;
        });
    }
});

export const { actions, reducer } = slice;
