import { createSlice } from "@reduxjs/toolkit";

import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    systemSwitch: false,
    userManagementSwitch: false,
    resourceManagementSwitch: false,
    roleManagementSwitch: false,
    alarmConfigurationSwitch: false,
    tvWallSwitch: false,
    playBack: false,
    operationLogsSwitch: false,
    electronicMapSwitch: false
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        setSystemSwitch: (state, action) => {
            state.systemSwitch = action.payload;
        },
        setUserManagementSwitch: (state, action) => {
            state.userManagementSwitch = action.payload;
        },
        setResourceManagementSwitch: (state, action) => {
            state.resourceManagementSwitch = action.payload;
        },
        setRoleManagementSwitch: (state, action) => {
            state.roleManagementSwitch = action.payload;
        },
        setAlarmConfigurationSwitch: (state, action) => {
            state.alarmConfigurationSwitch = action.payload;
        },
        setTVWallSwitch: (state, action) => {
            state.tvWallSwitch = action.payload;
        },
        setPlayBack: (state, action) => {
            state.playBack = action.payload;
        },
        setOperationLogsSwitch: (state, action) => {
            state.operationLogsSwitch = action.payload;
        },
        setElectronicMapSwitch: (state, action) => {
            state.electronicMapSwitch = action.payload;
        }
    },
    extraReducers: () => { }
});
export const { actions, reducer } = slice;
