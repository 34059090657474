import { FormLabel, Grid, TextField } from "@mui/material";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as YearPicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Field, getIn, ErrorMessage } from "formik";
import _ from "lodash";

import "react-datepicker/dist/react-datepicker.css";
import { FORM_CONTROL_STYLE, useStyles } from "./style";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "../../../../utils/dateUtils";
import TextError from "./TextError";
import { useSelector } from "react-redux";
import { STATE_REDUCER_KEY } from "../../../../modules/common/constants";

const customStyles = `
  .MuiPickersDay-root.Mui-selected {
    background-color: #E4002B !important;
    color: #000 !important;
  }
  .MuiMultiSectionDigitalClockSection-item.Mui-selected {
    background-color: #E4002B !important;
    color: #000 !important;
  }
  .MuiPickersYear-yearButton.Mui-selected{
    background-color: #E4002B !important;
    color: #000 !important;
  }
  .MuiButton-text{
    color: #000 !important;
  }
  .MuiInputBase-input {
    color: #000 !important;
  }
`;

const darkCustomStyles = `
  .MuiPickersDay-root.Mui-selected {
    background-color: #E4002B !important;
    color: #FFF !important;
  }
  .MuiMultiSectionDigitalClockSection-item.Mui-selected {
    background-color: #E4002B !important;
    color: #FFF !important;
  }
  .MuiPickersYear-yearButton.Mui-selected{
    background-color: #E4002B !important;
    color: #FFF !important;
  }
  .MuiButton-text{
    color: #FFF !important;
  }
  .MuiInputBase-input {
    color: #FFF !important;
  }
`;

const DatePicker = (props) => {
  const classes = useStyles();
  const themes = useSelector(state => state[STATE_REDUCER_KEY].switch.data);
  const { label = "", name = "", mode = "", onChangeFromController, isMandatory = false, minDateTime, maxDateTime, statusError = false, ...rest } = props;
  return (
    <>
      <style>{themes !== "darkTheme" ? customStyles : darkCustomStyles}</style>
      <Grid sx={FORM_CONTROL_STYLE}>
        <FormLabel className={classes.label} htmlFor={name}>{label} {isMandatory && <span style={{ color: "red", fontSize: "14px" }} > *</span>}</FormLabel>
        <Field name={name}>
          {({ form, field }) => {
            const { setFieldValue } = form;
            const { value = null } = field;
            const validValue = value ? (dayjs.isDayjs(value) ? value : dayjs(value)) : null;
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched);
            const touchedValue = _.some(touch, _.isEmpty);
            if (mode === "Date&&Time") {
              return (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      id={name}
                      views={["year", "day", "hours", "minutes"]}
                      {...field}
                      {...rest}
                      value={validValue}
                      inputFormat={DATE_TIME_FORMAT}
                      format="DD/MM/YYYY hh:mm A"
                      timeSteps={{ hours: 1, minutes: 1 }}
                      maxDateTime={maxDateTime && maxDateTime}
                      minDateTime={minDateTime && minDateTime}
                      renderInput={
                        (params) =>
                          <TextField sx={{ height: "50px" }} {...params} inputProps={{ ...params.inputProps, readOnly: true, style: { padding: "8px", color: "#FFF" }, placeholder: "dd/mm/yyy hh:mm:ss" }} />
                      }
                      onChange={val => {
                        onChangeFromController && onChangeFromController(val);
                        setFieldValue(name, val || null);// Ensure that val is a valid date string or a dayjs object
                      }}
                      InputProps={{
                        error: props.error ? props.error : null,
                        classes: {
                          input: classes.toolbarText
                        }
                      }}
                    />
                  </LocalizationProvider>
                  {/* {touchedValue ? <TextError>{error}</TextError> : ""} */}
                  <ErrorMessage component={TextError} name={name} />
                </>
              );
            } else if (mode === "Time") {
              return (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      id={name}
                      {...field}
                      {...rest}
                      value={validValue}
                      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                      inputFormat={TIME_FORMAT}
                      onChange={val => {
                        onChangeFromController && onChangeFromController(val);
                        form.setFieldValue(field.name, val);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ height: "40px" }}
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true, style: { padding: "8px" } }}
                        />
                      )}
                      InputProps={{
                        error: props.error ? props.error : null,
                        classes: {
                          input: classes.toolbarText
                        }
                      }}
                    />
                  </LocalizationProvider>
                  {statusError ? <TextError>{error}</TextError> : ""}
                </>
              );
            } else if (mode === "Year") {
              return (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <YearPicker
                      views={["year"]}
                      id={name}
                      {...field}
                      {...rest}
                      value={value ? `01/01/${value}` : null}
                      onChange={(val) => {
                        const selectedYear = new Date(val).getFullYear();
                        onChangeFromController && onChangeFromController(selectedYear);
                        form.setFieldValue(field.name, selectedYear);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ height: "50px" }}
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true, style: { padding: "8.5px 14px" } }}
                        />
                      )}
                      InputProps={{
                        error: props.error ? props.error : null,
                        classes: {
                          input: classes.toolbarText
                        }
                      }}
                    />
                  </LocalizationProvider>
                  {touchedValue ? <TextError>{error}</TextError> : ""}
                </>
              );
            } else {
              return (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      id={name}
                      {...field}
                      {...rest}
                      value={value ? dayjs(value, DATE_FORMAT) : null}
                      inputFormat={DATE_FORMAT}
                      onChange={(val) => {
                        onChangeFromController && onChangeFromController(val);
                        form.setFieldValue(field.name, val);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ height: "40px" }}
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true, style: { padding: "8px" } }}
                        />
                      )}
                      InputProps={{
                        error: props.error ? props.error : null,
                        classes: {
                          input: classes.toolbarText
                        }
                      }}
                    />
                  </LocalizationProvider>
                  {touchedValue ? <TextError>{error}</TextError> : ""}
                </>
              );
            }
          }}
        </Field>
      </Grid>
    </>
  );
};

export default DatePicker;
