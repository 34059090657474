export const STATE_REDUCER_KEY = "systemSetup";

export const SYSTEM_SETUP = {
    COMMON_SETTINGS: "commonSettings",
    VIDEO_SETTINGS: "videoSettings",
    ALARM_GRADE: "alarmGrade",
    STORAGE_MANAGEMENT: "storageManagement",
    RECORD_PLAN: "recordPlan",
    P2P_SETTINGS: "p2pSettings"
};

export const DAY = [
    { id: "SUNDAY", name: "Sun" },
    { id: "MONDAY", name: "Mon" },
    { id: "TUESDAY", name: "Tue" },
    { id: "WEDNESDAY", name: "Wed" },
    { id: "THURSDAY", name: "Thu" },
    { id: "FRIDAY", name: "Fri" },
    { id: "SATURDAY", name: "Sat" }
];

export const collectionList = [
    { id: "1", name: "User Authority" },
    { id: "2", name: "Device List" }
    // { id: "3", name: "E-Map" }
];

export const SPACE_ID = "04c1883a-d543-11ee-890a-325096b39f47";
export const PERCENTAGE_ID = "04c18556-d543-11ee-9611-325096b39f47";
