import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    POST_USER_DETAILS: `${STATE_REDUCER_KEY}/POST_USER_DETAILS`,
    POST_USER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_USER_DETAILS_REQUEST`,
    POST_USER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_USER_DETAILS_SUCCESS`,
    POST_USER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_USER_DETAILS_FAILURE`,

    LIST_USERS: `${STATE_REDUCER_KEY}/LIST_USERS`,
    LIST_USERS_REQUEST: `${STATE_REDUCER_KEY}/LIST_USERS_REQUEST`,
    LIST_USERS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_USERS_SUCCESS`,
    LIST_USERS_FAILURE: `${STATE_REDUCER_KEY}/LIST_USERS_FAILURE`,

    USER_DETAILS_BY_ID: `${STATE_REDUCER_KEY}/USER_DETAILS_BY_ID`,
    USER_DETAILS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/USER_DETAILS_BY_ID_REQUEST`,
    USER_DETAILS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/USER_DETAILS_BY_ID_SUCCESS`,
    USER_DETAILS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/USER_DETAILS_BY_ID_FAILURE`,

    UPDATE_USER_DETAILS: `${STATE_REDUCER_KEY}/UPDATE_USER_DETAILS`,
    UPDATE_USER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_USER_DETAILS_REQUEST`,
    UPDATE_USER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_USER_DETAILS_SUCCESS`,
    UPDATE_USER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_USER_DETAILS_FAILURE`,

    DELETE_USER_DETAILS: `${STATE_REDUCER_KEY}/DELETE_USER_DETAILS`,
    DELETE_USER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_USER_DETAILS_REQUEST`,
    DELETE_USER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_USER_DETAILS_SUCCESS`,
    DELETE_USER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_USER_DETAILS_FAILURE`,

    SELECT_USER_ROLES: `${STATE_REDUCER_KEY}/SELECT_USER_ROLES`,
    SELECT_USER_ROLES_REQUEST: `${STATE_REDUCER_KEY}/SELECT_USER_ROLES_REQUEST`,
    SELECT_USER_ROLES_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_USER_ROLES_SUCCESS`,
    SELECT_USER_ROLES_FAILURE: `${STATE_REDUCER_KEY}/SELECT_USER_ROLES_FAILURE`,

    CHECKED_RESOURCE_DATA: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA`,
    CHECKED_RESOURCE_DATA_REQUEST: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA_REQUEST`,
    CHECKED_RESOURCE_DATA_SUCCESS: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA_SUCCESS`,
    CHECKED_RESOURCE_DATA_FAILURE: `${STATE_REDUCER_KEY}/CHECKED_RESOURCE_DATA_FAILURE`

};

export const submitUserDetails = createAction(ACTION_TYPES.POST_USER_DETAILS);
export const listUsers = createAction(ACTION_TYPES.LIST_USERS);
export const userDetailsById = createAction(ACTION_TYPES.USER_DETAILS_BY_ID);
export const updateUserDetails = createAction(ACTION_TYPES.UPDATE_USER_DETAILS);
export const deleteUserDetails = createAction(ACTION_TYPES.DELETE_USER_DETAILS);
export const selectUserRoles = createAction(ACTION_TYPES.SELECT_USER_ROLES);
export const checkedResourceData = createAction(ACTION_TYPES.CHECKED_RESOURCE_DATA);
