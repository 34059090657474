import { all, fork, select, takeLatest } from "redux-saga/effects";

import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { TABLE_IDS } from "../../../common/constants";
import { setCommonTableData } from "../../../utils/tableUtils";
import { getTableProps } from "../../common/selectors";

export function* listAlarmConfiguration({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.ALARM_CONFIGURATION]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listAlarmConfiguration, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_ALARM_CONFIGURATION_SUCCESS, ACTION_TYPES.LIST_ALARM_CONFIGURATION_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ALARM_CONFIGURATION);
}

export default function* alarmConfigurationSaga() {
    yield all([
        takeLatest(ACTION_TYPES.LIST_ALARM_CONFIGURATION, listAlarmConfiguration)
    ]);
}
