import { Checkbox, CheckboxController, CustomInput, CustomSelect, DatePicker, Input, RadioButtons, Textarea, ToggleButton } from "./form-controller-components";
import CustomFileUpload from "./form-controller-components/FileUpload";
import ImageUpload from "./form-controller-components/ImageUpload";

const FormController = (props) => {
  const { control, ...rest } = props;

  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <CustomSelect {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "customInput":
      return <CustomInput {...rest} />;
    case "toggleButton":
      return <ToggleButton {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "checkboxController":
      return <CheckboxController {...rest} />;
    case "attachments":
      return <CustomFileUpload {...rest} />;
    case "file":
      return <ImageUpload {...rest} />;
    default:
      return null;
  }
};

export default FormController;
