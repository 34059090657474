import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getCommon = (state) => state[STATE_REDUCER_KEY];

const systemSwitch = (state) => state.systemSwitch;
export const getSystemSwitch = flow(getCommon, systemSwitch);

const userManagementSwitch = (state) => state.userManagementSwitch;
export const getUserManagementSwitch = flow(getCommon, userManagementSwitch);

const resourceManagementSwitch = (state) => state.resourceManagementSwitch;
export const getResourceManagementSwitch = flow(getCommon, resourceManagementSwitch);

const roleManagementSwitch = (state) => state.roleManagementSwitch;
export const getRoleManagementSwitch = flow(getCommon, roleManagementSwitch);

const alarmConfigurationSwitch = (state) => state.alarmConfigurationSwitch;
export const getAlarmConfigurationSwitch = flow(getCommon, alarmConfigurationSwitch);

const tvWallSwitch = (state) => state.tvWallSwitch;
export const getTvWallSwitch = flow(getCommon, tvWallSwitch);

const playBack = (state) => state.playBack;
export const getPlayBack = flow(getCommon, playBack);

const operationLogsSwitch = (state) => state.operationLogsSwitch;
export const getOperationLogsSwitch = flow(getCommon, operationLogsSwitch);

const electronicMapSwitch = (state) => state.electronicMapSwitch;
export const getElectronicMapSwitch = flow(getCommon, electronicMapSwitch);
