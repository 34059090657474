import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Form, withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Components, FormController, I18n, Icons } from "../../../../common/components";
import { STATE_REDUCER_KEY } from "../../../common/constants";
import { securityQuestions, updatePassword } from "../actions";
import { getUpdatePasswordForm } from "../selectors";
import { getUserDetails } from "../../../../utils/userUtils";
import { customUpdatePasswordSchema } from "../validate";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "primary.light",
    border: "none",
    p: 2,
    height: "80%",
    width: "50%",
    overflow: "auto",
    borderRadius: "20px"
};

const { Grid, Card, CardActions, CardHeader, CardContent, Button, Modal, CustomCardHeader, IconButton } = Components;
const { Close } = Icons;

const UpdatePassword = (props) => {
    const { handleSubmit, resetForm, open, setOpenStatus, setFieldValue, setOpen } = props;
    const themes = useSelector(state => state[STATE_REDUCER_KEY].switch.data);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const data = getUserDetails();
    const securityQuestionData = useSelector(state => state.register?.securityQuestions?.data);

    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const dispatch = useDispatch();

    useEffect(() => {
        setFieldValue("userId", data.userId);
    }, [data]);

    useEffect(() => {
        if (open === true) {
            setOpen(null);
            dispatch(securityQuestions());

        }
        resetForm();
    }, [open]);

    const handleClose = () => {
        setOpenStatus(false);
        resetForm();
    };

    return (
        <Modal open={open} sx={{ zIndex: 200 }} onClose={handleClose} >
            <Card style={modalStyle} >
                <Form onSubmit={handleSubmit}>
                    <CardHeader sx={{ borderRadius: "10px 10px 0 0", mb: 2, color: "fontColor.light" }} title={<CustomCardHeader title={I18n("update_password")} component={<IconButton onClick={() => handleClose()}>
                        <Close fontSize="large" style={{ color: themes === "darkTheme" ? "#E4002B" : "#000" }} />
                    </IconButton>} />} />
                    <CardContent sx={{ ml: 2, mr: 2 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12} lg={12}>
                                <FormController control="input" name="oldPassword" onClick={handleClickShowOldPassword} type={showOldPassword ? "text" : "password"} icon={showOldPassword ? <VisibilityOff /> : <Visibility />} label={I18n("current_password")} isMandatory={true} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormController control="input" name="newPassword" onClick={handleClickShowNewPassword} type={showNewPassword ? "text" : "password"} icon={showNewPassword ? <VisibilityOff /> : <Visibility />} label={I18n("new_password")} isMandatory={true} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormController control="input" name="confirmPassword" onClick={handleClickShowConfirmPassword} type={showConfirmPassword ? "text" : "password"} icon={showConfirmPassword ? <VisibilityOff /> : <Visibility />} label={I18n("confirm_password")} isMandatory={true} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <FormController control="select" name="questionOne" label={I18n("security_question_one")} menuPlacement="top" isMandatory={true} options={securityQuestionData || []} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <FormController control="input" name="answerOne" label={I18n("answer_one")} isMandatory={true} menuPlacement="top" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <FormController control="select" name="questionTwo" label={I18n("security_question_two")} isMandatory={true} options={securityQuestionData || []} menuPlacement="top" />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <FormController control="input" name="answerTwo" label={I18n("answer_two")} isMandatory={true} menuPlacement="top" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <FormController control="select" name="questionThree" label={I18n("security_question_three")} isMandatory={true} options={securityQuestionData || []} menuPlacement="top" />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <FormController control="input" name="answerThree" label={I18n("answer_three")} isMandatory={true} menuPlacement="top" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <CardActions>
                            <Grid sx={{ display: "flex", alignItems: "center", gap: "15px" }} container justifyContent="center" >
                                <Button sx={{ transition: "280ms linear", textTransform: "none", fontFamily: "Noto Sans", height: "45px", fontSize: "14px", borderRadius: "20px", backgroundColor: "transparent", boxShadow: "none", border: "1px solid #E4002B", fontWeight: 400, color: "background.light", "&:hover": { backgroundColor: "background.light", color: "white.main", border: "1px solid background.light" } }} onClick={() => handleClose()} variant="contained">{I18n("cancel")}</Button>
                                <Button type="submit" variant="contained">{I18n("update")}</Button>
                            </Grid>
                        </CardActions>
                    </CardContent>
                </Form >
            </Card >
        </Modal>
    );
};

const mapStateToProps = createStructuredSelector({
    updatePasswordDetails: getUpdatePasswordForm
});

const mapDispatchToProps = (dispatch) => ({
    submitValues: (data, setOpenStatus) => dispatch(updatePassword({ data, setOpenStatus }))
});

const UpdatePasswordForm = withFormik({
    enableReinitialize: true,
    validate: customUpdatePasswordSchema,
    mapPropsToValues: (props) => {
        return props.updatePasswordDetails;
    },
    handleSubmit: (values, { props }) => {
        props.submitValues(values, props.setOpenStatus);
    },
    displayName: "UpdatePassword"
})(UpdatePassword);

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
