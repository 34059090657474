import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchGroupDeviceListApi = (data) => {
    return {
        url: API_URL.LIVE.GROUP_DEVICE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GROUP_DEVICE_LIST_REQUEST, ACTION_TYPES.GROUP_DEVICE_LIST_SUCCESS, ACTION_TYPES.GROUP_DEVICE_LIST_FAILURE],
            data
        }
    };
};

export const liveDetailsById = (data = {}) => {
    return {
        url: `${API_URL.LIVE.BY_ID}`,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.LIVE_VIEW_BY_ID_REQUEST, ACTION_TYPES.LIVE_VIEW_BY_ID_SUCCESS, ACTION_TYPES.LIVE_VIEW_BY_ID_FAILURE],
            data
        }
    };
};

export const stopStreaming = (data) => {
    return {
        url: `${API_URL.LIVE.STOP_STREAMING}`,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.STOP_STREAMING_REQUEST, ACTION_TYPES.STOP_STREAMING_SUCCESS, ACTION_TYPES.STOP_STREAMING_FAILURE],
            data
        }
    };
};

export const startRecording = (data) => {
    return {
        url: `${API_URL.LIVE.START_RECORDING}`,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.START_RECORDING_REQUEST, ACTION_TYPES.START_RECORDING_SUCCESS, ACTION_TYPES.START_RECORDING_FAILURE],
            data
        }
    };
};

export const stopRecording = (data) => {
    return {
        url: `${API_URL.LIVE.STOP_RECORDING}`,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.STOP_RECORDING_REQUEST, ACTION_TYPES.STOP_RECORDING_SUCCESS, ACTION_TYPES.STOP_RECORDING_FAILURE],
            data
        }
    };
};

export const saveViewApi = (data) => {
    return {
        url: `${API_URL.LIVE.SAVE_VIEW}`,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_VIEW_REQUEST, ACTION_TYPES.SAVE_VIEW_SUCCESS, ACTION_TYPES.SAVE_VIEW_FAILURE],
            data
        }
    };
};

export const listSaveViewListApi = (data) => {
    const { saveToCruisePlan, userId, payload: { pageNo, pageSize } } = data;
    return {
        url: `${API_URL.LIVE.LIST_SAVE_VIEW}?userId=${userId}&saveToCruisePlan=${saveToCruisePlan}&pageNo=${pageNo}&pageSize=${pageSize}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_VIEW_REQUEST, ACTION_TYPES.LIST_VIEW_SUCCESS, ACTION_TYPES.LIST_VIEW_FAILURE]
        }
    };
};

export const fetchViewByIdApi = (data) => {
    return {
        url: `${API_URL.LIVE.VIEW_BY_ID}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.EDIT_VIEW_BY_ID_REQUEST, ACTION_TYPES.EDIT_VIEW_BY_ID_SUCCESS, ACTION_TYPES.EDIT_VIEW_BY_ID_FAILURE],
            data
        }
    };
};

export const updateViewApi = (data) => {
    return {
        url: `${API_URL.LIVE.SAVE_VIEW}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_VIEW_REQUEST, ACTION_TYPES.UPDATE_VIEW_SUCCESS, ACTION_TYPES.UPDATE_VIEW_FAILURE],
            data
        }
    };
};

export const deleteViewApi = (data) => {
    return {
        url: `${API_URL.LIVE.DELETE_VIEW}?viewIds=${data}`,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_VIEW_REQUEST, ACTION_TYPES.DELETE_VIEW_SUCCESS, ACTION_TYPES.DELETE_VIEW_FAILURE]
        }
    };
};

export const moveUpViewApi = (id) => {
    return {
        url: `${API_URL.LIVE.MOVE_UP_VIEW.replace(":id", id)}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.MOVE_UP_VIEW_REQUEST, ACTION_TYPES.MOVE_UP_VIEW_SUCCESS, ACTION_TYPES.MOVE_UP_VIEW_FAILURE]
        }
    };
};

export const moveDownViewApi = (id) => {
    return {
        url: `${API_URL.LIVE.MOVE_DOWN_VIEW.replace(":id", id)}`,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.MOVE_DOWN_VIEW_REQUEST, ACTION_TYPES.MOVE_DOWN_VIEW_SUCCESS, ACTION_TYPES.MOVE_DOWN_VIEW_FAILURE]
        }
    };
};

export const fetchViewListApi = (data) => {
    return {
        url: `${API_URL.LIVE.VIEW_LIST}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.VIEW_LIST_REQUEST, ACTION_TYPES.VIEW_LIST_SUCCESS, ACTION_TYPES.VIEW_LIST_FAILURE],
            data
        }
    };
};

export const viewById = (data = {}) => {
    return {
        url: API_URL.LIVE.VIEW_BY_ID.replace(":id", data),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.VIEW_LIST_BY_ID_REQUEST, ACTION_TYPES.VIEW_LIST_BY_ID_SUCCESS, ACTION_TYPES.VIEW_LIST_BY_ID_FAILURE],
            data
        }
    };
};

export const viewDelete = (data = {}) => {
    return {
        url: API_URL.LIVE.VIEW_DELETE.replace(":id", data),
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.VIEW_DELETE_REQUEST, ACTION_TYPES.VIEW_DELETE_SUCCESS, ACTION_TYPES.VIEW_DELETE_FAILURE],
            data
        }
    };
};

export const getStreamSwitch = (data = {}) => {
    return {
        url: `${API_URL.LIVE.STREAM_SWITCH}`,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.GET_STREAM_SWITCH_REQUEST, ACTION_TYPES.GET_STREAM_SWITCH_SUCCESS, ACTION_TYPES.GET_STREAM_SWITCH_FAILURE],
            data
        }
    };
};
