import { AddCircleOutline } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { BASE64_DATA } from "../../../constants";
import { I18n } from "../..";

const ImageUpload = ({ name = "imageUploads", label = "", onBase64Change, imageBase64Data = {}, imageView = "false" }) => {
    const [imageData, setImageData] = useState("");

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 2048 * 1000;
        imageBase64Data = {};
        if (file) {
            if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/svg+xml" || file.type === "image/jpg") {
                if (file.size > maxSize) {
                    Swal.fire({
                        title: I18n("file_size_exceeds_the_maximum_allowed_size_of_2MB"),
                        showDenyButton: false,
                        confirmButtonColor: "#FF7400"
                    });
                } else {
                    imageBase64Data = "";
                    const reader = new FileReader();
                    setImageData();
                    reader.addEventListener("load", () => {
                        const regex = /data:.*base64,/;
                        const base64EncodedData = reader.result.replace(regex, "");
                        const fileExtension = file.name.split(".").pop();
                        const fileKb = file.size * 0.001;
                        const fileMb = fileKb * (1 / 1024);
                        setImageData(reader.result);
                        onBase64Change({
                            image: base64EncodedData,
                            fileExtension,
                            fileSize: parseInt(fileMb)
                        });
                    });
                    reader.readAsDataURL(file);
                }
            } else {
                Swal.fire({
                    title: I18n("file_format_is_not_allowed"),
                    showDenyButton: false,
                    confirmButtonColor: "#FF7400"
                });
            }
        }
    };
    return (
        <>
            {imageView === "true" ? <Grid>
                <label
                    className="image-container"
                    style={{
                        position: "relative",
                        width: "150px",
                        height: "150px",
                        overflow: "hidden",
                        borderRadius: "50%",
                        border: "2px solid #ddd",
                        display: "inline-block"
                    }} >
                    <img
                        src={imageData !== "" ? imageData : imageBase64Data !== "" ? `${BASE64_DATA}${imageBase64Data?.image}` : "http://via.placeholder.com/150x150"}
                        alt="Profile Picture"
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                    <Grid
                        sx={{
                            position: "absolute",
                            top: "87%",
                            left: "72%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                            color: "#fff",
                            padding: "4px",
                            backgroundColor: "#fff",
                            borderRadius: "50%"
                        }} >
                        <Grid sx={{ backgroundColor: "#FFF", width: "18px", height: "15px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path
                                    d="M15.213 0H3.0426C1.3646 0 0 1.3646 0 3.0426V12.1704C0 13.8484 1.3646 15.213 3.0426 15.213H5.26597L8.11765 17.6144C8.40822 17.8722 8.77257 18 9.13464 18C9.49062 18 9.8428 17.876 10.1174 17.6318L13.0444 15.213H15.2137C16.8917 15.213 18.2563 13.8484 18.2563 12.1704V3.0426C18.2563 1.3646 16.891 0 15.213 0ZM16.7343 12.1704C16.7343 13.0094 16.052 13.6917 15.213 13.6917H12.7698C12.5933 13.6917 12.4214 13.7533 12.2852 13.8659L9.11258 16.4643L6.03347 13.8704C5.89579 13.7548 5.72236 13.6917 5.54285 13.6917H3.04184C2.20284 13.6917 1.52054 13.0094 1.52054 12.1704V3.0426C1.52054 2.2036 2.20284 1.5213 3.04184 1.5213H15.2122C16.0512 1.5213 16.7335 2.2036 16.7335 3.0426L16.7343 12.1704ZM9.7964 3.71121L5.45538 8.05223C4.88033 8.62652 4.5639 9.39097 4.5639 10.2033V11.4097C4.5639 11.8296 4.90391 12.1704 5.32454 12.1704H6.53093C7.34331 12.1704 8.10776 11.8532 8.68205 11.2789L13.0231 6.93788C13.4536 6.50659 13.6917 5.93382 13.6917 5.32378C13.6917 4.71374 13.4536 4.14097 13.0231 3.71045C12.1331 2.82049 10.6864 2.82049 9.7964 3.71045V3.71121ZM11.9475 5.86232L7.60649 10.2033C7.31897 10.4909 6.93712 10.6491 6.53093 10.6491H6.08519V10.2033C6.08519 9.80324 6.24797 9.41075 6.53093 9.12779L10.872 4.78677C11.1694 4.49011 11.6501 4.49011 11.9475 4.78677C12.0913 4.92977 12.1704 5.12069 12.1704 5.32454C12.1704 5.5284 12.0913 5.71856 11.9475 5.86232Z"
                                    fill="#19A7F5"
                                />
                            </svg>
                        </Grid>
                    </Grid>
                    <input
                        type="file"
                        accept="image/*"
                        size="60"
                        name={name} style={{ display: "none" }} onChange={handleImageChange} label={label} />
                </label>
            </Grid> :
                <>
                    <Grid>
                        <label
                            className="image-container"
                            style={{
                                position: "relative",
                                width: "250px",
                                height: "150px",
                                overflow: "hidden",
                                border: "2px solid #ddd",
                                display: "inline-block"
                            }} >
                            <Grid sx={{
                                border: "2px dashed #A9ABAD", width: "90%",
                                height: "90%", marginTop: "8px", marginLeft: "10px"
                            }}>
                                <img
                                    src={imageData !== "" ? imageData : imageBase64Data !== "" ? `${BASE64_DATA}${imageBase64Data?.image}` : "http://via.placeholder.com/150x150"}
                                    alt="Profile Picture"
                                    style={{ width: "98%", height: "97%", objectFit: "cover", margin: "2px" }}
                                /></Grid>
                            <Grid
                                sx={{
                                    position: "absolute",
                                    top: "91%",
                                    left: "94%",
                                    transform: "translate(-50%, -50%)",
                                    cursor: "pointer",
                                    color: "#fff"
                                }} >
                                <Grid sx={{ backgroundColor: "#FFF", width: "26px", height: "25px" }}>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M15.213 0H3.0426C1.3646 0 0 1.3646 0 3.0426V12.1704C0 13.8484 1.3646 15.213 3.0426 15.213H5.26597L8.11765 17.6144C8.40822 17.8722 8.77257 18 9.13464 18C9.49062 18 9.8428 17.876 10.1174 17.6318L13.0444 15.213H15.2137C16.8917 15.213 18.2563 13.8484 18.2563 12.1704V3.0426C18.2563 1.3646 16.891 0 15.213 0ZM16.7343 12.1704C16.7343 13.0094 16.052 13.6917 15.213 13.6917H12.7698C12.5933 13.6917 12.4214 13.7533 12.2852 13.8659L9.11258 16.4643L6.03347 13.8704C5.89579 13.7548 5.72236 13.6917 5.54285 13.6917H3.04184C2.20284 13.6917 1.52054 13.0094 1.52054 12.1704V3.0426C1.52054 2.2036 2.20284 1.5213 3.04184 1.5213H15.2122C16.0512 1.5213 16.7335 2.2036 16.7335 3.0426L16.7343 12.1704ZM9.7964 3.71121L5.45538 8.05223C4.88033 8.62652 4.5639 9.39097 4.5639 10.2033V11.4097C4.5639 11.8296 4.90391 12.1704 5.32454 12.1704H6.53093C7.34331 12.1704 8.10776 11.8532 8.68205 11.2789L13.0231 6.93788C13.4536 6.50659 13.6917 5.93382 13.6917 5.32378C13.6917 4.71374 13.4536 4.14097 13.0231 3.71045C12.1331 2.82049 10.6864 2.82049 9.7964 3.71045V3.71121ZM11.9475 5.86232L7.60649 10.2033C7.31897 10.4909 6.93712 10.6491 6.53093 10.6491H6.08519V10.2033C6.08519 9.80324 6.24797 9.41075 6.53093 9.12779L10.872 4.78677C11.1694 4.49011 11.6501 4.49011 11.9475 4.78677C12.0913 4.92977 12.1704 5.12069 12.1704 5.32454C12.1704 5.5284 12.0913 5.71856 11.9475 5.86232Z"
                                            fill="#19A7F5"
                                        />
                                    </svg> */}
                                    <AddCircleOutline size="large" color="#000" sx={{
                                        color: "#000",
                                        cursor: "pointer"
                                    }} />
                                </Grid>
                            </Grid>
                            <input
                                type="file"
                                accept="image/*"
                                size="60"
                                name={name} style={{ display: "none" }} onChange={handleImageChange} label={label} />
                        </label>
                    </Grid>
                </>
            }</>
    );
};

export default ImageUpload;
