import { createTheme } from "@mui/material/styles";
import button from "./button.json";
import buttonBase from "./buttonBase.json";
import components from "./components.json";
import palette from "./palette.json";
import select from "./lightSelect.json";
import shape from "./shape.json";
import spacing from "./spacing.json";
import typography from "./typography.json";
import pagination from "./pagination.json";
import card from "./card.json";
import listItemButton from "./listItemButton.json";
import cardHeader from "./cardHeader.json";
import darkTheme from "./darkTheme";
import lightTheme from "./lightTheme";

const overrides = {
  components: {
    ...button,
    ...select,
    ...buttonBase,
    ...card,
    ...pagination,
    ...listItemButton,
    ...cardHeader
  }
};
const customThemes = {
  ...palette,
  ...typography,
  ...spacing,
  ...shape,
  ...components,
  ...darkTheme,
  ...lightTheme,
  ...overrides
};
const theme = createTheme({ ...customThemes });
export default theme;
