import { lazy } from "react";
import { I18n } from "../../common/components";
import { ThemeProvider } from "../../layouts/ThemeContext";
const Login = lazy(() => import("./register/components/Login"));
const ChangePassword = lazy(() => import("./register/components/ChangePassword"));
const ForgetPassword = lazy(() => import("./register/components/ForgetPassword"));

export const routes = [

    {
        title: I18n("login"),
        path: "login",
        element:
            <ThemeProvider >
                <Login />
            </ThemeProvider>
    },
    {
        title: I18n("change_password"),
        path: "change-password",
        element:
            <ThemeProvider >
                <ChangePassword />
            </ThemeProvider>
    },
    {
        title: I18n("forget_password"),
        path: "forget-password",
        element:
            <ThemeProvider >
                <ForgetPassword />
            </ThemeProvider>
    }
];
