import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listAlarmConfiguration = (data) => ({
    url: API_URL.ALARM_CONFIGURATION.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_ALARM_CONFIGURATION_REQUEST, ACTION_TYPES.LIST_ALARM_CONFIGURATION_SUCCESS, ACTION_TYPES.LIST_ALARM_CONFIGURATION_FAILURE],
        data
    }
});
