import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";
// import { fromEpoch } from "../../../utils/dateUtils";

const initialState = {
    playbackDownload: {
        data: [],
        requestInProgress: false
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clearPlayBackDownload: (state) => {
            state.playbackDownload = initialState.playbackDownload;
        },
        clearDeviceListData: (state) => {
            state.deviceListData = initialState.deviceListData;
        },
        setDownloadUrl: (state, action) => {
            state.playbackDownload.data = action?.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_DOWNLOAD_REQUEST, (state) => {
                state.playbackDownload.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_DOWNLOAD_SUCCESS, (state) => {
                _.set(state, "playbackDownload.requestInProgress", false);
                // _.set(state, "playbackDownload.data", action.payload);
            })
            .addCase(ACTION_TYPES.FETCH_DOWNLOAD_FAILURE, (state) => {
                state.playbackDownload.requestInProgress = false;
            });
    }
});
export const { actions, reducer } = slice;
