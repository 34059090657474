import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { FORM_CONTROL_STYLE } from "./style";
import TextError from "./TextError";

const CheckboxController = (props) => {
    const { label, name, onChangeFromController, checked, indeterminate = null, iconLabel, style, ...rest } = props;

    return (
        <Grid sx={FORM_CONTROL_STYLE}>
            <Field style={{ color: "label.main" }} name={name} >
                {({ field, form }) => {
                    return <FormControlLabel
                        {...field}
                        {...rest}
                        id={name}
                        control={<Checkbox size="small" color="secondary" sx={{
                            "&.Mui-checked": {
                                color: "#E4002B"
                            },
                            "&.MuiCheckbox-root": {
                                padding: 0
                            },
                            ...style
                        }}
                            indeterminate={indeterminate}
                        />}
                        label={label || iconLabel}
                        checked={checked ?? field.value}
                        onChange={(e) => {
                            form.setFieldValue(name, e.target.checked);
                            onChangeFromController && onChangeFromController(e.target.checked);
                        }}
                    />;
                }}
            </Field>
            <ErrorMessage component={TextError} name={name} />
        </Grid>
    );
};
export default CheckboxController;
