import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent, getPayloadData } from "../../../utils/apiUtils";

const USER_MANAGEMENT_DETAILS = {
    userValidity: "",
    loginPassword: "",
    userDescription: "",
    userName: "",
    roles: "",
    loginId: "",
    designation: "",
    emailId: "",
    mobileNo: "",
    confirmPassword: ""
};

const initialState = {
    userManagementById: {
        requestInProgress: false,
        data: {
            ...USER_MANAGEMENT_DETAILS
        }
    },
    userLevelDropdown: {
        requestInProgress: false,
        data: []
    },
    usersList: {
        requestInProgress: false,
        data: {}
    },
    count: 0,
    activeRoleResourceList: [],
    dataAccessMappings: []
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.userManagementById = initialState.userManagementById;
        },
        setCount: (state) => {
            state.count = state.count + 1;
        },
        decrementCount: (state) => {
            state.count = state.count - 1;
        },
        setRowSelectionData: (state, action) => {
            state.usersList.data = action.payload;
        },
        setActiveRoleResourceList: (state, action) => {
            state.activeRoleResourceList = action.payload;
        },
        setDataAccessMappings: (state, action) => {
            state.dataAccessMappings = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.SELECT_USER_ROLES_REQUEST, (state) => {
                state.userLevelDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_USER_ROLES_SUCCESS, (state, action) => {
                _.set(state, "userLevelDropdown.requestInProgress", false);
                _.set(state, "userLevelDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_USER_ROLES_FAILURE, (state) => {
                state.userLevelDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.USER_DETAILS_BY_ID_REQUEST, (state) => {
                state.userManagementById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.USER_DETAILS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "userManagementById.requestInProgress", false);
                _.set(state, "userManagementById.data", getPayloadData(action));
            })
            .addCase(ACTION_TYPES.USER_DETAILS_BY_ID_FAILURE, (state) => {
                state.userManagementById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.CHECKED_RESOURCE_DATA_SUCCESS, (state, action) => {
                _.set(state, "activeRoleResourceList", action?.payload?.content);
            })
            .addCase(ACTION_TYPES.POST_USER_DETAILS_REQUEST, (state) => {
                state.userManagementById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.POST_USER_DETAILS_SUCCESS, (state) => {
                _.set(state, "userManagementById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.POST_USER_DETAILS_FAILURE, (state) => {
                state.userManagementById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.UPDATE_USER_DETAILS_REQUEST, (state) => {
                state.userManagementById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.UPDATE_USER_DETAILS_SUCCESS, (state) => {
                _.set(state, "userManagementById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.UPDATE_USER_DETAILS_FAILURE, (state) => {
                state.userManagementById.requestInProgress = false;
            });
    }
});
export const { actions, reducer } = slice;
