import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent, getPayloadData } from "../../../utils/apiUtils";

const initialState = {
    commonSettingsById: {
        requestInProgress: false,
        data: {
            photoPath: "",
            videoPath: "",
            language: {},
            skin: {},
            operationLogPeriod: {},
            alarmLogPeriod: {},
            automaticLogin: false
        }
    },
    recordPlanFilter: {
        data: {
            enableRecording: false,
            streamType: "",
            timeTemplate: ""
        }
    },
    commonSettingsListAll: {
        requestInProgress: false,
        data: []
    },
    videoSettingsById: {
        requestInProgress: false,
        data: {
            playProperty: {},
            decodingSetting: {},
            videoDownloadFormat: {},
            frameRate: {},
            playbackTime: {},
            restoreStatus: false,
            stream: {},
            screenNumber: "",
            streamType: {}
        }
    },
    videoSettingsListAll: {
        requestInProgress: false,
        data: []
    },
    skinDropdown: {
        requestInProgress: false,
        data: []
    },
    languageDropdown: {
        requestInProgress: false,
        data: []
    },
    logSettingDropdown: {
        requestInProgress: false,
        data: []
    },
    streamTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    frameRateDropdown: {
        requestInProgress: false,
        data: []
    },
    playPropertyDropdown: {
        requestInProgress: false,
        data: []
    },
    decodingSettingDropdown: {
        requestInProgress: false,
        data: []
    },
    videoDownloadFormatDropdown: {
        requestInProgress: false,
        data: []
    },
    playbackTimeDropdown: {
        requestInProgress: false,
        data: []
    },
    isAutomaticDropdown: {
        requestInProgress: false,
        data: []
    },
    alarmGradeList: {
        requestInProgress: false,
        data: []
    },
    storageManagementById: {
        requestInProgress: false,
        data: {
            recordingTime: "",
            recordingFormat: {},
            recordType: {},
            minDiskSpace: "",
            diskSpaceType: {},
            diskFull: {},
            checked: false
        }
    },
    recordPlanById: {
        requestInProgress: false,
        data: {
            timeTemplate: "",
            day: [],
            periodSetting: [
                {
                    startTime: "",
                    endTime: ""
                }
            ]
        }
    },
    diskSpaceDropdown: {
        requestInProgress: false,
        data: []
    },
    recordingFormatDropdown: {
        requestInProgress: false,
        data: []
    },
    recordTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    diskFullDropdown: {
        requestInProgress: false,
        data: []
    },
    storageManagementListAll: {
        requestInProgress: false,
        data: []
    },
    driveList: {
        requestInProgress: false,
        data: []
    },
    timeTemplateDropdown: {
        requestInProgress: false,
        data: []
    },
    codeStreamDropdown: {
        requestInProgress: false,
        data: []
    },
    customizedList: {
        requestInProgress: false,
        data: []
    },
    timeProgressById: {
        requestInProgress: false,
        data: {
            settings: "",
            timeTemplate: "",
            enableRecording: false
        }
    },
    listMessages: {
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clearCommonSettings: (state) => {
            state.commonSettingsById = initialState.commonSettingsById;
            state.listMessages.data = initialState.listMessages.data;
        },
        clearProgress: (state) => {
            state.timeProgressById.data.settings = initialState.timeProgressById.data.settings;
        },
        clearListMessages: (state) => {
            state.listMessages.data = initialState.listMessages.data;
        },
        clearVideoSettings: (state) => {
            state.videoSettingsById = initialState.videoSettingsById;
        },
        clearStorageManagement: (state) => {
            state.storageManagementById = initialState.storageManagementById;
        },
        clearRecordPlanById: (state) => {
            state.recordPlanById = initialState.recordPlanById;
        },
        setRecordPlanFilter: (state, action) => {
            _.set(state, "recordPlanFilter.data", action.payload);
        },
        setChecked: (state, { payload }) => {
            state.storageManagementById.data.checked = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.COMMON_SETTINGS_BY_ID_REQUEST, (state) => {
                state.commonSettingsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.COMMON_SETTINGS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "commonSettingsById.requestInProgress", false);
                _.set(state, "commonSettingsById.data", action.payload);
            })
            .addCase(ACTION_TYPES.COMMON_SETTINGS_BY_ID_FAILURE, (state) => {
                state.commonSettingsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.COMMON_SETTINGS_LIST_ALL_SUCCESS, (state, action) => {
                _.set(state, "commonSettingsListAll.requestInProgress", false);
                _.set(state, "commonSettingsListAll.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.VIDEO_SETTINGS_BY_ID_REQUEST, (state) => {
                state.videoSettingsById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.VIDEO_SETTINGS_BY_ID_SUCCESS, (state, action) => {
                _.set(state, "videoSettingsById.requestInProgress", false);
                _.set(state, "videoSettingsById.data", action.payload);
            })
            .addCase(ACTION_TYPES.VIDEO_SETTINGS_BY_ID_FAILURE, (state) => {
                state.videoSettingsById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.VIDEO_SETTINGS_LIST_ALL_SUCCESS, (state, action) => {
                _.set(state, "videoSettingsListAll.requestInProgress", false);
                _.set(state, "videoSettingsListAll.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_SKIN_SUCCESS, (state, action) => {
                _.set(state, "skinDropdown.requestInProgress", false);
                _.set(state, "skinDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_LANGUAGE_SUCCESS, (state, action) => {
                _.set(state, "languageDropdown.requestInProgress", false);
                _.set(state, "languageDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_LOG_SUCCESS, (state, action) => {
                _.set(state, "logSettingDropdown.requestInProgress", false);
                _.set(state, "logSettingDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_FRAME_RATE_SUCCESS, (state, action) => {
                _.set(state, "frameRateDropdown.requestInProgress", false);
                _.set(state, "frameRateDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_DECODING_SETTING_SUCCESS, (state, action) => {
                _.set(state, "decodingSettingDropdown.requestInProgress", false);
                _.set(state, "decodingSettingDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_PLAY_BACK_TIME_SUCCESS, (state, action) => {
                _.set(state, "playbackTimeDropdown.requestInProgress", false);
                _.set(state, "playbackTimeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_PLAY_PROPERTY_SUCCESS, (state, action) => {
                _.set(state, "playPropertyDropdown.requestInProgress", false);
                _.set(state, "playPropertyDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_VIDEO_DOWNLOAD_FORMAT_SUCCESS, (state, action) => {
                _.set(state, "videoDownloadFormatDropdown.requestInProgress", false);
                _.set(state, "videoDownloadFormatDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_STREAM_TYPE_SUCCESS, (state, action) => {
                _.set(state, "streamTypeDropdown.requestInProgress", false);
                _.set(state, "streamTypeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_IS_AUTOMATIC_SUCCESS, (state, action) => {
                _.set(state, "isAutomaticDropdown.requestInProgress", false);
                _.set(state, "isAutomaticDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.ALARM_GRADE_LIST_ALL_REQUEST, (state) => {
                state.alarmGradeList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.ALARM_GRADE_LIST_ALL_SUCCESS, (state, action) => {
                _.set(state, "alarmGradeList.requestInProgress", false);
                _.set(state, "alarmGradeList.data", action.payload);
            })
            .addCase(ACTION_TYPES.ALARM_GRADE_LIST_ALL_FAILURE, (state) => {
                state.alarmGradeList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_DISK_SPACE_SUCCESS, (state, action) => {
                _.set(state, "diskSpaceDropdown.requestInProgress", false);
                _.set(state, "diskSpaceDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_RECORDING_FORMAT_SUCCESS, (state, action) => {
                _.set(state, "recordingFormatDropdown.requestInProgress", false);
                _.set(state, "recordingFormatDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_RECORDING_TYPE_SUCCESS, (state, action) => {
                _.set(state, "recordTypeDropdown.requestInProgress", false);
                _.set(state, "recordTypeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_DISK_FULL_SUCCESS, (state, action) => {
                _.set(state, "diskFullDropdown.requestInProgress", false);
                _.set(state, "diskFullDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID_REQUEST, (state) => {
                state.storageManagementById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID_SUCCESS, (state, action) => {
                let tempValue = [...state.driveList.data];
                let exist = tempValue.some(d => d?.driveName === action.payload?.drives?.driveName);
                _.set(state, "storageManagementById.requestInProgress", false);
                _.set(state, "storageManagementById.data", action.payload);
                if (Array.isArray(state.driveList.data)) {
                    if (!exist) {
                        state.driveList.data.push(action.payload?.drives);
                    }
                }
            })
            .addCase(ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID_FAILURE, (state) => {
                state.storageManagementById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.STORAGE_MANAGEMENT_LIST_ALL_SUCCESS, (state, action) => {
                _.set(state, "storageManagementListAll.requestInProgress", false);
                _.set(state, "storageManagementListAll.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.GET_DRIVE_LIST_REQUEST, (state) => {
                state.driveList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_DRIVE_LIST_SUCCESS, (state, action) => {
                _.set(state, "driveList.requestInProgress", false);
                _.set(state, "driveList.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_DRIVE_LIST_FAILURE, (state) => {
                state.driveList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_TIME_TEMPLATE_SUCCESS, (state, action) => {
                _.set(state, "timeTemplateDropdown.requestInProgress", false);
                _.set(state, "timeTemplateDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.GET_CODE_STREAM_SUCCESS, (state, action) => {
                _.set(state, "codeStreamDropdown.requestInProgress", false);
                _.set(state, "codeStreamDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.GET_RECORD_PLAN_SETTING_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "recordPlanById.requestInProgress", false);
                _.set(state, "recordPlanById.data", payload.settings || state.recordPlanById.data);
            })
            .addCase(ACTION_TYPES.LIST_CUSTOMIZED_DEVICES_REQUEST, (state) => {
                state.customizedList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIST_CUSTOMIZED_DEVICES_SUCCESS, (state, { payload }) => {
                _.set(state, "customizedList.requestInProgress", false);
                _.set(state, "customizedList.data", payload);
            })
            .addCase(ACTION_TYPES.LIST_CUSTOMIZED_DEVICES_FAILURE, (state) => {
                state.customizedList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.TIME_PROGRESS_BY_ID_REQUEST, (state) => {
                state.timeProgressById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.TIME_PROGRESS_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "timeProgressById.requestInProgress", false);
                _.set(state, "timeProgressById.data", payload);
                // _.set(state, "recordPlanFilter.data", payload);
            })
            .addCase(ACTION_TYPES.TIME_PROGRESS_BY_ID_FAILURE, (state) => {
                state.timeProgressById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.LIST_BACKUP_MESSAGES_SUCCESS, (state, action) => {
                _.set(state, "listMessages.data", getPayloadData(action));
            })
            .addCase(ACTION_TYPES.RESTORE_DEFAULT_REQUEST, (state) => {
                state.storageManagementById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.RESTORE_DEFAULT_SUCCESS, (state, action) => {
                let tempValue = [...state.driveList.data];
                let exist = tempValue.some(d => d?.driveName === action.payload?.drives?.driveName);
                _.set(state, "storageManagementById.requestInProgress", false);
                _.set(state, "storageManagementById.data", getPayloadData(action));

                if (Array.isArray(state.driveList.data)) {
                    if (!exist) {
                        state.driveList.data.push(action.payload?.drives);
                    }
                }
            })
            .addCase(ACTION_TYPES.RESTORE_DEFAULT_FAILURE, (state) => {
                state.storageManagementById.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
