import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const fetchQueryRecordApi = (data) => {
    return {
        url: API_URL.PLAYBACK.QUERY_RECORD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FETCH_QUERY_RECORD_REQUEST, ACTION_TYPES.FETCH_QUERY_RECORD_SUCCESS, ACTION_TYPES.FETCH_QUERY_RECORD_FAILURE],
            data
        }
    };
};
export const fetchDownloadApi = (data) => {
    return {
        url: API_URL.PLAYBACK.QUERY_RECORD,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FETCH_DOWNLOAD_REQUEST, ACTION_TYPES.FETCH_DOWNLOAD_SUCCESS, ACTION_TYPES.FETCH_DOWNLOAD_FAILURE],
            data
        }
    };
};

export const fetchStopPlaybackApi = (data) => {
    return {
        url: API_URL.PLAYBACK.STOP,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.STOP_PLAYBACK_REQUEST, ACTION_TYPES.STOP_PLAYBACK_SUCCESS, ACTION_TYPES.STOP_PLAYBACK_FAILURE],
            data
        }
    };
};
