/* eslint-disable camelcase */
export default {
    welcome: "Welcome",
    home: "Home",
    login: "Login",
    lets_go: "Let's Go",
    try_again: "Try Again",
    something_went_wrong: "Something went wrong",
    copyright: "Copyright",
    privacy_policy: "Privacy Policy",
    terms_of_service: "Terms of Service",
    cpu_management: "CPU Management",
    about_us: "About Us",
    settings: "Settings",
    user_name: "Username",
    password: "Password",
    is_required: "{{type}} is Required",
    device: "Device",
    live: "Live",
    alarm: "Alarm",
    logout: "Logout",
    profile: "Profile",
    malayalam: "Malayalam",
    english: "English",
    english_uk: "English (UK)",
    english_us: "English (US)",
    arabic: "Arabic",
    vietnamese: "Vietnamese",
    bahasa_indonesia: "Bahasa Indonesia",
    successfully: "Successfully",
    logged: "Login",
    users: "{{type}} login successfully",
    password_changed: "Password Changed Successfully",
    user: "User",
    user_name_message: "White space not allowed",
    password_message: "White space not allowed",
    cpu_usage: "CPU",
    memory: "Mem",
    security_question_one: "Security Question 1",
    answer_one: "Answer 1",
    security_question_two: "Security Question 2",
    answer_two: "Answer 2",
    security_question_three: "Security Question 3",
    answer_three: "Answer 3",
    forget_password: "Forgot Password ?",
    security_answer_mismatch: "Security Answer Mismatch",
    change_password: "Change Password",
    downloading_file: "Downloading {{file}}",
    download: "Download",
    data_bakup: "Data Backup",

    //notification messages
    login_user_not_found: "User Not Found",
    login_invalid_password: "Invalid Password",
    login_no_active_role: "No Active Role",
    general_nodata_found: "No data found",
    general_data_exist: "Data exist",
    general_operation_not_allowed: "Operation not allowed",
    validation_parent_child_resource_same: "Parent child resource same",
    validation_parent_child: "Parent child",
    group_not_found: "Group not found",
    group_cannot_move_up: "Group can not move up",
    group_cannot_move_down: "Group can not move down",
    device_not_found: "Device not found",
    time_overlapping_not_allowed: "Time overlapping not allowed",
    starttime_endtime_mandatory: "Start Time and end Time mandatory",
    device_name_exist: "Device name already exist",
    device_ip_exist: "Device IP already exist",
    user_mobile_exist: "User mobile already exist",
    operation_failed: "Could not process this request.",
    user_loginid_exist: "Username already exist",
    login_invalid_credentails: "Invalid login credentials",
    device_channel_not_available: "Device channel is not available",
    device_rtsp_url_not_available: "Device RTSP url not available",
    slot_not_received: "Slot ID is not received",
    user_creation_not_allowed: "User creation not allowed",
    update_default_password: "Please update the password",
    invalid_percentage: "Invalid percentage",
    invalid_recording_time: "Invalid recording time",
    incorrect_old_password: "Incorrect old password",
    cannot_move_up: "Cannot Move Up",
    cannot_move_down: "Cannot Move Down",
    storage_limit_exceed: "Storage limit exceeded",
    slot_id_missing: "Slot ID Missing",
    device_not_connected: "Unable to connect device online",
    invalid_payload: "Invalid Payload",
    device_group_empty: "Device Group Empty",
    cannot_delete_device_group: "Cannot Delete Device Group",
    group_name_exist: "Group name already exist",
    view_name_exist: "View name already exist",
    session_expired: "Session expired",
    back_to_login: "Back to Login",
    cannot_delete_e_map: "Cannot delete E-Map",

    //home module
    common_functions: "Common Functions",
    favourites: "Favourites",
    basic_functions: "Basic Functions",
    electronic_map: "Electronic Map",
    management_and_operation_function_of_electronic_map: "Management and operation function of electronic map.",
    tv_wall_configuration_preview_offline_switch_settings: "TV wall configuration, preview, offline switch settings.",
    tv_wall: "TV Wall",
    alarm_control: "Alarm Control",
    real_time_indication_of_unit_alarm_message_ti_facilitate_access_ti_unit_exception: "Real-time indication of unit alarm message ti facilitate access ti unit exception.",
    typical_operation_on_client_onfiguration: "Typical operation on client Configuration.",
    system_setup: "System Setup",
    add_modify_and_delete_groups: "Add, Modify and Delete groups.",
    add_modify_and_delete_users: "Add, Modify and Delete users.",
    device_administration: "Device Administration",
    search_and_play_the_recording_file_at_the_monitoring_point: "Search and play the recording file at the monitoring point.",
    playback: "Playback",
    view_the_live_images_at_the_monitoring_point: "View the live images at the monitoring point.",
    alarm_configuration: "Alarm Configuration",
    operation_logs: "Operation Logs",
    user_managemet: "User Management",

    //device module
    name: "Name",
    group: "Group",
    device_type: "Device Type",
    device_model: "Device Model",
    ip_address: "IP Address",
    port: "Port",
    video_channel: "Video Channel",
    connection: "Connection",
    operation: "Operation",
    encode_device: "Encode Device",
    decoding_device: "Decoding Device",
    dns: "DNS",
    please_enter_dns: "Please Enter DNS",
    refresh: "Refresh",
    auto_search: "Auto Search",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    invert_selection: "Invert Selection",
    add_mode: "Add Mode",
    device_name: "Device Name",
    select_protocol: "Select Protocol",
    device_ip: "Device IP",
    alarm_input: "Alarm Input",
    alarm_output: "Alarm Output",
    add_device: "Add Device",
    user_names: "Username",
    off_line_add: "Off-line Add",
    import_to_group: "Import to Group",
    cancel: "Cancel",
    please_enter_device_name: "Please enter device name",
    please_enter_device_model: "Please enter device model",
    please_enter_device_ip: "Please enter device IP",
    please_enter_user_name: "Please enter user name",
    online_devices: "Online Devices",
    offline_devices: "Offline Devices",
    devices_added: "Devices Added Successfully",

    //system setup
    common_settings: "Common Settings",
    video_settings: "Video Settings",
    alarm_grade: "Alarm Grade",
    storage_management: "Storage Management",
    record_plan: "Record Plan",
    p2p_settings: "P2P Settings",
    restore_default: "Restore Default",
    save: "Save",
    login_settings: "Login Settings",
    automatic_login_software: "Automatic Login Software",
    browse: "Browse",
    path_settings: "Path Settings",
    save_path_of_capture_photo: "Save Path of Capture Photo",
    video_download_save_path: "Video Download Save Path",
    system: "System",
    language: "Language",
    skin: "Skin",
    data_backup: "Data Backup",
    database_backup: "Database Backup",
    backup: "Backup",
    recover: "Recover",
    reset: "Reset",
    re_index: "Re Index",
    output: "Output",
    import: "Import",
    log_settings: "Log Settings",
    period_of_keeping_operation_log: "Period of Keeping Operation Log (days)",
    period_of_keeping_alarm_log: "Period of Keeping Alarm Log (days)",
    automatically_restore_the_state_last_time_you_exit_from_the_system: "Automatically restore the state last time you exit from the system",
    switch_between_main_and_s: "Switch between Main and S",
    automatic_switch_screen_number: "Automatic Switch Screen Number",
    mannual_default_connection: "Mannual Default Connection",
    play_property_setting: "Play Property Setting",
    decoding_mode_setting: "Decoding Mode Setting",
    video_download_format: "Video Download Format",
    preview_frame_rate: "Preview Frame Rate",
    instant_playback_time: "Instant Playback Time (sec)",
    region_selection: "Region Selection",
    continuous_recording_time: "Continuous Recording Time(min)",
    recording_format: "Recording Format",
    type_of_record: "Type Of Record",
    min_desk_space: "Min. Disk Space",
    percentage: "Percentage (%)",
    space: "Space (G)",
    disk_full: "Disk Full",
    location: "Location",
    total_size_g: "Total Size(G)",
    available_space_g: "Available Space(G)",
    resource_management: "Resource Management",
    role_management: "Role Management",
    resource_id: "Resource Id",
    module: "Module",
    description: "Description",
    parent: "Parent",
    group_type: "Group Type",
    is_parent: "Is Parent",
    show_checkbox: "Show Checkbox",
    id: "Id",
    role_name: "Role Name",
    role_level: "Role Level",
    add_and_manage_resources: "Add and Manage Resources.",
    assign_permission_to_the_required_roles: "Assign Permission to the required roles.",
    user_management: "User Management",
    user_level: "User Level",
    validity: "Validity",
    confirm_password: "Confirm Password",
    add_user: "Add User",
    user_type: "User Type",
    login_id: "Login ID",
    designation: "Designation",
    email: "Email",
    mobile_no: "Mobile No",
    update_user: "Update User",
    are_sure_want_delete: "Are you sure want to delete?",
    update: "Update",
    password_mismatch: "Password Mismatch",
    mismatch_password: "New and confirm passwords must match.",
    no_leading_trailing_whitespace: "No space allowed",
    must_be: "{{type}} must be at least {{count}} length",
    updated: "{{type}} updated successfully",
    exported: "Exported successfully",
    imported: "Imported successfully",
    delete_success: "{{type}} successfully deleted",
    recording_started: "{{type}} successfully started",
    recording_stoped: "{{type}} successfully stopped",
    stream_stopped: "Stream stopped",
    please_choose_option: "Please choose an option",
    live_view: "Live View",
    select: "Select",
    advanced_functions: "Advanced Functions",
    operations: "Operations",
    device_list: "Device List",
    view: "View",
    device_mode: "Device Mode",
    update_device: "Update Device",
    invalid_type: "Invalid Type",
    invalid_device_ip: "Invalid Device IP",
    invalid_resource_id: "Invalid Resource Id",
    invalid_mobile_no: "Invalid Mobile No",
    invalid_email: "Invalid Email",
    success: "Success",
    created: "{{type}} created successfully",
    test: "Test",
    offline: "Offline",
    video_loss_alarm: "Video loss alarm",
    alarm_input_alarm: "Alarm input alarm",
    audio_loss_alarm: "Audio loss alarm",
    disk_absence_on_until: "Disk absence on until exception",
    recording_exception_on: "Recording exception on until",
    containing_capacity: "Containing capacity exceeding",
    sharply_increasing: "Sharply increasing capacity",
    grade: "{{value}} - Grade",
    color_mode: "Color Mode",
    customer_device_list: "Customer Device List",
    enable_local_recoding_schedule: "Enable local recoding schedule",
    channel_name: "Channel Name : ",
    main_stream: "Main Stream",
    code_stream: "Code Stream",
    time_template: "Time Template",
    full_cycle_template: "Full Cycle Template",
    copy_to: "Copy To",
    all: "All",
    ok: "Ok",
    period: "Period",
    regular: "Regular",
    motion: "Motion",
    select_one_drive: "Select any Drive",
    take_effects_after_reboot: "*Take effects after reboot",
    backup_successfully: "Backup Successfully completed",
    data_are_restored_please_reboot_the_software_immediately: "Data are restored. Please re-boot the software immediately",
    recover_successfully: "Recover Successfully completed",
    import_successfully: "Import Successfully completed",

    //Alarm Control
    real_time_alarm: "Real-Time Alarm",
    history_alarm: "History Alarm",
    time_of_alarm: "Time of Alarm",
    source_of_alarm: "Source of Alarm",
    alarm_type: "Alarm Type",
    alarm_status: "Alarm Status",
    preview: "Preview",
    comment: "Comment",
    check: "Check",
    filter_level: "Filter Level",
    select_all: "Select All",
    grade_1: "Grade 1",
    grade_2: "Grade 2",
    grade_3: "Grade 3",
    grade_4: "Grade 4",
    grade_5: "Grade 5",
    user_check: "User Check",
    status_check: "Status Check",
    time_check: "Time Check",
    time: "Time",
    query: "Query",

    enabled: "Enabled",
    coordinated_client_action: "Coordinated Client Action",
    move_upward: "Move Upward",
    move_down: "Move Down",
    export: "Export",
    customized_list: "Customized List",
    add_group: "Add Group",
    modify_group: "Modify Group",
    delete_group: "Delete Group",
    get_device_name: "Get Device Name",
    sync_to_device: "Sync to Device",

    //TV Wall
    tv_wall_config: "TV Wall Config",
    offline_switch_setup: "Offline Switch Setup",
    set: "Set",
    stop_offline_switching: "Stop Offline Switching",
    initiate_offline_switching: "Initiate Offline Switching",
    switch_time: "Switch time(10-1000 Second)",
    stream_type: "Stream Type",
    decode_output_channel: "Decode Output Channel",
    decode_output_settings: "Decode Output Settings",
    custom_device_list: "Custom Device List",
    ptz_control: "PTZ Control",
    advanced_function: "Advanced Function",
    tv_wall_name: "TV Wall Name",
    local_preview: "Local Preview",
    to: "To",
    total_device_searched: "Total Device Searched : 00",
    product_number: "Product Number",
    channel_no: "Channel No",
    search: "Search",
    modify_device_ip: "Modify Device IP",
    new_ip: "New IP",
    gateway: "Gateway",
    subnet_mask: "Subnet Mask",
    modify: "Modify",
    device_group: "Device Group",

    //PlayBack
    file_name: "File Name",
    size_mb: "Size (MB)",
    export_time: "Export Time",
    progress: "Progress",
    status: "Status",
    storage_path: "Storage Path",
    front_end_exported: "Front-end exported",
    locally_exported: "Locally exported",
    remote_storage: "Remote Storage",
    storage_location: "Storage Location",
    sub_stream: "Substream",
    from_time: "From Time",
    to_time: "To Time",
    time_based_download: "Time based download",
    recent_browsing: "Recent Browsing",
    query_results: "Query Results",
    download_list: "Download List",
    warm: "Warm",
    timing: "Timing",
    manual: "Manual",
    playback_query_filtered: "Playback Query Filtered",
    recording_received: "Recording Received",
    recording: "Recording",
    available_space_percentage: "Available Space Percentage",
    available_space: "Available Space(G)",
    total_size: "Total Size",
    storage_space_snapshot_and_image: "Storage Space Snapshot and Image",
    downloaded: "Downloaded",
    toTime_should_be_greater_than_fromTime: "To Time should be\ngreater than From Time",
    only_4_channels_can_be_selected: "Only 4 channels can be selected",
    previous_playback_stopped: "Previous playback stopped",
    downloading: "Downloading...",
    close: "Close",

    //Live
    add_to_favorites: "Add to favorites",
    turn_off_the_video: "Turn off the video",
    turn_off_all_video: "Turn off all video",
    snapshot: "Snapshot",
    adjust_the_videos_aspect_ratio: "Adjust the videos aspect ratio",
    control: "Control",
    switching_of_main_code_and_subcode_streams: "Switching of main code and subcode streams",
    video_parameter: "Video Parameter",
    time_calibration: "Time Calibration",
    device_config: "Device Config",
    full_screen: "Full Screen",
    clear_alarm: "Clear Alarm",
    electronic_zoom: "Electronic Zoom",
    start_time: "Start Time",
    end_time: "End Time",
    end_time_must_be_later_than_start_time: "End time must be later than start time",
    user_authority: "User Authority",
    permission: "Permission",
    submit: "Submit",
    min_value_error: "Minimum Recording Time {{min}} minutes",
    max_value_error: "Maximum Recording Time {{max}} minutes",
    record_plan_for_this_channel: "Enabled Record Plan For This Chanel",
    record_plan_for_this_channel_disabled: "Disabled Record Plan For This Chanel",
    current_password: "Current Password",
    new_password: "New Password",
    update_password: "Update Password",
    old_and_new_password_not_be_same: "Current Password And New Password Should Not Be Same",
    confirm: "Confirm",
    delete_confirmation: "Delete Confirmation",
    sure_want_to_delete_the_selected_device: "Sure want to delete the selected Device?",
    download_initiating: "Download Initiating",
    download_initiated: "Download Initiated",
    save_view: "Save View",
    view_name: "View Name",
    please_enter_view_name: "Please enter view name",
    save_to_cruise_plan: "Save to Cruise Plan",
    view_saved: "View Saved",
    view_cruise_plan_list: "View Cruise Plan List",
    move_up: "Move Up",
    time_interval: "Time Interval",
    modify_the_cruise_intervals: "Modify the Cruise Intervals",
    sure_to_delete_the_view_from_the_cruise_plan: "Sure to delete the view from the Cruise plan?",
    name_change: "Name change",
    add_to_the_regular_rotating_switch_program: "Add to the regular rotating switch program",
    moved_down: "Moved Down",
    moved_up: "Moved Up",
    view_deleted: "View Deleted",
    min_value_error_in_view: "Minimum Time Interval {{min}} seconds",
    max_value_error_in_view: "Maximum Time Interval {{max}} seconds",
    view_updated: "View Updated",
    modify_the_view_name: "Modify the View Name",
    used_space: "Used Space(G)",
    time_caliberation: "Time Calibration",
    add_to_the_list_of_switch_groups: "Add to the list of switch groups",
    disconnect_this_group_of_video: "Disconnect this group of video",
    log_off_device: "Log off Device",
    turn_off_video: "Turn off Video",
    device_configuration: "Device Configuration",
    login_into_the_device: "Login into the Device",
    stream_switched: "Stream Switched",
    min_characters: "Minimum {{min}} characters required",
    view_switch: "View Switch",
    select_grid: "Select Grid",
    take_snap_shot: "Take snap shot",
    stop_streaming: "Stop Streaming",
    stop_recording: "Stop Recording",
    start_recording: "Start Recording",
    instant_playback: "Instant Playback",
    turn_on_audio: "Turn ON Audio",
    add_tab: "Add New Tab",
    remove_tab: "Close Tab",
    zoom_in: "Zoom In",
    zoom_out: "Zoom Out",
    full_name: "Full Name",

    //Video Parameter
    brightness: "Brightness",
    contrast: "Contrast",
    hue: "Hue",
    saturation: "Saturation",

    //operation logs
    operation_time: "Operation TIme",
    log_type: "Log Type",
    detailed_type: "Detailed Type",
    log_description: "Log Description",
    starting_time: "Starting Time",
    ending_time: "Ending Time",
    unlock_screen: "Unlock Screen",
    video_not_available: "Video not available",

    //electronic map
    e_map: "E-Map",
    add_map: "Add Map",
    map_name: "Map Name",
    link_channel: "Link Channel",
    add_hot_zone: "Add Hot Zone",
    required: "Required",
    related_hot_zone: "Related Hot Zone",
    delete_device: "Delete Device",
    update_map: "Update Map",
    map: "Map",
    type_here: "Type Here",
    file_size_should_not_exceed_5MB: "File size should not exceed 5MB.",
    modify_map: "Modify Map",
    cancel_full_screen: "Cancel Full Screen"
};
