import { useDispatch } from "react-redux";

import { Components, I18n } from "../../../../common/components";
import { actions as commonActions } from "../../slice";

const { MenuItem, Menu } = Components;

const LanguageSelect = ({ anchorEl, handleClose, open }) => {
    const dispatch = useDispatch();
    const active = open ? "block" : "none";

    const languageChange = (e) => {
        switch (e) {
            case "ml":
                dispatch(commonActions.setLanguage(e));
                break;
            case "en":
                dispatch(commonActions.setLanguage(e));
                break;
            default:
                dispatch(commonActions.setLanguage(e));
                break;
        }
        location.reload();
    };

    const listStyle = {
        mx: 1, pl: 1, pr: 4, borderRadius: "10px", color: "primary.light", fontSize: "14px",
        "&:hover": {
            color: "primary.light"
        }
    };
    return (
        <Menu
            sx={{ display: active, mt: 5, mr: 2 }}
            id="basic-menu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            onClose={handleClose}
            MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
            <MenuItem onClick={() => languageChange("en")} sx={listStyle} > {I18n("english_uk")} </MenuItem>
            {/* <Divider sx={{ color: "primary.light" }} />
            <MenuItem onClick={() => languageChange("en")} sx={listStyle} > {I18n("english_us")} </MenuItem>
            <Divider sx={{ color: "primary.light" }} />
            <MenuItem onClick={() => languageChange("en")} sx={listStyle} > {I18n("arabic")} </MenuItem>
            <Divider sx={{ color: "primary.light" }} />
            <MenuItem onClick={() => languageChange("en")} sx={listStyle} > {I18n("vietnamese")} </MenuItem>
            <Divider sx={{ color: "primary.light" }} />
            <MenuItem onClick={() => languageChange("en")} sx={listStyle} > {I18n("bahasa_indonesia")} </MenuItem> */}
        </Menu >
    );
};

export default LanguageSelect;
