export const API_URL = {
  USER_MANAGEMENT: {
    AUTH_TOKEN: "",
    USER_PROFILE: "admin-service/api/no-auth/v1/user/login",
    LOGOUT: "admin-service/api/auth/v1/crud/model/users/logout",
    FETCH_SEARCH_DATA: "admin-service/api/auth/v1/crud/model/device-group/custom-device-list",
    CHANGE_PASSWORD: "admin-service/api/no-auth/v1/user/update-password",
    SECURITY_QUESTIONS: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?dropDown=true&lookupType=1d6599b6-5474-11ef-bf04-325096b39f47",
    FORGET_PASSWORD: "admin-service/api/no-auth/v1/user/forgot-password",
    UNLOCK_SCREEN: "admin-service/api/no-auth/v1/user/unlock",
    LOCK_SCREEN: "admin-service/api/auth/v1/crud/model/users/lock",
    AUTOMATIC_LOGIN: "admin-service/api/no-auth/v1/user/automatic/login/check",
    SEND_ROUTES: "admin-service/api/auth/v1/crud/model/user/update-route"
  }
};
