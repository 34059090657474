import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            light: "#9DB2BF",
            main: "#151F2D",
            theme: "#fff"
        },
        secondary: {
            light: "#1E2B3D",
            main: "#151F2D",
            primary: "#526D82"
        },
        background: {
            main: "",
            light: "#E4002B"
        },
        cardBackground: {
            main: ""
        },
        purple: {
            main: "#7066e0"
        },
        error: {
            main: "#FC3B3B "
        },
        white: {
            main: "#ffffff"
        },
        shaded: {
            main: "#000"
        },
        dark: {
            main: "#000"
        },
        boneWhite: {
            main: "#F9F6EE"
        },
        fontColor: {
            main: "#9DB2BF",
            light: "#ffffff"
        },
        header: {
            main: "#9DB2BF",
            light: ""
        },
        button: {
            main: "",
            primary: "#1CD6CE"
        },
        sideBar: {
            main: "#526D82",
            dark: "#1E2B3D"
        },
        inputField: {
            main: "#151F2D"
        },
        footer: {
            main: "#080A0E",
            primary: "#9DB2BF"
        },
        label: {
            main: "#9DB2BF"
        },
        tablabel: {
            main: "#526D82"
        }
    }
});

export default darkTheme;
