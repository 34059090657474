export const HTTP_CONSTANTS = {
  HTTP_HEADERS: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
  BASE_URL: "http://103.73.188.232:7080"
};

export const REQUEST_METHOD = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",
  FILE: "FILE",
  POST_FORMDATA: "POST_FORMDATA",
  PATCH_FORMDATA: "PATCH_FORMDATA"
};

export const STORAGE_KEYS = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  SESSION_ID: "SESSION_ID"
};

export const ROWS_PER_PAGE = [10, 15, 20];

export const TABLE_IDS = {
  USERS_LIST: "USERS_LIST",
  RESOURCE_MANAGEMENT_LIST: "RESOURCE_MANAGEMENT_LIST",
  ROLE_MANAGEMENT_LIST: "ROLE_MANAGEMENT_LIST",
  DEVICE_LIST: "DEVICE_LIST",
  REAL_TIME_ALARM_CONTROL_LIST: "REAL TIME ALARM CONTROL LIST",
  HISTORY_ALARM_CONTROL_LIST: "HISTORY ALARM CONTROL LIST",
  ALARM_CONFIGURATION: "ALARM_CONFIGURATION",
  AUTO_SEARCH: "AUTO_SEARCH",
  DECODE_DEVICE_LIST: "DECODE_DEVICE_LIST",
  PLAYBACK_FRONTEND_EXPORTED: "PLAYBACK_FRONTEND_EXPORTED",
  LIVE_VIEW_SAVE_LIST: "LIVE_VIEW_SAVE_LIST",
  OPERATION_LOGS: "OPERATION_LOGS"
};

export const REACT_TABLE_COMMON_OPTIONS = {
  enableRowActions: true,
  enableRowSelection: true,
  getCanMultiSelect: true,
  enableSelectAll: false,
  enablePagination: false,
  enableGlobalFilter: false,
  enableColumnActions: false,
  enableDensityToggle: false,
  enableHiding: false,
  enableColumnFilterModes: false,
  enableStickyHeader: true,
  positionToolbarAlertBanner: "top",
  enableTopToolbar: false
};
export const imageURL = `${process.env.REACT_APP_API_URL}/resource/api/auth/multimedia/download?id=`;
export const BASE64_DATA = "data:image/*;base64,";
