import WarningIcon from "@mui/icons-material/Warning";
import React from "react";
import { useSelector } from "react-redux";

import { Components, I18n } from "../../../../common/components";
import { STATE_REDUCER_KEY } from "../../../common/constants";

const { Container, Box, Typography, Button } = Components;

const WarningBox = ({ handleClose }) => {
    const warnings = useSelector(state => state[STATE_REDUCER_KEY].recordingWarningMessages);

    return (
        <>
            <div style={{ height: "auto", width: "400px", position: "absolute", left: 10, bottom: "4rem", minHeight: "5rem", padding: "30px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", backgroundColor: "#ebd834", zIndex: 300, border: "1px solid #ccc" }}>
                <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "150px", textAlign: "center" }}>
                    <Box >
                        <WarningIcon sx={{ fontSize: 80, color: "orange" }} />
                    </Box>
                    <Typography variant="h4" gutterBottom> Warning </Typography>
                    <Typography variant="body1" sx={{ color: "#000000" }}> {warnings} </Typography>
                    <Button variant="contained" color="primary" onClick={handleClose}> {I18n("cancel")} </Button>
                </Container >
            </div >
        </>
    );
};

export default WarningBox;
