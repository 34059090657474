import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "./style.css";
import { STATE_REDUCER_KEY } from "../../../common/constants";

function ProgressiveBox({ failed, success, handleClose }) {
    const initialCheckedDevices = useSelector(state => state.common.deviceBulkAdd);
    let totalCount = initialCheckedDevices?.length ?? 0;
    const progressCount = useSelector(state => state.common.progressiveCount);
    const progressWidth = totalCount > 0 ? (progressCount / totalCount) * 100 : 0;
    const themes = useSelector(state => state[STATE_REDUCER_KEY].switch.data);

    return (
        <div className="progress-container" style={{
            position: "absolute", right: 10, bottom: "4rem", minHeight: "5rem", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            backgroundColor: themes === "darkTheme" ? "#151F2D" : "#F6F6F6", zIndex: 300
        }}>
            <div>
                {<IconButton sx={{ position: "absolute", right: "0.7rem", top: "-0.4rem" }} onClick={handleClose}>
                    <Close fontSize="large" style={{ color: themes === "darkTheme" ? "#E4002B" : "#000" }} />
                </IconButton>}
                <label htmlFor="">Auto Search Device Adding...</label>
            </div>
            <div className="progress-bar" style={{ marginTop: "1rem" }}>
                <div
                    className="progress-fill"
                    style={{ width: `${progressWidth}%` }}
                >
                </div>
            </div>
            <div>
                <p>{progressCount} out of {totalCount} {progressWidth === 100 ? "Completed" : "Added"}</p>
                <div style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                    <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#dc3545", content: "", border: "3px solid white" }}></div> <p >Failed: {failed}</p>
                    <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#28a745", content: "", border: "3px solid white" }}></div><p>Success:{success}</p></div>
            </div>
        </div>
    );
}

export default ProgressiveBox;
