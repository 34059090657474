export const STATE_REDUCER_KEY = "playback";

export const PLAYBACK = {
    FRONT_END_EXPORTED: "frontEnd",
    LOCALLY_EXPORTED: "locally"
};

export const SPEED = [
    { id: 0.2, name: "0.2x" },
    { id: 0.5, name: "0.5x" },
    { id: 0.7, name: "0.7x" },
    { id: 1, name: "1x" },
    { id: 1.5, name: "1.5x" },
    { id: 2, name: "2x" }
];
