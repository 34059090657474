import { REQUEST_METHOD } from "../../common/constants";
import { API_URL } from "../user-management/apiUrls";
import { ACTION_TYPES } from "./actions";

export const logoutUserApi = (data = {}) => ({
    url: API_URL.USER_MANAGEMENT.LOGOUT,
    method: REQUEST_METHOD.POST,
    payload: {
        types: [ACTION_TYPES.LOG_OUT_REQUEST, ACTION_TYPES.LOG_OUT_SUCCESS, ACTION_TYPES.LOG_OUT_FAILURE],
        data
    }
});

export const fetchSearchDataApi = (data) => ({
    url: API_URL.USER_MANAGEMENT.FETCH_SEARCH_DATA,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.FETCH_SEARCH_DATA_REQUEST, ACTION_TYPES.FETCH_SEARCH_DATA_SUCCESS, ACTION_TYPES.FETCH_SEARCH_DATA_FAILURE],
        data: { searchInput: data }
    }
});

export const screenUnlock = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.UNLOCK_SCREEN,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FETCH_UNLOCK_SCREEN_REQUEST, ACTION_TYPES.FETCH_UNLOCK_SCREEN_SUCCESS, ACTION_TYPES.FETCH_UNLOCK_SCREEN_FAILURE],
            data: { ...data }
        }
    };
};

export const screenLock = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.LOCK_SCREEN,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.FETCH_LOCK_SCREEN_REQUEST, ACTION_TYPES.FETCH_LOCK_SCREEN_SUCCESS, ACTION_TYPES.FETCH_LOCK_SCREEN_FAILURE],
            data: { ...data }
        }
    };
};

export const commonDownloadFile = ({ url, data }) => {
    return {
        url,
        method: REQUEST_METHOD.FILE,
        payload: {
            types: [ACTION_TYPES.COMMON_DOWNLOAD_FILE_REQUEST, ACTION_TYPES.COMMON_DOWNLOAD_FILE_SUCCESS, ACTION_TYPES.COMMON_DOWNLOAD_FILE_FAILURE],
            data
        }
    };
};
