import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getUserManagement = (state) => state[STATE_REDUCER_KEY];

const userLevelDropdown = (state) => state.userLevelDropdown.data;
export const getUserLevelDropdown = flow(getUserManagement, userLevelDropdown);

const userManagementForm = (state) => state.userManagementById.data;
export const getUserManagementForm = flow(getUserManagement, userManagementForm);

const count = (state) => state.count;
export const getCount = flow(getUserManagement, count);

const progress = (state) => state.userManagementById.requestInProgress;
export const getRequestInProgress = flow(getUserManagement, progress);

const activeRoleResourceList = (state) => state.activeRoleResourceList;
export const getActiveRoleResourceList = flow(getUserManagement, activeRoleResourceList);

const dataAccessMappings = (state) => state.dataAccessMappings;
export const getDataAccessMappings = flow(getUserManagement, dataAccessMappings);
