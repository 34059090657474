import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_DEVICE_LIST_DETAILS: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DETAILS`,
    FETCH_DEVICE_LIST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DETAILS_REQUEST`,
    FETCH_DEVICE_LIST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DETAILS_SUCCESS`,
    FETCH_DEVICE_LIST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DEVICE_LIST_DETAILS_FAILURE`,

    SAVE_IMAGE_DETAILS: `${STATE_REDUCER_KEY}/SAVE_IMAGE_DETAILS`,
    SAVE_IMAGE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/SAVE_IMAGE_DETAILS_REQUEST`,
    SAVE_IMAGE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_IMAGE_DETAILS_SUCCESS`,
    SAVE_IMAGE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/SAVE_IMAGE_DETAILS_FAILURE`,

    GET_BY_IMAGE_DETAILS: `${STATE_REDUCER_KEY}/GET_BY_IMAGE_DETAILS`,
    GET_BY_IMAGE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/GET_BY_IMAGE_DETAILS_REQUEST`,
    GET_BY_IMAGE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/GET_BY_IMAGE_DETAILS_SUCCESS`,
    GET_BY_IMAGE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/GET_BY_IMAGE_DETAILS_FAILURE`,

    DELETE_MAP_DETAILS: `${STATE_REDUCER_KEY}/DELETE_MAP_DETAILS`,
    DELETE_MAP_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_MAP_DETAILS_REQUEST`,
    DELETE_MAP_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_MAP_DETAILS_SUCCESS`,
    DELETE_MAP_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_MAP_DETAILS_FAILURE`,

    FETCH_MAP_LIST_DETAILS: `${STATE_REDUCER_KEY}/FETCH_MAP_LIST_DETAILS`,
    FETCH_MAP_LIST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_MAP_LIST_DETAILS_REQUEST`,
    FETCH_MAP_LIST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_MAP_LIST_DETAILS_SUCCESS`,
    FETCH_MAP_LIST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_MAP_LIST_DETAILS_FAILURE`,

    PATCH_DROPPED_DEVICE: `${STATE_REDUCER_KEY}/PATCH_DROPPED_DEVICE`,
    PATCH_DROPPED_DEVICE_REQUEST: `${STATE_REDUCER_KEY}/PATCH_DROPPED_DEVICE_REQUEST`,
    PATCH_DROPPED_DEVICE_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_DROPPED_DEVICES_SUCCESS`,
    PATCH_DROPPED_DEVICE_FAILURE: `${STATE_REDUCER_KEY}/PATCH_DROPPED_DEVICE_FAILURE`,

    PATCH_HOT_ZONE_DETAILS: `${STATE_REDUCER_KEY}/PATCH_HOT_ZONE_DETAILS`,
    PATCH_HOT_ZONE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/PATCH_HOT_ZONE_DETAILS_REQUEST`,
    PATCH_HOT_ZONE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_HOT_ZONE_DETAILS_SUCCESS`,
    PATCH_HOT_ZONE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/PATCH_HOT_ZONE_DETAILS_FAILURE`,

    FETCH_HOT_ZONE_MAP_DETAILS: `${STATE_REDUCER_KEY}/FETCH_HOT_ZONE_MAP_DETAILS`,
    FETCH_HOT_ZONE_MAP_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_HOT_ZONE_MAP_DETAILS_REQUEST`,
    FETCH_HOT_ZONE_MAP_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_HOT_ZONE_MAP_DETAILS_SUCCESS`,
    FETCH_HOT_ZONE_MAP_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_HOT_ZONE_MAP_DETAILS_FAILURE`,

    PATCH_MAP_NAME: `${STATE_REDUCER_KEY}/PATCH_MAP_NAME`,
    PATCH_MAP_NAME_REQUEST: `${STATE_REDUCER_KEY}/PATCH_MAP_NAME_REQUEST`,
    PATCH_MAP_NAME_SUCCESS: `${STATE_REDUCER_KEY}/PATCH_MAP_NAME_SUCCESS`,
    PATCH_MAP_NAME_FAILURE: `${STATE_REDUCER_KEY}/PATCH_MAP_NAME_FAILURE`,

    DELETE_DEVICE_DETAILS: `${STATE_REDUCER_KEY}/DELETE_DEVICE_DETAILS`,
    DELETE_DEVICE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DEVICE_DETAILS_REQUEST`,
    DELETE_DEVICE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DEVICE_DETAILS_SUCCESS`,
    DELETE_DEVICE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DEVICE_DETAILS_FAILURE`,

    GET_MAP_NAME: `${STATE_REDUCER_KEY}/GET_MAP_NAME`,
    GET_MAP_NAME_REQUEST: `${STATE_REDUCER_KEY}/GET_MAP_NAME_REQUEST`,
    GET_MAP_NAME_SUCCESS: `${STATE_REDUCER_KEY}/GET_MAP_NAME_SUCCESS`,
    GET_MAP_NAME_FAILURE: `${STATE_REDUCER_KEY}/GET_MAP_NAME_FAILURE`

};

export const fetchDeviceList = createAction(ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS);
export const saveImageDetails = createAction(ACTION_TYPES.SAVE_IMAGE_DETAILS);
export const getByImageDetails = createAction(ACTION_TYPES.GET_BY_IMAGE_DETAILS);
export const deleteMapDetails = createAction(ACTION_TYPES.DELETE_MAP_DETAILS);
export const fetchEMapDetails = createAction(ACTION_TYPES.FETCH_MAP_LIST_DETAILS);
export const fetchHotZoneMap = createAction(ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS);
export const patchDroppedDevice = createAction(ACTION_TYPES.PATCH_DROPPED_DEVICE);
export const patchHotZone = createAction(ACTION_TYPES.PATCH_HOT_ZONE_DETAILS);
export const patchMapName = createAction(ACTION_TYPES.PATCH_MAP_NAME);
export const getMapName = createAction(ACTION_TYPES.GET_MAP_NAME);
export const deleteDevice = createAction(ACTION_TYPES.DELETE_DEVICE_DETAILS);
