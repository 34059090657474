import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listOperationLogs = (data) => ({
    url: API_URL.OPERATION_LOGS.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_OPERATION_LOGS_REQUEST, ACTION_TYPES.LIST_OPERATION_LOGS_SUCCESS, ACTION_TYPES.LIST_OPERATION_LOGS_FAILURE],
        data
    }
});

export const selectUsername = () => {
    return {
        url: API_URL.OPERATION_LOGS.SELECT_USERNAME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_USERNAME_REQUEST, ACTION_TYPES.SELECT_USERNAME_SUCCESS, ACTION_TYPES.SELECT_USERNAME_FAILURE],
            data: { dropDown: true, pageSize: 200 }
        }
    };
};

export const selectLogType = () => {
    return {
        url: API_URL.OPERATION_LOGS.SELECT_LOG_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_LOG_TYPE_REQUEST, ACTION_TYPES.SELECT_LOG_TYPE_SUCCESS, ACTION_TYPES.SELECT_LOG_TYPE_FAILURE],
            data: { dropDown: true, pageSize: 200 }
        }
    };
};

export const selectDetailedType = (lookupType) => {
    return {
        url: API_URL.OPERATION_LOGS.SELECT_DETAILED_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_DETAILED_TYPE_REQUEST, ACTION_TYPES.SELECT_DETAILED_TYPE_SUCCESS, ACTION_TYPES.SELECT_DETAILED_TYPE_FAILURE],
            data: { dropDown: true, lookupType, pageSize: 200 }
        }
    };
};

export const filterDescription = (data) => {
    return {
        url: API_URL.OPERATION_LOGS.FILTER_DESCRIPTION,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.FILTER_DESCRIPTION_REQUEST, ACTION_TYPES.FILTER_DESCRIPTION_SUCCESS, ACTION_TYPES.FILTER_DESCRIPTION_FAILURE],
            data: data
        }
    };
};
