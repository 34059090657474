import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";

import { TABLE_IDS } from "../../../common/constants";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { getTableProps } from "../../common/selectors";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { successNotify } from "../../../utils/notificationUtils";

export function* listResourceManagement({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.ROLE_MANAGEMENT_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listResourceDetails, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_ROLE_DATA_SUCCESS, ACTION_TYPES.LIST_ROLE_DATA_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.ROLE_MANAGEMENT_LIST);
}

export function* getResource({ payload }) {
    yield call(handleAPIRequest, Api.fetchResourcesData, payload);
}

export function* checkedResource({ payload }) {
    yield call(handleAPIRequest, Api.checkedResourcesData, payload);
}

export function* postResourcePermission({ payload }) {
    const { roleResourceMappings } = payload;
    yield fork(handleAPIRequest, Api.saveResourcesData, payload);
    const response = yield take([ACTION_TYPES.CREATE_RESOURCE_DATA_SUCCESS, ACTION_TYPES.CREATE_RESOURCE_DATA_FAILURE]);
    if (response.type === ACTION_TYPES.CREATE_RESOURCE_DATA_SUCCESS) {
        yield put(successNotify({ title: "Resources", message: "Added successfully" }));
        yield call(checkedResource, { payload: { roleId: roleResourceMappings[0]?.roleId?.id } });
    }
}

export function* updateResourcePermission({ payload }) {
    const { roleResourceMappings } = payload;
    yield fork(handleAPIRequest, Api.updateResourcesData, payload);
    const response = yield take([ACTION_TYPES.UPDATE_RESOURCE_DATA_SUCCESS, ACTION_TYPES.UPDATE_RESOURCE_DATA_FAILURE]);
    if (response.type === ACTION_TYPES.UPDATE_RESOURCE_DATA_SUCCESS) {
        yield put(successNotify({ title: "Resources", message: "Updated successfully" }));
        yield call(checkedResource, { payload: { roleId: roleResourceMappings[0]?.roleId?.id } });
    }
}

export default function* roleManagementSaga() {
    yield all([
        takeLatest(ACTION_TYPES.LIST_ROLE_DATA, listResourceManagement),
        takeLatest(ACTION_TYPES.FETCH_RESOURCE_DATA, getResource),
        takeLatest(ACTION_TYPES.CHECKED_RESOURCE_DATA, checkedResource),
        takeLatest(ACTION_TYPES.CREATE_RESOURCE_DATA, postResourcePermission),
        takeLatest(ACTION_TYPES.UPDATE_RESOURCE_DATA, updateResourcePermission)
    ]);
}
