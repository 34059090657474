import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { ACTION_TYPES as COMMON_ACTION } from "../../common/actions";

const initialState = {
    liveViewById: {
        requestInProgress: false,
        data: {}
    },
    groupDeviceList: {
        requestInProgress: false,
        data: []
    },
    startRecording: {
        requestInProgress: false,
        data: []
    },
    count: 0,
    saveViewList: {
        requestInProgress: false,
        data: []
    },
    viewListById: {
        requestInProgress: false,
        data: {}
    },
    viewList: {
        requestInProgress: false,
        data: []
    },
    viewById: {
        requestInProgress: false,
        data: {
            timeInterval: "",
            name: ""
        }
    },
    streamError: {}
};
const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.liveViewById = initialState.liveViewById;
        },
        updateConnectionStatus: (state, { payload }) => {
            let tempValues = state.groupDeviceList.data.map(gData => {
                if (gData.isGroup) {
                    if (!_.isEmpty(gData?.groupinfo)) {
                        return {
                            ...gData,
                            groupinfo: {
                                ...gData.groupinfo,
                                devices: gData.groupinfo.devices.map(dData => {
                                    if (dData.id === payload?.payload?.deviceId) {
                                        return { ...dData, connectionStatus: payload?.payload?.connectionStatus };
                                    }
                                    return dData;
                                })
                            }
                        };
                    }
                } else {
                    if (!_.isEmpty(gData?.deviceinfo)) {
                        return {
                            ...gData,
                            deviceinfo: gData.deviceinfo.map(dData => {
                                if (dData.id === payload?.payload?.deviceId) {
                                    return { ...dData, connectionStatus: payload?.payload?.connectionStatus };
                                }
                                return dData;
                            })
                        };
                    }
                }
                return gData;
            });
            state.groupDeviceList.data = tempValues;
        },
        updateLiveViewUrl: (state, { payload }) => {
            state.liveViewById.data = payload;
        },
        setCount: (state) => {
            state.count = state.count + 1;
        },
        decrementCount: (state) => {
            state.count = state.count - 1;
        },
        setRowSelectionData: (state, action) => {
            state.saveViewList.data = action.payload;
        },
        setStreamError: (state, { payload }) => {
            state.streamError = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.LIVE_VIEW_BY_ID_REQUEST, (state) => {
                state.liveViewById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.LIVE_VIEW_BY_ID_SUCCESS, (state) => {
                _.set(state, "liveViewById.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.LIVE_VIEW_BY_ID_FAILURE, (state) => {
                state.liveViewById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GROUP_DEVICE_LIST_REQUEST, (state) => {
                state.groupDeviceList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GROUP_DEVICE_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "groupDeviceList.requestInProgress", false);
                _.set(state, "groupDeviceList.data", payload);
            })
            .addCase(ACTION_TYPES.GROUP_DEVICE_LIST_FAILURE, (state) => {
                state.groupDeviceList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.START_RECORDING_SUCCESS, (state, { payload }) => {
                _.set(state, "startRecording.requestInProgress", true);
                _.set(state, "startRecording.data", [...state.startRecording.data, ...payload]);
            })
            .addCase(ACTION_TYPES.STOP_RECORDING_SUCCESS, (state) => {
                _.set(state, "startRecording.requestInProgress", false);
            })
            .addCase(COMMON_ACTION.FETCH_SEARCH_DATA_REQUEST, (state) => {
                _.set(state, "groupDeviceList.requestInProgress", true);
            })
            .addCase(COMMON_ACTION.FETCH_SEARCH_DATA_SUCCESS, (state, { payload }) => {
                _.set(state, "groupDeviceList.data", payload);
                _.set(state, "groupDeviceList.requestInProgress", false);
            })
            .addCase(COMMON_ACTION.FETCH_SEARCH_DATA_FAILURE, (state) => {
                _.set(state, "groupDeviceList.requestInProgress", false);
            })
            .addCase(ACTION_TYPES.EDIT_VIEW_BY_ID_REQUEST, (state) => {
                state.viewListById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.EDIT_VIEW_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "viewListById.requestInProgress", false);
                _.set(state, "viewListById.data", payload);
            })
            .addCase(ACTION_TYPES.EDIT_VIEW_BY_ID_FAILURE, (state) => {
                state.viewListById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.VIEW_LIST_REQUEST, (state) => {
                state.viewList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.VIEW_LIST_SUCCESS, (state, { payload }) => {
                _.set(state, "viewList.requestInProgress", false);
                _.set(state, "viewList.data", payload);
            })
            .addCase(ACTION_TYPES.VIEW_LIST_FAILURE, (state) => {
                state.viewList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.VIEW_LIST_BY_ID_REQUEST, (state) => {
                state.viewById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.VIEW_LIST_BY_ID_SUCCESS, (state, { payload }) => {
                _.set(state, "viewById.requestInProgress", false);
                _.set(state, "viewById.data", payload);
            })
            .addCase(ACTION_TYPES.VIEW_LIST_BY_ID_FAILURE, (state) => {
                state.viewById.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;
