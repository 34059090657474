import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "../../../utils/http";
import { ACTION_TYPES, authenticateUserDetails } from "./actions";
import * as API from "./api";
import { STORAGE_KEYS } from "../../../common/constants";
import { I18n } from "../../../common/components";
import { successNotify } from "../../../utils/notificationUtils";
import { actions as sliceActions } from "../../user-management/register/slice";
import { getUserId } from "./selectors";
import { getUserDetails } from "../../../utils/userUtils";
import { getNavigator } from "../../user/selectors";
import { navigateTo } from "../../common/actions";
import { getAlarmConfigurationSwitch, getElectronicMapSwitch, getOperationLogsSwitch, getPlayBack, getResourceManagementSwitch, getRoleManagementSwitch, getSystemSwitch, getTvWallSwitch, getUserManagementSwitch } from "../../home/homeModule/selector";
import { actions as homeAction } from "../../home/homeModule/slice";

export function* authenticateUserDetail(action) {
    let { loginId, loginPassword, newPassword } = action.payload;
    yield fork(handleAPIRequest, API.authenticateUser, { loginId: loginId, loginPassword });
    const navigate = yield select(getNavigator);
    const responseAction = yield take([ACTION_TYPES.AUTHENTICATE_USER_SUCCESS, ACTION_TYPES.AUTHENTICATE_USER_FAILURE]);
    if (responseAction.type === ACTION_TYPES.AUTHENTICATE_USER_SUCCESS) {
        yield put(sliceActions.setLoginId(loginId));
        const { payload: { authToken, sessionId = "", automaticLogin, instantPlaybackTime = "" } = {} } = responseAction;
        yield put(sliceActions.setInstancePlayBackTime(instantPlaybackTime));
        localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, authToken);
        localStorage.setItem(STORAGE_KEYS.SESSION_ID, sessionId);
        yield put(sliceActions.setSocketOpen(true));
        yield put(sliceActions.setAutomaticLogin(automaticLogin));
        if (newPassword === true) {
            // yield put(navigateTo("/home"));
            yield navigate("/home");
        }
        // localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
    }
}

export function* changePassword({ payload }) {
    const { oldPassword, newPassword, confirmPassword, loginId } = payload;
    let userId = yield select(getUserId);
    const securityQuestionAnswers = [
        { questionId: payload?.questionOne?.id, questionName: payload?.questionOne?.name, answer: payload?.answerOne },
        { questionId: payload?.questionTwo?.id, questionName: payload?.questionTwo?.name, answer: payload?.answerTwo },
        { questionId: payload?.questionThree?.id, questionName: payload?.questionThree?.name, answer: payload?.answerThree }
    ];
    const changePasswordPayload = { oldPassword, newPassword, confirmPassword, loginId, securityQuestionAnswers };
    const updatedPayload = { loginId: userId, loginPassword: payload.newPassword, newPassword: true };
    yield fork(handleAPIRequest, API.changePassword, changePasswordPayload);
    const responseAction = yield take([ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, ACTION_TYPES.CHANGE_PASSWORD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.CHANGE_PASSWORD_SUCCESS) {
        yield put(authenticateUserDetails(updatedPayload));
        yield put(successNotify({ title: I18n("success"), message: I18n("users", { type: I18n("user") }) }));
    }
}

export function* updatePassword({ payload }) {
    const data = getUserDetails();
    const securityQuestionAnswers = [
        { questionId: payload?.data?.questionOne?.id, questionName: payload?.data?.questionOne?.name, answer: payload?.data?.answerOne },
        { questionId: payload?.data?.questionTwo?.id, questionName: payload?.data?.questionTwo?.name, answer: payload?.data?.answerTwo },
        { questionId: payload?.data?.questionThree?.id, questionName: payload?.data?.questionThree?.name, answer: payload?.data?.answerThree }

    ];
    let loginId = data.userDto.loginId;
    let updatedPayload = { loginId: loginId, oldPassword: payload.data.oldPassword, newPassword: payload.data.newPassword, confirmPassword: payload.data.confirmPassword, securityQuestionAnswers: securityQuestionAnswers };
    const { setOpenStatus } = payload;
    yield fork(handleAPIRequest, API.updatePassword, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.UPDATE_PASSWORD_SUCCESS, ACTION_TYPES.UPDATE_PASSWORD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_PASSWORD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("password") }) }));
        if (setOpenStatus !== undefined) {
            setOpenStatus(false);
        }
        setOpenStatus(false);
    }
}

export function* forgetPassword({ payload }) {
    const { newPassword, confirmPassword, loginId } = payload;
    const securityQuestionAnswers = [
        { questionId: payload?.questionOne?.id, questionName: payload?.questionOne?.name, answer: payload?.answerOne },
        { questionId: payload?.questionTwo?.id, questionName: payload?.questionTwo?.name, answer: payload?.answerTwo },
        { questionId: payload?.questionThree?.id, questionName: payload?.questionThree?.name, answer: payload?.answerThree }

    ];
    const forgetPasswordPayload = { newPassword, confirmPassword, loginId, securityQuestionAnswers };
    yield fork(handleAPIRequest, API.forgetPassword, forgetPasswordPayload);
    const navigate = yield select(getNavigator);
    const responseAction = yield take([ACTION_TYPES.FORGET_PASSWORD_SUCCESS, ACTION_TYPES.FORGET_PASSWORD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.FORGET_PASSWORD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("password_changed") }));
        yield navigate("/login");
    }
}

export function* getUserDetail(action) {
    let payload = action.payload;
    yield call(handleAPIRequest, API.getUserProfile, payload);
}

export function* getSecurityQuestion() {
    yield call(handleAPIRequest, API.getSecurityQuestionsApi);
}

export function* automaticLoginSaga({ payload }) {
    yield fork(handleAPIRequest, API.automaticLoginApi, payload);
    const responseAction = yield take([ACTION_TYPES.AUTOMATIC_LOGIN_SUCCESS, ACTION_TYPES.AUTOMATIC_LOGIN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.AUTOMATIC_LOGIN_SUCCESS) {
        const { payload: { authToken, sessionId = "", route = "", userId, restoreStatus } = {} } = responseAction;
        localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, authToken);
        localStorage.setItem(STORAGE_KEYS.SESSION_ID, sessionId);
        yield put(sliceActions.setLoginId(userId));
        if (restoreStatus) {
            if (route === "/live") {
                yield put(navigateTo("/live"));
            } else if (route === "/home") {
                yield put(navigateTo("/home"));
            } else if (route === "/device") {
                yield put(navigateTo("/device"));
            } else if (route === "systemSwitch") {
                const systemSwitch = yield select(getSystemSwitch);
                yield put(homeAction.setSystemSwitch(!systemSwitch));
            } else if (route === "userManagementSwitch") {
                const userManagementSwitch = yield select(getUserManagementSwitch);
                yield put(homeAction.setUserManagementSwitch(!userManagementSwitch));
            } else if (route === "resourceManagementSwitch") {
                const resourceManagementSwitch = yield select(getResourceManagementSwitch);
                yield put(homeAction.setResourceManagementSwitch(!resourceManagementSwitch));
            } else if (route === "roleManagementSwitch") {
                const roleManagementSwitch = yield select(getRoleManagementSwitch);
                yield put(homeAction.setRoleManagementSwitch(!roleManagementSwitch));
            } else if (route === "alarmConfigurationSwitch") {
                const alarmConfigurationSwitch = yield select(getAlarmConfigurationSwitch);
                yield put(homeAction.setAlarmConfigurationSwitch(!alarmConfigurationSwitch));
            } else if (route === "tvWallSwitch") {
                const tvWallSwitch = yield select(getTvWallSwitch);
                yield put(homeAction.setTVWallSwitch(!tvWallSwitch));
            } else if (route === "playBack") {
                const playBack = yield select(getPlayBack);
                yield put(homeAction.setPlayBack(!playBack));
            } else if (route === "operationLogsSwitch") {
                const operationLogsSwitch = yield select(getOperationLogsSwitch);
                yield put(homeAction.setOperationLogsSwitch(!operationLogsSwitch));
            } else if (route === "electronicMapSwitch") {
                const electronicMapSwitch = yield select(getElectronicMapSwitch);
                yield put(homeAction.setElectronicMapSwitch(!electronicMapSwitch));
            }
        } else {
            yield put(navigateTo("/home"));
        }
    }
}

export function* sendRoutes({ payload }) {
    yield call(handleAPIRequest, API.sendRoutesApi, payload);
}

export default function* userManagementSaga() {
    yield all([
        takeLatest(ACTION_TYPES.AUTHENTICATE_USER, authenticateUserDetail),
        takeLatest(ACTION_TYPES.FETCH_USER_PROFILE, getUserDetail),
        takeLatest(ACTION_TYPES.CHANGE_PASSWORD, changePassword),
        takeLatest(ACTION_TYPES.UPDATE_PASSWORD, updatePassword),
        takeLatest(ACTION_TYPES.SECURITY_QUESTIONS, getSecurityQuestion),
        takeLatest(ACTION_TYPES.FORGET_PASSWORD, forgetPassword),
        takeLatest(ACTION_TYPES.AUTOMATIC_LOGIN, automaticLoginSaga),
        takeLatest(ACTION_TYPES.SEND_ROUTES, sendRoutes)
    ]);
}
