import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    POST_COMMON_SETTINGS: `${STATE_REDUCER_KEY}/POST_COMMON_SETTINGS`,
    POST_COMMON_SETTINGS_REQUEST: `${STATE_REDUCER_KEY}/POST_COMMON_SETTINGS_REQUEST`,
    POST_COMMON_SETTINGS_SUCCESS: `${STATE_REDUCER_KEY}/POST_COMMON_SETTINGS_SUCCESS`,
    POST_COMMON_SETTINGS_FAILURE: `${STATE_REDUCER_KEY}/POST_COMMON_SETTINGS_FAILURE`,

    UPDATE_COMMON_SETTINGS: `${STATE_REDUCER_KEY}/UPDATE_COMMON_SETTINGS`,
    UPDATE_COMMON_SETTINGS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_COMMON_SETTINGS_REQUEST`,
    UPDATE_COMMON_SETTINGS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_COMMON_SETTINGS_SUCCESS`,
    UPDATE_COMMON_SETTINGS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_COMMON_SETTINGS_FAILURE`,

    COMMON_SETTINGS_BY_ID: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_BY_ID`,
    COMMON_SETTINGS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_BY_ID_REQUEST`,
    COMMON_SETTINGS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_BY_ID_SUCCESS`,
    COMMON_SETTINGS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_BY_ID_FAILURE`,

    COMMON_SETTINGS_LIST_ALL: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_LIST_ALL`,
    COMMON_SETTINGS_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_LIST_ALL_REQUEST`,
    COMMON_SETTINGS_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_LIST_ALL_SUCCESS`,
    COMMON_SETTINGS_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/COMMON_SETTINGS_LIST_ALL_FAILURE`,

    SELECT_SKIN: `${STATE_REDUCER_KEY}/SELECT_SKIN`,
    SELECT_SKIN_REQUEST: `${STATE_REDUCER_KEY}/SELECT_SKIN_REQUEST`,
    SELECT_SKIN_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_SKIN_SUCCESS`,
    SELECT_SKIN_FAILURE: `${STATE_REDUCER_KEY}/SELECT_SKIN_FAILURE`,

    SELECT_LANGUAGE: `${STATE_REDUCER_KEY}/SELECT_LANGUAGE`,
    SELECT_LANGUAGE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LANGUAGE_REQUEST`,
    SELECT_LANGUAGE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LANGUAGE_SUCCESS`,
    SELECT_LANGUAGE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LANGUAGE_FAILURE`,

    SELECT_LOG: `${STATE_REDUCER_KEY}/SELECT_LOG`,
    SELECT_LOG_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LOG_REQUEST`,
    SELECT_LOG_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LOG_SUCCESS`,
    SELECT_LOG_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LOG_FAILURE`,

    POST_VIDEO_SETTINGS: `${STATE_REDUCER_KEY}/POST_VIDEO_SETTINGS`,
    POST_VIDEO_SETTINGS_REQUEST: `${STATE_REDUCER_KEY}/POST_VIDEO_SETTINGS_REQUEST`,
    POST_VIDEO_SETTINGS_SUCCESS: `${STATE_REDUCER_KEY}/POST_VIDEO_SETTINGS_SUCCESS`,
    POST_VIDEO_SETTINGS_FAILURE: `${STATE_REDUCER_KEY}/POST_VIDEO_SETTINGS_FAILURE`,

    UPDATE_VIDEO_SETTINGS: `${STATE_REDUCER_KEY}/UPDATE_VIDEO_SETTINGS`,
    UPDATE_VIDEO_SETTINGS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_VIDEO_SETTINGS_REQUEST`,
    UPDATE_VIDEO_SETTINGS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_VIDEO_SETTINGS_SUCCESS`,
    UPDATE_VIDEO_SETTINGS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_VIDEO_SETTINGS_FAILURE`,

    VIDEO_SETTINGS_BY_ID: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_BY_ID`,
    VIDEO_SETTINGS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_BY_ID_REQUEST`,
    VIDEO_SETTINGS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_BY_ID_SUCCESS`,
    VIDEO_SETTINGS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_BY_ID_FAILURE`,

    VIDEO_SETTINGS_LIST_ALL: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_LIST_ALL`,
    VIDEO_SETTINGS_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_LIST_ALL_REQUEST`,
    VIDEO_SETTINGS_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_LIST_ALL_SUCCESS`,
    VIDEO_SETTINGS_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/VIDEO_SETTINGS_LIST_ALL_FAILURE`,

    SELECT_PLAY_PROPERTY: `${STATE_REDUCER_KEY}/SELECT_PLAY_PROPERTY`,
    SELECT_PLAY_PROPERTY_REQUEST: `${STATE_REDUCER_KEY}/SELECT_PLAY_PROPERTY_REQUEST`,
    SELECT_PLAY_PROPERTY_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_PLAY_PROPERTY_SUCCESS`,
    SELECT_PLAY_PROPERTY_FAILURE: `${STATE_REDUCER_KEY}/SELECT_PLAY_PROPERTY_FAILURE`,

    SELECT_STREAM_TYPE: `${STATE_REDUCER_KEY}/SELECT_STREAM_TYPE`,
    SELECT_STREAM_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_STREAM_TYPE_REQUEST`,
    SELECT_STREAM_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_STREAM_TYPE_SUCCESS`,
    SELECT_STREAM_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_STREAM_TYPE_FAILURE`,

    SELECT_DECODING_SETTING: `${STATE_REDUCER_KEY}/SELECT_DECODING_SETTING`,
    SELECT_DECODING_SETTING_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DECODING_SETTING_REQUEST`,
    SELECT_DECODING_SETTING_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DECODING_SETTING_SUCCESS`,
    SELECT_DECODING_SETTING_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DECODING_SETTING_FAILURE`,

    SELECT_FRAME_RATE: `${STATE_REDUCER_KEY}/SELECT_FRAME_RATE`,
    SELECT_FRAME_RATE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_FRAME_RATE_REQUEST`,
    SELECT_FRAME_RATE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_FRAME_RATE_SUCCESS`,
    SELECT_FRAME_RATE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_FRAME_RATE_FAILURE`,

    SELECT_VIDEO_DOWNLOAD_FORMAT: `${STATE_REDUCER_KEY}/SELECT_VIDEO_DOWNLOAD_FORMAT`,
    SELECT_VIDEO_DOWNLOAD_FORMAT_REQUEST: `${STATE_REDUCER_KEY}/SELECT_VIDEO_DOWNLOAD_FORMAT_REQUEST`,
    SELECT_VIDEO_DOWNLOAD_FORMAT_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_VIDEO_DOWNLOAD_FORMAT_SUCCESS`,
    SELECT_VIDEO_DOWNLOAD_FORMAT_FAILURE: `${STATE_REDUCER_KEY}/SELECT_VIDEO_DOWNLOAD_FORMAT_FAILURE`,

    SELECT_PLAY_BACK_TIME: `${STATE_REDUCER_KEY}/SELECT_PLAY_BACK_TIME`,
    SELECT_PLAY_BACK_TIME_REQUEST: `${STATE_REDUCER_KEY}/SELECT_PLAY_BACK_TIME_REQUEST`,
    SELECT_PLAY_BACK_TIME_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_PLAY_BACK_TIME_SUCCESS`,
    SELECT_PLAY_BACK_TIME_FAILURE: `${STATE_REDUCER_KEY}/SELECT_PLAY_BACK_TIME_FAILURE`,

    SELECT_IS_AUTOMATIC: `${STATE_REDUCER_KEY}/SELECT_IS_AUTOMATIC`,
    SELECT_IS_AUTOMATIC_REQUEST: `${STATE_REDUCER_KEY}/SELECT_IS_AUTOMATIC_REQUEST`,
    SELECT_IS_AUTOMATIC_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_IS_AUTOMATIC_SUCCESS`,
    SELECT_IS_AUTOMATIC_FAILURE: `${STATE_REDUCER_KEY}/SELECT_IS_AUTOMATIC_FAILURE`,

    ALARM_GRADE_LIST_ALL: `${STATE_REDUCER_KEY}/ALARM_GRADE_LIST_ALL`,
    ALARM_GRADE_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/ALARM_GRADE_LIST_ALL_REQUEST`,
    ALARM_GRADE_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/ALARM_GRADE_LIST_ALL_SUCCESS`,
    ALARM_GRADE_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/ALARM_GRADE_LIST_ALL_FAILURE`,

    UPDATE_ALARM_GRADE: `${STATE_REDUCER_KEY}/UPDATE_ALARM_GRADE`,
    UPDATE_ALARM_GRADE_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_ALARM_GRADE_REQUEST`,
    UPDATE_ALARM_GRADE_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_ALARM_GRADE_SUCCESS`,
    UPDATE_ALARM_GRADE_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_ALARM_GRADE_FAILURE`,

    SELECT_DISK_SPACE: `${STATE_REDUCER_KEY}/SELECT_DISK_SPACE`,
    SELECT_DISK_SPACE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DISK_SPACE_REQUEST`,
    SELECT_DISK_SPACE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DISK_SPACE_SUCCESS`,
    SELECT_DISK_SPACE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DISK_SPACE_FAILURE`,

    SELECT_RECORDING_FORMAT: `${STATE_REDUCER_KEY}/SELECT_RECORDING_FORMAT`,
    SELECT_RECORDING_FORMAT_REQUEST: `${STATE_REDUCER_KEY}/SELECT_RECORDING_FORMAT_REQUEST`,
    SELECT_RECORDING_FORMAT_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_RECORDING_FORMAT_SUCCESS`,
    SELECT_RECORDING_FORMAT_FAILURE: `${STATE_REDUCER_KEY}/SELECT_RECORDING_FORMAT_FAILURE`,

    SELECT_RECORDING_TYPE: `${STATE_REDUCER_KEY}/SELECT_RECORDING_TYPE`,
    SELECT_RECORDING_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_RECORDING_TYPE_REQUEST`,
    SELECT_RECORDING_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_RECORDING_TYPE_SUCCESS`,
    SELECT_RECORDING_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_RECORDING_TYPE_FAILURE`,

    SELECT_DISK_FULL: `${STATE_REDUCER_KEY}/SELECT_DISK_FULL`,
    SELECT_DISK_FULL_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DISK_FULL_REQUEST`,
    SELECT_DISK_FULL_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DISK_FULL_SUCCESS`,
    SELECT_DISK_FULL_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DISK_FULL_FAILURE`,

    STORAGE_MANAGEMENT_BY_ID: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_BY_ID`,
    STORAGE_MANAGEMENT_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_BY_ID_REQUEST`,
    STORAGE_MANAGEMENT_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_BY_ID_SUCCESS`,
    STORAGE_MANAGEMENT_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_BY_ID_FAILURE`,

    POST_STORAGE_MANAGEMENT: `${STATE_REDUCER_KEY}/POST_STORAGE_MANAGEMENT`,
    POST_STORAGE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/POST_STORAGE_MANAGEMENT_REQUEST`,
    POST_STORAGE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/POST_STORAGE_MANAGEMENT_SUCCESS`,
    POST_STORAGE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/POST_STORAGE_MANAGEMENT_FAILURE`,

    UPDATE_STORAGE_MANAGEMENT: `${STATE_REDUCER_KEY}/UPDATE_STORAGE_MANAGEMENT`,
    UPDATE_STORAGE_MANAGEMENT_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_STORAGE_MANAGEMENT_REQUEST`,
    UPDATE_STORAGE_MANAGEMENT_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_STORAGE_MANAGEMENT_SUCCESS`,
    UPDATE_STORAGE_MANAGEMENT_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_STORAGE_MANAGEMENT_FAILURE`,

    STORAGE_MANAGEMENT_LIST_ALL: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_LIST_ALL`,
    STORAGE_MANAGEMENT_LIST_ALL_REQUEST: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_LIST_ALL_REQUEST`,
    STORAGE_MANAGEMENT_LIST_ALL_SUCCESS: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_LIST_ALL_SUCCESS`,
    STORAGE_MANAGEMENT_LIST_ALL_FAILURE: `${STATE_REDUCER_KEY}/STORAGE_MANAGEMENT_LIST_ALL_FAILURE`,

    GET_DRIVE_LIST: `${STATE_REDUCER_KEY}/GET_DRIVE_LIST`,
    GET_DRIVE_LIST_REQUEST: `${STATE_REDUCER_KEY}/GET_DRIVE_LIST_REQUEST`,
    GET_DRIVE_LIST_SUCCESS: `${STATE_REDUCER_KEY}/GET_DRIVE_LIST_SUCCESS`,
    GET_DRIVE_LIST_FAILURE: `${STATE_REDUCER_KEY}/GET_DRIVE_LIST_FAILURE`,

    GET_TIME_TEMPLATE: `${STATE_REDUCER_KEY}/GET_TIME_TEMPLATE`,
    GET_TIME_TEMPLATE_REQUEST: `${STATE_REDUCER_KEY}/GET_TIME_TEMPLATE_REQUEST`,
    GET_TIME_TEMPLATE_SUCCESS: `${STATE_REDUCER_KEY}/GET_TIME_TEMPLATE_SUCCESS`,
    GET_TIME_TEMPLATE_FAILURE: `${STATE_REDUCER_KEY}/GET_TIME_TEMPLATE_FAILURE`,

    GET_CODE_STREAM: `${STATE_REDUCER_KEY}/GET_CODE_STREAM`,
    GET_CODE_STREAM_REQUEST: `${STATE_REDUCER_KEY}/GET_CODE_STREAM_REQUEST`,
    GET_CODE_STREAM_SUCCESS: `${STATE_REDUCER_KEY}/GET_CODE_STREAM_SUCCESS`,
    GET_CODE_STREAM_FAILURE: `${STATE_REDUCER_KEY}/GET_CODE_STREAM_FAILURE`,

    SAVE_RECORD_PLAN: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN`,
    SAVE_RECORD_PLAN_REQUEST: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN_REQUEST`,
    SAVE_RECORD_PLAN_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN_SUCCESS`,
    SAVE_RECORD_PLAN_FAILURE: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN_FAILURE`,

    SAVE_COPY_TO: `${STATE_REDUCER_KEY}/SAVE_COPY_TO`,
    SAVE_COPY_TO_REQUEST: `${STATE_REDUCER_KEY}/SAVE_COPY_TO_REQUEST`,
    SAVE_COPY_TO_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_COPY_TO_SUCCESS`,
    SAVE_COPY_TO_FAILURE: `${STATE_REDUCER_KEY}/SAVE_COPY_TO_FAILURE`,

    GET_RECORD_PLAN_SETTING_BY_ID: `${STATE_REDUCER_KEY}/GET_RECORD_PLAN_SETTING_BY_ID`,
    GET_RECORD_PLAN_SETTING_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/GET_RECORD_PLAN_SETTING_BY_ID_REQUEST`,
    GET_RECORD_PLAN_SETTING_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/GET_RECORD_PLAN_SETTING_BY_ID_SUCCESS`,
    GET_RECORD_PLAN_SETTING_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/GET_RECORD_PLAN_SETTING_BY_ID_FAILURE`,

    LIST_CUSTOMIZED_DEVICES: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED_DEVICES`,
    LIST_CUSTOMIZED_DEVICES_REQUEST: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED_DEVICES_REQUEST`,
    LIST_CUSTOMIZED_DEVICES_SUCCESS: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED_DEVICES_SUCCESS`,
    LIST_CUSTOMIZED_DEVICES_FAILURE: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED_DEVICES_FAILURE`,

    SAVE_RECORD_PLAN_FULL_CYCLE: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN_FULL_CYCLE`,
    SAVE_RECORD_PLAN_FULL_CYCLE_REQUEST: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN_FULL_CYCLE_REQUEST`,
    SAVE_RECORD_PLAN_FULL_CYCLE_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN_FULL_CYCLE_SUCCESS`,
    SAVE_RECORD_PLAN_FULL_CYCLE_FAILURE: `${STATE_REDUCER_KEY}/SAVE_RECORD_PLAN_FULL_CYCLE_FAILURE`,

    SAVE_ENABLE_LOCAL_DECODING: `${STATE_REDUCER_KEY}/SAVE_ENABLE_LOCAL_DECODING`,
    SAVE_ENABLE_LOCAL_DECODING_REQUEST: `${STATE_REDUCER_KEY}/SAVE_ENABLE_LOCAL_DECODING_REQUEST`,
    SAVE_ENABLE_LOCAL_DECODING_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_ENABLE_LOCAL_DECODING_SUCCESS`,
    SAVE_ENABLE_LOCAL_DECODING_FAILURE: `${STATE_REDUCER_KEY}/SAVE_ENABLE_LOCAL_DECODING_FAILURE`,

    TIME_PROGRESS_BY_ID: `${STATE_REDUCER_KEY}/TIME_PROGRESS_BY_ID`,
    TIME_PROGRESS_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/TIME_PROGRESS_BY_ID_REQUEST`,
    TIME_PROGRESS_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/TIME_PROGRESS_BY_ID_SUCCESS`,
    TIME_PROGRESS_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/TIME_PROGRESS_BY_ID_FAILURE`,

    SAVE_OUTPUT: `${STATE_REDUCER_KEY}/SAVE_OUTPUT`,
    SAVE_OUTPUT_REQUEST: `${STATE_REDUCER_KEY}/SAVE_OUTPUT_REQUEST`,
    SAVE_OUTPUT_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_OUTPUT_SUCCESS`,
    SAVE_OUTPUT_FAILURE: `${STATE_REDUCER_KEY}/SAVE_OUTPUT_FAILURE`,

    SAVE_BACKUP: `${STATE_REDUCER_KEY}/SAVE_BACKUP`,
    SAVE_BACKUP_REQUEST: `${STATE_REDUCER_KEY}/SAVE_BACKUP_REQUEST`,
    SAVE_BACKUP_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_BACKUP_SUCCESS`,
    SAVE_BACKUP_FAILURE: `${STATE_REDUCER_KEY}/SAVE_BACKUP_FAILURE`,

    SAVE_RECOVER: `${STATE_REDUCER_KEY}/SAVE_RECOVER`,
    SAVE_RECOVER_REQUEST: `${STATE_REDUCER_KEY}/SAVE_RECOVER_REQUEST`,
    SAVE_RECOVER_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_RECOVER_SUCCESS`,
    SAVE_RECOVER_FAILURE: `${STATE_REDUCER_KEY}/SAVE_RECOVER_FAILURE`,

    SAVE_IMPORT: `${STATE_REDUCER_KEY}/SAVE_IMPORT`,
    SAVE_IMPORT_REQUEST: `${STATE_REDUCER_KEY}/SAVE_IMPORT_REQUEST`,
    SAVE_IMPORT_SUCCESS: `${STATE_REDUCER_KEY}/SAVE_IMPORT_SUCCESS`,
    SAVE_IMPORT_FAILURE: `${STATE_REDUCER_KEY}/SAVE_IMPORT_FAILURE`,

    LIST_BACKUP_MESSAGES: `${STATE_REDUCER_KEY}/LIST_BACKUP_MESSAGES`,
    LIST_BACKUP_MESSAGES_REQUEST: `${STATE_REDUCER_KEY}/LIST_BACKUP_MESSAGES_REQUEST`,
    LIST_BACKUP_MESSAGES_SUCCESS: `${STATE_REDUCER_KEY}/LIST_BACKUP_MESSAGES_SUCCESS`,
    LIST_BACKUP_MESSAGES_FAILURE: `${STATE_REDUCER_KEY}/LIST_BACKUP_MESSAGES_FAILURE`,

    RESTORE_DEFAULT: `${STATE_REDUCER_KEY}/RESTORE_DEFAULT`,
    RESTORE_DEFAULT_REQUEST: `${STATE_REDUCER_KEY}/RESTORE_DEFAULT_REQUEST`,
    RESTORE_DEFAULT_SUCCESS: `${STATE_REDUCER_KEY}/RESTORE_DEFAULT_SUCCESS`,
    RESTORE_DEFAULT_FAILURE: `${STATE_REDUCER_KEY}/RESTORE_DEFAULT_FAILURE`

};

export const submitCommonSettings = createAction(ACTION_TYPES.POST_COMMON_SETTINGS);
export const timeProgressById = createAction(ACTION_TYPES.TIME_PROGRESS_BY_ID);
export const updateCommonSettings = createAction(ACTION_TYPES.UPDATE_COMMON_SETTINGS);
export const commonSettingsById = createAction(ACTION_TYPES.COMMON_SETTINGS_BY_ID);
export const commonSettingsListAll = createAction(ACTION_TYPES.COMMON_SETTINGS_LIST_ALL);
export const selectSkin = createAction(ACTION_TYPES.SELECT_SKIN);
export const selectLanguage = createAction(ACTION_TYPES.SELECT_LANGUAGE);
export const selectLog = createAction(ACTION_TYPES.SELECT_LOG);
export const submitVideoSettings = createAction(ACTION_TYPES.POST_VIDEO_SETTINGS);
export const updateVideoSettings = createAction(ACTION_TYPES.UPDATE_VIDEO_SETTINGS);
export const videoSettingsById = createAction(ACTION_TYPES.VIDEO_SETTINGS_BY_ID);
export const videoSettingsListAll = createAction(ACTION_TYPES.VIDEO_SETTINGS_LIST_ALL);
export const selectPlayProperty = createAction(ACTION_TYPES.SELECT_PLAY_PROPERTY);
export const selectStreamType = createAction(ACTION_TYPES.SELECT_STREAM_TYPE);
export const selectFrameRate = createAction(ACTION_TYPES.SELECT_FRAME_RATE);
export const selectDecodingSetting = createAction(ACTION_TYPES.SELECT_DECODING_SETTING);
export const selectVideoDownloadFormat = createAction(ACTION_TYPES.SELECT_VIDEO_DOWNLOAD_FORMAT);
export const selectPlayBackTime = createAction(ACTION_TYPES.SELECT_PLAY_BACK_TIME);
export const selectIsAutomatic = createAction(ACTION_TYPES.SELECT_IS_AUTOMATIC);
export const alarmGradeList = createAction(ACTION_TYPES.ALARM_GRADE_LIST_ALL);
export const updateAlarmGrade = createAction(ACTION_TYPES.UPDATE_ALARM_GRADE);
export const selectDiskSpace = createAction(ACTION_TYPES.SELECT_DISK_SPACE);
export const selectRecordingFormat = createAction(ACTION_TYPES.SELECT_RECORDING_FORMAT);
export const selectRecordingType = createAction(ACTION_TYPES.SELECT_RECORDING_TYPE);
export const selectDiskFull = createAction(ACTION_TYPES.SELECT_DISK_FULL);
export const storageManagementById = createAction(ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID);
export const submitStorageManagement = createAction(ACTION_TYPES.POST_STORAGE_MANAGEMENT);
export const updateStorageManagement = createAction(ACTION_TYPES.UPDATE_STORAGE_MANAGEMENT);
export const storageManagementListAll = createAction(ACTION_TYPES.STORAGE_MANAGEMENT_LIST_ALL);
export const getDriveList = createAction(ACTION_TYPES.GET_DRIVE_LIST);
export const getTimeTemplate = createAction(ACTION_TYPES.GET_TIME_TEMPLATE);
export const getCodeStream = createAction(ACTION_TYPES.GET_CODE_STREAM);
export const saveRecordPlan = createAction(ACTION_TYPES.SAVE_RECORD_PLAN);
export const saveCopyTo = createAction(ACTION_TYPES.SAVE_COPY_TO);
export const getRecordPlanSettingsById = createAction(ACTION_TYPES.GET_RECORD_PLAN_SETTING_BY_ID);
export const listCustomizedDevices = createAction(ACTION_TYPES.LIST_CUSTOMIZED_DEVICES);
export const submitFullCycle = createAction(ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE);
export const saveEnableLocalDecoding = createAction(ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING);
export const saveOutput = createAction(ACTION_TYPES.SAVE_OUTPUT);
export const saveBackup = createAction(ACTION_TYPES.SAVE_BACKUP);
export const saveRecover = createAction(ACTION_TYPES.SAVE_RECOVER);
export const saveImport = createAction(ACTION_TYPES.SAVE_IMPORT);
export const listBackupMessages = createAction(ACTION_TYPES.LIST_BACKUP_MESSAGES);
export const restoreDefault = createAction(ACTION_TYPES.RESTORE_DEFAULT);
