import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent } from "../../../utils/apiUtils";

const initialState = {
    filterUserName: {
        requestInProgress: false,
        data: []
    },
    logTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    detailedTypeDropdown: {
        requestInProgress: false,
        data: []
    },
    userNameDropdown: {
        requestInProgress: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.filterUserName = initialState.filterUserName;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FILTER_DESCRIPTION_SUCCESS, (state, { payload }) => {
                _.set(state, "filterUserName.requestInProgress", false);
                _.set(state, "filterUserName.data", payload);
            })
            .addCase(ACTION_TYPES.SELECT_LOG_TYPE_REQUEST, (state) => {
                state.logTypeDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_LOG_TYPE_SUCCESS, (state, action) => {
                _.set(state, "logTypeDropdown.requestInProgress", false);
                _.set(state, "logTypeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_LOG_TYPE_FAILURE, (state) => {
                state.logTypeDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_DETAILED_TYPE_REQUEST, (state) => {
                state.detailedTypeDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_DETAILED_TYPE_SUCCESS, (state, action) => {
                _.set(state, "detailedTypeDropdown.requestInProgress", false);
                _.set(state, "detailedTypeDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_DETAILED_TYPE_FAILURE, (state) => {
                state.detailedTypeDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_USERNAME_REQUEST, (state) => {
                state.userNameDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_USERNAME_SUCCESS, (state, action) => {
                _.set(state, "userNameDropdown.requestInProgress", false);
                _.set(state, "userNameDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_USERNAME_FAILURE, (state) => {
                state.userNameDropdown.requestInProgress = false;
            });
    }
});

export const { actions, reducer } = slice;
