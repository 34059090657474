import { createTheme } from "@mui/material/styles";
import button from "./button.json";
import buttonBase from "./buttonBase.json";
import checkbox from "./darkCheckbox.json";
import components from "./components.json";
import inputField from "./darkInputField.json";
import palette from "./palette.json";
import select from "./darkSelect.json";
import shape from "./shape.json";
import spacing from "./spacing.json";
import textfield from "./darkTextfield.json";
import typography from "./typography.json";
import pagination from "./pagination.json";
import card from "./card.json";
import inputBase from "./darkInputBase.json";
import listItemButton from "./listItemButton.json";
import paper from "./darkPaper.json";
import cardHeader from "./cardHeader.json";
import darkTheme from "./darkTheme";
import radioButton from "./darkRadioButton.json";

const overrides = {
  components: {
    ...inputField,
    ...button,
    ...checkbox,
    ...select,
    ...textfield,
    ...buttonBase,
    ...card,
    ...pagination,
    ...inputBase,
    ...listItemButton,
    ...paper,
    ...cardHeader,
    ...radioButton
  }
};
const customThemes = {
  ...palette,
  ...typography,
  ...spacing,
  ...shape,
  ...components,
  ...darkTheme,
  ...overrides
};
const theme = createTheme({ ...customThemes });
export default theme;
