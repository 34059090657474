import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
// import GTranslateSharpIcon from "@mui/icons-material/GTranslateSharp";
import { Avatar, ButtonBase, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import LOCK_ICON_DARK from "../../../../assets/images/lockIconDark.png";
import LOCK_ICON_LIGHT from "../../../../assets/images/lockIconLight.png";
import TROIS_LOGO_IMAGE from "../../../../assets/images/Trois_logo.svg";
import LOGO_IMAGE from "../../../../assets/images/logo.png";

import { Components, I18n } from "../../../../common/components";
import { MaterialUISwitch } from "../../../../common/components/custom/MaterialUISwitch";
import { DisableComponent } from "../../../../utils/disableComponent";
import { STATE_REDUCER_KEY } from "../../../home/homeModule/constants";
import { actions as sliceActions } from "../../../home/homeModule/slice";
import { RESOURCE_IDS } from "../../../resources";
import { STATE_REDUCER_KEY as COMMON_REDUCER_KEY } from "../../constants";
import { actions as commonActions } from "../../slice";
import LanguageSelect from "./LanguageSelect";
import ProfileMenu from "./ProfileMenu";
import "./style.css";
import WebSocketComponent from "../../../home/homeModule/components/WebSocket";
import LockScreen from "./LockScreen";
import { fetchLockScreen } from "../../actions";

const { Box, Grid } = Components;

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [language, setLanguage] = useState(null);
    const [status, setStatus] = useState(false);
    const theme = useSelector(state => state[COMMON_REDUCER_KEY].switch.data);
    const systemSwitch = useSelector(state => state[STATE_REDUCER_KEY].systemSwitch);
    const userManagementSwitch = useSelector(state => state[STATE_REDUCER_KEY].userManagementSwitch);
    const resourceManagementSwitch = useSelector(state => state[STATE_REDUCER_KEY].resourceManagementSwitch);
    const roleManagementSwitch = useSelector(state => state[STATE_REDUCER_KEY].roleManagementSwitch);
    const alarmConfigurationSwitch = useSelector(state => state[STATE_REDUCER_KEY].alarmConfigurationSwitch);
    const tvWallSwitch = useSelector(state => state[STATE_REDUCER_KEY].tvWallSwitch);
    const playBack = useSelector(state => state[STATE_REDUCER_KEY].playBack);
    const operationLogsSwitch = useSelector(state => state[STATE_REDUCER_KEY].operationLogsSwitch);
    const { logoSwitch } = useSelector((state) => state[COMMON_REDUCER_KEY]);
    const electronicMapSwitch = useSelector(state => state[STATE_REDUCER_KEY].electronicMapSwitch);
    const socketOpen = useSelector((state) => state?.register?.socketOpen);
    // const socket = useSelector((state) => state?.register?.socket);
    const open = Boolean(anchorEl);
    const openLanguage = Boolean(language);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const disableLive = (DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.LIVE.ACTIONS.CLEAR_ALARM) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.LIVE.ACTIONS.CONTEXT_MENU) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.LIVE.ACTIONS.DEVICE_CONFIG) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.LIVE.ACTIONS.LOCAL_RECORDING) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.LIVE.ACTIONS.SNAPSHOT) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.LIVE.ACTIONS.TIME_CALIBRATION) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.LIVE.ACTIONS.VIEW.ACTIONS.CREATE));
    const disableDevice = (DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.EDIT) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.AUTO_SEARCH) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.ADD) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.GROUP.ACTIONS.ADD) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.GROUP.ACTIONS.VIEW) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.GROUP.ACTIONS.EDIT) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.GROUP.ACTIONS.DELETE) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.LIST) || DisableComponent(RESOURCE_IDS.HOME.COMMON_FUNCTIONS.ACTIONS.DEVICE_ADMINISTRATION.ACTIONS.DELETE));

    const handleKeyDown = (event) => {
        if (event.ctrlKey === true && event.shiftKey === true && event.altKey === true) {
            dispatch(commonActions.setSwitchLogo(!logoSwitch));
        }
    };
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);
    const handleHome = () => {
        navigate("/home");
        if (systemSwitch !== false) {
            dispatch(sliceActions.setSystemSwitch(!systemSwitch));
        }
        if (userManagementSwitch !== false) {
            dispatch(sliceActions.setUserManagementSwitch(!userManagementSwitch));
        }
        if (resourceManagementSwitch !== false) {
            dispatch(sliceActions.setResourceManagementSwitch(!resourceManagementSwitch));
        }
        if (roleManagementSwitch !== false) {
            dispatch(sliceActions.setRoleManagementSwitch(!roleManagementSwitch));
        }
        if (alarmConfigurationSwitch !== false) {
            dispatch(sliceActions.setAlarmConfigurationSwitch(!alarmConfigurationSwitch));
        }
        if (tvWallSwitch !== false) {
            dispatch(sliceActions.setTVWallSwitch(!tvWallSwitch));
        }
        if (playBack !== false) {
            dispatch(sliceActions.setPlayBack(!playBack));
        }
        if (operationLogsSwitch !== false) {
            dispatch(sliceActions.setOperationLogsSwitch(!operationLogsSwitch));
        }
        if (electronicMapSwitch !== false) {
            dispatch(sliceActions.setElectronicMapSwitch(!electronicMapSwitch));
        }
    };
    const handleDevice = () => {
        navigate("/device");
    };
    const handleLive = () => {
        navigate("/live");
    };
    // const handleAlarm = () => {
    //     navigate("/alarm");
    // };

    const handleChange = () => {
        if (theme === "lightTheme") {
            dispatch(commonActions.setSwitch("darkTheme"));
        } else {
            dispatch(commonActions.setSwitch("lightTheme"));
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLock = () => {
        setStatus(true);
        dispatch(fetchLockScreen());
        // dispatch(commonActions.setLock(true));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const handleLanguageClick = (event) => {
    //     setLanguage(event.currentTarget);
    // };
    const handleLanguageClose = () => {
        setLanguage(null);
    };

    let rotate = open === false ? "rotate(-90deg)" : "rotate(-270deg)";
    // let rotateIcon = openLanguage === false ? "rotate(-90deg)" : "rotate(-270deg)";

    return (
        <Grid
            component="header"
            sx={{ border: theme === "darkTheme" ? "1px solid #526D82" : "", borderRadius: "15px", width: "98%", height: "55px", position: "sticky", left: "17px", top: "10px", zIndex: 100, backgroundColor: "secondary.light", display: "flex", justifyContent: "space-between", alignItems: "center", fontFamily: "Noto Sans", fontSize: "18px", fontWeight: 600, marginTop: "8px" }}
        >
            <Box sx={{ width: "240px", display: { xs: "none", md: "block" }, pl: 6 }}>
                <img alt="cover" src={logoSwitch ? TROIS_LOGO_IMAGE : LOGO_IMAGE} ></img>
            </Box>
            <Grid sx={{ height: "40px", backgroundColor: "secondary.main", display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", padding: "10px", borderRadius: "30px" }}>
                <Grid className={(location.pathname === "/home") ? "active-style" : "non-active-style"} sx={{ cursor: "pointer", display: "grid", placeContent: "center", width: "110px", padding: "5px" }} onClick={handleHome}>
                    {I18n("home")}
                </Grid >
                {disableDevice && <Grid className={(location.pathname === "/device") ? "active-style" : "non-active-style"} sx={{ cursor: "pointer", display: "grid", placeContent: "center", width: "110px", padding: "5px" }} onClick={handleDevice}>
                    {I18n("device")}
                </Grid>}
                {disableLive && <Grid className={(location.pathname === "/live") ? "active-style" : "non-active-style"} sx={{ cursor: "pointer", display: "grid", placeContent: "center", width: "110px", padding: "5px" }} onClick={handleLive}>
                    {I18n("live")}
                </Grid>}
                {/* {DisableComponent(RESOURCE_IDS.ALARM.ROUTE) && <Grid className={(location.pathname === "/alarm") ? "active-style" : "non-active-style"} sx={{ cursor: "pointer", display: "grid", placeContent: "center", width: "110px", padding: "10px" }} onClick={handleAlarm}>
                    {I18n("alarm")}
                </Grid>} */}
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Box
                    sx={{ display: "flex", justifyContent: "end", alignItems: "center", borderRadius: "20px", p: 1, position: "relative", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
                    variant="contained" component="div"
                >
                    {/* <ButtonBase sx={{}} onClick={handleLanguageClick}>
                        <GTranslateSharpIcon fontSize="large" style={{ width: "33px", height: "33px", color: theme === "darkTheme" ? "#151F2D" : "#000" }} />
                        <ArrowLeftIcon sx={{ height: "40px", width: "40px", color: "primary.theme", transform: rotateIcon }} />
                    </ButtonBase> */}
                </Box>
                <IconButton >
                    <MaterialUISwitch onClick={handleChange} sx={{ m: 1 }} checked={theme === "darkTheme" ? true : false} />
                </IconButton>
                <IconButton onClick={handleLock} >
                    <Avatar sx={{ width: "33px", height: "33px" }} src={theme === "darkTheme" ? LOCK_ICON_DARK : LOCK_ICON_LIGHT} />
                </IconButton>
                <Box
                    sx={{ display: "flex", justifyContent: "end", alignItems: "center", mr: 5, borderRadius: "20px", p: 1, position: "relative", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
                    variant="contained" component="div"
                >
                    <ButtonBase sx={{}} onClick={handleClick}>
                        <Avatar sx={{ width: "33px", height: "33px", color: "secondary.main" }} />
                        <ArrowLeftIcon sx={{ height: "40px", width: "40px", color: "primary.theme", transform: rotate }} />
                    </ButtonBase>
                </Box>
            </Grid>
            <ProfileMenu open={open} handleClose={handleClose} anchorEl={anchorEl} />
            <LanguageSelect open={openLanguage} handleClose={handleLanguageClose} anchorEl={language} />
            {status && <LockScreen />}
            {socketOpen && <WebSocketComponent />}
        </Grid >
    );
};

export default Header;
