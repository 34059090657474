import { useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";
import { useSelector } from "react-redux";

import EN_IN from "./locales/en_IN";
import ML_IN from "./locales/ml_IN";
import { STATE_REDUCER_KEY } from "../modules/common/constants";

// Initialize i18n outside the component
i18n
    .use(initReactI18next)
    .use(i18nextPlugin)
    .init({
        resources: {
            en: {
                translation: { ...EN_IN }
            },
            ml: {
                translation: { ...ML_IN }
            }
        },
        lng: "en", // Default language
        fallbackLng: "en-IN",
        debug: true,

        interpolation: {
            escapeValue: false
        }
    });

const I18n = () => {
    const language = useSelector((state) => state[STATE_REDUCER_KEY].language.data);

    useEffect(() => {
        // Update the language when the component mounts or when language changes
        i18n.changeLanguage(language || "en");
    }, [language]);

    return null; // or any other component you want to render
};

export default I18n;

