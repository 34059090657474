import { flow } from "lodash";

const { STATE_REDUCER_KEY } = require("./constants");

const getCommon = (state) => state[STATE_REDUCER_KEY];

const tableData = (common) => common.tableData;
export const getTableDataOfKey = flow(getCommon, tableData);

const tableRowSelection = (common) => common.tableRowSelection;
export const getRowSelectedByKey = flow(getCommon, tableRowSelection);

const tableRequestInProgress = (common) => common.tableRequestInProgress;
export const getRowTableState = flow(getCommon, tableRequestInProgress);

const tableFilters = (common) => common.tableFilters;
export const getTableFiltersByKey = flow(getCommon, tableFilters);

const tablePagination = (common) => common.tablePagination;
export const getTablePaginationByKey = flow(getCommon, tablePagination);

const table = (common) => common.table;
export const getTableProps = flow(getCommon, table);

const searchFilter = (common) => common.searchFilter;
export const getSearchFilters = flow(getCommon, searchFilter);

const updateMappingData = (state) => state.updateMapping;
export const getUpdateMapping = flow(getCommon, updateMappingData);

const lengthOfVideoPlayerData = (state) => state.lengthOfVideoPlayer.data;
export const getLengthOfVideoPlayerData = flow(getCommon, lengthOfVideoPlayerData);

const idsForDeviceCheck = (state) => state.deviceListIds.data;
export const getDeviceCheckIds = flow(getCommon, idsForDeviceCheck);

const idsForChannelCheck = (state) => state.channelListIds.data;
export const getChannelCheckIds = flow(getCommon, idsForChannelCheck);

const idsForGroupCheck = (state) => state.groupListIds.data;
export const getGroupCheckIds = flow(getCommon, idsForGroupCheck);

const viewItemList = (state) => state.viewItems.data;
export const getViewItemList = flow(getCommon, viewItemList);
