export const API_URL = {
    LIST_VIEW: {
        VIDEO: "resource/api/no-auth/multimedia/download"
    },
    LIVE: {
        GROUP_DEVICE_LIST: "admin-service/api/auth/v1/crud/model/device-group/custom-device-list",
        BY_ID: "admin-service/api/auth/v1/connector/start/live-stream",
        STOP_STREAMING: "admin-service/api/auth/v1/connector/stop/live-stream",
        START_RECORDING: "admin-service/api/auth/v1/connector/start/recording",
        STOP_RECORDING: "admin-service/api/auth/v1/connector/stop/recording",
        SAVE_VIEW: "admin-service/api/auth/v1/crud/model/view",
        LIST_SAVE_VIEW: "admin-service/api/auth/v1/crud/model/view/all",
        DELETE_VIEW: "admin-service/api/auth/v1/crud/model/view/delete/cruise-plan",
        VIEW_BY_ID: "admin-service/api/auth/v1/crud/model/view/:id",
        MOVE_UP_VIEW: "admin-service/api/auth/v1/crud/model/view/:id/move-up",
        MOVE_DOWN_VIEW: "admin-service/api/auth/v1/crud/model/view/:id/move-down",
        VIEW_LIST: "admin-service/api/auth/v1/crud/model/view/all",
        VIEW_DELETE: "admin-service/api/auth/v1/crud/model/view/:id",
        STREAM_SWITCH: "admin-service/api/auth/v1/connector/live/switch-stream"
    }
};
