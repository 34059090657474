import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIST_OPERATION_LOGS: `${STATE_REDUCER_KEY}/LIST_OPERATION_LOGS`,
    LIST_OPERATION_LOGS_REQUEST: `${STATE_REDUCER_KEY}/LIST_OPERATION_LOGS_REQUEST`,
    LIST_OPERATION_LOGS_SUCCESS: `${STATE_REDUCER_KEY}/LIST_OPERATION_LOGS_SUCCESS`,
    LIST_OPERATION_LOGS_FAILURE: `${STATE_REDUCER_KEY}/LIST_OPERATION_LOGS_FAILURE`,

    FILTER_DESCRIPTION: `${STATE_REDUCER_KEY}/FILTER_DESCRIPTION`,
    FILTER_DESCRIPTION_REQUEST: `${STATE_REDUCER_KEY}/FILTER_DESCRIPTION_REQUEST`,
    FILTER_DESCRIPTION_SUCCESS: `${STATE_REDUCER_KEY}/FILTER_DESCRIPTION_SUCCESS`,
    FILTER_DESCRIPTION_FAILURE: `${STATE_REDUCER_KEY}/FILTER_DESCRIPTION_FAILURE`,

    SELECT_USERNAME: `${STATE_REDUCER_KEY}/SELECT_USERNAME`,
    SELECT_USERNAME_REQUEST: `${STATE_REDUCER_KEY}/SELECT_USERNAME_REQUEST`,
    SELECT_USERNAME_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_USERNAME_SUCCESS`,
    SELECT_USERNAME_FAILURE: `${STATE_REDUCER_KEY}/SELECT_USERNAME_FAILURE`,

    SELECT_LOG_TYPE: `${STATE_REDUCER_KEY}/SELECT_LOG_TYPE`,
    SELECT_LOG_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_LOG_TYPE_REQUEST`,
    SELECT_LOG_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_LOG_TYPE_SUCCESS`,
    SELECT_LOG_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_LOG_TYPE_FAILURE`,

    SELECT_DETAILED_TYPE: `${STATE_REDUCER_KEY}/SELECT_DETAILED_TYPE`,
    SELECT_DETAILED_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DETAILED_TYPE_REQUEST`,
    SELECT_DETAILED_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DETAILED_TYPE_SUCCESS`,
    SELECT_DETAILED_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DETAILED_TYPE_FAILURE`

};

export const listOperationLogs = createAction(ACTION_TYPES.LIST_OPERATION_LOGS);
export const filterDescription = createAction(ACTION_TYPES.FILTER_DESCRIPTION);
export const selectUsername = createAction(ACTION_TYPES.SELECT_USERNAME);
export const selectLogType = createAction(ACTION_TYPES.SELECT_LOG_TYPE);
export const selectDetailedType = createAction(ACTION_TYPES.SELECT_DETAILED_TYPE);
