import { useSelector } from "react-redux";
import { Components, I18n } from "../../../../common/components";
import { Link as MUILink, Tooltip, Typography } from "@mui/material";
import PROCESSOR from "../../../../assets/images/processor.png";
import WHITE_PROCESSOR from "../../../../assets/images/white-processor.png";

const { Grid } = Components;

const listStyle = {
    px: 2,
    borderLeft: "1px solid #009992",
    color: "footer.primary",
    fontSize: "12px",
    display: {
        xs: "none",
        md: "block"
    }
};

const Footer = () => {
    const cpuMemoryData = useSelector(state => state?.common?.cpuMemoryData?.data);
    const theme = useSelector(state => state.common.switch.data);

    return (
        <Grid sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", justifyContent: "end", width: "100%", padding: "5px 20px", color: "footer.primary", backgroundColor: "footer.main" }}>
            {process.env.REACT_APP_ENV_TYPE === "app" ? (<>
                <Grid sx={{ display: "flex", alignItems: "center", px: 2 }}>
                    <Tooltip
                        title={`${I18n("cpu_usage")} ${cpuMemoryData && typeof cpuMemoryData === "object" && !Array.isArray(cpuMemoryData) ? cpuMemoryData.cpuLoad + "%" : "N/A"} | ${I18n("memory")} ${cpuMemoryData && typeof cpuMemoryData === "object" && !Array.isArray(cpuMemoryData) ? cpuMemoryData.totalUsedMemory + "%" : "N/A"}`}
                    >
                        <img src={theme === "lightTheme" ? PROCESSOR : WHITE_PROCESSOR} alt="" height="20px" width="20px" style={{ cursor: "pointer" }} />
                    </Tooltip>
                </Grid>
                <Typography sx={{ pl: 2, fontSize: "12px", borderLeft: process.env.REACT_APP_ENV_TYPE === "app" ? "1px solid #009992" : "" }}>{"Version " + process.env.REACT_APP_VERSION}</Typography>
            </>
            ) : (
                <>
                    <Typography sx={{ pl: 2, fontSize: "12px", borderRight: process.env.REACT_APP_ENV_TYPE !== "app" ? "1px solid #009992" : "", px: 2 }}>{"Version " + process.env.REACT_APP_VERSION}</Typography>
                    <Typography sx={{ pl: 2, fontSize: "12px", px: 2 }}>
                        {`${I18n("cpu_usage")} ${cpuMemoryData && typeof cpuMemoryData === "object" && !Array.isArray(cpuMemoryData) ? cpuMemoryData.cpuLoad + "%" : "N/A"} | ${I18n("memory")} ${cpuMemoryData && typeof cpuMemoryData === "object" && !Array.isArray(cpuMemoryData) ? cpuMemoryData.totalUsedMemory + "%" : "N/A"}`}
                    </Typography>
                    <MUILink target="_blank" rel="noopener noreferrer" sx={listStyle} > {I18n("about_us")} </MUILink>
                </>
            )}
        </Grid >
    );
};

export default Footer;
