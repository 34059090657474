import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const userLevelDropDown = () => {
    return {
        url: API_URL.USER_MANAGEMENT.SELECT_USER_LEVEL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_USER_ROLES_REQUEST, ACTION_TYPES.SELECT_USER_ROLES_SUCCESS, ACTION_TYPES.SELECT_USER_ROLES_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const submitUserDetails = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_USER_DETAILS_REQUEST, ACTION_TYPES.POST_USER_DETAILS_SUCCESS, ACTION_TYPES.POST_USER_DETAILS_FAILURE],
            data
        }
    };
};

export const updateUserDetails = (data) => {
    return {
        url: API_URL.USER_MANAGEMENT.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_USER_DETAILS_REQUEST, ACTION_TYPES.UPDATE_USER_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_USER_DETAILS_FAILURE],
            data
        }
    };
};

export const listUsersDetails = (data) => ({
    url: API_URL.USER_MANAGEMENT.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_USERS_REQUEST, ACTION_TYPES.LIST_USERS_SUCCESS, ACTION_TYPES.LIST_USERS_FAILURE],
        data
    }
});

export const userDetailsById = (id) => {
    return {
        url: API_URL.USER_MANAGEMENT.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.USER_DETAILS_BY_ID_REQUEST, ACTION_TYPES.USER_DETAILS_BY_ID_SUCCESS, ACTION_TYPES.USER_DETAILS_BY_ID_FAILURE]
        }
    };
};

export const deleteUserDetails = (id) => {
    return {
        url: `${API_URL.USER_MANAGEMENT.DELETE}?userIds=${id}`,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_USER_DETAILS_REQUEST, ACTION_TYPES.DELETE_USER_DETAILS_SUCCESS, ACTION_TYPES.DELETE_USER_DETAILS_FAILURE]
        }
    };
};

export const checkedResourcesData = (data) => {
    return {
        url: API_URL.ROLE_MANAGEMENT.CHECKED_RESOURCE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.CHECKED_RESOURCE_DATA_REQUEST, ACTION_TYPES.CHECKED_RESOURCE_DATA_SUCCESS, ACTION_TYPES.CHECKED_RESOURCE_DATA_FAILURE],
            data
        }
    };
};
