import { createSlice } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import _ from "lodash";
const initialState = {
    deviceList: {
        requestInProgress: false,
        data: []
    },
    imageDetails: {
        requestInProgress: false,
        data: {
            file: "",
            name: ""
        }
    },
    mapList: {
        requestInProgress: false,
        data: []
    },
    hotZoneMap: {
        requestInProgress: false,
        data: []
    },
    mapDetails: {
        requestInProgress: false,
        data: {
            name: ""
        }
    }
};

const slice = createSlice({
    name: STATE_REDUCER_KEY,
    initialState: initialState,
    reducers: {
        clearAll: () => initialState,
        // clear: (state) => {
        //     state.mapList = initialState.mapList;
        // },
        updateMapList: (state, { payload }) => {
            state.mapList.data = payload;
        },
        setRightCardList: (state, { payload }) => {
            state.imageDetails.data = payload;
        },
        resetMapData: (state) => {
            state.imageDetails = initialState.imageDetails;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS_REQUEST, (state) => {
                state.deviceList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "deviceList.requestInProgress", false);
                _.set(state, "deviceList.data", action.payload);
            })
            .addCase(ACTION_TYPES.FETCH_DEVICE_LIST_DETAILS_FAILURE, (state) => {
                state.deviceList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_BY_IMAGE_DETAILS_REQUEST, (state) => {
                state.imageDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_BY_IMAGE_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "imageDetails.requestInProgress", false);
                _.set(state, "imageDetails.data", action.payload);
            })
            .addCase(ACTION_TYPES.GET_BY_IMAGE_DETAILS_FAILURE, (state) => {
                state.imageDetails.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_MAP_LIST_DETAILS_REQUEST, (state) => {
                state.mapList.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_MAP_LIST_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "mapList.requestInProgress", false);
                _.set(state, "mapList.data", action.payload?.content);
            })
            .addCase(ACTION_TYPES.FETCH_MAP_LIST_DETAILS_FAILURE, (state) => {
                state.mapList.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS_REQUEST, (state) => {
                state.hotZoneMap.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS_SUCCESS, (state, action) => {
                _.set(state, "hotZoneMap.requestInProgress", false);
                _.set(state, "hotZoneMap.data", action.payload);
            })
            .addCase(ACTION_TYPES.FETCH_HOT_ZONE_MAP_DETAILS_FAILURE, (state) => {
                state.hotZoneMap.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.GET_MAP_NAME_REQUEST, (state) => {
                state.mapDetails.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.GET_MAP_NAME_SUCCESS, (state, { payload }) => {
                _.set(state, "mapDetails.requestInProgress", false);
                _.set(state, "mapDetails.data", payload);
            })
            .addCase(ACTION_TYPES.GET_MAP_NAME_FAILURE, (state) => {
                state.mapDetails.requestInProgress = false;
            });
    }
});

export const { reducer, actions } = slice;
