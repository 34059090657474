import { Box } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import Header from "../modules/common/components/header/Header";
import Footer from "../modules/common/components/footer/Footer";
import ProgressiveBox from "../modules/device/device/components/ProgressiveBox";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { actions as commonAction } from "../modules/common/slice";
// import CancelIcon from "@mui/icons-material/Cancel";
// import RemoveIcon from "@mui/icons-material/Remove";
// import FilterNoneOutlinedIcon from "@mui/icons-material/FilterNoneOutlined";
// import CropSquareIcon from "@mui/icons-material/CropSquare";
import { logoutUser } from "../modules/common/actions";
import { actions as sliceActions } from "../modules/user-management/register/slice";
import { STORAGE_KEYS, TABLE_IDS } from "../common/constants";
import { actions as playbackActions } from "../modules/home/playback-persist/slice";
import { actions as homeAction } from "../modules/home/homeModule/slice";
import WarningBox from "../modules/live/liveView/components/WarningBox";
// import { I18n } from "../common/components";
// import PROCESSOR from "../assets/images/processor.png";
// import WHITE_PROCESSOR from "../assets/images/white-processor.png";
// import { STATE_REDUCER_KEY } from "../modules/common/constants";

const DashboardLayout = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [showProgressBox, setShowProgressBox] = useState(false);
    const [showWarningBox, setShowWarningBox] = useState(false);
    // const [isMaximized, setIsMaximized] = useState(false);

    const initialCheckedDevices = useSelector(state => state.common.deviceBulkAdd);
    // const theme = useSelector(state => state.common.switch.data);
    // const screenLock = useSelector(state => state[STATE_REDUCER_KEY].lockScreen);
    let totalCount = initialCheckedDevices?.length ?? 0;
    const progressCount = useSelector(state => state.common.progressiveCount);
    const stopedMessage = useSelector(state => state.common.recordingWarningMessages);
    const resumedMessage = useSelector(state => state.common.recordingSuccessMessages);
    const progressiveData = useSelector(state => state.common.progressiveData);
    const success = useSelector(state => state.common.successDeviceAdd);
    const failure = useSelector(state => state.common.failedDeviceAdd);
    const socket = useSelector((state) => state?.register?.socket);
    const pingInterval = useSelector((state) => state?.register?.pingInterval);
    const autoLogin = useSelector((state) => state?.register?.autoMaticLogin?.data);
    // const cpuMemoryData = useSelector(state => state?.common?.cpuMemoryData?.data);

    useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "app") {
            window.electron.sendAutoLogin(autoLogin);
        }
    }, [autoLogin]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "app") {
            window.electron.onClosed(() => {
                dispatch(logoutUser({ type: "app", autoLogin: autoLogin }));
                localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
                dispatch(commonAction.clearLive());
                dispatch(playbackActions.clearPlayBack());
            });
            return () => {
                window.electron.removeListenerAll();
            };
        }
    }, []);

    useEffect(() => {
        if (stopedMessage) {
            setShowWarningBox(true);
        }
        if (resumedMessage) {
            setTimeout(() => {
                setShowWarningBox(false);
                dispatch(commonAction.clearWarningMessages());
            }, 2000);
        }
    }, [resumedMessage, stopedMessage]);

    // Function to close the app
    const handleClosed = () => {
        if (process.env.REACT_APP_ENV_TYPE === "app") {
            dispatch(logoutUser({ type: "app" }));
            localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
            if (autoLogin === false) {
                localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
            }
            dispatch(homeAction?.clearAll());
            dispatch(commonAction.clearLive());
            dispatch(playbackActions.clearPlayBack());
            if (socket) {
                if (socket.readyState === WebSocket.OPEN) {
                    clearInterval(pingInterval);
                    dispatch(sliceActions.setSocketOpen(false));
                    dispatch(sliceActions.setPingInterval(0));
                    dispatch(sliceActions.setWebSocket({}));
                    socket.close();
                }
            }
            window.electron.closeWindow();
            // setTimeout(() => {
            // }, 1000);
        }
    };

    // Function to minimize the app
    // const handleMinimize = () => {
    //     if (process.env.REACT_APP_ENV_TYPE === "app") {
    //         window.electron.minimizeWindow();
    //     }
    // };

    // Function to toggle maximize/restore window
    // const handleMaximize = () => {
    //     if (process.env.REACT_APP_ENV_TYPE === "app") {
    //         window.electron.maximizeWindow();
    //         setIsMaximized(prev => !prev);
    //     }
    // };

    const handleKeyDown = useCallback((event) => {
        if (event.ctrlKey === true && event.altKey === true && (event.code === "F20" || event.keyCode === 115)) {
            handleClosed();
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (!_.isEmpty(progressiveData?.payload)) {
            if (progressiveData?.payload?.deviceAdded === false) {
                dispatch(commonAction.setFailed());
            } else {
                dispatch(commonAction.setSuccess());
            }
        }

        // Show progress box if progressCount is updated
        if (progressCount > 0) {
            setShowProgressBox(true);
        }

        // Close the progress box after 5 seconds if all devices are processed
        if (progressCount === totalCount && totalCount !== 0) {
            dispatch(commonAction.setRowSelection({ key: TABLE_IDS.AUTO_SEARCH, items: {} }));
            const timer = setTimeout(() => {
                setShowProgressBox(false);
                dispatch(commonAction.clearBulkAddData());
                dispatch(commonAction.clearProgressiveCount());
                dispatch(commonAction.clearProgressiveData());
                dispatch(commonAction.clearSetFailed());
                dispatch(commonAction.clearSetSuccess());
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [progressCount, totalCount, progressiveData, dispatch]);

    const handleClose = () => {
        setShowProgressBox(false);
        dispatch(commonAction.clearBulkAddData());
        dispatch(commonAction.clearProgressiveCount());
        dispatch(commonAction.clearProgressiveData());
        dispatch(commonAction.clearSetFailed());
        dispatch(commonAction.clearSetSuccess());
    };

    const handleCloseWarningBox = () => {
        setShowWarningBox(false);
        dispatch(commonAction.clearWarningMessages());
    };

    return (
        <Box sx={{ bgcolor: "secondary.main", minHeight: "100vh", width: "100%", display: "flex", flexDirection: "column" }}>
            {/* {process.env.REACT_APP_ENV_TYPE === "app" && (
                <Grid container sx={{ display: "flex", justifyContent: "flex-end", position: "sticky", top: 0, zIndex: 400, backgroundColor: theme === "lightTheme" ? "#EFEFEF" : "#1E2B3D", height: "40px" }}>
                    <Grid sx={{ display: "flex", alignItems: "center", mr: 10 }}>
                        <Tooltip
                            title={`${I18n("cpu_usage")} ${cpuMemoryData && typeof cpuMemoryData === "object" && !Array.isArray(cpuMemoryData) ? cpuMemoryData.cpuLoad + "%" : "N/A"} | ${I18n("memory")} ${cpuMemoryData && typeof cpuMemoryData === "object" && !Array.isArray(cpuMemoryData) ? cpuMemoryData.totalUsedMemory + "%" : "N/A"}`}
                        >
                            <img src={theme === "lightTheme" ? PROCESSOR : WHITE_PROCESSOR} alt="" height="25px" width="25px" style={{ cursor: "pointer" }} />
                        </Tooltip>
                    </Grid>
                    <IconButton onClick={handleMinimize}><Tooltip title="Minimize"><RemoveIcon /></Tooltip></IconButton>
                    <IconButton onClick={handleMaximize}>
                        <Tooltip title={isMaximized ? "Restore" : "Maximize"}>
                            {isMaximized ? <CropSquareIcon fontSize="small" /> : <FilterNoneOutlinedIcon fontSize="small" />}
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={() => screenLock === false ? handleClosed() : ""}><Tooltip title="Close"><CancelIcon sx={{ color: "#E4002B" }} /></Tooltip></IconButton>
                </Grid>
            )} */}
            <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, height: "calc(100vh - 63px)" }}>
                <Box sx={{ flexGrow: 1, overflow: "auto", height: "100%", display: "flex", flexDirection: "column", px: { xs: 0, md: 2 }, pb: { xs: 0, md: 2 } }}>
                    <Box sx={{ flexGrow: 1, bgcolor: "black.main", borderRadius: { xs: 0, md: "20px" }, height: "100%" }}>
                        {children}
                    </Box>
                    {showProgressBox && <ProgressiveBox failed={failure} success={success} handleClose={handleClose} />}
                    {showWarningBox && <WarningBox handleClose={handleCloseWarningBox} />}
                </Box>
                <Footer sx={{ mt: 2 }} />
            </Box>
        </Box>
    );
};

export default DashboardLayout;
