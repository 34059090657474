import { createTheme } from "@mui/material/styles";
import button from "./button.json";
import buttonBase from "./buttonBase.json";
import checkbox from "./lightCheckbox.json";
import components from "./components.json";
import inputField from "./lightInputField.json";
import palette from "./palette.json";
import select from "./lightSelect.json";
import shape from "./shape.json";
import spacing from "./spacing.json";
import textfield from "./lightTextfield.json";
import typography from "./typography.json";
import pagination from "./pagination.json";
import card from "./card.json";
import inputBase from "./lightInputBase.json";
import listItemButton from "./listItemButton.json";
import paper from "./lightPaper.json";
import cardHeader from "./cardHeader.json";
import lightTheme from "./lightTheme";
import radioButton from "./lightRadioButton.json";

const overrides = {
  components: {
    ...inputField,
    ...button,
    ...checkbox,
    ...select,
    ...textfield,
    ...buttonBase,
    ...card,
    ...pagination,
    ...inputBase,
    ...listItemButton,
    ...paper,
    ...cardHeader,
    ...radioButton
  }
};
const customThemes = {
  ...palette,
  ...typography,
  ...spacing,
  ...shape,
  ...components,
  ...lightTheme,
  ...overrides
};
const theme = createTheme({ ...customThemes });
export default theme;
