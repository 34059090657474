import { useSelector } from "react-redux";
import { activeRoles } from "./activeRoleResourceList";

export const DisableComponent = (id) => {
    const data = useSelector(state => state?.register?.userDetails?.resourceId?.userDto?.activeRoleResourceMappingList);
    const activeRole = useSelector(state => state?.register?.userDetails?.resourceId?.userDto?.roles[0]?.name);
    let resourceIDs = activeRoles(data);

    if ((resourceIDs?.includes(id) || activeRole === "ADMIN")) {
        //not disabled
        return true;
    } else {
        //disabled
        return false;
    }
};
