import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    DEVICE_GET_BY_ID: `${STATE_REDUCER_KEY}/DEVICE_GET_BY_ID`,
    DEVICE_GET_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DEVICE_GET_BY_ID_REQUEST`,
    DEVICE_GET_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DEVICE_GET_BY_ID_SUCCESS`,
    DEVICE_GET_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DEVICE_GET_BY_ID_FAILURE`,

    DELETE_DEVICE: `${STATE_REDUCER_KEY}/DELETE_DEVICE`,
    DELETE_DEVICE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DEVICE_REQUEST`,
    DELETE_DEVICE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DEVICE_SUCCESS`,
    DELETE_DEVICE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DEVICE_FAILURE`,

    LIST_DEVICE: `${STATE_REDUCER_KEY}/LIST_DEVICE`,
    LIST_DEVICE_REQUEST: `${STATE_REDUCER_KEY}/LIST_DEVICE_REQUEST`,
    LIST_DEVICE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DEVICE_SUCCESS`,
    LIST_DEVICE_FAILURE: `${STATE_REDUCER_KEY}/LIST_DEVICE_FAILURE`,

    SELECT_DEVICE_MODE: `${STATE_REDUCER_KEY}/SELECT_DEVICE_MODE`,
    SELECT_DEVICE_MODE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DEVICE_MODE_REQUEST`,
    SELECT_DEVICE_MODE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DEVICE_MODE_SUCCESS`,
    SELECT_DEVICE_MODE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DEVICE_MODE_FAILURE`,

    SELECT_DEVICE_TYPE: `${STATE_REDUCER_KEY}/SELECT_DEVICE_TYPE`,
    SELECT_DEVICE_TYPE_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DEVICE_TYPE_REQUEST`,
    SELECT_DEVICE_TYPE_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DEVICE_TYPE_SUCCESS`,
    SELECT_DEVICE_TYPE_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DEVICE_TYPE_FAILURE`,

    SELECT_DEVICE_PROTOCOL: `${STATE_REDUCER_KEY}/SELECT_DEVICE_PROTOCOL`,
    SELECT_DEVICE_PROTOCOL_REQUEST: `${STATE_REDUCER_KEY}/SELECT_DEVICE_PROTOCOL_REQUEST`,
    SELECT_DEVICE_PROTOCOL_SUCCESS: `${STATE_REDUCER_KEY}/SELECT_DEVICE_PROTOCOL_SUCCESS`,
    SELECT_DEVICE_PROTOCOL_FAILURE: `${STATE_REDUCER_KEY}/SELECT_DEVICE_PROTOCOL_FAILURE`,

    POST_DEVICE_DETAILS: `${STATE_REDUCER_KEY}/POST_DEVICE_DETAILS`,
    POST_DEVICE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_DEVICE_DETAILS_REQUEST`,
    POST_DEVICE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_DEVICE_DETAILS_SUCCESS`,
    POST_DEVICE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_DEVICE_DETAILS_FAILURE`,

    UPDATE_DEVICE_DETAILS: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_DETAILS`,
    UPDATE_DEVICE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_DETAILS_REQUEST`,
    UPDATE_DEVICE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_DETAILS_SUCCESS`,
    UPDATE_DEVICE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_DETAILS_FAILURE`,

    LIST_DEVICE_ALL: `${STATE_REDUCER_KEY}/LIST_DEVICE_ALL`,
    LIST_DEVICE_ALL_REQUEST: `${STATE_REDUCER_KEY}/LIST_DEVICE_ALL_REQUEST`,
    LIST_DEVICE_ALL_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DEVICE_ALL_SUCCESS`,
    LIST_DEVICE_ALL_FAILURE: `${STATE_REDUCER_KEY}/LIST_DEVICE_ALL_FAILURE`,

    LIST_CUSTOMIZED: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED`,
    LIST_CUSTOMIZED_REQUEST: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED_REQUEST`,
    LIST_CUSTOMIZED_SUCCESS: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED_SUCCESS`,
    LIST_CUSTOMIZED_FAILURE: `${STATE_REDUCER_KEY}/LIST_CUSTOMIZED_FAILURE`,

    LIST_DECODE_DEVICE: `${STATE_REDUCER_KEY}/LIST_DECODE_DEVICE`,
    LIST_DECODE_DEVICE_REQUEST: `${STATE_REDUCER_KEY}/LIST_DECODE_DEVICE_REQUEST`,
    LIST_DECODE_DEVICE_SUCCESS: `${STATE_REDUCER_KEY}/LIST_DECODE_DEVICE_SUCCESS`,
    LIST_DECODE_DEVICE_FAILURE: `${STATE_REDUCER_KEY}/LIST_DECODE_DEVICE_FAILURE`,

    POST_DEVICE_GROUP_DETAILS: `${STATE_REDUCER_KEY}/POST_DEVICE_GROUP_DETAILS`,
    POST_DEVICE_GROUP_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/POST_DEVICE_GROUP_DETAILS_REQUEST`,
    POST_DEVICE_GROUP_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/POST_DEVICE_GROUP_DETAILS_SUCCESS`,
    POST_DEVICE_GROUP_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/POST_DEVICE_GROUP_DETAILS_FAILURE`,

    UPDATE_DEVICE_GROUP_DETAILS: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_GROUP_DETAILS`,
    UPDATE_DEVICE_GROUP_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_GROUP_DETAILS_REQUEST`,
    UPDATE_DEVICE_GROUP_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_GROUP_DETAILS_SUCCESS`,
    UPDATE_DEVICE_GROUP_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/UPDATE_DEVICE_GROUP_DETAILS_FAILURE`,

    DEVICE_GROUP_GET_BY_ID: `${STATE_REDUCER_KEY}/DEVICE_GROUP_GET_BY_ID`,
    DEVICE_GROUP_GET_BY_ID_REQUEST: `${STATE_REDUCER_KEY}/DEVICE_GROUP_GET_BY_ID_REQUEST`,
    DEVICE_GROUP_GET_BY_ID_SUCCESS: `${STATE_REDUCER_KEY}/DEVICE_GROUP_GET_BY_ID_SUCCESS`,
    DEVICE_GROUP_GET_BY_ID_FAILURE: `${STATE_REDUCER_KEY}/DEVICE_GROUP_GET_BY_ID_FAILURE`,

    IMPORT_GROUP: `${STATE_REDUCER_KEY}/IMPORT_GROUP`,
    IMPORT_GROUP_REQUEST: `${STATE_REDUCER_KEY}/IMPORT_GROUP_REQUEST`,
    IMPORT_GROUP_SUCCESS: `${STATE_REDUCER_KEY}/IMPORT_GROUP_SUCCESS`,
    IMPORT_GROUP_FAILURE: `${STATE_REDUCER_KEY}/IMPORT_GROUP_FAILURE`,

    EXPORT_GROUP: `${STATE_REDUCER_KEY}/EXPORT_GROUP`,
    EXPORT_GROUP_REQUEST: `${STATE_REDUCER_KEY}/EXPORT_GROUP_REQUEST`,
    EXPORT_GROUP_SUCCESS: `${STATE_REDUCER_KEY}/EXPORT_GROUP_SUCCESS`,
    EXPORT_GROUP_FAILURE: `${STATE_REDUCER_KEY}/EXPORT_GROUP_FAILURE`,

    MOVE_UPWARD: `${STATE_REDUCER_KEY}/MOVE_UPWARD`,
    MOVE_UPWARD_REQUEST: `${STATE_REDUCER_KEY}/MOVE_UPWARD_REQUEST`,
    MOVE_UPWARD_SUCCESS: `${STATE_REDUCER_KEY}/MOVE_UPWARD_SUCCESS`,
    MOVE_UPWARD_FAILURE: `${STATE_REDUCER_KEY}/MOVE_UPWARD_FAILURE`,

    MOVE_DOWN: `${STATE_REDUCER_KEY}/MOVE_DOWN`,
    MOVE_DOWN_REQUEST: `${STATE_REDUCER_KEY}/MOVE_DOWN_REQUEST`,
    MOVE_DOWN_SUCCESS: `${STATE_REDUCER_KEY}/MOVE_DOWN_SUCCESS`,
    MOVE_DOWN_FAILURE: `${STATE_REDUCER_KEY}/MOVE_DOWN_FAILURE`,

    DELETE_GROUP: `${STATE_REDUCER_KEY}/DELETE_GROUP`,
    DELETE_GROUP_REQUEST: `${STATE_REDUCER_KEY}/DELETE_GROUP_REQUEST`,
    DELETE_GROUP_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_GROUP_SUCCESS`,
    DELETE_GROUP_FAILURE: `${STATE_REDUCER_KEY}/DELETE_GROUP_FAILURE`,

    AUTO_SEARCH_LIST: `${STATE_REDUCER_KEY}/AUTO_SEARCH_LIST`,
    AUTO_SEARCH_LIST_REQUEST: `${STATE_REDUCER_KEY}/AUTO_SEARCH_LIST_REQUEST`,
    AUTO_SEARCH_LIST_SUCCESS: `${STATE_REDUCER_KEY}/AUTO_SEARCH_LIST_SUCCESS`,
    AUTO_SEARCH_LIST_FAILURE: `${STATE_REDUCER_KEY}/AUTO_SEARCH_LIST_FAILURE`,

    DELETE_DECODE_DEVICE: `${STATE_REDUCER_KEY}/DELETE_DECODE_DEVICE`,
    DELETE_DECODE_DEVICE_REQUEST: `${STATE_REDUCER_KEY}/DELETE_DECODE_DEVICE_REQUEST`,
    DELETE_DECODE_DEVICE_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_DECODE_DEVICE_SUCCESS`,
    DELETE_DECODE_DEVICE_FAILURE: `${STATE_REDUCER_KEY}/DELETE_DECODE_DEVICE_FAILURE`,

    COUNT_ONLINE_OFFLINE_DEVICES: `${STATE_REDUCER_KEY}/COUNT_ONLINE_OFFLINE_DEVICES`,
    COUNT_ONLINE_OFFLINE_DEVICES_REQUEST: `${STATE_REDUCER_KEY}/COUNT_ONLINE_OFFLINE_DEVICES_REQUEST`,
    COUNT_ONLINE_OFFLINE_DEVICES_SUCCESS: `${STATE_REDUCER_KEY}/COUNT_ONLINE_OFFLINE_DEVICES_SUCCESS`,
    COUNT_ONLINE_OFFLINE_DEVICES_FAILURE: `${STATE_REDUCER_KEY}/COUNT_ONLINE_OFFLINE_DEVICES_FAILURE`,

    DEVICE_ADD_VIA_AUTO_SEARCH: `${STATE_REDUCER_KEY}/DEVICE_ADD_VIA_AUTO_SEARCH`,
    DEVICE_ADD_VIA_AUTO_SEARCH_REQUEST: `${STATE_REDUCER_KEY}/DEVICE_ADD_VIA_AUTO_SEARCH_REQUEST`,
    DEVICE_ADD_VIA_AUTO_SEARCH_SUCCESS: `${STATE_REDUCER_KEY}/DEVICE_ADD_VIA_AUTO_SEARCH_SUCCESS`,
    DEVICE_ADD_VIA_AUTO_SEARCH_FAILURE: `${STATE_REDUCER_KEY}/DEVICE_ADD_VIA_AUTO_SEARCH_FAILURE`,

    LIVE_STATUS_BULK_DEVICE_ADDING: `${STATE_REDUCER_KEY}/LIVE_STATUS_BULK_DEVICE_ADDING`,
    LIVE_STATUS_BULK_DEVICE_ADDING_REQUEST: `${STATE_REDUCER_KEY}/LIVE_STATUS_BULK_DEVICE_ADDING_REQUEST`,
    LIVE_STATUS_BULK_DEVICE_ADDING_SUCCESS: `${STATE_REDUCER_KEY}/LIVE_STATUS_BULK_DEVICE_ADDING_SUCCESS`,
    LIVE_STATUS_BULK_DEVICE_ADDING_FAILURE: `${STATE_REDUCER_KEY}/LIVE_STATUS_BULK_DEVICE_ADDING_FAILURE`

};

export const getDeviceById = createAction(ACTION_TYPES.DEVICE_GET_BY_ID);
export const deleteDevice = createAction(ACTION_TYPES.DELETE_DEVICE);
export const deleteDecodeDevice = createAction(ACTION_TYPES.DELETE_DECODE_DEVICE);
export const listDevice = createAction(ACTION_TYPES.LIST_DEVICE);
export const selectDeviceMode = createAction(ACTION_TYPES.SELECT_DEVICE_MODE);
export const selectDeviceType = createAction(ACTION_TYPES.SELECT_DEVICE_TYPE);
export const selectDeviceProtocol = createAction(ACTION_TYPES.SELECT_DEVICE_PROTOCOL);
export const submitDeviceDetails = createAction(ACTION_TYPES.POST_DEVICE_DETAILS);
export const updateDeviceDetails = createAction(ACTION_TYPES.UPDATE_DEVICE_DETAILS);
export const fetchDeviceList = createAction(ACTION_TYPES.LIST_DEVICE_ALL);
export const listCustomized = createAction(ACTION_TYPES.LIST_CUSTOMIZED);
export const listDecodeDevice = createAction(ACTION_TYPES.LIST_DECODE_DEVICE);
export const submitDeviceGroupDetails = createAction(ACTION_TYPES.POST_DEVICE_GROUP_DETAILS);
export const updateDeviceGroupDetails = createAction(ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS);
export const getDeviceGroupById = createAction(ACTION_TYPES.DEVICE_GROUP_GET_BY_ID);
export const importGroup = createAction(ACTION_TYPES.IMPORT_GROUP);
export const exportGroup = createAction(ACTION_TYPES.EXPORT_GROUP);
export const moveUpward = createAction(ACTION_TYPES.MOVE_UPWARD);
export const moveDown = createAction(ACTION_TYPES.MOVE_DOWN);
export const deleteGroup = createAction(ACTION_TYPES.DELETE_GROUP);
export const getAutoSearchList = createAction(ACTION_TYPES.AUTO_SEARCH_LIST);
export const getCountOnlineOfflineDevices = createAction(ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES);
export const deviceAddViaAutoSearch = createAction(ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH);
export const liveStatusBulkAdd = createAction(ACTION_TYPES.LIVE_STATUS_BULK_DEVICE_ADDING);
