
import React, { useState } from "react";
import { Box, Button, Card, Grid, InputLabel, Stack, Typography } from "@mui/material";
import { useStyles } from "./style";
import { ErrorMessage, Field } from "formik";
import _ from "lodash";
import TextError from "./TextError";
import { useSelector } from "react-redux";
import { STATE_REDUCER_KEY } from "../../../../modules/common/constants";
import { Icons } from "../../material/Icons";

const { Close } = Icons;

const CustomMultiFileUpload = ({ isMandatory = false, label = "", mode = "", fileLength = 5, name = "attachmentIds", disabled = false }) => {
    const [files, setFiles] = useState([]);
    const themes = useSelector((state) => state[STATE_REDUCER_KEY].switch.data);

    const [previews, setPreviews] = useState([]);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [fileIds, setFileIds] = useState([]);

    // Handle file selection
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);

        // Generate file previews
        const filePreviews = selectedFiles.map((file) =>
            URL.createObjectURL(file)
        );
        setPreviews(filePreviews);
    };

    // Handle deleting a file
    const deleteImage = (index) => {
        const updatedFiles = [...files];
        const updatedPreviews = [...previews];
        updatedFiles.splice(index, 1);
        updatedPreviews.splice(index, 1);

        setFiles(updatedFiles);
        setPreviews(updatedPreviews);
    };

    const loadFiles = (form) => {
        if (loading && form?.field?.value && !_.isEqual(_.sortBy(fileIds), _.sortBy(form?.field?.value))) {
            setFileIds(form.field.value);
            setLoading(false);
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <InputLabel className={classes.label}>
                {label} {isMandatory && <span style={{ color: "red", fontSize: "14px" }}> *</span>}
            </InputLabel>
            <Field as="select" name={name}>
                {(form) => {
                    if (["pdf", "jpeg", "png", "xls", "jpg"].includes(mode)) {
                        return (
                            <Card sx={{ backgroundColor: "transparent", width: "100%", p: "5px", display: "flex", alignItems: "center", boxShadow: "none", border: "1px solid #CFE7DE", overflow: "auto", borderRadius: "10px", justifyContent: "center" }} >
                                <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                                    <Stack direction={{ sm: "row" }} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="center">
                                        {loadFiles(form)}
                                    </Stack>
                                </Box>
                                <Stack direction="row">
                                    <Grid sx={{ p: "32px" }}>
                                        {previews.map((preview, index) => (
                                            <div key={index} style={{ position: "relative", display: "inline-block" }}>
                                                <div style={{ cursor: "pointer", display: "flex", justifyContent: "end" }} onClick={() => deleteImage(index)} >
                                                    <Close sx={{ color: themes === "darkTheme" ? "#fff" : "#000" }} />
                                                </div>
                                                <img src={preview} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                                            </div>
                                        ))}
                                        {!disabled && files.length < fileLength && previews.length < 1 && (
                                            <div
                                                htmlFor={name}
                                                style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
                                            >
                                                <Button sx={{ minWidth: "40px", borderRadius: "5px", backgroundColor: themes === "darkTheme" ? "" : "#000", border: "solid 1px #CFE7DE", color: themes === "darkTheme" ? "#E4002B" : "#FFF", "&:hover": { backgroundColor: themes === "darkTheme" ? "" : "#000", color: "#E4002B" } }} component="label" > +
                                                    <input id={name} type="file" accept=".jpg" style={{ display: "none" }} onChange={(event) => handleFileChange(event, form.form)} />
                                                </Button>
                                                <Typography sx={{ color: "label.main" }}>Add Picture</Typography>
                                            </div>
                                        )}
                                    </Grid>
                                </Stack>
                            </Card>
                        );
                    }
                }}
            </Field>
            <ErrorMessage component={TextError} name={name} />
        </div>
    );
};

export default CustomMultiFileUpload;
