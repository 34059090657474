import { all, call, fork, select, takeLatest } from "redux-saga/effects";

import { TABLE_IDS } from "../../../common/constants";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { getTableProps } from "../../common/selectors";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";

export function* listOperationLogs({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.OPERATION_LOGS]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listOperationLogs, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_OPERATION_LOGS_SUCCESS, ACTION_TYPES.LIST_OPERATION_LOGS_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.OPERATION_LOGS);
}

export function* selectUsername(action) {
    yield call(handleAPIRequest, Api.selectUsername, action.payload);
}

export function* selectLogType(action) {
    yield call(handleAPIRequest, Api.selectLogType, action.payload);
}

export function* selectDetailedType({ payload }) {
    yield call(handleAPIRequest, Api.selectDetailedType, payload);
}

export function* filterDescription({ payload }) {
    yield call(handleAPIRequest, Api.filterDescription, payload);
}

export default function* operationLogSaga() {
    yield all([
        takeLatest(ACTION_TYPES.LIST_OPERATION_LOGS, listOperationLogs),
        takeLatest(ACTION_TYPES.SELECT_USERNAME, selectUsername),
        takeLatest(ACTION_TYPES.SELECT_LOG_TYPE, selectLogType),
        takeLatest(ACTION_TYPES.SELECT_DETAILED_TYPE, selectDetailedType),
        takeLatest(ACTION_TYPES.FILTER_DESCRIPTION, filterDescription)
    ]);
}
