export const STATE_REDUCER_KEY = "common";

export const COMMON_TABLE_PAGINATION = { pageNo: 0, totalRecords: 0, pageSize: 5, totalPages: 1 };

export const COMMON_TABLE_INITIAL_STATE = {
    requestInProgress: false,
    data: [],
    pagination: COMMON_TABLE_PAGINATION,
    filters: {}
};

export const DEFAULT_TABLE_ID = "DEFAULT_TABLE";
