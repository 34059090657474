import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { STATE_REDUCER_KEY } from "./constants";
import { ACTION_TYPES } from "./actions";
import { getPayloadContent } from "../../../utils/apiUtils";

const RESOURCE_MANAGEMENT_DETAILS = {
    name: "",
    description: "",
    parentResourceId: "",
    isParent: false,
    moduleId: "",
    groupType: "",
    showCheckBox: false
};

const initialState = {
    resourceManagementGetById: {
        requestInProgress: false,
        data: {
            ...RESOURCE_MANAGEMENT_DETAILS
        }
    },
    moduleIdDropdown: {
        requestInProgress: false,
        data: []
    },
    parentIdDropdown: {
        requestInProgress: false,
        data: []
    },
    resourceList: {
        requestInProgress: false,
        data: {}
    },
    count: 0
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clearAll: () => initialState,
        clear: (state) => {
            state.resourceManagementGetById = initialState.resourceManagementGetById;
        },
        setIsParent: (state) => {
            _.set(state, "resourceManagementGetById.data.isParent", !state.resourceManagementGetById.data.isParent);
        },
        setCount: (state) => {
            state.count = state.count + 1;
        },
        decrementCount: (state) => {
            state.count = state.count - 1;
        },
        setRowSelectionData: (state, action) => {
            state.resourceList.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID_REQUEST, (state) => {
                state.resourceManagementGetById.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID_SUCCESS, (state, action) => {
                let data = action.payload;
                _.set(data, "resourceId", data.id);
                _.set(state, "resourceManagementGetById.requestInProgress", false);
                _.set(state, "resourceManagementGetById.data", data);
            })
            .addCase(ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID_FAILURE, (state) => {
                state.resourceManagementGetById.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_MODULE_ID_REQUEST, (state) => {
                state.moduleIdDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_MODULE_ID_SUCCESS, (state, action) => {
                _.set(state, "moduleIdDropdown.requestInProgress", false);
                _.set(state, "moduleIdDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_MODULE_ID_FAILURE, (state) => {
                state.moduleIdDropdown.requestInProgress = false;
            })
            .addCase(ACTION_TYPES.SELECT_PARENT_ID_REQUEST, (state) => {
                state.parentIdDropdown.requestInProgress = true;
            })
            .addCase(ACTION_TYPES.SELECT_PARENT_ID_SUCCESS, (state, action) => {
                _.set(state, "parentIdDropdown.requestInProgress", false);
                _.set(state, "parentIdDropdown.data", getPayloadContent(action));
            })
            .addCase(ACTION_TYPES.SELECT_PARENT_ID_FAILURE, (state) => {
                state.parentIdDropdown.requestInProgress = false;
            });
    }
});
export const { actions, reducer } = slice;
