import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";

import { TABLE_IDS } from "../../../common/constants";
import { handleAPIRequest } from "../../../utils/http";
import { setCommonTableData } from "../../../utils/tableUtils";
import { getTableProps } from "../../common/selectors";
import { ACTION_TYPES } from "./actions";
import * as Api from "./api";
import { successNotify } from "../../../utils/notificationUtils";
import { I18n } from "../../../common/components";
import { actions as commonActions } from "../../common/slice";
import { actions as sliceActions } from "../device/slice";
// import { stopStreaming } from "../../live/liveView/actions";

export function* onlineOfflineDevices({ payload }) {
    yield call(handleAPIRequest, Api.countOnlineOfflineDevice, payload);
}

export function* listDevices({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.DEVICE_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listDeviceDetails, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_DEVICE_SUCCESS, ACTION_TYPES.LIST_DEVICE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.DEVICE_LIST);
    yield call(onlineOfflineDevices, {});
}

export function* listDecodeDevices({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.DECODE_DEVICE_LIST]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listDecodeDeviceDetails, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.LIST_DECODE_DEVICE_SUCCESS, ACTION_TYPES.LIST_DECODE_DEVICE_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.DECODE_DEVICE_LIST);
    yield call(onlineOfflineDevices, {});
}

export function* addModeDropDown(action) {
    yield call(handleAPIRequest, Api.addModeDropDown, action?.payload);
}

export function* deviceTypeDropDown(action) {
    yield call(handleAPIRequest, Api.deviceTypeDropDown, action?.payload);
}

export function* deviceProtocolDropDown(action) {
    yield call(handleAPIRequest, Api.deviceProtocolDropDown, action?.payload);
}

export function* submitDeviceDetailsForm({ payload }) {
    const filteredData = Object.keys(payload).reduce((acc, key) => {
        const value = payload[key];
        // Check if the value is not null, not undefined, not an empty string, and not NaN
        if (value !== null && value !== undefined && value !== "" && !Number.isNaN(value)) {
            acc[key] = value;
        }
        return acc;
    }, {});
    yield fork(handleAPIRequest, Api.submitDeviceDetails, filteredData);
    const responseAction = yield take([ACTION_TYPES.POST_DEVICE_DETAILS_SUCCESS, ACTION_TYPES.POST_DEVICE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_DEVICE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("device") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listDevices, { payload: tablePayload });
        yield call(listDecodeDevices, { payload: tablePayload });
        yield put(sliceActions.clear());
        yield put(commonActions.setOpenModal(false));
    }
}

export function* updateDeviceDetailsForm({ payload }) {
    const { setOpenEditModal } = payload;
    yield fork(handleAPIRequest, Api.updateDeviceDetails, payload.data);
    const responseAction = yield take([ACTION_TYPES.UPDATE_DEVICE_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_DEVICE_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_DEVICE_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("device") }) }));
        if (setOpenEditModal !== undefined) {
            setOpenEditModal(false);
        }
        setOpenEditModal(false);
        yield put(commonActions.setRowSelection({ key: TABLE_IDS.DEVICE_LIST }));
        yield put(commonActions.setRowSelection({ key: TABLE_IDS.DECODE_DEVICE_LIST }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listDevices, { payload: tablePayload });
        yield call(listDecodeDevices, { payload: tablePayload });
    }
}

export function* deviceDetailsById({ payload: id }) {
    yield call(handleAPIRequest, Api.deviceDetailsById, id);
}

export function* deleteDeviceDetails({ payload }) {
    const { setOpenStatus, id } = payload;
    yield fork(handleAPIRequest, Api.deleteDeviceDetails, id);
    const response = yield take([ACTION_TYPES.DELETE_DEVICE_SUCCESS, ACTION_TYPES.DELETE_DEVICE_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_DEVICE_SUCCESS) {
        // let updatedMappingData = yield select(getUpdateMapping);
        // let newUpdatedArray = [...updatedMappingData];
        // let slotList = [];
        // for (let i = 0; i < updatedMappingData?.length; i++) {
        //     if (id?.includes(updatedMappingData[i].streamUrl.deviceId)) {
        //         yield slotList = [...slotList, updatedMappingData[i].streamUrl?.slotId];
        //     }
        // }
        // if (slotList?.length > 0) {
        //     yield put(stopStreaming(slotList));
        //     slotList = [];
        // }
        // newUpdatedArray = newUpdatedArray.map((data) => {
        //     if (id?.includes(data?.streamUrl?.deviceId)) {
        //         return { ...data, streamUrl: "" };
        //     }
        //     return { ...data };
        // });
        // yield put(commonActions.setUpdatedMapping(newUpdatedArray));
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("device") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listDevices, { payload: tablePayload });
        setOpenStatus(false);
    }
}

export function* deleteDecodeDeviceDetails({ payload }) {
    const { setOpenStatus, id } = payload;
    yield fork(handleAPIRequest, Api.deleteDecodeDeviceDetails, id);
    const response = yield take([ACTION_TYPES.DELETE_DECODE_DEVICE_SUCCESS, ACTION_TYPES.DELETE_DECODE_DEVICE_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_DECODE_DEVICE_SUCCESS) {
        // let updatedMappingData = yield select(getUpdateMapping);
        // let newUpdatedArray = [...updatedMappingData];
        // let slotList = [];
        // for (let i = 0; i < updatedMappingData?.length; i++) {
        //     if (id?.includes(updatedMappingData[i]?.streamUrl?.deviceId)) {
        //         yield slotList = [...slotList, updatedMappingData[i]?.streamUrl?.slotId];
        //     }
        // }
        // if (slotList?.length > 0) {
        //     yield put(stopStreaming(slotList));
        //     slotList = [];
        // }
        // newUpdatedArray = newUpdatedArray.map((data) => {
        //     if (id?.includes(data?.streamUrl?.deviceId)) {
        //         return { ...data, streamUrl: "" };
        //     }
        //     return { ...data };
        // });
        // yield put(commonActions.setUpdatedMapping(newUpdatedArray));
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("device") }) }));
        yield put(commonActions.setPagination({ pagination: { pageNo: 0 } }));
        let tablePayload = { pageNo: 0, pageSize: 5 };
        yield call(listDecodeDevices, { payload: tablePayload });
        setOpenStatus(false);
    }
}

export function* listCustomized({ payload }) {
    yield call(handleAPIRequest, Api.listCustomizedDetails, payload);
}

export function* listDeviceAll({ payload }) {
    yield call(handleAPIRequest, Api.listDeviceAll, payload);
}

export function* submitDeviceGroupDetailsForm({ payload }) {
    yield fork(handleAPIRequest, Api.submitDeviceGroupDetails, payload);
    const responseAction = yield take([ACTION_TYPES.POST_DEVICE_GROUP_DETAILS_SUCCESS, ACTION_TYPES.POST_DEVICE_GROUP_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.POST_DEVICE_GROUP_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("device_group") }) }));
        yield call(listCustomized, {});
        yield call(listDeviceAll, {});
        yield put(commonActions.setOpenModal(false));
    }
}

export function* updateDeviceGroupDetailsForm({ payload }) {
    const { setOpen } = payload;
    yield fork(handleAPIRequest, Api.updateDeviceGroupDetails, payload.data);
    const responseAction = yield take([ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS_SUCCESS, ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS_FAILURE]);
    if (responseAction.type === ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("created", { type: I18n("device_group") }) }));
        yield call(listCustomized, {});
        yield call(listDeviceAll, {});
        setOpen(false);
    }
}
const formatImportValues = (values = {}) => {
    const groupId = values?.checked; // Assuming checked represents the group ID
    const importOrExportChannels = [];
    if (values?.checkedDevicesIds && values?.checkedDevicesIds?.length > 0) {
        values?.checkedDevicesIds?.forEach(device => {
            importOrExportChannels.push({
                id: device?.id,
                name: device?.name,
                deviceChannel: device?.deviceChannel
            });
        });
    }
    const formattedPayload = {
        importOperation: true,
        groupId: groupId,
        importOrExportChannels: importOrExportChannels
    };
    return formattedPayload;
};

const formatExportValues = (checkedDevicesIds = {}) => {
    const formattedPayload = {
        importOperation: false,
        importOrExportChannels: checkedDevicesIds ? checkedDevicesIds?.map(device => ({
            id: device?.id,
            name: device?.name,
            deviceChannel: device?.deviceChannel?.map(channel => ({
                id: channel?.id,
                name: channel?.name,
                deviceGroup: channel?.deviceGroup
            }))
        })) : [{}] // Ensuring importOrExportChannels is always an array with at least one object
    };
    return formattedPayload;
};


export function* importGroupDetails({ payload }) {
    yield fork(handleAPIRequest, Api.importGroupDetails, formatImportValues(payload));
    const responseAction = yield take([ACTION_TYPES.IMPORT_GROUP_SUCCESS, ACTION_TYPES.IMPORT_GROUP_FAILURE]);
    if (responseAction.type === ACTION_TYPES.IMPORT_GROUP_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("imported") }));
        yield call(listCustomized, {});
        yield call(listDeviceAll, {});
    }
}

export function* exportGroupDetails({ payload }) {
    let { checkedDevicesIds, setDisableExport } = payload;
    yield fork(handleAPIRequest, Api.exportGroupDetails, formatExportValues(checkedDevicesIds));
    const responseAction = yield take([ACTION_TYPES.EXPORT_GROUP_SUCCESS, ACTION_TYPES.EXPORT_GROUP_FAILURE]);
    if (responseAction.type === ACTION_TYPES.EXPORT_GROUP_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("exported") }));
        yield call(listCustomized, {});
        yield call(listDeviceAll, {});
        setDisableExport(true);
    }
}

export function* moveUpwardGroupDetails({ payload }) {
    const id = payload?.checked;
    yield fork(handleAPIRequest, Api.moveUpwardGroupDetails, id);
    const responseAction = yield take([ACTION_TYPES.MOVE_UPWARD_SUCCESS, ACTION_TYPES.MOVE_UPWARD_FAILURE]);
    if (responseAction.type === ACTION_TYPES.MOVE_UPWARD_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("device_group") }) }));
        yield call(listCustomized, {});
        yield call(listDeviceAll, {});
    }
}

export function* moveDownGroupDetails({ payload }) {
    const id = payload?.checked;
    yield fork(handleAPIRequest, Api.moveDownGroupDetails, id);
    const responseAction = yield take([ACTION_TYPES.MOVE_DOWN_SUCCESS, ACTION_TYPES.MOVE_DOWN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.MOVE_DOWN_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("updated", { type: I18n("device_group") }) }));
        yield call(listCustomized, {});
        yield call(listDeviceAll, {});
    }
}

export function* deviceGroupDetailsById({ payload: id }) {
    yield call(handleAPIRequest, Api.deviceGroupDetailsById, id);
}

export function* deleteGroupDetails({ payload: id }) {
    yield fork(handleAPIRequest, Api.deleteGroupDetails, id);
    const response = yield take([ACTION_TYPES.DELETE_GROUP_SUCCESS, ACTION_TYPES.DELETE_GROUP_FAILURE]);
    if (response.type === ACTION_TYPES.DELETE_GROUP_SUCCESS) {
        yield put(successNotify({ title: I18n("success"), message: I18n("delete_success", { type: I18n("group") }) }));
        yield call(listCustomized, {});
        yield call(listDeviceAll, {});
    }
}

export function* listAutoSearch({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.AUTO_SEARCH]: { filters = {} } = {} } = tableProps;
    yield fork(handleAPIRequest, Api.listAutoSearchData, { ...filters, ...payload });
    const COMPARISON_TYPES = [ACTION_TYPES.AUTO_SEARCH_LIST_SUCCESS, ACTION_TYPES.AUTO_SEARCH_LIST_FAILURE];
    yield* setCommonTableData(COMPARISON_TYPES, TABLE_IDS.AUTO_SEARCH);
}

export function* autoSearchDeviceAdd({ payload }) {
    let updatedPayload = { ...payload?.values, autoSearchDevices: payload?.data };
    yield put(commonActions.setDeviceBulkAdd(payload?.data));
    yield fork(handleAPIRequest, Api.deviceAddViaAutoSearchApi, updatedPayload);
    // const responseAction = yield take([ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH_SUCCESS, ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH_FAILURE]);
    // if (responseAction.type === ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH_SUCCESS) {
    //     yield put(successNotify({ title: I18n("success"), message: I18n("devices_added") }));
    // }
}
//function to update the live device bulk add status in table
export function* liveStatusBulkAdd({ payload }) {
    let tableProps = yield select(getTableProps);
    let { [TABLE_IDS.AUTO_SEARCH]: { data = [] } = {} } = tableProps;
    let updatedData = data?.map((d) => {
        if (d?.id === payload?.payload?.id) {
            return { ...d, deviceAdded: payload?.payload?.deviceAdded };
        }
        return d;
    });
    yield put(commonActions.setTableData({ key: TABLE_IDS.AUTO_SEARCH, data: updatedData }));
    yield call(onlineOfflineDevices, {});
}

export default function* deviceSaga() {
    yield all([
        takeLatest(ACTION_TYPES.LIST_DEVICE, listDevices),
        takeLatest(ACTION_TYPES.SELECT_DEVICE_MODE, addModeDropDown),
        takeLatest(ACTION_TYPES.SELECT_DEVICE_TYPE, deviceTypeDropDown),
        takeLatest(ACTION_TYPES.SELECT_DEVICE_PROTOCOL, deviceProtocolDropDown),
        takeLatest(ACTION_TYPES.POST_DEVICE_DETAILS, submitDeviceDetailsForm),
        takeLatest(ACTION_TYPES.UPDATE_DEVICE_DETAILS, updateDeviceDetailsForm),
        takeLatest(ACTION_TYPES.DEVICE_GET_BY_ID, deviceDetailsById),
        takeLatest(ACTION_TYPES.DELETE_DEVICE, deleteDeviceDetails),
        takeLatest(ACTION_TYPES.LIST_DECODE_DEVICE, listDecodeDevices),
        takeLatest(ACTION_TYPES.LIST_CUSTOMIZED, listCustomized),
        takeLatest(ACTION_TYPES.POST_DEVICE_GROUP_DETAILS, submitDeviceGroupDetailsForm),
        takeLatest(ACTION_TYPES.UPDATE_DEVICE_GROUP_DETAILS, updateDeviceGroupDetailsForm),
        takeLatest(ACTION_TYPES.LIST_DEVICE_ALL, listDeviceAll),
        takeLatest(ACTION_TYPES.IMPORT_GROUP, importGroupDetails),
        takeLatest(ACTION_TYPES.EXPORT_GROUP, exportGroupDetails),
        takeLatest(ACTION_TYPES.MOVE_UPWARD, moveUpwardGroupDetails),
        takeLatest(ACTION_TYPES.MOVE_DOWN, moveDownGroupDetails),
        takeLatest(ACTION_TYPES.DEVICE_GROUP_GET_BY_ID, deviceGroupDetailsById),
        takeLatest(ACTION_TYPES.DELETE_GROUP, deleteGroupDetails),
        takeLatest(ACTION_TYPES.AUTO_SEARCH_LIST, listAutoSearch),
        takeLatest(ACTION_TYPES.DELETE_DECODE_DEVICE, deleteDecodeDeviceDetails),
        takeLatest(ACTION_TYPES.COUNT_ONLINE_OFFLINE_DEVICES, onlineOfflineDevices),
        takeLatest(ACTION_TYPES.DEVICE_ADD_VIA_AUTO_SEARCH, autoSearchDeviceAdd),
        takeLatest(ACTION_TYPES.LIVE_STATUS_BULK_DEVICE_ADDING, liveStatusBulkAdd)
    ]);
}
