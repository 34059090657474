

//  The function takes in a list of active roles and returns a flattened list of resource actions and
//  resource IDs associated with those roles.
//  @param activeRoleResourceList - The `activeRoleResourceList` parameter is an array of objects that
//  represent active roles and their associated resources and actions. Each object in the array has the
//  following properties:
//  @returns The function `activeRoles` returns an array of resource actions and resource IDs extracted
//  from the `activeRoleResourceList` parameter. The function uses the `flatMap` method to iterate over
//  each item in the `activeRoleResourceList` array and extract the `resourceActions` and `resourceId`
//  properties. If `resourceActions` is defined, it is concatenated to the `resourceActions` array
export const activeRoles = (activeRoleResourceList) => {
    return activeRoleResourceList?.flatMap((item) => {
        let resourceActions = [];
        if (item?.resourceActions) {
            resourceActions = resourceActions.concat(item.resourceActions);
        }
        return resourceActions;
    });
};
