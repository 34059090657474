import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const listResourceDetails = (data) => ({
    url: API_URL.RESOURCE_MANAGEMENT.LIST,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.LIST_RESOURCE_MANAGEMENT_FAILURE],
        data
    }
});

export const submitResourceDetails = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.POST_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.POST_RESOURCE_MANAGEMENT_FAILURE],
            data
        }
    };
};

export const updateResourceDetails = (data) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.UPDATE_RESOURCE_MANAGEMENT_FAILURE],
            data
        }
    };
};

export const resourceDetailsById = (id) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID_REQUEST, ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID_SUCCESS, ACTION_TYPES.RESOURCE_MANAGEMENT_BY_ID_FAILURE]
        }
    };
};

export const deleteResourceDetails = (id) => {
    return {
        url: `${API_URL.RESOURCE_MANAGEMENT.DELETE}?resourceIds=${id}`,
        method: REQUEST_METHOD.DELETE,
        payload: {
            types: [ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_REQUEST, ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_SUCCESS, ACTION_TYPES.DELETE_RESOURCE_MANAGEMENT_FAILURE]
        }
    };
};

export const selecthModuleId = () => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.SELECT_MODULE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_MODULE_ID_REQUEST, ACTION_TYPES.SELECT_MODULE_ID_SUCCESS, ACTION_TYPES.SELECT_MODULE_ID_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const selectParent = ({ isParent, moduleId }) => {
    return {
        url: API_URL.RESOURCE_MANAGEMENT.SELECT_PARENT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_PARENT_ID_REQUEST, ACTION_TYPES.SELECT_PARENT_ID_SUCCESS, ACTION_TYPES.SELECT_PARENT_ID_FAILURE],
            data: { isParent, moduleId }
        }
    };
};
