export const STATE_REDUCER_KEY = "home";

export const HOME_FUNCTIONS = {
    COMMON_FUNCTIONS: "commonFunctions",
    FAVOURITES: "favourites"
};

export const SOCKET_TYPES = {
    OUT_DEVICE_ALIVENESS: "OUT_DEVICE_ALIVENESS",
    OUT_ADD_AUTO_SEARCH_DEVICES: "OUT_ADD_AUTO_SEARCH_DEVICES",
    OUT_LIVE_STREAM_SUCCESS: "OUT_LIVE_STREAM_SUCCESS",
    OUT_LIVE_STREAM_FAILED: "OUT_LIVE_STREAM_FAILED",
    OUT_CORE_ERROR: "OUT_CORE_ERROR",
    OUT_FILE_DOWNLOADED: "OUT_FILE_DOWNLOADED",
    OUT_LIVE_STREAM_RETRY_SUCCESS: "OUT_LIVE_STREAM_RETRY_SUCCESS",
    OUT_LIVE_STREAM_RETRY_FAILED: "OUT_LIVE_STREAM_RETRY_FAILED",
    OUT_SYSTEM_INFO: "OUT_SYSTEM_INFO",
    OUT_BACKUP_STATUS: "OUT_BACKUP_STATUS",
    OUT_RESTORE_STATUS: "OUT_RESTORE_STATUS",
    OUT_SYSTEM_RECORDING_STOPPED: "OUT_SYSTEM_RECORDING_STOPPED",
    OUT_SYSTEM_RECORDING_RESUMED: "OUT_SYSTEM_RECORDING_RESUMED"
};

export const SOCKET_ERROR_TYPES = {
    STREAMING: "STREAMING"
};

