const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  toolbarText: {
    fontSize: "14px",
    height: "23px"
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
    color: "label.main",
    fontFamily: "Noto Sans"
  },
  "@media (min-width: 900px)": {
    toolbarText: {
      fontSize: "14px",
      height: "33px"
    },
    label: {
      fontSize: "14px",
      color: "label.main",
      fontWeight: "400",
      fontFamily: "Noto Sans",
      marginBottom: "5px"
    }
  }
});

export const FORM_CONTROL_STYLE = { display: "flex", flexDirection: "column", color: "label.main", position: "relative", pb: { xs: 0, md: 0 } };
