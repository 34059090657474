export const USER_ROLE = {
    SUPER_ADMIN: "6c68c89f-cc73-4d98-a3be-760b31ce1e57"
};

export const RESOURCE_IDS = {
    HOME: {
        ROUTE: "d73159ac-06de-4ce3-8857-c62021966c60",
        COMMON_FUNCTIONS: {
            ROUTE: "143a1d3b-41ec-40f4-bf27-224515c93230",
            ACTIONS: {
                LIVE: {
                    ROUTE: "6fb5e253-82ba-4be5-a253-6dc24ad7cc0f",
                    ACTIONS: {
                        LOCAL_RECORDING: "80752419-a85d-4f9f-a5e9-4196a79b8611",
                        SNAPSHOT: "0fb50748-2da8-4d49-b39a-987ed39be3e5",
                        CONTEXT_MENU: "e732ff7f-2be5-40ab-bbe5-68e2d748da18",
                        TIME_CALIBRATION: "e96141a7-3c71-47c6-b814-1bf7f5941981",
                        DEVICE_CONFIG: "6975da61-dfd2-4311-93ff-593dd9384701",
                        CLEAR_ALARM: "f65fd082-04bb-46df-a609-03e9ce4716cf",
                        VIEW: {
                            ROUTE: "993e9d0c-ce65-4af8-810a-5c483bc70d49",
                            ACTIONS: {
                                CREATE: "52abf907-2663-435a-a837-c4b8447e33b0"
                            }
                        }
                    }
                },
                PLAY_BACK: {
                    ROUTE: "ac3a3434-9dfa-4056-ad55-202db2a577a6",
                    ACTIONS: {
                        DOWNLOAD: "b3733f74-7f01-4a41-b0df-03f6bace98e7"
                    }
                },
                DEVICE_ADMINISTRATION: {
                    ROUTE: "5b49f7e3-b821-4f65-9e54-75c72cf8d1c7",
                    ACTIONS: {
                        ADD: "3653ecac-6304-4ea5-a08e-9b2120d72ae4",
                        EDIT: "2518b28d-a0f9-48ae-802a-cdbd3f573755",
                        DELETE: "9416d3de-f010-4103-aeb2-193d4d7d1acb",
                        LIST: "36b4ddbb-6d99-4b31-9553-0fc9c0927163",
                        AUTO_SEARCH: "dd527412-b247-40de-b88b-1574bbe029b6",
                        GROUP: {
                            ROUTE: "d5997674-0ea0-426f-9522-c097d17ea98b",
                            ACTIONS: {
                                ADD: "372c8201-56d0-4904-a11d-c4559c962832",
                                EDIT: "61415dbe-2707-42d6-8fd8-b6e2a521c36a",
                                DELETE: "c376e78a-c376-41ed-a40c-e20fe66e5cae",
                                VIEW: "4f8b59fa-fa04-420d-8338-2166ca064696"
                            }
                        }
                    }
                },
                SYSTEM_SETUP: {
                    ROUTE: "4076a4d6-b3f4-4576-b7a0-3219097783d3",
                    ACTIONS: {
                        COMMON_SETTINGS: {
                            ROUTE: "7bd222c0-e29b-4937-a8d2-6f62cfc02435",
                            ACTIONS: {
                                VIEW: "a1891a3e-dd5d-4931-bd75-052a9f512ca0",
                                CONTROL: "914e6d55-db06-41fb-a3d9-5c7d4dc07a6f",
                                DATA_BACKUP: "7634ab49-081b-42cd-956c-87e940b47c88"
                            }
                        },
                        STORAGE_MANAGEMENT: {
                            ROUTE: "33ba2966-d3b4-4842-a61f-f9ea1351612e",
                            ACTIONS: {
                                VIEW: "844391e5-9cb4-4ac1-8365-f82f085d6bff",
                                CONTROL: "6911b055-dbde-4286-98b6-0bc48becac41"
                            }
                        },
                        RECORD_PLAN: {
                            ROUTE: "162a09de-e158-455c-a93b-373e4b6349ed",
                            ACTIONS: {
                                VIEW: "6a5d8ac7-c6b1-4990-a036-79ae0f42355f",
                                SCHEDULE_RECORDING: "165edd4c-dc56-4efc-9166-6b0a6efb5ab4"
                            }
                        },
                        VIDEO_SETTINGS: {
                            ROUTE: "3a0c7fd1-eb1e-47fb-b36f-9b59feffb426",
                            ACTIONS: {
                                VIEW: "2bc9765a-f541-4769-a641-95decd82e3cf",
                                CONTROL: "a12073d5-15a9-49d1-8ca1-8e4888441d2f"
                            }
                        },
                        ALARM_GRADE: {
                            ROUTE: "8acafe43-e7bd-4d2d-bc72-86b3bd86baa2",
                            ACTIONS: {
                                VIEW: "65214e7d-6d3b-4aad-9c02-5d98cabb1eca",
                                CONTROL: "1166e7d4-f647-463e-a4e0-10ceaee1e86b"
                            }
                        }
                    }
                },
                ELECTRONIC_MAP: {
                    ROUTE: "e064286a-a3ff-4794-8748-a171ef4cbab5",
                    ACTIONS: {
                        ADD: "d7e6fe8b-f3d6-4d55-9c3a-8497dc2adf7e",
                        VIEW: "4c7da8ce-fc02-4621-9324-90c0d44e290f",
                        EDIT: "f86f1b4e-dad0-4776-a351-ca99659e6e42"
                    }
                },
                ALARM_CONTROL: "382751c4-5b19-4389-859b-4397b78d7b45",
                TV_WALL: "95287594-9915-46c5-8e62-747520e4d046",
                ROLE_MANAGEMENT: "c50f28cc-466b-471c-b277-d97dd6b68fb8",
                RESOURCE_MANAGEMENT: "3682ab40-c80f-4fcb-afa1-5ef40177682c",
                USER_MANAGEMENT: {
                    ROUTE: "ee9d9603-f77c-406d-8282-7154eddcd7ea",
                    ACTIONS: {
                        ADD: "d1379060-792f-449d-b2ec-ffb0671ef836",
                        EDIT: "d1545eb8-80db-47d1-9db4-471cd35f4315",
                        DELETE: "860f8f98-e229-400f-a7d0-b27009e8fbe0",
                        LIST: "8e7f3c55-72f6-4a92-b388-9d970c072ebc"
                    }
                },
                OPERATION_LOGS: "994b91f2-24d4-4c94-9cc6-235bf1534b5f",
                ALARM_CONFIGURATION: "389501d4-b0ad-45c7-8710-a691249289bd"
            }
        },
        FAVOURITES: {
            ROUTE: "612ef269-c2e8-4794-ae32-c36e7617e728",
            ACTIONS: {}
        }
    }
};
export const RESOURCE_PERMISSIONS = {
    [RESOURCE_IDS.HOME.ROUTE]: [USER_ROLE.SUPER_ADMIN]
};
