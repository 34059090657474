import { REQUEST_METHOD } from "../../../common/constants";
import { API_URL } from "../apiUrls";
import { ACTION_TYPES } from "./actions";

export const submitCommonSettings = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_COMMON_SETTINGS_REQUEST, ACTION_TYPES.POST_COMMON_SETTINGS_SUCCESS, ACTION_TYPES.POST_COMMON_SETTINGS_FAILURE],
            data
        }
    };
};

export const updateCommonSettings = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_COMMON_SETTINGS_REQUEST, ACTION_TYPES.UPDATE_COMMON_SETTINGS_SUCCESS, ACTION_TYPES.UPDATE_COMMON_SETTINGS_FAILURE],
            data
        }
    };
};

export const commonSettingsListAll = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.COMMON_SETTINGS_LIST_ALL_REQUEST, ACTION_TYPES.COMMON_SETTINGS_LIST_ALL_SUCCESS, ACTION_TYPES.COMMON_SETTINGS_LIST_ALL_FAILURE],
            data
        }
    };
};

export const commonSettingsById = (id) => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.COMMON_SETTINGS_BY_ID_REQUEST, ACTION_TYPES.COMMON_SETTINGS_BY_ID_SUCCESS, ACTION_TYPES.COMMON_SETTINGS_BY_ID_FAILURE]
        }
    };
};

export const skinDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.SKIN,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_SKIN_REQUEST, ACTION_TYPES.SELECT_SKIN_SUCCESS, ACTION_TYPES.SELECT_SKIN_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const languageDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.LANGUAGE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_LANGUAGE_REQUEST, ACTION_TYPES.SELECT_LANGUAGE_SUCCESS, ACTION_TYPES.SELECT_LANGUAGE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const logDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.LOG,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_LOG_REQUEST, ACTION_TYPES.SELECT_LOG_SUCCESS, ACTION_TYPES.SELECT_LOG_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const submitVideoSettings = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_VIDEO_SETTINGS_REQUEST, ACTION_TYPES.POST_VIDEO_SETTINGS_SUCCESS, ACTION_TYPES.POST_VIDEO_SETTINGS_FAILURE],
            data
        }
    };
};

export const updateVideoSettings = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_VIDEO_SETTINGS_REQUEST, ACTION_TYPES.UPDATE_VIDEO_SETTINGS_SUCCESS, ACTION_TYPES.UPDATE_VIDEO_SETTINGS_FAILURE],
            data
        }
    };
};

export const videoSettingsListAll = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.VIDEO_SETTINGS_LIST_ALL_REQUEST, ACTION_TYPES.VIDEO_SETTINGS_LIST_ALL_SUCCESS, ACTION_TYPES.VIDEO_SETTINGS_LIST_ALL_FAILURE],
            data
        }
    };
};

export const videoSettingsById = (id) => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.VIDEO_SETTINGS_BY_ID_REQUEST, ACTION_TYPES.VIDEO_SETTINGS_BY_ID_SUCCESS, ACTION_TYPES.VIDEO_SETTINGS_BY_ID_FAILURE]
        }
    };
};

export const streamTypeDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.STREAM_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_STREAM_TYPE_REQUEST, ACTION_TYPES.SELECT_STREAM_TYPE_SUCCESS, ACTION_TYPES.SELECT_STREAM_TYPE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const decodingSettingDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.DECODING_SETTING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_DECODING_SETTING_REQUEST, ACTION_TYPES.SELECT_DECODING_SETTING_SUCCESS, ACTION_TYPES.SELECT_DECODING_SETTING_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const frameRateDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.FRAME_RATE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_FRAME_RATE_REQUEST, ACTION_TYPES.SELECT_FRAME_RATE_SUCCESS, ACTION_TYPES.SELECT_FRAME_RATE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const playBackTimeDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.PLAY_BACK_TIME,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_PLAY_BACK_TIME_REQUEST, ACTION_TYPES.SELECT_PLAY_BACK_TIME_SUCCESS, ACTION_TYPES.SELECT_PLAY_BACK_TIME_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const playPropertySettingDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.PLAY_PROPERTY_SETTING,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_PLAY_PROPERTY_REQUEST, ACTION_TYPES.SELECT_PLAY_PROPERTY_SUCCESS, ACTION_TYPES.SELECT_PLAY_PROPERTY_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const videoDownloadFormatDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.VIDEO_DOWNLOAD_FORMAT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_VIDEO_DOWNLOAD_FORMAT_REQUEST, ACTION_TYPES.SELECT_VIDEO_DOWNLOAD_FORMAT_SUCCESS, ACTION_TYPES.SELECT_VIDEO_DOWNLOAD_FORMAT_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const isAutomaticDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.VIDEO_SETTINGS.IS_AUTOMATIC,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_IS_AUTOMATIC_REQUEST, ACTION_TYPES.SELECT_IS_AUTOMATIC_SUCCESS, ACTION_TYPES.SELECT_IS_AUTOMATIC_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const alarmGradeListAll = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.ALARM_GRADE.LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.ALARM_GRADE_LIST_ALL_REQUEST, ACTION_TYPES.ALARM_GRADE_LIST_ALL_SUCCESS, ACTION_TYPES.ALARM_GRADE_LIST_ALL_FAILURE],
            data
        }
    };
};

export const submitAlarmGrade = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.ALARM_GRADE.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_ALARM_GRADE_REQUEST, ACTION_TYPES.UPDATE_ALARM_GRADE_SUCCESS, ACTION_TYPES.UPDATE_ALARM_GRADE_FAILURE],
            data
        }
    };
};

export const diskSpaceDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.DISK_SPACE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_DISK_SPACE_REQUEST, ACTION_TYPES.SELECT_DISK_SPACE_SUCCESS, ACTION_TYPES.SELECT_DISK_SPACE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const diskFullDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.DISK_FULL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_DISK_FULL_REQUEST, ACTION_TYPES.SELECT_DISK_FULL_SUCCESS, ACTION_TYPES.SELECT_DISK_FULL_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const recordingFormatDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.RECORDING_FORMAT,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_RECORDING_FORMAT_REQUEST, ACTION_TYPES.SELECT_RECORDING_FORMAT_SUCCESS, ACTION_TYPES.SELECT_RECORDING_FORMAT_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const recordingTypeDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.RECORDING_TYPE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SELECT_RECORDING_TYPE_REQUEST, ACTION_TYPES.SELECT_RECORDING_TYPE_SUCCESS, ACTION_TYPES.SELECT_RECORDING_TYPE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const submitStorageManagement = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.POST_STORAGE_MANAGEMENT_REQUEST, ACTION_TYPES.POST_STORAGE_MANAGEMENT_SUCCESS, ACTION_TYPES.POST_STORAGE_MANAGEMENT_FAILURE],
            data
        }
    };
};

export const updateStorageManagement = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.SAVE,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.UPDATE_STORAGE_MANAGEMENT_REQUEST, ACTION_TYPES.UPDATE_STORAGE_MANAGEMENT_SUCCESS, ACTION_TYPES.UPDATE_STORAGE_MANAGEMENT_FAILURE],
            data
        }
    };
};

export const storageManagementListAll = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.LIST_ALL,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.STORAGE_MANAGEMENT_LIST_ALL_REQUEST, ACTION_TYPES.STORAGE_MANAGEMENT_LIST_ALL_SUCCESS, ACTION_TYPES.STORAGE_MANAGEMENT_LIST_ALL_FAILURE],
            data
        }
    };
};

export const storageManagementById = (id) => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.BY_ID.replace(":id", id),
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID_REQUEST, ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID_SUCCESS, ACTION_TYPES.STORAGE_MANAGEMENT_BY_ID_FAILURE]
        }
    };
};

export const getDriveList = () => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.DRIVE_LIST,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_DRIVE_LIST_REQUEST, ACTION_TYPES.GET_DRIVE_LIST_SUCCESS, ACTION_TYPES.GET_DRIVE_LIST_FAILURE]
        }
    };
};

export const codeStreamDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.RECORD_PLAN.CODE_STREAM,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_CODE_STREAM_REQUEST, ACTION_TYPES.GET_CODE_STREAM_SUCCESS, ACTION_TYPES.GET_CODE_STREAM_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const timeTemplateDropDown = () => {
    return {
        url: API_URL.SYSTEM_SETUP.RECORD_PLAN.TIME_TEMPLATE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_TIME_TEMPLATE_REQUEST, ACTION_TYPES.GET_TIME_TEMPLATE_SUCCESS, ACTION_TYPES.GET_TIME_TEMPLATE_FAILURE],
            data: { dropDown: true }
        }
    };
};

export const submitRecordPlan = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.RECORD_PLAN.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_RECORD_PLAN_REQUEST, ACTION_TYPES.SAVE_RECORD_PLAN_SUCCESS, ACTION_TYPES.SAVE_RECORD_PLAN_FAILURE],
            data
        }
    };
};

export const submitCopyTo = (data = {}) => {
    return {
        url: API_URL.SYSTEM_SETUP.RECORD_PLAN.COPY_TO,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_COPY_TO_REQUEST, ACTION_TYPES.SAVE_COPY_TO_SUCCESS, ACTION_TYPES.SAVE_COPY_TO_FAILURE],
            data
        }
    };
};

export const getRecordPlanSettingById = (data = {}) => {
    let { channelId, deviceId, streamType, day } = data;
    return {
        url: `${API_URL.SYSTEM_SETUP.RECORD_PLAN.GET_RECORD_PLAN_SETTING_BY_ID}?channelId=${channelId}&deviceId=${deviceId}&streamType=${streamType}&day=${day}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.GET_RECORD_PLAN_SETTING_BY_ID_REQUEST, ACTION_TYPES.GET_RECORD_PLAN_SETTING_BY_ID_SUCCESS, ACTION_TYPES.GET_RECORD_PLAN_SETTING_BY_ID_FAILURE]
        }
    };
};

export const getTimeProgressById = (data = {}) => {
    let { channelId, deviceId, streamType } = data;
    return {
        url: `${API_URL.SYSTEM_SETUP.RECORD_PLAN.GET_TIME_PROGRESS_BY_ID}?channelId=${channelId}&deviceId=${deviceId}&streamTypeId=${streamType}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.TIME_PROGRESS_BY_ID_REQUEST, ACTION_TYPES.TIME_PROGRESS_BY_ID_SUCCESS, ACTION_TYPES.TIME_PROGRESS_BY_ID_FAILURE]
        }
    };
};

export const listCustomizedDetails = (data) => ({
    url: API_URL.SYSTEM_SETUP.RECORD_PLAN.CUSTOMIZED_DEVICE,
    method: REQUEST_METHOD.GET,
    payload: {
        types: [ACTION_TYPES.LIST_CUSTOMIZED_DEVICES_REQUEST, ACTION_TYPES.LIST_CUSTOMIZED_DEVICES_SUCCESS, ACTION_TYPES.LIST_CUSTOMIZED_DEVICES_FAILURE],
        data
    }
});

export const submitFullCycle = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.RECORD_PLAN.SAVE,
        method: REQUEST_METHOD.POST,
        payload: {
            types: [ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE_REQUEST, ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE_SUCCESS, ACTION_TYPES.SAVE_RECORD_PLAN_FULL_CYCLE_FAILURE],
            data
        }
    };
};

export const saveEnableLocalEncoding = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.RECORD_PLAN.ENABLE_LOCAL_ENCODING,
        method: REQUEST_METHOD.PATCH,
        payload: {
            types: [ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING_REQUEST, ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING_SUCCESS, ACTION_TYPES.SAVE_ENABLE_LOCAL_DECODING_FAILURE],
            data
        }
    };
};

export const saveOutput = (data) => {
    const collectionList = Array.isArray(data) ? data.join(",") : data;
    return {
        url: `${API_URL.SYSTEM_SETUP.COMMON_SETTINGS.OUTPUT}?collectionList=${collectionList}`,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.SAVE_OUTPUT_REQUEST, ACTION_TYPES.SAVE_OUTPUT_SUCCESS, ACTION_TYPES.SAVE_OUTPUT_FAILURE]
        }
    };
};

export const saveBackup = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.OUTPUT,
        method: REQUEST_METHOD.FILE,
        payload: {
            types: [ACTION_TYPES.SAVE_BACKUP_REQUEST, ACTION_TYPES.SAVE_BACKUP_SUCCESS, ACTION_TYPES.SAVE_BACKUP_FAILURE],
            data
        }
    };
};

export const saveRecover = (data) => {
    const formData = new FormData();
    formData.append("file", data);
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.RESTORE,
        method: REQUEST_METHOD.POST_FORMDATA,
        payload: {
            types: [ACTION_TYPES.SAVE_RECOVER_REQUEST, ACTION_TYPES.SAVE_RECOVER_SUCCESS, ACTION_TYPES.SAVE_RECOVER_FAILURE],
            data: formData
        }
    };
};

export const saveImport = (data) => {
    const formData = new FormData();
    formData.append("file", data);
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.RESTORE,
        method: REQUEST_METHOD.POST_FORMDATA,
        payload: {
            types: [ACTION_TYPES.SAVE_IMPORT_REQUEST, ACTION_TYPES.SAVE_IMPORT_SUCCESS, ACTION_TYPES.SAVE_IMPORT_FAILURE],
            data: formData
        }
    };
};

export const listBackupMessages = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.COMMON_SETTINGS.LIST_MESSAGES,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.LIST_BACKUP_MESSAGES_REQUEST, ACTION_TYPES.LIST_BACKUP_MESSAGES_SUCCESS, ACTION_TYPES.LIST_BACKUP_MESSAGES_FAILURE],
            data
        }
    };
};

export const restoreDefault = (data) => {
    return {
        url: API_URL.SYSTEM_SETUP.STORAGE_MANAGEMENT.RESTORE,
        method: REQUEST_METHOD.GET,
        payload: {
            types: [ACTION_TYPES.RESTORE_DEFAULT_REQUEST, ACTION_TYPES.RESTORE_DEFAULT_SUCCESS, ACTION_TYPES.RESTORE_DEFAULT_FAILURE],
            data
        }
    };
};
