import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {

    LIST_ALARM_CONFIGURATION: `${STATE_REDUCER_KEY}/LIST_ALARM_CONFIGURATION`,
    LIST_ALARM_CONFIGURATION_REQUEST: `${STATE_REDUCER_KEY}/LIST_ALARM_CONFIGURATION_REQUEST`,
    LIST_ALARM_CONFIGURATION_SUCCESS: `${STATE_REDUCER_KEY}/LIST_ALARM_CONFIGURATION_SUCCESS`,
    LIST_ALARM_CONFIGURATION_FAILURE: `${STATE_REDUCER_KEY}/LIST_ALARM_CONFIGURATION_FAILURE`

};

export const listAlarmConfiguration = createAction(ACTION_TYPES.LIST_ALARM_CONFIGURATION);
