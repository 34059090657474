export const API_URL = {
    DEVICE: {
        ENCODE_LIST: "admin-service/api/auth/v1/crud/model/device/all?deviceType=d2ed1d30-c72b-11ee-b69a-325096b39f47,d2ed1c86-c72b-11ee-bb46-325096b39f47,d2ed1a10-c72b-11ee-8b58-325096b39f47",
        DECODE_LIST: "admin-service/api/auth/v1/crud/model/device/all?deviceType=d2ed1e34-c72b-11ee-8174-325096b39f47",
        DEVICE_MODE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f85c-df69-11ee-91a5-325096b39f47",
        DEVICE_TYPE: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763f94c-df69-11ee-b9c9-325096b39f47",
        DEVICE_PROTOCOL: "admin-service/api/no-auth/v1/crud/model/lookup-values/all?lookupType=2763fa50-df69-11ee-9fbc-325096b39f47",
        SAVE: "admin-service/api/auth/v1/crud/model/device",
        BY_ID: "admin-service/api/auth/v1/crud/model/device/:id",
        DELETE: "admin-service/api/auth/v1/crud/model/device/delete",
        AUTO_SEARCH: "admin-service/api/auth/v1/core/dds/auto/device-discovery",
        ONLINE_OFFLINE_DEVICES: "admin-service/api/auth/v1/crud/model/device/online-offline/device-count ",
        DEVICE_BULK_ADD: "admin-service/api/auth/v1/core/dds/devices/bulk-add"
    },
    GROUP: {
        CUSTOMIZED: "admin-service/api/auth/v1/crud/model/device-group/custom-device-list",
        SAVE_GROUP: "admin-service/api/auth/v1/crud/model/device-group",
        DEVICE_ALL: "admin-service/api/auth/v1/crud/model/device/no-group/all",
        IMPORT: "admin-service/api/auth/v1/crud/model/device/operation",
        MOVE_DOWN: "admin-service/api/auth/v1/crud/model/device-group/:id/move-down",
        MOVE_UPWARD: "admin-service/api/auth/v1/crud/model/device-group/:id/move-up",
        GROUP_BY_ID: "admin-service/api/auth/v1/crud/model/device-group/:id",
        DELETE: "admin-service/api/auth/v1/crud/model/device-group/:id"
    }
};
