import { all, call, fork, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES, navigateTo } from "./actions";
import { getNavigator } from "../user/selectors";
import { handleAPIRequest } from "../../utils/http";
import { fetchSearchDataApi, logoutUserApi, screenLock, screenUnlock } from "./api";
import { actions as commonActions } from "./slice";
import { actions as sliceActions } from "../../modules/home/homeModule/slice";
import { STORAGE_KEYS } from "../../common/constants";
import { actions as playbackSliceActions } from "../../modules/home/playback-persist/slice";
import { downloadFileAsync } from "../../utils/commonUtils";
import { I18n } from "../../common/components";
import { successNotify } from "../../utils/notificationUtils";
import * as Api from "./api";

function* navigateToFn({ payload = "/" }) {
    const navigate = yield select(getNavigator);
    if (navigate instanceof Function) {
        yield navigate(payload);
    } else {
        // eslint-disable-next-line no-console
        console.log("navigate function not found");
    }
}

function* logoutUser({ payload }) {
    let { from = "" } = payload;
    yield fork(handleAPIRequest, logoutUserApi, { clearAll: from === "header" ? true : false });
    yield put(commonActions.clearLive());
    yield put(playbackSliceActions.clearPlayBack());
    if (payload?.type !== "app") {
        yield put(navigateTo("/login"));
    }
    if (from === "header") {
        localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
        yield put(sliceActions.clearAll());
    }
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
    // if (responseAction.type === ACTION_TYPES.LOG_OUT_SUCCESS) {
    //     localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
    //     if (from === "header") {
    //         localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
    //         yield put(sliceActions.clearAll());
    //     }
    //     yield put(commonActions.clearLive());
    //     yield put(playbackSliceActions.clearPlayBack());
    //     if (payload?.type !== "app") {
    //         yield put(navigateTo("/login"));
    //     }
    // }
}

function* fetchSearchData(action) {
    let { data } = action?.payload || "";
    yield call(handleAPIRequest, fetchSearchDataApi, data);

}

function* fetchUnlockScreen({ payload }) {
    let sessionId = localStorage.getItem(STORAGE_KEYS.SESSION_ID);
    let updatedPayload = { ...payload, sessionId };
    yield fork(handleAPIRequest, screenUnlock, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.FETCH_UNLOCK_SCREEN_SUCCESS, ACTION_TYPES.FETCH_UNLOCK_SCREEN_FAILURE]);
    if (responseAction.type === ACTION_TYPES.FETCH_UNLOCK_SCREEN_SUCCESS) {
        yield put(commonActions.setLock(false));
    }
}

function* fetchLockScreen({ payload }) {
    let sessionId = localStorage.getItem(STORAGE_KEYS.SESSION_ID);
    let updatedPayload = { ...payload, sessionId };
    yield fork(handleAPIRequest, screenLock, updatedPayload);
    const responseAction = yield take([ACTION_TYPES.FETCH_LOCK_SCREEN_SUCCESS, ACTION_TYPES.FETCH_LOCK_SCREEN_SUCCESS]);
    if (responseAction.type === ACTION_TYPES.FETCH_LOCK_SCREEN_SUCCESS) {
        yield put(commonActions.setLock(true));
    }
}

export function* commonDownloadFile({ payload }) {
    let { url, file, data = {} } = payload;
    yield put(commonActions.setStatusModal({ isOpen: true, content: I18n("downloading_file", { file: I18n(file.name || "download") }) }));
    yield fork(handleAPIRequest, Api.commonDownloadFile, { url, data });
    const response = yield take([ACTION_TYPES.COMMON_DOWNLOAD_FILE_SUCCESS, ACTION_TYPES.COMMON_DOWNLOAD_FILE_FAILURE]);
    if (response.type === ACTION_TYPES.COMMON_DOWNLOAD_FILE_SUCCESS) {
        yield call(downloadFileAsync, { response: response.payload, file });
        yield put(commonActions.resetStatusModal());
        yield put(successNotify({ title: I18n("successfully"), message: I18n("downloaded") }));
    } else if (response.type === ACTION_TYPES.COMMON_DOWNLOAD_FILE_FAILURE) {
        yield put(commonActions.resetStatusModal());
    }
}

export default function* commonSaga() {
    yield all([
        yield takeEvery(ACTION_TYPES.NAVIGATE_TO, navigateToFn),
        yield takeEvery(ACTION_TYPES.LOG_OUT, logoutUser),
        yield takeLatest(ACTION_TYPES.FETCH_SEARCH_DATA, fetchSearchData),
        yield takeLatest(ACTION_TYPES.FETCH_UNLOCK_SCREEN, fetchUnlockScreen),
        yield takeLatest(ACTION_TYPES.FETCH_LOCK_SCREEN, fetchLockScreen),
        yield takeLatest(ACTION_TYPES.COMMON_DOWNLOAD_FILE, commonDownloadFile)
    ]);
}
