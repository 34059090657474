import { store } from "../app/store";

export const getUserDetails = () => {
    let { register: { userDetails: { resourceId = {} } = {} } = {} } = store.getState();
    return resourceId || {};
};

export const getActiveUserType = () => {
    let { data: { activeProfile: { userType = {} } = {} } = {} } = getUserDetails();
    return userType || {};
};

export const getActiveUserId = () => {
    let { data: { activeProfile: { userId } = {} } = {} } = getUserDetails();
    return userId;
};
