import { notify } from "reapop";
import Swal from "sweetalert2";
import palette from "../common/themes/palette.json";
import { I18n } from "../common/components";
import { useSelector } from "react-redux";
import { STATE_REDUCER_KEY } from "../modules/common/constants";
import "./style.css";

const NOTIFICATION_PROPS = {
    // id: "NOTIFICATION",
    title: "",
    message: "",
    dismissible: false,
    dismissAfter: 2500,
    position: "top-right",
    allowHTML: true
};

export const successNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "success" });

export const infoNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "info" });

export const warningNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "warning", dismissAfter: 4000 });

export const errorNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "error", dismissAfter: 4000 });

export const loderNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "loading" });

export const useConfirmDialog = () => {
    const themes = useSelector(state => state[STATE_REDUCER_KEY].switch.data);

    const showDialog = (payload = {}) => {
        const titleClass = themes === "darkTheme" ? "custom-title-dark" : "custom-title-light";

        return Swal.fire({
            title: `${I18n("are_you_sure")} ?`,
            showDenyButton: true,
            confirmButtonText: I18n("ok"),
            denyButtonText: I18n("cancel"),
            denyButtonColor: palette.palette.primary.main,
            confirmButtonColor: palette.palette.dark.main,
            background: themes === "darkTheme" ? "#526D82" : "#F9F6EE",
            customClass: {
                title: titleClass
            },
            ...payload
        });
    };

    return showDialog;
};

export const confirmDialog = (payload = {}) => Swal.fire({
    title: `${I18n("are_you_sure")} ?`, showDenyButton: true, confirmButtonText: I18n("ok"), denyButtonText: I18n("cancel"), denyButtonColor: palette.palette.error.main,
    confirmButtonColor: palette.palette.purple.main, ...payload
});
